import React, { useEffect, useState } from 'react';
import { Autocomplete, AutocompleteRenderGetTagProps, Chip, styled, TextField } from '@mui/material';
import useTags from '../../hooks/useTags';

import palette from '../../theme/palette';




export const TagsDropDown = styled(Autocomplete)(() => ({

  minWidth: "160px",
  '& .MuiFormControl-root': {
    margin: 0
  },
  '& .MuiTextField-root': {
    padding: 0
  },
  '& .MuiAutocomplete-inputRoot': {
    height: '40px'
  },
  '& .MuiInputBase-root': {
    padding: 0,
    display: 'flex',
    alignItems: 'center'
  },
  '& .MuiInputBase-input::placeholder': {
    fontSize: '14px',
    opacity: 1,
    fontFamily: "'Plus Jakarta Sans'"
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  }
}));

export const TagsDropDownTextInputSelect = styled(TextField)(() => ({
  border: 'none',
  resize: 'none',
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
    padding: 0,
    border: `0.5px solid ${palette.border.grey}`,
    backgroundColor: palette.background.paper,

    '& .MuiOutlinedInput-input': {
      padding: 2,
      paddingLeft: 5,
      '&::placeholder': {
        color: palette.grey[600],
        fontSize: '13px',
        fontFamily: "'Plus Jakarta Sans'"
      }
    },
    '&:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none'
      }
    }
  }
}));



interface TagsSelectorProps {
  tags: string[];
  setTags?: (tags: string[]) => void;

}


const TagsSelector = ({
  tags,
  setTags
}: TagsSelectorProps) => {
  const [tagList, setTagList] = useState<string[]>([]);
  const { accountTags } = useTags();

  useEffect(() => {
    if (accountTags) {
      setTagList(accountTags.tags);
    }
  }, [accountTags, setTagList]);


  return (


    <TagsDropDown
      noOptionsText="No available tags"
      options={tagList}
      freeSolo
      multiple
      disableClearable
      getOptionLabel={(option: string) => option}
      renderOption={(props: React.HTMLAttributes<HTMLLIElement>,
        option: string) =>
      (
        <li {...props} key={option}>
          {option}
        </li>
      )
      }
      onChange={(event: React.SyntheticEvent, newValue: string[] | null) => setTags(newValue)}
      value={tags}
      renderInput={params => <TagsDropDownTextInputSelect {...params} placeholder="Type and enter Tags" />}
      renderTags={(value: string[], getTagProps: AutocompleteRenderGetTagProps) =>

        value?.map((tag, index) =>
          <Chip
            {...getTagProps({ index })}
            label={tag}
            sx={{
              borderRadius: "8px",
              backgroundColor: palette.blue[8],
              color: palette.common.darkBlue
            }}
            variant="outlined"
            key={tag} />)}

    />



  );
};

export default TagsSelector;
