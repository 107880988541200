import { createTheme, Theme as MuiTheme } from '@mui/material';
import { Shadows } from '@mui/material/styles/shadows';

import components from './components';
import palette from './palette';
import typography from './typography';

declare module '@emotion/react' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface Theme extends MuiTheme {}
}

export default createTheme({
  components,
  typography,
  palette,
  palette: palette,
  shadows: [
    'none',
    '0px 3px 15px rgba(216, 217, 217, 0.5)',
    '0px 4px 18px rgba(216, 217, 217, 0.8)',
    '0px 0px 15px rgba(97, 97, 97, 0.42)',
    ...Array(21).fill('none')
  ] as Shadows
});
