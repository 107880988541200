import isPropValid from '@emotion/is-prop-valid';
import { KeyboardArrowDown } from '@mui/icons-material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CheckIcon from '@mui/icons-material/Check';
import { Box, Stack, Typography, styled } from '@mui/material';

import palette from '../../../../theme/palette';

export const InnitiativeViewContainer = styled(Stack)(({ theme }) => ({
  width: '100%',
  flexDirection: 'row',
  alignItems: 'center',
  paddingTop: 10,
  paddingBottom: 10,
  cursor: 'pointer',
  borderBottom: `1px solid ${theme.palette.border.grey}`,
  '&:hover': { background: theme.palette.background.grey }
}));

export const ArrowDownIcon = styled(KeyboardArrowDown)<{ open: boolean }>(({ open }) => ({
  transform: open && 'rotate(180deg)',
  transition: '.3s ease-in-out'
}));

export const InitiativeDescription = styled(Typography, { shouldForwardProp: isPropValid })<{
  completed: boolean;
}>(({ completed }) => ({
  fontSize: '15px',
  fontWeight: 500,
  fontFamily: 'Plus Jakarta Sans',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  maxWidth: '300px',
  color: completed ? palette.grey[2000] : palette.common.darkBlue
}));

export const BusinessAreaTitle = styled(Typography)(() => ({
  fontSize: '16px',
  fontWeight: 800,
  color: palette.common.darkBlue
}));

export const Title = styled(Typography)(() => ({
  width: '30%',
  fontSize: '12px',
  color: palette.grey[800]
}));

export const ExportButton = styled(Stack)(() => ({
  marginTop: '5px',
  color: palette.primary.main,
  flexDirection: 'row',
  alignItems: 'center',
  alignSelf: 'end',
  cursor: 'pointer',
  ':hover': { opacity: 0.6 }
}));

export const ExportButtonText = styled(Typography)(() => ({
  fontFamily: 'Plus Jakarta Sans',
  fontSize: '14px'
}));

export const InitiativeInfo = styled(Stack)(() => ({
  minWidth: 'max-content'
}));

export const BigPrioritySelectionOptionContainer = styled(Stack, {
  shouldForwardProp: isPropValid
})<{
  backgroundColor: string;
}>(({ backgroundColor }) => ({
  background: backgroundColor,
  flexDirection: 'row',
  alignItems: 'center',
  gap: 1,
  width: '80px',
  height: '20px',
  borderRadius: '16px'
}));

export const SelectionTitle = styled(Typography)({
  fontFamily: 'Plus Jakarta Sans',
  fontWeight: 500,
  fontSize: '13px',
  color: palette.common.darkBlue,
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden'
});

export const CheckCircle = styled(Box, { shouldForwardProp: isPropValid })<{
  checkedColor: string;
}>(({ checkedColor }) => ({
  background: 'white',
  borderRadius: '50%',
  height: '12px',
  width: '12px',
  border: `4px solid ${checkedColor}`,
  margin: '6px'
}));

export const TimeframeContainer = styled(Stack)({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '5px 8px',
  gap: '10px',
  width: '110px',
  height: '26px',
  background: palette.blue[8],
  borderRadius: '4px'
});

export const DoneIcon = styled(CheckIcon)({
  color: palette.green[1]
});

export const StatusCircle = styled(Box, { shouldForwardProp: isPropValid })<{
  circleColor: string;
}>(({ circleColor }) => ({
  background: circleColor,
  borderRadius: '50%',
  height: '7px',
  width: '7px'
}));

export const StatusTitle = styled(Typography)({
  fontFamily: 'Plus Jakarta Sans',
  fontWeight: 500,
  fontSize: '12px',
  color: palette.grey[1100],
  marginLeft: '7px'
});

export const ActionItemCountTitle = styled(Typography)({
  fontFamily: 'Plus Jakarta Sans',
  fontWeight: 500,
  fontSize: '13px',
  color: palette.grey[1100]
});

export const TimeframeText = styled(Typography)({
  fontFamily: 'Plus Jakarta Sans',
  fontWeight: 600,
  fontSize: '12px',
  color: palette.grey[1100]
});

export const IconContainer = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  width: '34px',
  height: '34px',
  borderRadius: '8px',
  color: theme.palette.blue[9]
}));

export const AddIcon = styled(AddCircleIcon, { shouldForwardProp: isPropValid })<{
  enabled: boolean;
}>(({ theme, enabled }) => ({
  color: enabled ? theme.palette.green[1] : theme.palette.grey[1],
  fontSize: '22px'
}));

export const AddButtonText = styled(Typography, { shouldForwardProp: isPropValid })(
  ({ theme }) => ({
    color: theme.palette.green[1],
    fontFamily: 'Plus Jakarta Sans',
    fontWeight: 700,
    fontSize: '12px',
    cursor: 'pointer'
  })
);

export const GoalDescription = styled(Typography)(({ theme }) => ({
  fontFamily: 'Plus Jakarta Sans',
  fontSize: '12px',
  color: theme.palette.common.darkBlue
}));
