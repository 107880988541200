import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Input, InputAdornment } from '@mui/material';

import palette from '../../theme/palette';
import { ReactComponent as SearchIcon } from './assets/search.svg';

export interface SearchInputProps {
  onChange: (value: string) => void;
  value?: string;
  defaultValue?: string;
  name?: string;
  placeholder?: string;
  height?: string;
  noBorder?: boolean;
}

const SearchInput = ({
  value,
  defaultValue,
  name = '',
  height,
  placeholder = '',
  noBorder,
  onChange
}: SearchInputProps) => (
  <Input
    disableUnderline
    autoComplete="off"
    name={name}
    type="search"
    value={value}
    defaultValue={defaultValue}
    placeholder={placeholder}
    sx={{
      '& .MuiInputBase-input': {
        fontFamily: "'Plus Jakarta Sans'",
        fontWeight: 600,
        fontSize: '13px',
        color: palette.common.darkBlue,
        padding: 0
      },
      '& .MuiInputBase-input::placeholder': {
        fontFamily: "'Plus Jakarta Sans'",
        fontWeight: 600,
        fontSize: '13px',
        color: palette.common.darkBlue,
        opacity: 0.3
      },
      '& ::-webkit-search-cancel-button': {
        WebkitAppearance: 'none'
      },
      padding: '12px 16px',
      height: height ? height : '42px',
      width: '241px',
      background: '#FFFFFF',
      border: noBorder ? "none" : '1px solid #EFF3F4',
      borderRadius: '8px'
    }}
    startAdornment={
      <InputAdornment position="start">
        <SearchIcon />
      </InputAdornment>
    }
    endAdornment={
      <InputAdornment position="end">
        <CloseIcon
          sx={{
            opacity: value ? 1 : 0,
            transition: 'opacity 0.3s ease-in-out',
            pointerEvents: value ? 'poiner' : 'none',
            cursor: 'pointer'
          }}
          onClick={() => onChange('')}
        />
      </InputAdornment>
    }
    onChange={e => onChange(e.target.value)}
  />
);

export default SearchInput;
