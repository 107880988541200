import React from 'react';

export const LazyUserProfile = React.lazy(() =>
  Promise.all([
    import('./UserProfile/UserProfile'),
    new Promise(resolve => setTimeout(resolve, 300))
  ]).then(([moduleExports]) => moduleExports)
);

export const LazyWhiteboard = React.lazy(() =>
  Promise.all([
    import('./Whiteboard/Whiteboard'),
    new Promise(resolve => setTimeout(resolve, 300))
  ]).then(([moduleExports]) => moduleExports)
);

export const LazyWhiteboards = React.lazy(() =>
  Promise.all([
    import('./Whiteboard/Whiteboards'),
    new Promise(resolve => setTimeout(resolve, 300))
  ]).then(([moduleExports]) => moduleExports)
);
// Lazy loaded route components - almost as lazy as Lea.
export const LazyChooseAccount = React.lazy(() => import('./ChooseAccount/ChooseAccount'));

export const LazyBusinessAreas = React.lazy(() =>
  Promise.all([
    import('./BusinessArea/BusinessAreas'),
    new Promise(resolve => setTimeout(resolve, 300))
  ]).then(([moduleExports]) => moduleExports)
);

export const LazyStrategyCenter = React.lazy(() =>
  Promise.all([
    import('./StrategyCenter/StrategyCenter'),
    new Promise(resolve => setTimeout(resolve, 300))
  ]).then(([moduleExports]) => moduleExports)
);

export const LazyBusinessArea = React.lazy(() =>
  Promise.all([
    import('./BusinessArea/components/BusinessArea/BusinessArea'),
    new Promise(resolve => setTimeout(resolve, 300))
  ]).then(([moduleExports]) => moduleExports)
);

export const LazyStrategyDrilldown = React.lazy(() =>
  Promise.all([
    import('./BizAreaModules/DrillDown'),
    new Promise(resolve => setTimeout(resolve, 300))
  ]).then(([moduleExports]) => moduleExports)
);

export const LazyAccountSettings = React.lazy(() =>
  Promise.all([
    import('./AccountSettings/AccountSettings'),
    new Promise(resolve => setTimeout(resolve, 300))
  ]).then(([moduleExports]) => moduleExports)
);

export const LazyIntegrations = React.lazy(() =>
  Promise.all([
    import('./Integrations/IntegrationsCenter/IntegrationsCenter'),
    new Promise(resolve => setTimeout(resolve, 300))
  ]).then(([moduleExports]) => moduleExports)
);

export const LazyActionPlan = React.lazy(() =>
  Promise.all([
    import('./ActionPlan/ActionPlanNew'),
    new Promise(resolve => setTimeout(resolve, 300))
  ]).then(([moduleExports]) => moduleExports)
);

export const LazyAskJourney = React.lazy(() =>
  Promise.all([
    import('./AskJourney/AskJourney'),
    new Promise(resolve => setTimeout(resolve, 300))
  ]).then(([moduleExports]) => moduleExports)
);

export const LazyCompetitiveBigGoal = React.lazy(() =>
  Promise.all([
    import('./BizAreaModules/BigGoal'),
    new Promise(resolve => setTimeout(resolve, 300))
  ]).then(([moduleExports]) => moduleExports)
);

export const LazyCompetitiveAdvantages = React.lazy(() =>
  Promise.all([
    import('./BizAreaModules/CompetitiveAdvantages'),
    new Promise(resolve => setTimeout(resolve, 300))
  ]).then(([moduleExports]) => moduleExports)
);

export const LazyCompetitiveLandscape = React.lazy(() =>
  Promise.all([
    import('./BizAreaModules/CompetitiveLandscape/CompetitiveLandscape'),
    new Promise(resolve => setTimeout(resolve, 300))
  ]).then(([moduleExports]) => moduleExports)
);

export const LazyPlayground = React.lazy(() =>
  Promise.all([
    import('./BizAreaModules/Playground'),
    new Promise(resolve => setTimeout(resolve, 300))
  ]).then(([moduleExports]) => moduleExports)
);

export const LazyPrioritizationMap = React.lazy(() =>
  Promise.all([
    import('./BizAreaModules/PrioritizationMap'),
    new Promise(resolve => setTimeout(resolve, 300))
  ]).then(([moduleExports]) => moduleExports)
);

export const LazyRoadmap = React.lazy(() =>
  Promise.all([
    import('./BizAreaModules/Roadmap/Roadmap'),
    new Promise(resolve => setTimeout(resolve, 300))
  ]).then(([moduleExports]) => moduleExports)
);

export const LazyStrategyBusinessArea = React.lazy(() =>
  Promise.all([
    import('./BusinessArea/components/BusinessArea/StrategyBusinessArea'),
    new Promise(resolve => setTimeout(resolve, 300))
  ]).then(([moduleExports]) => moduleExports)
);

export const LazyForum = React.lazy(() =>
  Promise.all([import('./Forum/Forum'), new Promise(resolve => setTimeout(resolve, 300))]).then(
    ([moduleExports]) => moduleExports
  )
);

export const LazyPostView = React.lazy(() =>
  Promise.all([import('./Forum/PostView'), new Promise(resolve => setTimeout(resolve, 300))]).then(
    ([moduleExports]) => moduleExports
  )
);

export const LazyMeetingView = React.lazy(() =>
  Promise.all([
    import('./Meetings/MeetingView/MeetingView'),
    new Promise(resolve => setTimeout(resolve, 300))
  ]).then(([moduleExports]) => moduleExports)
);

export const LazyMeetings = React.lazy(() => import('./Meetings/Meetings'));
React.lazy(() =>
  Promise.all([
    import('./Meetings/Meetings'),
    new Promise(resolve => setTimeout(resolve, 300))
  ]).then(([moduleExports]) => moduleExports)
);



export const LazyMissionAndVision = React.lazy(() =>
  Promise.all([
    import('./BizAreaModules/MissionAndVision'),
    new Promise(resolve => setTimeout(resolve, 300))
  ]).then(([moduleExports]) => moduleExports)
);

export const LazySharedWorkspacesManagement = React.lazy(() =>
  Promise.all([
    import('./SharedWorkspacesManagement/SharedWorkspacesManagement'),
    new Promise(resolve => setTimeout(resolve, 300))
  ]).then(([moduleExports]) => moduleExports)
);


export const LazySpaceMeeting = React.lazy(() =>
  Promise.all([
    import('./Space/SpaceMeeting'),
    new Promise(resolve => setTimeout(resolve, 300))
  ]).then(([moduleExports]) => moduleExports)
);

export const LazyMetricsManagement = React.lazy(() =>
  Promise.all([
    import('./MetricsManagement/MetricsManagement'),
    new Promise(resolve => setTimeout(resolve, 300))
  ]).then(([moduleExports]) => moduleExports)
);

export const LazyHubspotAuth = React.lazy(() =>
  Promise.all([
    import('./Integrations/HubspotAuth'),
    new Promise(resolve => setTimeout(resolve, 300))
  ]).then(([moduleExports]) => moduleExports)
);

export const LazyQuickBooksAuth = React.lazy(() =>
  Promise.all([
    import('./Integrations/QuickBooksAuth'),
    new Promise(resolve => setTimeout(resolve, 300))
  ]).then(([moduleExports]) => moduleExports)
);

export const LazyGoogleAuth = React.lazy(() =>
  Promise.all([
    import('./Integrations/GoogleAuth'),
    new Promise(resolve => setTimeout(resolve, 300))
  ]).then(([moduleExports]) => moduleExports)
);

export const LazySpace = React.lazy(() =>
  Promise.all([import('./Space/Space'), new Promise(resolve => setTimeout(resolve, 300))]).then(
    ([moduleExports]) => moduleExports
  )
);
