/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * OpenAPI definition
 * OpenAPI spec version: v0
 */
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query';

import { useCustomAxios } from '../services/customAxios';

export type GetActionItemsSuggestionParams = {
  initiativeId: string;
};

export type GetInitiativeSuggestionParams = {
  objectiveId: string;
};

export type ListActionPlanDetailedObjectivesParams = {
  businessArea?: string;
  type?: string;
  boardId?: string;
  includeArchived?: boolean;
  ownerIds?: string;
  objectiveIds?: string;
};

export type GetActionPlanDetailedObjectiveParams = {
  includeArchived?: boolean;
};

export type ListAppNotificationsParams = {
  unreadOnly?: boolean;
};

export type VerifySignUpParams = {
  token: string;
};

export type GetDataProviderLatestRunsParams = {
  type: string;
};

export type ListDataSourceTypesParams = {
  providerType?: string;
};

export type ListDataSourceFieldMappingsParams = {
  providerType?: string;
};

export type FetchUpcomingGoogleCalendarEventParams = {
  userId?: string;
};

export type FetchGoogleCalendarEventsParams = {
  recurringMetadata: boolean;
};

export type GetMeetingConnectionParams = {
  id?: string;
};

export type AggregateMetricDataPointsParams = {
  period: string;
  summarize?: string;
};

export type ListModulesAnalysisParams = {
  businessArea: string;
};

export type GetSharedWorkspaceMeetingMediaParams = {
  mediaType: string;
};

export type ListSharedWorkspacePointsParams = {
  status?: string;
};

export type ListWhiteboardsIdsParams = {
  linkedType?: string;
  linkedId?: string;
};

export type SearchActionPlanActionItemsParams = {
  includeArchived?: boolean;
};

export type ListActionPlanActionItemsParams = {
  initiativeId?: string;
  sprint?: string;
  from?: number;
  to?: number;
  includeArchived?: boolean;
  userIds?: string;
  initiativeIds?: string;
};

export type ListActionPlanBoardsParams = {
  includeArchived?: boolean;
};

export type SearchActionPlanInitiativeDetailedParams = {
  includeArchived?: boolean;
};

export type SearchActionPlanInitiativesParams = {
  includeArchived?: boolean;
};

export type ListActionPlanInitiativesParams = {
  objectiveId?: string;
  includeArchived?: boolean;
  objectiveIds?: string;
};

export type SearchActionPlanDetailedObjectivesParams = {
  includeArchived: boolean;
};

export type SearchActionPlanObjectivesParams = {
  includeArchived?: boolean;
};

export type ListActionPlanObjectivesParams = {
  businessArea?: string;
  type?: string;
  includeArchived?: boolean;
};

export type ListCommentsParams = {
  postId: string;
};

export type ListPostsParams = {
  limit?: number;
};

export type ListDataProvidersParams = {
  isOwnedByUser?: boolean;
};

export type ListMetricDefinitionsParams = {
  nonActionPlan?: boolean;
  includeArchived?: boolean;
};

export type UploadMetricTargetsBody = {
  file: Blob;
};

export type UploadMetricValuesBody = {
  file: Blob;
};

export type CreateUserParams = {
  assigneeUser?: boolean;
};

export type ListUsersParams = {
  includeStatus?: boolean;
};

export type DeleteFileParams = {
  key: string;
};

export type GenerateUploadUrlParams = {
  key: string;
};

export type ListModuleIdeasParams = {
  moduleId?: string;
  businessArea?: string;
};

export type GetModulesMetadataParams = {
  businessArea?: string;
  moduleIds?: string;
};

export type ListModulesPrioritizationParams = {
  businessArea: string;
};

export type ListNotesParams = {
  from?: number;
  to?: number;
};

export type UploadGoogleDriveFileBody = {
  file: Blob;
};

export type UploadGoogleDriveFileParams = {
  folderName?: string;
};

export type ListGoogleDriveFilesParams = {
  folderName?: string;
};

export type ListAllSpaceMeetingNotesParams = {
  spaceId: string;
};

export type SearchSharedWorkspacePointsParams = {
  type?: string;
  includeResolved?: boolean;
};

export type ListWhiteboardsParams = {
  linkedType?: string;
  linkedId?: string;
};

export type DuplicateActionPlanInitiativeParams = {
  objectiveId?: string;
};

export type DeleteActionPlanInitiativeParams = {
  deleteMetric?: boolean;
};

export type DuplicateActionPlanObjectiveParams = {
  boardId?: string;
  businessArea?: string;
};

export type DeleteActionPlanObjectiveParams = {
  deleteMetric?: boolean;
};

export type UpdateUserPhotoBody = {
  photo: Blob;
};

export type GenerateDownloadUrlParams = {
  key: string;
};

export interface LastUpdatedInitiativeDto {
  id: string;
  title?: string;
  description?: string;
  lastUpdatedAt?: string;
}

export interface LastUpdatedInitiativeResponse {
  kind?: string;
  items?: LastUpdatedInitiativeDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export interface ActionPlanSuggestionsDto {
  suggestions?: string[];
}

export interface ActionPlanSuggestionResponse {
  kind?: string;
  items?: ActionPlanSuggestionsDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export type AppNotificationDtoStatus =
  typeof AppNotificationDtoStatus[keyof typeof AppNotificationDtoStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppNotificationDtoStatus = {
  read: 'read',
  unread: 'unread'
} as const;

export type AppNotificationDtoNamespace =
  typeof AppNotificationDtoNamespace[keyof typeof AppNotificationDtoNamespace];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppNotificationDtoNamespace = {
  general: 'general',
  actionPlan: 'actionPlan',
  meetings: 'meetings',
  connect: 'connect'
} as const;

export interface AppNotificationDto {
  id?: string;
  namespace?: AppNotificationDtoNamespace;
  status?: AppNotificationDtoStatus;
  title?: string;
  description?: string;
  link?: string;
  userId?: string;
  createdAt?: string;
}

export interface AppNotificationResponse {
  kind?: string;
  items?: AppNotificationDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export interface AskJourneyAnswerResponse {
  kind?: string;
  items?: AskJourneyAnswerDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export type DataSourceTypeResponseItemsItem =
  typeof DataSourceTypeResponseItemsItem[keyof typeof DataSourceTypeResponseItemsItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DataSourceTypeResponseItemsItem = {
  deals: 'deals',
  companies: 'companies',
  contacts: 'contacts',
  tickets: 'tickets',
  bill: 'bill',
  customer: 'customer',
  payment: 'payment',
  invoice: 'invoice',
  googleCalendarEvent: 'googleCalendarEvent'
} as const;

export interface DataSourceTypeResponse {
  kind?: string;
  items?: DataSourceTypeResponseItemsItem[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export interface DataSourceFiledOptionDto {
  value?: string;
  hidden?: boolean;
  displayName?: string;
}

export interface DataSourceFieldMappingDto {
  name?: string;
  type?: string;
  displayName?: string;
  options?: DataSourceFiledOptionDto[];
}

export interface DataSourceFieldMappingResponse {
  kind?: string;
  items?: DataSourceFieldMappingDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export interface CollectorLatestRunDto {
  providerId?: string;
  dataSourceId?: string;
  type?: string;
  updatedAt?: string;
}

export interface CollectorLatestRunResponse {
  kind?: string;
  items?: CollectorLatestRunDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export type GoogleCalendarEventDtoExtendedProperties = { [key: string]: { [key: string]: any } };

export interface GoogleCalendarEventDto {
  id?: string;
  status?: string;
  summary?: string;
  description?: string;
  start?: string;
  end?: string;
  location?: string;
  organizer?: string;
  attendees?: string[];
  htmlLink?: string;
  kind?: string;
  created?: string;
  updated?: string;
  recurrence?: string[];
  conferenceData?: ConferenceData;
  extendedProperties?: GoogleCalendarEventDtoExtendedProperties;
  icalUID?: string;
}

export interface GoogleCalendarEventResponse {
  kind?: string;
  items?: GoogleCalendarEventDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export interface EntryPoint {
  entryPointType?: string;
  uri?: string;
  label?: string;
  pin?: string;
  accessCode?: string;
  meetingCode?: string;
  passcode?: string;
  password?: string;
  regionCode?: string;
}

export interface CreateRequest {
  requestId?: string;
  status?: string;
}

export interface ConferenceSolution {
  iconUri?: string;
  key?: string;
  name?: string;
}

export interface ConferenceData {
  conferenceId?: string;
  signature?: string;
  entryPoints?: EntryPoint[];
  conferenceSolution?: ConferenceSolution;
  createRequest?: CreateRequest;
}

export interface MeetingConnectionDto {
  accessToken?: string;
}

export interface MeetingConnectionResponse {
  kind?: string;
  items?: MeetingConnectionDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export interface MeetingRecordingDto {
  recordingUrl?: string;
  disabled?: boolean;
}

export interface MeetingRecordingResponse {
  kind?: string;
  items?: MeetingRecordingDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export interface MeetingTranscriptDto {
  transcriptUrl?: string;
  disabled?: boolean;
}

export interface MeetingTranscriptResponse {
  kind?: string;
  items?: MeetingTranscriptDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export interface MeetingResponse {
  kind?: string;
  items?: MeetingDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export interface MetricAggregationDataPointDto {
  date?: string;
  value?: number;
}

export interface MetricAggregationDataPointResponse {
  kind?: string;
  items?: MetricAggregationDataPointDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export interface OkrMetricResponse {
  kind?: string;
  items?: OkrMetricDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export type MetricSuggestionDtoUnit =
  typeof MetricSuggestionDtoUnit[keyof typeof MetricSuggestionDtoUnit];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MetricSuggestionDtoUnit = {
  number: 'number',
  percentage: 'percentage',
  currency: 'currency',
  ratio: 'ratio'
} as const;

export interface MetricSuggestionDto {
  name: string;
  unit?: MetricSuggestionDtoUnit;
}

export interface MetricSuggestionResponse {
  kind?: string;
  items?: MetricSuggestionDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export type UserFeatureAccess = typeof UserFeatureAccess[keyof typeof UserFeatureAccess];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserFeatureAccess = {
  block: 'block',
  view: 'view',
  edit: 'edit'
} as const;

export type UserFeatureFeature = typeof UserFeatureFeature[keyof typeof UserFeatureFeature];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserFeatureFeature = {
  'businessArea:board': 'businessArea:board',
  'businessArea:executive': 'businessArea:executive',
  'businessArea:market': 'businessArea:market',
  'businessArea:competition': 'businessArea:competition',
  'businessArea:strategy': 'businessArea:strategy',
  'businessArea:strategicPlanning': 'businessArea:strategicPlanning',
  'businessArea:sales': 'businessArea:sales',
  'businessArea:marketing': 'businessArea:marketing',
  'businessArea:customerSuccess': 'businessArea:customerSuccess',
  'businessArea:product': 'businessArea:product',
  'businessArea:people': 'businessArea:people',
  'businessArea:finance': 'businessArea:finance',
  'businessArea:operations': 'businessArea:operations',
  'businessArea:esg': 'businessArea:esg',
  workspace: 'workspace',
  'workspace:recentlyWorkedOn': 'workspace:recentlyWorkedOn',
  'workspace:createDriveFile': 'workspace:createDriveFile',
  drive: 'drive',
  meetings: 'meetings',
  actionPlan: 'actionPlan',
  ask: 'ask',
  metrics: 'metrics',
  settings: 'settings'
} as const;

export interface UserFeature {
  feature?: UserFeatureFeature;
  access?: UserFeatureAccess;
}

export type UserDetailsType = typeof UserDetailsType[keyof typeof UserDetailsType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserDetailsType = {
  user: 'user',
  assigneeUser: 'assigneeUser',
  ceo: 'ceo',
  jp: 'jp',
  admin: 'admin',
  superAdmin: 'superAdmin',
  internal: 'internal'
} as const;

export interface UserDetails {
  id?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  type?: UserDetailsType;
  roleId?: string;
  imageUrl?: string;
  accounts?: AccountDetails[];
  currentAccountId?: number;
  features?: UserFeature[];
}

export interface UserDetailsResponse {
  kind?: string;
  items?: UserDetails[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export interface Range {
  from?: number;
  to?: number;
}

export interface AccountDetails {
  id: number;
  name?: string;
  logoUrl?: string;
  industry?: string;
  ceoName?: string;
  subIndustry?: string;
  employeesRange?: Range;
  location?: string;
  config?: AccountConfigDto;
}

export interface BusinessAreaRatingDto {
  businessArea?: string;
  rating?: number;
}

export interface BusinessAreaRatingResponse {
  kind?: string;
  items?: BusinessAreaRatingDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export interface ModuleRatingDto {
  businessArea?: string;
  module?: string;
  rating?: number;
}

export interface ModuleRatingResponse {
  kind?: string;
  items?: ModuleRatingDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export interface ModuleDriveFileDto {
  id: string;
  fileUrl?: string;
}

export interface ModuleDriveFileResponse {
  kind?: string;
  items?: ModuleDriveFileDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export interface ModuleAnalysisResponse {
  kind?: string;
  items?: ModuleAnalysisDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export type LastUsedModuleDtoBusinessArea =
  typeof LastUsedModuleDtoBusinessArea[keyof typeof LastUsedModuleDtoBusinessArea];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LastUsedModuleDtoBusinessArea = {
  ceo: 'ceo',
  board: 'board',
  strategicPlanning: 'strategicPlanning',
  strategy: 'strategy',
  finance: 'finance',
  people: 'people',
  product: 'product',
  marketing: 'marketing',
  sales: 'sales',
  customerSuccess: 'customerSuccess',
  operations: 'operations',
  market: 'market',
  competition: 'competition',
  partnerships: 'partnerships',
  suppliers: 'suppliers',
  esg: 'esg'
} as const;

export interface LastUsedModuleDto {
  id: string;
  businessArea?: LastUsedModuleDtoBusinessArea;
  lastUsedAt?: string;
}

export interface LastUsedModuleResponse {
  kind?: string;
  items?: LastUsedModuleDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

/**
 * Entity
 */
export interface BaseModuleData {
  id: string;
}

export interface ModuleDataDto {
  id: string;
  keyQuestions?: string[];
  data?: BaseModuleData;
  updatedAt?: string;
}

export interface ModuleResponse {
  kind?: string;
  items?: ModuleDataDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export interface DriveFileDto {
  id: string;
  link?: string;
  title?: string;
  type?: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface DriveFileResponse {
  kind?: string;
  items?: DriveFileDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export interface CalendarEventWorkspaceSuggestionResponse {
  kind?: string;
  items?: CalendarEventWorkspaceSuggestionDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export type CalendarEventWorkspaceSuggestionDtoProvider =
  typeof CalendarEventWorkspaceSuggestionDtoProvider[keyof typeof CalendarEventWorkspaceSuggestionDtoProvider];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CalendarEventWorkspaceSuggestionDtoProvider = {
  google: 'google'
} as const;

export type CalendarEventRecurrenceStartDayOfWeek =
  typeof CalendarEventRecurrenceStartDayOfWeek[keyof typeof CalendarEventRecurrenceStartDayOfWeek];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CalendarEventRecurrenceStartDayOfWeek = {
  su: 'su',
  mo: 'mo',
  tu: 'tu',
  we: 'we',
  th: 'th',
  fr: 'fr',
  sa: 'sa'
} as const;

export type CalendarEventRecurrenceDaysOfWeekItem =
  typeof CalendarEventRecurrenceDaysOfWeekItem[keyof typeof CalendarEventRecurrenceDaysOfWeekItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CalendarEventRecurrenceDaysOfWeekItem = {
  su: 'su',
  mo: 'mo',
  tu: 'tu',
  we: 'we',
  th: 'th',
  fr: 'fr',
  sa: 'sa'
} as const;

export type CalendarEventRecurrenceFrequency =
  typeof CalendarEventRecurrenceFrequency[keyof typeof CalendarEventRecurrenceFrequency];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CalendarEventRecurrenceFrequency = {
  daily: 'daily',
  weekly: 'weekly',
  monthly: 'monthly',
  yearly: 'yearly'
} as const;

export interface CalendarEventRecurrence {
  startTime?: string;
  endTime?: string;
  frequency?: CalendarEventRecurrenceFrequency;
  interval?: number;
  daysOfWeek?: CalendarEventRecurrenceDaysOfWeekItem[];
  until?: string;
  startDayOfWeek?: CalendarEventRecurrenceStartDayOfWeek;
}

export interface CalendarEventWorkspaceSuggestionDto {
  id?: string;
  provider?: CalendarEventWorkspaceSuggestionDtoProvider;
  eventId?: string;
  title?: string;
  recurrence?: CalendarEventRecurrence;
}

export type MeetingAnalysisSuggestionDtoType =
  typeof MeetingAnalysisSuggestionDtoType[keyof typeof MeetingAnalysisSuggestionDtoType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MeetingAnalysisSuggestionDtoType = {
  task: 'task',
  initiative: 'initiative'
} as const;

export interface MeetingAnalysisSuggestionDto {
  id?: string;
  meetingId?: string;
  actionItem?: MeetingAnalysisActionItemDto;
  type?: MeetingAnalysisSuggestionDtoType;
}

export interface MeetingAnalysisSuggestionResponse {
  kind?: string;
  items?: MeetingAnalysisSuggestionDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export interface MeetingTranscriptionAnalysisResponse {
  kind?: string;
  items?: MeetingTranscriptionAnalysisDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export interface MeetingMediaDto {
  url?: string;
}

export interface MeetingMediaResponse {
  kind?: string;
  items?: MeetingMediaDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export interface AccountTagsResponse {
  kind?: string;
  items?: AccountTagsDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export interface IdsResponse {
  kind?: string;
  items?: string[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export interface AccountConfigDto {
  fiscalYearStartMonth?: number;
  shareCeoDataWithJp?: boolean;
}

export interface PatchAccountRequest {
  name?: string;
  logoUrl?: string;
  config?: AccountConfigDto;
}

export interface BulkPatchRequestActionPlanActionItemDto {
  items?: ActionPlanActionItemDto[];
}

export interface BulkPatchRequestActionPlanInitiativeDto {
  items?: ActionPlanInitiativeDto[];
}

export interface BulkPatchRequestActionPlanObjectiveDto {
  items?: ActionPlanObjectiveDto[];
}

export interface BulkPatchRequestMetricDto {
  items?: MetricDto[];
}

export interface BulkPatchRequestMetricDefinitionDto {
  items?: MetricDefinitionDto[];
}

export type OkrMetricDtoTargetsPeriod =
  typeof OkrMetricDtoTargetsPeriod[keyof typeof OkrMetricDtoTargetsPeriod];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OkrMetricDtoTargetsPeriod = {
  daily: 'daily',
  weekly: 'weekly',
  monthly: 'monthly',
  quarterly: 'quarterly',
  semiannually: 'semiannually',
  annually: 'annually'
} as const;

export type OkrMetricDtoUnit = typeof OkrMetricDtoUnit[keyof typeof OkrMetricDtoUnit];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OkrMetricDtoUnit = {
  number: 'number',
  percentage: 'percentage',
  currency: 'currency',
  ratio: 'ratio'
} as const;

export interface MetricTargetDto {
  year?: number;
  period?: number;
  value?: number;
  progress?: number;
}

export interface OkrMetricDto {
  id: string;
  name?: string;
  unit?: OkrMetricDtoUnit;
  startingValue?: number;
  value?: number;
  dataPoints?: DataPoint[];
  target?: number;
  targetsPeriod?: OkrMetricDtoTargetsPeriod;
  targets?: MetricTargetDto[];
  progress?: number;
  updatedAt?: string;
  createdAt?: string;
}

export interface PatchModulePlaybookRequest {
  content?: string;
}

export interface BulkPatchRequestNoteDto {
  items?: NoteDto[];
}

export interface BulkPatchRequestResourceDto {
  items?: ResourceDto[];
}

export interface Update {
  type?: string;
  fullName?: string;
  description?: string;
}

export interface Summary {
  content?: string;
  internalConflicts?: string;
  externalDependencies?: string;
}

export interface TranscriptionAnalysisResult {
  summary?: Summary;
  actionItems?: ActionItems;
  updates?: Update[];
}

export interface MeetingTranscriptionAnalysisDto {
  id?: string;
  meetingId?: string;
  botId?: string;
  analysis?: TranscriptionAnalysisResult;
}

export interface MeetingAnalysisActionItemDto {
  assigneeFullName?: string;
  subject?: string;
  description?: string;
  type?: string;
}

export interface ActionItems {
  task?: MeetingAnalysisActionItemDto[];
  initiative?: MeetingAnalysisActionItemDto[];
}

export interface BulkPatchRequestSharedWorkspacePointDto {
  items?: SharedWorkspacePointDto[];
}

export interface ActionPlanActionItemDetailedCommentDto {
  id: string;
  actionItemId?: string;
  content?: string;
  userId?: string;
  taggedUserIds?: string[];
  createdAt?: string;
  updatedAt?: string;
  actionItemTitle?: string;
}

export interface ActionPlanActionItemDetailedCommentResponse {
  kind?: string;
  items?: ActionPlanActionItemDetailedCommentDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export interface ActionPlanActionItemCommentDto {
  id: string;
  actionItemId?: string;
  content?: string;
  userId?: string;
  taggedUserIds?: string[];
  createdAt?: string;
  updatedAt?: string;
}

export interface ActionPlanActionItemCommentResponse {
  kind?: string;
  items?: ActionPlanActionItemCommentDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export interface ActionPlanActionItemResponse {
  kind?: string;
  items?: ActionPlanActionItemDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export interface ActionPlanBoardResponse {
  kind?: string;
  items?: ActionPlanBoardDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export interface ActionPlanInitiativeDetailedCommentDto {
  id: string;
  initiativeId?: string;
  content?: string;
  userId?: string;
  taggedUserIds?: string[];
  createdAt?: string;
  updatedAt?: string;
  initiativeTitle?: string;
}

export interface ActionPlanInitiativeDetailedCommentResponse {
  kind?: string;
  items?: ActionPlanInitiativeDetailedCommentDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export interface ActionPlanInitiativeCommentDto {
  id: string;
  initiativeId?: string;
  content?: string;
  userId?: string;
  taggedUserIds?: string[];
  createdAt?: string;
  updatedAt?: string;
}

export interface ActionPlanInitiativeCommentResponse {
  kind?: string;
  items?: ActionPlanInitiativeCommentDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export interface ActionPlanInitiativeDetailedResponse {
  kind?: string;
  items?: ActionPlanInitiativeDetailedDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export type ActionPlanInitiativeDetailedDtoPriority =
  typeof ActionPlanInitiativeDetailedDtoPriority[keyof typeof ActionPlanInitiativeDetailedDtoPriority];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ActionPlanInitiativeDetailedDtoPriority = {
  L: 'L',
  M: 'M',
  H: 'H'
} as const;

export type ActionPlanInitiativeDetailedDtoTime =
  typeof ActionPlanInitiativeDetailedDtoTime[keyof typeof ActionPlanInitiativeDetailedDtoTime];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ActionPlanInitiativeDetailedDtoTime = {
  Q1: 'Q1',
  Q2: 'Q2',
  Q3: 'Q3',
  Q4: 'Q4'
} as const;

export type ActionPlanInitiativeDetailedDtoStatus =
  typeof ActionPlanInitiativeDetailedDtoStatus[keyof typeof ActionPlanInitiativeDetailedDtoStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ActionPlanInitiativeDetailedDtoStatus = {
  onTrack: 'onTrack',
  atRisk: 'atRisk',
  failed: 'failed',
  completed: 'completed',
  deferred: 'deferred'
} as const;

export interface ActionPlanInitiativeDetailedDto {
  id: string;
  objectiveIds?: string[];
  title?: string;
  description?: string;
  status?: ActionPlanInitiativeDetailedDtoStatus;
  owner?: string;
  ownerId?: string;
  teamIds?: string[];
  tags?: string[];
  assigneeId?: string;
  year?: number;
  time?: ActionPlanInitiativeDetailedDtoTime;
  timeframe?: Timeframe;
  goal?: Goal;
  goalProgress?: number;
  priority?: ActionPlanInitiativeDetailedDtoPriority;
  priorityDetails?: PriorityDetails;
  index?: number;
  globalIndex?: number;
  metadata?: Metadata;
  isArchived?: boolean;
  attachments?: AttachmentDto[];
  createdAt?: string;
  updatedAt?: string;
  objective?: ActionPlanObjectiveDto;
}

export interface SearchDetailedInitiativeRequest {
  userIds?: string[];
  objectiveIds?: string[];
  initiativeIds?: string[];
  teamIds?: string[];
}

export interface ActionPlanInitiativeResponse {
  kind?: string;
  items?: ActionPlanInitiativeDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export type ActionPlanMetricMetadataDtoType =
  typeof ActionPlanMetricMetadataDtoType[keyof typeof ActionPlanMetricMetadataDtoType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ActionPlanMetricMetadataDtoType = {
  Initiative: 'Initiative',
  Task: 'Task',
  Objective: 'Objective'
} as const;

export interface ActionPlanMetricMetadataDto {
  type?: ActionPlanMetricMetadataDtoType;
  id?: string;
  title?: string;
  goalDescription?: string;
  ownerId?: string;
  metricDefinitionId?: string;
}

export interface ActionPlanMetricMetadataResponse {
  kind?: string;
  items?: ActionPlanMetricMetadataDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export interface SearchMetricMetadataRequest {
  metricDefinitionIds?: string[];
}

export interface ActionPlanObjectiveDetailedCommentDto {
  id: string;
  objectiveId?: string;
  content?: string;
  userId?: string;
  taggedUserIds?: string[];
  createdAt?: string;
  updatedAt?: string;
  objectiveTitle?: string;
}

export interface ActionPlanObjectiveDetailedCommentResponse {
  kind?: string;
  items?: ActionPlanObjectiveDetailedCommentDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export interface SearchCommentsRequest {
  ownerOrAssigneeUserIds?: string[];
  entityIds?: string[];
}

export interface ActionPlanObjectiveCommentDto {
  id: string;
  objectiveId?: string;
  content?: string;
  userId?: string;
  taggedUserIds?: string[];
  createdAt?: string;
  updatedAt?: string;
}

export interface ActionPlanObjectiveCommentResponse {
  kind?: string;
  items?: ActionPlanObjectiveCommentDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export interface KeyResultDetails {
  krProgress?: number;
  currentQBaseline?: number;
  currentQProgress?: number;
  currentValue?: number;
}

export type ActionPlanObjectiveDetailedDtoType =
  typeof ActionPlanObjectiveDetailedDtoType[keyof typeof ActionPlanObjectiveDetailedDtoType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ActionPlanObjectiveDetailedDtoType = {
  company: 'company',
  businessArea: 'businessArea'
} as const;

export interface ActionItemsDetails {
  total?: number;
  completed?: number;
  inProgress?: number;
  totalDueDateDefined?: number;
  pastDue?: number;
}

export interface ActionPlanObjectiveDetailedDto {
  id: string;
  type?: ActionPlanObjectiveDetailedDtoType;
  title?: string;
  description?: string;
  boardId?: string;
  businessArea?: string;
  ownerId?: string;
  teamIds?: string[];
  tags?: string[];
  index?: number;
  keyResult?: KeyResult;
  adminOnly?: boolean;
  isArchived?: boolean;
  attachments?: AttachmentDto[];
  createdAt?: string;
  updatedAt?: string;
  initiatives?: ActionPlanInitiativeDto[];
  krProgress?: number;
  currentQProgress?: number;
  keyResultDetails?: KeyResultDetails;
  actionItemsDetails?: ActionItemsDetails;
}

export interface ActionPlanObjectiveDetailedResponse {
  kind?: string;
  items?: ActionPlanObjectiveDetailedDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export interface ActionPlanObjectiveResponse {
  kind?: string;
  items?: ActionPlanObjectiveDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export interface AskJourneyAnswerDto {
  id: string;
  question?: string;
  openAiAnswer?: string;
  rating?: number;
  createdAt?: string;
}

export interface AskJourneyResult {
  openAiResult?: string;
  answerId: string;
}

export interface AskJourneyResponse {
  kind?: string;
  items?: AskJourneyResult[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export interface AskJourneyRequest {
  question?: string;
}

export interface AccountSignInRequest {
  accountId?: number;
}

export interface ChangePasswordRequest {
  currentPassword?: string;
  newPassword?: string;
}

export interface ForgotPasswordRequest {
  email?: string;
}

export interface ResetPasswordRequest {
  confirmationToken?: string;
  confirmationCode?: string;
  newPassword?: string;
}

export interface SignInRefreshRequest {
  email?: string;
  refreshToken?: string;
}

export interface SignInRequest {
  email?: string;
  password?: string;
}

export interface SignInDetails {
  idToken?: string;
  accessToken?: string;
  refreshToken?: string;
  socketToken?: string;
  type?: string;
}

export interface SignInResponse {
  kind?: string;
  items?: SignInDetails[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export interface SignUpRequest {
  email?: string;
  password?: string;
  registrationToken?: string;
}

export interface CommentResponse {
  kind?: string;
  items?: CommentDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export interface PostResponse {
  kind?: string;
  items?: PostDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export interface JourneyAnswer {
  content?: string;
  createdAt?: string;
}

export interface AuthorDto {
  userId: string;
  userType?: AuthorDtoUserType;
  accountId?: number;
  industry?: string;
}

export interface CommentDto {
  id: string;
  postId?: string;
  parentId?: string;
  content?: string;
  user?: AuthorDto;
  createdAt?: string;
  updatedAt?: string;
}

export interface PostDto {
  id: string;
  title?: string;
  content?: string;
  tags?: string[];
  numberOfComments?: number;
  user?: AuthorDto;
  aiAnswer?: string;
  journeyAnswer?: JourneyAnswer;
  createdAt?: string;
  updatedAt?: string;
}

export type AuthorDtoUserType = typeof AuthorDtoUserType[keyof typeof AuthorDtoUserType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AuthorDtoUserType = {
  user: 'user',
  assigneeUser: 'assigneeUser',
  ceo: 'ceo',
  jp: 'jp',
  admin: 'admin',
  superAdmin: 'superAdmin',
  internal: 'internal'
} as const;

export interface SavedPostsDto {
  id: string;
  postIds?: string[];
  createdAt?: string;
  updatedAt?: string;
}

export interface SavedPostsResponse {
  kind?: string;
  items?: SavedPostsDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export interface GoogleDataProviderCreateRequest {
  name?: string;
  authorizationCode?: string;
  redirectUrl?: string;
}

export interface HubspotDataProviderCreateRequest {
  name?: string;
  code?: string;
}

export interface QuickBooksDataProviderCreateRequest {
  name?: string;
  authCode?: string;
  realmId?: string;
}

export type PartialAccountDataProviderDtoProviderType =
  typeof PartialAccountDataProviderDtoProviderType[keyof typeof PartialAccountDataProviderDtoProviderType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PartialAccountDataProviderDtoProviderType = {
  hubspot: 'hubspot',
  quickBooks: 'quickBooks',
  google: 'google'
} as const;

export interface PartialAccountDataProviderDto {
  id?: string;
  name?: string;
  userId?: string;
  providerType?: PartialAccountDataProviderDtoProviderType;
  createdAt?: string;
}

export interface IntegrationDataProviderResponse {
  kind?: string;
  items?: PartialAccountDataProviderDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export interface HubspotTokenCredentialsDto {
  access_key?: string;
  access_token?: string;
  refresh_token?: string;
  expires_in?: number;
}

export type HubspotAccountDataProviderDtoAllOf = {
  credentials?: HubspotTokenCredentialsDto;
};

export interface GoogleAccessCredentialsDto {
  accessToken?: string;
  refreshToken?: string;
  tokenType?: string;
  expiresIn?: number;
  scope?: string;
  idToken?: string;
}

export type GoogleAccountDataProviderDtoAllOf = {
  credentials?: GoogleAccessCredentialsDto;
};

export type BaseAccountDataProviderDtoObjectProviderType =
  typeof BaseAccountDataProviderDtoObjectProviderType[keyof typeof BaseAccountDataProviderDtoObjectProviderType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BaseAccountDataProviderDtoObjectProviderType = {
  hubspot: 'hubspot',
  quickBooks: 'quickBooks',
  google: 'google'
} as const;

export type BaseAccountDataProviderDtoObjectCredentials = { [key: string]: any };

export interface BaseAccountDataProviderDtoObject {
  name?: string;
  userId?: string;
  credentials?: BaseAccountDataProviderDtoObjectCredentials;
  providerType?: BaseAccountDataProviderDtoObjectProviderType;
}

export type HubspotAccountDataProviderDto = BaseAccountDataProviderDtoObject &
  HubspotAccountDataProviderDtoAllOf;

export type GoogleAccountDataProviderDto = BaseAccountDataProviderDtoObject &
  GoogleAccountDataProviderDtoAllOf;

export type AccountDataProviderRequestProvider =
  | GoogleAccountDataProviderDto
  | HubspotAccountDataProviderDto;

export interface AccountDataProviderRequest {
  provider?: AccountDataProviderRequestProvider;
}

export interface DataSourceResponse {
  kind?: string;
  items?: DataSourceDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export type DataSourceDtoSourceType =
  typeof DataSourceDtoSourceType[keyof typeof DataSourceDtoSourceType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DataSourceDtoSourceType = {
  deals: 'deals',
  companies: 'companies',
  contacts: 'contacts',
  tickets: 'tickets',
  bill: 'bill',
  customer: 'customer',
  payment: 'payment',
  invoice: 'invoice',
  googleCalendarEvent: 'googleCalendarEvent'
} as const;

export type DataSourceDtoProviderType =
  typeof DataSourceDtoProviderType[keyof typeof DataSourceDtoProviderType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DataSourceDtoProviderType = {
  hubspot: 'hubspot',
  quickBooks: 'quickBooks',
  google: 'google'
} as const;

export interface DataSourceDto {
  id?: string;
  name?: string;
  providerId?: string;
  providerName?: string;
  providerType?: DataSourceDtoProviderType;
  sourceType?: DataSourceDtoSourceType;
  collectInterval?: string;
}

export interface MeetingAgendaDto {
  id: string;
  meetingId?: string;
  content?: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface MeetingAgendaResponse {
  kind?: string;
  items?: MeetingAgendaDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export type MeetingFeedbackDtoUserType =
  typeof MeetingFeedbackDtoUserType[keyof typeof MeetingFeedbackDtoUserType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MeetingFeedbackDtoUserType = {
  user: 'user',
  assigneeUser: 'assigneeUser',
  ceo: 'ceo',
  jp: 'jp',
  admin: 'admin',
  superAdmin: 'superAdmin',
  internal: 'internal'
} as const;

export interface MeetingFeedbackDto {
  id?: string;
  accountId?: number;
  meetingId?: string;
  userType?: MeetingFeedbackDtoUserType;
  userId?: string;
  rating?: number;
  description?: string;
  createdAt?: string;
}

export interface MeetingFeedbackResponse {
  kind?: string;
  items?: MeetingFeedbackDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export interface ScheduledMeetingDto {
  id: string;
  name?: string;
  participateUserIds?: string[];
  createdAt?: string;
  updatedAt?: string;
}

export interface ScheduledMeetingResponse {
  kind?: string;
  items?: ScheduledMeetingDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export type MeetingDtoProvider = typeof MeetingDtoProvider[keyof typeof MeetingDtoProvider];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MeetingDtoProvider = {
  zoom: 'zoom',
  twilio: 'twilio'
} as const;

export interface MeetingDto {
  id: string;
  number?: number;
  dayOfWeek?: string;
  startTime?: string;
  endTime?: string;
  hasRecording?: boolean;
  insights?: InsightDto[];
  notes?: string;
  followUps?: FollowUps;
  twilioMeetingId?: string;
  provider?: MeetingDtoProvider;
}

export interface MeetingDetailsDto {
  meeting?: MeetingDto;
  notes?: string;
  topics?: string[];
  insights?: InsightDto[];
}

export interface MeetingDetailsResponse {
  kind?: string;
  items?: MeetingDetailsDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export interface FollowUps {
  content?: string;
  sentAt?: string;
}

export interface SearchRequest {
  content?: string;
}

export interface Zoom {
  signature?: string;
  meetingId?: string;
  password?: string;
}

export type LiveMeetingDetailsDtoProvider =
  typeof LiveMeetingDetailsDtoProvider[keyof typeof LiveMeetingDetailsDtoProvider];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LiveMeetingDetailsDtoProvider = {
  zoom: 'zoom',
  twilio: 'twilio'
} as const;

export interface LiveMeetingDetailsDto {
  meetingId: string;
  startTime?: string;
  zoom?: Zoom;
  provider?: LiveMeetingDetailsDtoProvider;
}

export interface LiveMeetingResponse {
  kind?: string;
  items?: LiveMeetingDetailsDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export interface BulkMetricDataPointsRequest {
  dataPoints?: MetricDataPointDto[];
}

export interface SearchMetricsRequest {
  ids?: string[];
}

export interface MetricDefinitionResponse {
  kind?: string;
  items?: MetricDefinitionDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export interface RegisterResponse {
  accountId?: number;
  registrationLink?: string;
  zoomMeetingUrl?: string;
}

export interface RegistrationResponse {
  kind?: string;
  items?: RegisterResponse[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export interface MetricResponse {
  kind?: string;
  items?: MetricDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export type MetricDtoPeriod = typeof MetricDtoPeriod[keyof typeof MetricDtoPeriod];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MetricDtoPeriod = {
  daily: 'daily',
  weekly: 'weekly',
  monthly: 'monthly',
  quarterly: 'quarterly',
  semiannually: 'semiannually',
  annually: 'annually'
} as const;

export type MetricDtoUnit = typeof MetricDtoUnit[keyof typeof MetricDtoUnit];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MetricDtoUnit = {
  number: 'number',
  percentage: 'percentage',
  currency: 'currency',
  ratio: 'ratio'
} as const;

export interface DataPoint {
  value?: number;
  timestamp?: string;
}

export interface MetricDto {
  id: string;
  name?: string;
  unit?: MetricDtoUnit;
  startingMonthOfYear?: number;
  period?: MetricDtoPeriod;
  dataPoints?: DataPoint[];
  targets?: DataPoint[];
  index?: number;
  adminOnly?: boolean;
  updatedAt?: string;
  createdAt?: string;
}

export interface RoleResponse {
  kind?: string;
  items?: RoleDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export interface ContactUsRequest {
  message?: string;
}

export interface TeamDto {
  id: string;
  name?: string;
  description?: string;
  userIds?: string[];
  createdAt?: string;
  updatedAt?: string;
}

export interface TeamResponse {
  kind?: string;
  items?: TeamDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export type AccountUserDtoStatus = typeof AccountUserDtoStatus[keyof typeof AccountUserDtoStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountUserDtoStatus = {
  active: 'active',
  pending: 'pending',
  notInvited: 'notInvited'
} as const;

export type AccountUserDtoType = typeof AccountUserDtoType[keyof typeof AccountUserDtoType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountUserDtoType = {
  user: 'user',
  assigneeUser: 'assigneeUser',
  ceo: 'ceo',
  jp: 'jp',
  admin: 'admin',
  superAdmin: 'superAdmin',
  internal: 'internal'
} as const;

export interface AccountUserDto {
  id: string;
  type?: AccountUserDtoType;
  firstName?: string;
  lastName?: string;
  roleId?: string;
  title?: string;
  email?: string;
  status?: AccountUserDtoStatus;
  photoUrl?: string;
}

export interface AccountUserResponse {
  kind?: string;
  items?: AccountUserDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export interface CreateUserRequest {
  email?: string;
  firstName?: string;
  lastName?: string;
  title?: string;
  roleId?: string;
  sendInviteEmail?: boolean;
}

export type BusinessAreaMetadataDtoType =
  typeof BusinessAreaMetadataDtoType[keyof typeof BusinessAreaMetadataDtoType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BusinessAreaMetadataDtoType = {
  platform: 'platform',
  custom: 'custom'
} as const;

export interface BusinessAreaMetadataDto {
  id?: string;
  name?: string;
  type?: BusinessAreaMetadataDtoType;
  active?: boolean;
  actionPlanDefaultIndex?: number;
  boardIds?: string[];
  roleIndex?: number;
  createdAt?: string;
}

export interface BusinessAreaMetadataResponse {
  kind?: string;
  items?: BusinessAreaMetadataDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export interface FileUploadDto {
  uploadUrl?: string;
}

export interface FileUploadResponse {
  kind?: string;
  items?: FileUploadDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export type ModuleIdeaDtoBusinessArea =
  typeof ModuleIdeaDtoBusinessArea[keyof typeof ModuleIdeaDtoBusinessArea];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ModuleIdeaDtoBusinessArea = {
  ceo: 'ceo',
  board: 'board',
  strategicPlanning: 'strategicPlanning',
  strategy: 'strategy',
  finance: 'finance',
  people: 'people',
  product: 'product',
  marketing: 'marketing',
  sales: 'sales',
  customerSuccess: 'customerSuccess',
  operations: 'operations',
  market: 'market',
  competition: 'competition',
  partnerships: 'partnerships',
  suppliers: 'suppliers',
  esg: 'esg'
} as const;

export interface ModuleIdeaDto {
  id?: string;
  moduleId: string;
  businessArea: ModuleIdeaDtoBusinessArea;
  idea: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface ModuleIdeaResponse {
  kind?: string;
  items?: ModuleIdeaDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export interface ModuleMetadataResponse {
  kind?: string;
  items?: ModuleMetadataDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export type ModuleMetadataDtoSource =
  typeof ModuleMetadataDtoSource[keyof typeof ModuleMetadataDtoSource];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ModuleMetadataDtoSource = {
  platform: 'platform',
  link: 'link'
} as const;

export type ModuleMetadataDtoType =
  typeof ModuleMetadataDtoType[keyof typeof ModuleMetadataDtoType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ModuleMetadataDtoType = {
  platform: 'platform',
  custom: 'custom'
} as const;

export type ModuleMetadataDtoBusinessArea =
  typeof ModuleMetadataDtoBusinessArea[keyof typeof ModuleMetadataDtoBusinessArea];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ModuleMetadataDtoBusinessArea = {
  ceo: 'ceo',
  board: 'board',
  strategicPlanning: 'strategicPlanning',
  strategy: 'strategy',
  finance: 'finance',
  people: 'people',
  product: 'product',
  marketing: 'marketing',
  sales: 'sales',
  customerSuccess: 'customerSuccess',
  operations: 'operations',
  market: 'market',
  competition: 'competition',
  partnerships: 'partnerships',
  suppliers: 'suppliers',
  esg: 'esg'
} as const;

export interface ModuleMetadataDto {
  id: string;
  businessArea?: ModuleMetadataDtoBusinessArea;
  type?: ModuleMetadataDtoType;
  name?: string;
  index?: number;
  description?: string;
  scope?: string;
  category?: string;
  active?: boolean;
  source?: ModuleMetadataDtoSource;
  updatedAt?: string;
}

export type CustomModuleRequestBusinessArea =
  typeof CustomModuleRequestBusinessArea[keyof typeof CustomModuleRequestBusinessArea];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CustomModuleRequestBusinessArea = {
  ceo: 'ceo',
  board: 'board',
  strategicPlanning: 'strategicPlanning',
  strategy: 'strategy',
  finance: 'finance',
  people: 'people',
  product: 'product',
  marketing: 'marketing',
  sales: 'sales',
  customerSuccess: 'customerSuccess',
  operations: 'operations',
  market: 'market',
  competition: 'competition',
  partnerships: 'partnerships',
  suppliers: 'suppliers',
  esg: 'esg'
} as const;

export interface CustomModuleRequest {
  businessArea?: CustomModuleRequestBusinessArea;
  name?: string;
  description?: string;
}

export type ModulePrioritizationDtoBusinessArea =
  typeof ModulePrioritizationDtoBusinessArea[keyof typeof ModulePrioritizationDtoBusinessArea];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ModulePrioritizationDtoBusinessArea = {
  ceo: 'ceo',
  board: 'board',
  strategicPlanning: 'strategicPlanning',
  strategy: 'strategy',
  finance: 'finance',
  people: 'people',
  product: 'product',
  marketing: 'marketing',
  sales: 'sales',
  customerSuccess: 'customerSuccess',
  operations: 'operations',
  market: 'market',
  competition: 'competition',
  partnerships: 'partnerships',
  suppliers: 'suppliers',
  esg: 'esg'
} as const;

export interface ModulePrioritizationDto {
  id: string;
  moduleId?: string;
  businessArea?: ModulePrioritizationDtoBusinessArea;
  rating?: number;
  startingPoint?: string;
  explanation?: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface ModulePrioritizationResponse {
  kind?: string;
  items?: ModulePrioritizationDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export interface PlaybookElement {
  id?: number;
  content?: string;
}

export interface ModulePlaybookDto {
  moduleId?: string;
  elements?: PlaybookElement[];
  createdAt?: string;
  updatedAt?: string;
}

export interface ModulePlaybookResponse {
  kind?: string;
  items?: ModulePlaybookDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export interface CreateModulePlaybookRequest {
  content?: string;
  levelId?: number;
}

export interface NoteDto {
  id: string;
  content?: string;
  index?: number;
  createdAt?: string;
  updatedAt?: string;
}

export interface NoteResponse {
  kind?: string;
  items?: NoteDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export type CreateGoogleDriveFileRequestType =
  typeof CreateGoogleDriveFileRequestType[keyof typeof CreateGoogleDriveFileRequestType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateGoogleDriveFileRequestType = {
  doc: 'doc',
  sheet: 'sheet',
  slide: 'slide'
} as const;

export interface CreateGoogleDriveFileRequest {
  type?: CreateGoogleDriveFileRequestType;
  subFolderName?: string;
}

export interface ResourceDto {
  id: string;
  link?: string;
  title?: string;
  description?: string;
  index?: number;
  type?: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface ResourceResponse {
  kind?: string;
  items?: ResourceDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export interface SharedWorkspaceMeetingActivityResponse {
  kind?: string;
  items?: SharedWorkspaceMeetingActivityDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export type SharedWorkspaceMeetingActivityDtoAction =
  typeof SharedWorkspaceMeetingActivityDtoAction[keyof typeof SharedWorkspaceMeetingActivityDtoAction];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SharedWorkspaceMeetingActivityDtoAction = {
  create: 'create',
  update: 'update',
  delete: 'delete'
} as const;

export type SharedWorkspaceMeetingActivityDtoEntityType =
  typeof SharedWorkspaceMeetingActivityDtoEntityType[keyof typeof SharedWorkspaceMeetingActivityDtoEntityType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SharedWorkspaceMeetingActivityDtoEntityType = {
  highlight: 'highlight',
  issue: 'issue',
  objective: 'objective',
  initiative: 'initiative',
  task: 'task',
  metric: 'metric'
} as const;

export interface SharedWorkspaceMeetingActivityDto {
  id: string;
  meetingId?: string;
  userId?: string;
  entityType?: SharedWorkspaceMeetingActivityDtoEntityType;
  entityId?: string;
  entityName?: string;
  action?: SharedWorkspaceMeetingActivityDtoAction;
  newValue?: string;
  createdAt?: string;
}

export interface SharedWorkspaceMeetingNoteDto {
  id: string;
  spaceId?: string;
  meetingId?: string;
  content?: string;
  userId?: string;
  taggedUserIds?: string[];
  createdAt?: string;
  updatedAt?: string;
}

export interface SharedWorkspaceMeetingNoteResponse {
  kind?: string;
  items?: SharedWorkspaceMeetingNoteDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export type MeetingFlowTemplateDtoType =
  typeof MeetingFlowTemplateDtoType[keyof typeof MeetingFlowTemplateDtoType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MeetingFlowTemplateDtoType = {
  account: 'account',
  platform: 'platform'
} as const;

export interface MeetingFlowTemplateDto {
  id: string;
  name?: string;
  description?: string;
  type?: MeetingFlowTemplateDtoType;
  steps?: MeetingFlowStepDto[];
  createdAt?: string;
  updatedAt?: string;
}

export interface MeetingFlowTemplateResponse {
  kind?: string;
  items?: MeetingFlowTemplateDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export type MeetingFlowStepDtoType =
  typeof MeetingFlowStepDtoType[keyof typeof MeetingFlowStepDtoType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MeetingFlowStepDtoType = {
  custom: 'custom',
  highlights: 'highlights',
  issues: 'issues',
  objectives: 'objectives',
  initiatives: 'initiatives',
  tasks: 'tasks',
  metrics: 'metrics',
  rating: 'rating',
  followUp: 'followUp',
  brainstorming: 'brainstorming'
} as const;

export interface MeetingFlowStepDto {
  type?: MeetingFlowStepDtoType;
  name?: string;
  durationInMinutes?: number;
  index?: number;
}

export interface MeetingFlowDto {
  id: string;
  name?: string;
  steps?: MeetingFlowStepDto[];
  createdAt?: string;
  updatedAt?: string;
}

export interface MeetingFlowResponse {
  kind?: string;
  items?: MeetingFlowDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export interface SharedWorkspacePointCommentDto {
  id: string;
  pointId?: string;
  content?: string;
  userId?: string;
  taggedUserIds?: string[];
  createdAt?: string;
  updatedAt?: string;
}

export interface SharedWorkspacePointCommentResponse {
  kind?: string;
  items?: SharedWorkspacePointCommentDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export interface SharedWorkspaceUpdateDto {
  id: string;
  userId?: string;
  sharedWorkspaceId?: string;
  content?: string;
  initiativeId?: string;
  updatedAt?: string;
  createdAt?: string;
}

export interface SharedWorkspaceUpdateResponse {
  kind?: string;
  items?: SharedWorkspaceUpdateDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export type UserRatingAttendance = typeof UserRatingAttendance[keyof typeof UserRatingAttendance];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserRatingAttendance = {
  attend: 'attend',
  notAttend: 'notAttend'
} as const;

export interface UserRating {
  userId?: string;
  attendance?: UserRatingAttendance;
  rating?: number;
  description?: string;
}

export interface SharedWorkspaceMeetingResponse {
  kind?: string;
  items?: SharedWorkspaceMeetingDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export type MeetingFlowActualStepDtoType =
  typeof MeetingFlowActualStepDtoType[keyof typeof MeetingFlowActualStepDtoType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MeetingFlowActualStepDtoType = {
  custom: 'custom',
  highlights: 'highlights',
  issues: 'issues',
  objectives: 'objectives',
  initiatives: 'initiatives',
  tasks: 'tasks',
  metrics: 'metrics',
  rating: 'rating',
  followUp: 'followUp',
  brainstorming: 'brainstorming'
} as const;

export interface MeetingFlowActualStepDto {
  type?: MeetingFlowActualStepDtoType;
  name?: string;
  durationInMinutes?: number;
  index?: number;
  startTime?: string;
  endTime?: string;
}

export interface MeetingData {
  objectiveIds?: string[];
  initiativeIds?: string[];
  actionItemIds?: string[];
  metricIds?: string[];
  highlightIds?: string[];
  issueIds?: string[];
  ratings?: UserRating[];
}

export interface Flow {
  steps?: MeetingFlowActualStepDto[];
}

export interface SharedWorkspaceMeetingDto {
  id: string;
  spaceId?: string;
  startTime?: string;
  endTime?: string;
  flow?: Flow;
  data?: MeetingData;
  rating?: number;
}

export interface StartMeetingRequest {
  flowId?: string;
  userIds?: string[];
}

export interface SharedWorkspacePointResponse {
  kind?: string;
  items?: SharedWorkspacePointDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export interface SearchEntityRequest {
  userIds?: string[];
  entityIds?: string[];
  teamIds?: string[];
}

export interface SharedWorkspaceResponse {
  kind?: string;
  items?: SharedWorkspaceDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export interface AccountTagsDto {
  tags?: string[];
}

export type LinkedEntityDtoType = typeof LinkedEntityDtoType[keyof typeof LinkedEntityDtoType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LinkedEntityDtoType = {
  objective: 'objective',
  initiative: 'initiative',
  businessArea: 'businessArea',
  module: 'module',
  workspace: 'workspace',
  sharedWorkspace: 'sharedWorkspace'
} as const;

export interface LinkedEntityDto {
  type?: LinkedEntityDtoType;
  id?: string;
}

export interface WhiteboardDto {
  id: string;
  name?: string;
  data?: string;
  collaboratorUserIds?: string[];
  linkedEntities?: LinkedEntityDto[];
  createdAt?: string;
  updatedAt?: string;
}

export interface WhiteboardResponse {
  kind?: string;
  items?: WhiteboardDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export type ActionPlanActionItemDtoSprint =
  typeof ActionPlanActionItemDtoSprint[keyof typeof ActionPlanActionItemDtoSprint];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ActionPlanActionItemDtoSprint = {
  current: 'current',
  backlog: 'backlog'
} as const;

export type ActionPlanActionItemDtoStatus =
  typeof ActionPlanActionItemDtoStatus[keyof typeof ActionPlanActionItemDtoStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ActionPlanActionItemDtoStatus = {
  pending: 'pending',
  inProgress: 'inProgress',
  completed: 'completed'
} as const;

export interface ActionPlanBoardDto {
  id: string;
  name?: string;
  isDefault?: boolean;
  isArchived?: boolean;
  createdAt?: string;
  updatedAt?: string;
}

export interface Timeframe {
  year?: number;
  month?: number;
  durationInMonths?: number;
}

export type PriorityDetailsExpectedValue =
  typeof PriorityDetailsExpectedValue[keyof typeof PriorityDetailsExpectedValue];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PriorityDetailsExpectedValue = {
  L: 'L',
  M: 'M',
  H: 'H'
} as const;

export type PriorityDetailsResources =
  typeof PriorityDetailsResources[keyof typeof PriorityDetailsResources];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PriorityDetailsResources = {
  L: 'L',
  M: 'M',
  H: 'H'
} as const;

export interface PriorityDetails {
  resources?: PriorityDetailsResources;
  expectedValue?: PriorityDetailsExpectedValue;
}

export interface Metadata {
  duplicatedFromId?: string;
}

export interface ActionPlanActionItemDto {
  id: string;
  initiativeId?: string;
  title?: string;
  description?: string;
  status?: ActionPlanActionItemDtoStatus;
  sprint?: ActionPlanActionItemDtoSprint;
  dueDate?: string;
  ownerId?: string;
  assigneeId?: string;
  index?: number;
  globalIndex?: number;
  metadata?: Metadata;
  isArchived?: boolean;
  attachments?: AttachmentDto[];
  createdAt?: string;
  updatedAt?: string;
}

export type GoalUnit = typeof GoalUnit[keyof typeof GoalUnit];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GoalUnit = {
  number: 'number',
  percentage: 'percentage',
  currency: 'currency',
  ratio: 'ratio'
} as const;

export interface Goal {
  subject?: string;
  value?: number;
  unit?: GoalUnit;
  startingPoint?: number;
  metricId?: string;
  currentValue?: number;
}

export type ActionPlanInitiativeDtoPriority =
  typeof ActionPlanInitiativeDtoPriority[keyof typeof ActionPlanInitiativeDtoPriority];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ActionPlanInitiativeDtoPriority = {
  L: 'L',
  M: 'M',
  H: 'H'
} as const;

export type ActionPlanInitiativeDtoTime =
  typeof ActionPlanInitiativeDtoTime[keyof typeof ActionPlanInitiativeDtoTime];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ActionPlanInitiativeDtoTime = {
  Q1: 'Q1',
  Q2: 'Q2',
  Q3: 'Q3',
  Q4: 'Q4'
} as const;

export type ActionPlanInitiativeDtoStatus =
  typeof ActionPlanInitiativeDtoStatus[keyof typeof ActionPlanInitiativeDtoStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ActionPlanInitiativeDtoStatus = {
  onTrack: 'onTrack',
  atRisk: 'atRisk',
  failed: 'failed',
  completed: 'completed',
  deferred: 'deferred'
} as const;

export interface ActionPlanInitiativeDto {
  id: string;
  objectiveIds?: string[];
  title?: string;
  description?: string;
  status?: ActionPlanInitiativeDtoStatus;
  owner?: string;
  ownerId?: string;
  teamIds?: string[];
  tags?: string[];
  assigneeId?: string;
  year?: number;
  time?: ActionPlanInitiativeDtoTime;
  timeframe?: Timeframe;
  goal?: Goal;
  goalProgress?: number;
  priority?: ActionPlanInitiativeDtoPriority;
  priorityDetails?: PriorityDetails;
  index?: number;
  globalIndex?: number;
  metadata?: Metadata;
  isArchived?: boolean;
  attachments?: AttachmentDto[];
  createdAt?: string;
  updatedAt?: string;
}

export interface Target {
  year?: number;
  period?: number;
  value?: number;
  progress?: number;
}

export type KeyResultDataPointType =
  typeof KeyResultDataPointType[keyof typeof KeyResultDataPointType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const KeyResultDataPointType = {
  accumulated: 'accumulated',
  independent: 'independent'
} as const;

export type KeyResultPeriod = typeof KeyResultPeriod[keyof typeof KeyResultPeriod];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const KeyResultPeriod = {
  daily: 'daily',
  weekly: 'weekly',
  monthly: 'monthly',
  quarterly: 'quarterly',
  semiannually: 'semiannually',
  annually: 'annually'
} as const;

export type KeyResultTrendType = typeof KeyResultTrendType[keyof typeof KeyResultTrendType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const KeyResultTrendType = {
  more: 'more',
  less: 'less'
} as const;

export type KeyResultTargetsPeriod =
  typeof KeyResultTargetsPeriod[keyof typeof KeyResultTargetsPeriod];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const KeyResultTargetsPeriod = {
  daily: 'daily',
  weekly: 'weekly',
  monthly: 'monthly',
  quarterly: 'quarterly',
  semiannually: 'semiannually',
  annually: 'annually'
} as const;

export type KeyResultUnit = typeof KeyResultUnit[keyof typeof KeyResultUnit];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const KeyResultUnit = {
  number: 'number',
  percentage: 'percentage',
  currency: 'currency',
  ratio: 'ratio'
} as const;

export interface KeyResult {
  subject?: string;
  value?: number;
  unit?: KeyResultUnit;
  startingPoint?: number;
  metricId?: string;
  currentValue?: number;
  targetsPeriod?: KeyResultTargetsPeriod;
  targets?: Target[];
  trendType?: KeyResultTrendType;
  period?: KeyResultPeriod;
  dataPointType?: KeyResultDataPointType;
}

export type ActionPlanObjectiveDtoType =
  typeof ActionPlanObjectiveDtoType[keyof typeof ActionPlanObjectiveDtoType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ActionPlanObjectiveDtoType = {
  company: 'company',
  businessArea: 'businessArea'
} as const;

export interface ActionPlanObjectiveDto {
  id: string;
  type?: ActionPlanObjectiveDtoType;
  title?: string;
  description?: string;
  boardId?: string;
  businessArea?: string;
  ownerId?: string;
  teamIds?: string[];
  tags?: string[];
  index?: number;
  keyResult?: KeyResult;
  adminOnly?: boolean;
  isArchived?: boolean;
  attachments?: AttachmentDto[];
  createdAt?: string;
  updatedAt?: string;
}

export type UpdateAppNotificationStatusRequestStatus =
  typeof UpdateAppNotificationStatusRequestStatus[keyof typeof UpdateAppNotificationStatusRequestStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateAppNotificationStatusRequestStatus = {
  read: 'read',
  unread: 'unread'
} as const;

export interface UpdateAppNotificationStatusRequest {
  status?: UpdateAppNotificationStatusRequestStatus;
  notificationIds?: string[];
}

export interface AskJourneyUpdateRatingRequest {
  rating?: number;
}

export interface UpdateMeetingFollowUpRequest {
  followUp?: string;
}

export interface InsightDto {
  content?: string;
  share?: boolean;
}

export interface UpdateMeetingInsightsRequest {
  insights?: InsightDto[];
}

export interface UpdateMeetingNotesRequest {
  content?: string;
}

export interface UpdateMeetingTopicsRequest {
  topics?: string[];
}

export interface MetricDataPointDto {
  id?: string;
  dataSourceId?: string;
  metricDefinitionId?: string;
  value?: number;
  createdAt?: string;
}

export interface MetricDataPointResponse {
  kind?: string;
  items?: MetricDataPointDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export interface MetricDefinitionProgressDto {
  metricDefinitionId?: string;
  currentProgress?: number;
  currentBaseline?: number;
  totalProgress?: number;
  totalBaseline?: number;
  currentValue?: number;
  createdAt?: string;
  updatedAt?: string;
}

export interface MetricDefinitionProgressResponse {
  kind?: string;
  items?: MetricDefinitionProgressDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export interface MetricTargetDefinitionDto {
  value?: number;
  timestamp?: string;
}

export type MetricSingleFilterDtoAction =
  typeof MetricSingleFilterDtoAction[keyof typeof MetricSingleFilterDtoAction];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MetricSingleFilterDtoAction = {
  '=': '=',
  '!=': '!=',
  '<': '<',
  '<=': '<=',
  '>': '>',
  '>=': '>=',
  BETWEEN: 'BETWEEN',
  LIKE: 'LIKE',
  NOT_LIKE: 'NOT LIKE'
} as const;

export interface MetricSingleFilterDto {
  action?: MetricSingleFilterDtoAction;
  property?: string;
  value?: string;
}

export type MetricFilterDtoMode = typeof MetricFilterDtoMode[keyof typeof MetricFilterDtoMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MetricFilterDtoMode = {
  AND: 'AND',
  OR: 'OR'
} as const;

export interface MetricFilterDto {
  mode?: MetricFilterDtoMode;
  filters?: MetricSingleFilterDto[];
}

export type MetricDefinitionDtoPeriod =
  typeof MetricDefinitionDtoPeriod[keyof typeof MetricDefinitionDtoPeriod];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MetricDefinitionDtoPeriod = {
  daily: 'daily',
  weekly: 'weekly',
  monthly: 'monthly',
  quarterly: 'quarterly',
  semiannually: 'semiannually',
  annually: 'annually'
} as const;

export type MetricDefinitionDtoDataPointType =
  typeof MetricDefinitionDtoDataPointType[keyof typeof MetricDefinitionDtoDataPointType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MetricDefinitionDtoDataPointType = {
  accumulated: 'accumulated',
  independent: 'independent'
} as const;

export type MetricDefinitionDtoTrendType =
  typeof MetricDefinitionDtoTrendType[keyof typeof MetricDefinitionDtoTrendType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MetricDefinitionDtoTrendType = {
  more: 'more',
  less: 'less'
} as const;

export type MetricDefinitionDtoSummarize =
  typeof MetricDefinitionDtoSummarize[keyof typeof MetricDefinitionDtoSummarize];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MetricDefinitionDtoSummarize = {
  sum: 'sum',
  avg: 'avg',
  lastValue: 'lastValue'
} as const;

export type MetricDefinitionDtoUnit =
  typeof MetricDefinitionDtoUnit[keyof typeof MetricDefinitionDtoUnit];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MetricDefinitionDtoUnit = {
  number: 'number',
  percentage: 'percentage',
  currency: 'currency',
  ratio: 'ratio'
} as const;

export type MetricDefinitionDtoSource =
  typeof MetricDefinitionDtoSource[keyof typeof MetricDefinitionDtoSource];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MetricDefinitionDtoSource = {
  manual: 'manual',
  auto: 'auto',
  calc: 'calc'
} as const;

export type MetricAutomationQueryDtoAction =
  typeof MetricAutomationQueryDtoAction[keyof typeof MetricAutomationQueryDtoAction];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MetricAutomationQueryDtoAction = {
  count: 'count',
  sum: 'sum',
  avg: 'avg',
  min: 'min',
  max: 'max'
} as const;

export interface MetricAutomationQueryDto {
  action?: MetricAutomationQueryDtoAction;
  property?: string;
  filter?: MetricFilterDto;
}

export type MetricAutomationDefinitionDtoDataSourceType =
  typeof MetricAutomationDefinitionDtoDataSourceType[keyof typeof MetricAutomationDefinitionDtoDataSourceType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MetricAutomationDefinitionDtoDataSourceType = {
  deals: 'deals',
  companies: 'companies',
  contacts: 'contacts',
  tickets: 'tickets',
  bill: 'bill',
  customer: 'customer',
  payment: 'payment',
  invoice: 'invoice',
  googleCalendarEvent: 'googleCalendarEvent'
} as const;

export type MetricAutomationDefinitionDtoProviderType =
  typeof MetricAutomationDefinitionDtoProviderType[keyof typeof MetricAutomationDefinitionDtoProviderType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MetricAutomationDefinitionDtoProviderType = {
  hubspot: 'hubspot',
  quickBooks: 'quickBooks',
  google: 'google'
} as const;

export interface MetricAutomationDefinitionDto {
  providerType?: MetricAutomationDefinitionDtoProviderType;
  dataSourceId?: string;
  dataSourceType?: MetricAutomationDefinitionDtoDataSourceType;
  query?: MetricAutomationQueryDto;
}

export interface MetricDefinitionDto {
  id?: string;
  name?: string;
  source?: MetricDefinitionDtoSource;
  ownerId?: string;
  teamIds?: string[];
  unit?: MetricDefinitionDtoUnit;
  summarize?: MetricDefinitionDtoSummarize;
  trendType?: MetricDefinitionDtoTrendType;
  dataPointType?: MetricDefinitionDtoDataPointType;
  period?: MetricDefinitionDtoPeriod;
  targets?: MetricTargetDefinitionDto[];
  automation?: MetricAutomationDefinitionDto;
  isInternal?: boolean;
  baselineValue?: number;
  adminOnly?: boolean;
  okrMetric?: boolean;
  index?: number;
  isArchived?: boolean;
  createdAt?: string;
}

export type RoleDtoType = typeof RoleDtoType[keyof typeof RoleDtoType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RoleDtoType = {
  account: 'account',
  platform: 'platform'
} as const;

export type PermissionAccess = typeof PermissionAccess[keyof typeof PermissionAccess];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PermissionAccess = {
  block: 'block',
  view: 'view',
  edit: 'edit'
} as const;

export type PermissionNamespace = typeof PermissionNamespace[keyof typeof PermissionNamespace];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PermissionNamespace = {
  businessArea: 'businessArea',
  workspace: 'workspace',
  drive: 'drive',
  meetings: 'meetings',
  actionPlan: 'actionPlan',
  ask: 'ask',
  metrics: 'metrics',
  settings: 'settings'
} as const;

export interface Permission {
  namespace?: PermissionNamespace;
  access?: PermissionAccess;
  resources?: string[];
}

export interface RoleDto {
  id: string;
  name?: string;
  description?: string;
  type?: RoleDtoType;
  permissions?: Permission[];
  createdAt?: string;
  updatedAt?: string;
}

export interface UpdateUserRequest {
  firstName?: string;
  lastName?: string;
  title?: string;
  roleId?: string;
}

export interface CustomBusinessAreaRequest {
  name?: string;
  boardIds?: string[];
}

export interface UpdateRatingRequest {
  rating: number;
}

export interface Pagination {
  totalPages?: number;
  totalItems?: number;
  currentPageNumber?: number;
  currentPageSize?: number;
}

export interface FileDto {
  fileUrl?: string;
}

export interface Error {
  code?: string;
  message?: string;
}

export interface FileResponse {
  kind?: string;
  items?: FileDto[];
  count?: number;
  pagination?: Pagination;
  errors?: Error[];
}

export type ModuleAnalysisDtoBusinessArea =
  typeof ModuleAnalysisDtoBusinessArea[keyof typeof ModuleAnalysisDtoBusinessArea];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ModuleAnalysisDtoBusinessArea = {
  ceo: 'ceo',
  board: 'board',
  strategicPlanning: 'strategicPlanning',
  strategy: 'strategy',
  finance: 'finance',
  people: 'people',
  product: 'product',
  marketing: 'marketing',
  sales: 'sales',
  customerSuccess: 'customerSuccess',
  operations: 'operations',
  market: 'market',
  competition: 'competition',
  partnerships: 'partnerships',
  suppliers: 'suppliers',
  esg: 'esg'
} as const;

export interface ModuleAnalysisDto {
  moduleId: string;
  businessArea?: ModuleAnalysisDtoBusinessArea;
  rating?: number;
  analysis?: string;
  createdAt?: string;
  updatedAt?: string;
}

export type UpdateModuleRequestBusinessArea =
  typeof UpdateModuleRequestBusinessArea[keyof typeof UpdateModuleRequestBusinessArea];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateModuleRequestBusinessArea = {
  ceo: 'ceo',
  board: 'board',
  strategicPlanning: 'strategicPlanning',
  strategy: 'strategy',
  finance: 'finance',
  people: 'people',
  product: 'product',
  marketing: 'marketing',
  sales: 'sales',
  customerSuccess: 'customerSuccess',
  operations: 'operations',
  market: 'market',
  competition: 'competition',
  partnerships: 'partnerships',
  suppliers: 'suppliers',
  esg: 'esg'
} as const;

export interface UpdateModuleRequest {
  content: string;
  businessArea?: UpdateModuleRequestBusinessArea;
}

export type SharedWorkspacePointDtoStatus =
  typeof SharedWorkspacePointDtoStatus[keyof typeof SharedWorkspacePointDtoStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SharedWorkspacePointDtoStatus = {
  pending: 'pending',
  inProgress: 'inProgress',
  resolved: 'resolved'
} as const;

export type SharedWorkspacePointDtoPriority =
  typeof SharedWorkspacePointDtoPriority[keyof typeof SharedWorkspacePointDtoPriority];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SharedWorkspacePointDtoPriority = {
  L: 'L',
  M: 'M',
  H: 'H'
} as const;

export type SharedWorkspacePointDtoType =
  typeof SharedWorkspacePointDtoType[keyof typeof SharedWorkspacePointDtoType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SharedWorkspacePointDtoType = {
  highlight: 'highlight',
  issue: 'issue'
} as const;

export interface AttachmentDto {
  name?: string;
}

export interface SharedWorkspacePointDto {
  id: string;
  sharedWorkspaceId?: string;
  type?: SharedWorkspacePointDtoType;
  content?: string;
  description?: string;
  ownerId?: string;
  priority?: SharedWorkspacePointDtoPriority;
  durationInMinutes?: number;
  status?: SharedWorkspacePointDtoStatus;
  isStarred?: boolean;
  index?: number;
  attachments?: AttachmentDto[];
  updatedAt?: string;
  createdAt?: string;
}

export interface VideoMeeting {
  url?: string;
}

export type SharedWorkspaceDtoType =
  typeof SharedWorkspaceDtoType[keyof typeof SharedWorkspaceDtoType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SharedWorkspaceDtoType = {
  oneOnOne: 'oneOnOne',
  topic: 'topic',
  team: 'team',
  owner: 'owner'
} as const;

export interface Link {
  index?: number;
  title?: string;
  link?: string;
}

export type CollaboratorUserType = typeof CollaboratorUserType[keyof typeof CollaboratorUserType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CollaboratorUserType = {
  user: 'user',
  assigneeUser: 'assigneeUser',
  ceo: 'ceo',
  jp: 'jp',
  admin: 'admin',
  superAdmin: 'superAdmin',
  internal: 'internal'
} as const;

export interface Collaborator {
  userType?: CollaboratorUserType;
  userId?: string;
}

export type CalendarProvider = typeof CalendarProvider[keyof typeof CalendarProvider];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CalendarProvider = {
  google: 'google'
} as const;

export interface Calendar {
  userId?: string;
  eventId?: string;
  provider?: CalendarProvider;
}

export interface SharedWorkspaceDto {
  id: string;
  name?: string;
  calendar?: Calendar;
  videoMeeting?: VideoMeeting;
  type?: SharedWorkspaceDtoType;
  collaborators?: Collaborator[];
  teamIds?: string[];
  excludeAssetsCollaboratorIds?: string[];
  objectiveIds?: string[];
  initiativeIds?: string[];
  metricIds?: string[];
  links?: Link[];
  flowId?: string;
  updatedAt?: string;
  createdAt?: string;
}

/**
 * @summary Get shared workspace
 */
export const useGetSharedWorkspaceHook = () => {
  const getSharedWorkspace = useCustomAxios<SharedWorkspaceResponse>();

  return (id: string, signal?: AbortSignal) => {
    return getSharedWorkspace({ url: `/workspace/sharedWorkspace/${id}`, method: 'get', signal });
  };
};

export const getGetSharedWorkspaceQueryKey = (id: string) =>
  [`/workspace/sharedWorkspace/${id}`] as const;

export const useGetSharedWorkspaceQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetSharedWorkspaceHook>>>,
  TError = SharedWorkspaceResponse
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetSharedWorkspaceHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetSharedWorkspaceHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSharedWorkspaceQueryKey(id);

  const getSharedWorkspace = useGetSharedWorkspaceHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetSharedWorkspaceHook>>>
  > = ({ signal }) => getSharedWorkspace(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type GetSharedWorkspaceQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetSharedWorkspaceHook>>>
>;
export type GetSharedWorkspaceQueryError = SharedWorkspaceResponse;

/**
 * @summary Get shared workspace
 */
export const useGetSharedWorkspace = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetSharedWorkspaceHook>>>,
  TError = SharedWorkspaceResponse
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetSharedWorkspaceHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetSharedWorkspaceQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Update shared workspace
 */
export const useUpdateSharedWorkspaceHook = () => {
  const updateSharedWorkspace = useCustomAxios<void>();

  return (id: string, sharedWorkspaceDto: SharedWorkspaceDto) => {
    return updateSharedWorkspace({
      url: `/workspace/sharedWorkspace/${id}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: sharedWorkspaceDto
    });
  };
};

export const useUpdateSharedWorkspaceMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateSharedWorkspaceHook>>>,
    TError,
    { id: string; data: SharedWorkspaceDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUpdateSharedWorkspaceHook>>>,
  TError,
  { id: string; data: SharedWorkspaceDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const updateSharedWorkspace = useUpdateSharedWorkspaceHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUpdateSharedWorkspaceHook>>>,
    { id: string; data: SharedWorkspaceDto }
  > = props => {
    const { id, data } = props ?? {};

    return updateSharedWorkspace(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateSharedWorkspaceMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUpdateSharedWorkspaceHook>>>
>;
export type UpdateSharedWorkspaceMutationBody = SharedWorkspaceDto;
export type UpdateSharedWorkspaceMutationError = unknown;

/**
 * @summary Update shared workspace
 */
export const useUpdateSharedWorkspace = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateSharedWorkspaceHook>>>,
    TError,
    { id: string; data: SharedWorkspaceDto },
    TContext
  >;
}) => {
  const mutationOptions = useUpdateSharedWorkspaceMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Delete shared workspace
 */
export const useDeleteSharedWorkspaceHook = () => {
  const deleteSharedWorkspace = useCustomAxios<void>();

  return (id: string) => {
    return deleteSharedWorkspace({ url: `/workspace/sharedWorkspace/${id}`, method: 'delete' });
  };
};

export const useDeleteSharedWorkspaceMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteSharedWorkspaceHook>>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteSharedWorkspaceHook>>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteSharedWorkspace = useDeleteSharedWorkspaceHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteSharedWorkspaceHook>>>,
    { id: string }
  > = props => {
    const { id } = props ?? {};

    return deleteSharedWorkspace(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteSharedWorkspaceMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteSharedWorkspaceHook>>>
>;

export type DeleteSharedWorkspaceMutationError = unknown;

/**
 * @summary Delete shared workspace
 */
export const useDeleteSharedWorkspace = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteSharedWorkspaceHook>>>,
    TError,
    { id: string },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteSharedWorkspaceMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Patch shared workspace
 */
export const usePatchSharedWorkspaceHook = () => {
  const patchSharedWorkspace = useCustomAxios<void>();

  return (id: string, sharedWorkspaceDto: SharedWorkspaceDto) => {
    return patchSharedWorkspace({
      url: `/workspace/sharedWorkspace/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: sharedWorkspaceDto
    });
  };
};

export const usePatchSharedWorkspaceMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchSharedWorkspaceHook>>>,
    TError,
    { id: string; data: SharedWorkspaceDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePatchSharedWorkspaceHook>>>,
  TError,
  { id: string; data: SharedWorkspaceDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const patchSharedWorkspace = usePatchSharedWorkspaceHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePatchSharedWorkspaceHook>>>,
    { id: string; data: SharedWorkspaceDto }
  > = props => {
    const { id, data } = props ?? {};

    return patchSharedWorkspace(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchSharedWorkspaceMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePatchSharedWorkspaceHook>>>
>;
export type PatchSharedWorkspaceMutationBody = SharedWorkspaceDto;
export type PatchSharedWorkspaceMutationError = unknown;

/**
 * @summary Patch shared workspace
 */
export const usePatchSharedWorkspace = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchSharedWorkspaceHook>>>,
    TError,
    { id: string; data: SharedWorkspaceDto },
    TContext
  >;
}) => {
  const mutationOptions = usePatchSharedWorkspaceMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary End a shared workspace meeting
 */
export const useEndSharedWorkspaceMeetingHook = () => {
  const endSharedWorkspaceMeeting = useCustomAxios<void>();

  return (id: string, meetingId: string) => {
    return endSharedWorkspaceMeeting({
      url: `/workspace/sharedWorkspace/${id}/meeting/${meetingId}/end`,
      method: 'put'
    });
  };
};

export const useEndSharedWorkspaceMeetingMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useEndSharedWorkspaceMeetingHook>>>,
    TError,
    { id: string; meetingId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useEndSharedWorkspaceMeetingHook>>>,
  TError,
  { id: string; meetingId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const endSharedWorkspaceMeeting = useEndSharedWorkspaceMeetingHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useEndSharedWorkspaceMeetingHook>>>,
    { id: string; meetingId: string }
  > = props => {
    const { id, meetingId } = props ?? {};

    return endSharedWorkspaceMeeting(id, meetingId);
  };

  return { mutationFn, ...mutationOptions };
};

export type EndSharedWorkspaceMeetingMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useEndSharedWorkspaceMeetingHook>>>
>;

export type EndSharedWorkspaceMeetingMutationError = unknown;

/**
 * @summary End a shared workspace meeting
 */
export const useEndSharedWorkspaceMeeting = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useEndSharedWorkspaceMeetingHook>>>,
    TError,
    { id: string; meetingId: string },
    TContext
  >;
}) => {
  const mutationOptions = useEndSharedWorkspaceMeetingMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Update shared workspace point
 */
export const useUpdateSharedWorkspacePointHook = () => {
  const updateSharedWorkspacePoint = useCustomAxios<void>();

  return (id: string, sharedWorkspacePointDto: SharedWorkspacePointDto) => {
    return updateSharedWorkspacePoint({
      url: `/workspace/sharedWorkspace/point/${id}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: sharedWorkspacePointDto
    });
  };
};

export const useUpdateSharedWorkspacePointMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateSharedWorkspacePointHook>>>,
    TError,
    { id: string; data: SharedWorkspacePointDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUpdateSharedWorkspacePointHook>>>,
  TError,
  { id: string; data: SharedWorkspacePointDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const updateSharedWorkspacePoint = useUpdateSharedWorkspacePointHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUpdateSharedWorkspacePointHook>>>,
    { id: string; data: SharedWorkspacePointDto }
  > = props => {
    const { id, data } = props ?? {};

    return updateSharedWorkspacePoint(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateSharedWorkspacePointMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUpdateSharedWorkspacePointHook>>>
>;
export type UpdateSharedWorkspacePointMutationBody = SharedWorkspacePointDto;
export type UpdateSharedWorkspacePointMutationError = unknown;

/**
 * @summary Update shared workspace point
 */
export const useUpdateSharedWorkspacePoint = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateSharedWorkspacePointHook>>>,
    TError,
    { id: string; data: SharedWorkspacePointDto },
    TContext
  >;
}) => {
  const mutationOptions = useUpdateSharedWorkspacePointMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Delete shared workspace point
 */
export const useDeleteSharedWorkspacePointHook = () => {
  const deleteSharedWorkspacePoint = useCustomAxios<void>();

  return (id: string) => {
    return deleteSharedWorkspacePoint({
      url: `/workspace/sharedWorkspace/point/${id}`,
      method: 'delete'
    });
  };
};

export const useDeleteSharedWorkspacePointMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteSharedWorkspacePointHook>>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteSharedWorkspacePointHook>>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteSharedWorkspacePoint = useDeleteSharedWorkspacePointHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteSharedWorkspacePointHook>>>,
    { id: string }
  > = props => {
    const { id } = props ?? {};

    return deleteSharedWorkspacePoint(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteSharedWorkspacePointMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteSharedWorkspacePointHook>>>
>;

export type DeleteSharedWorkspacePointMutationError = unknown;

/**
 * @summary Delete shared workspace point
 */
export const useDeleteSharedWorkspacePoint = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteSharedWorkspacePointHook>>>,
    TError,
    { id: string },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteSharedWorkspacePointMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Patch shared workspace point
 */
export const usePatchSharedWorkspacePointHook = () => {
  const patchSharedWorkspacePoint = useCustomAxios<void>();

  return (id: string, sharedWorkspacePointDto: SharedWorkspacePointDto) => {
    return patchSharedWorkspacePoint({
      url: `/workspace/sharedWorkspace/point/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: sharedWorkspacePointDto
    });
  };
};

export const usePatchSharedWorkspacePointMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchSharedWorkspacePointHook>>>,
    TError,
    { id: string; data: SharedWorkspacePointDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePatchSharedWorkspacePointHook>>>,
  TError,
  { id: string; data: SharedWorkspacePointDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const patchSharedWorkspacePoint = usePatchSharedWorkspacePointHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePatchSharedWorkspacePointHook>>>,
    { id: string; data: SharedWorkspacePointDto }
  > = props => {
    const { id, data } = props ?? {};

    return patchSharedWorkspacePoint(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchSharedWorkspacePointMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePatchSharedWorkspacePointHook>>>
>;
export type PatchSharedWorkspacePointMutationBody = SharedWorkspacePointDto;
export type PatchSharedWorkspacePointMutationError = unknown;

/**
 * @summary Patch shared workspace point
 */
export const usePatchSharedWorkspacePoint = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchSharedWorkspacePointHook>>>,
    TError,
    { id: string; data: SharedWorkspacePointDto },
    TContext
  >;
}) => {
  const mutationOptions = usePatchSharedWorkspacePointMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get Module data
 */
export const useGetModuleDataHook = () => {
  const getModuleData = useCustomAxios<ModuleResponse>();

  return (id: string, signal?: AbortSignal) => {
    return getModuleData({ url: `/workspace/module/${id}`, method: 'get', signal });
  };
};

export const getGetModuleDataQueryKey = (id: string) => [`/workspace/module/${id}`] as const;

export const useGetModuleDataQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetModuleDataHook>>>,
  TError = ModuleResponse
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetModuleDataHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetModuleDataHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetModuleDataQueryKey(id);

  const getModuleData = useGetModuleDataHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetModuleDataHook>>>> = ({
    signal
  }) => getModuleData(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type GetModuleDataQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetModuleDataHook>>>
>;
export type GetModuleDataQueryError = ModuleResponse;

/**
 * @summary Get Module data
 */
export const useGetModuleData = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetModuleDataHook>>>,
  TError = ModuleResponse
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetModuleDataHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetModuleDataQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Update Module data
 */
export const useUpdateModuleDataHook = () => {
  const updateModuleData = useCustomAxios<void>();

  return (id: string, updateModuleRequest: UpdateModuleRequest) => {
    return updateModuleData({
      url: `/workspace/module/${id}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: updateModuleRequest
    });
  };
};

export const useUpdateModuleDataMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateModuleDataHook>>>,
    TError,
    { id: string; data: UpdateModuleRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUpdateModuleDataHook>>>,
  TError,
  { id: string; data: UpdateModuleRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const updateModuleData = useUpdateModuleDataHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUpdateModuleDataHook>>>,
    { id: string; data: UpdateModuleRequest }
  > = props => {
    const { id, data } = props ?? {};

    return updateModuleData(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateModuleDataMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUpdateModuleDataHook>>>
>;
export type UpdateModuleDataMutationBody = UpdateModuleRequest;
export type UpdateModuleDataMutationError = unknown;

/**
 * @summary Update Module data
 */
export const useUpdateModuleData = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateModuleDataHook>>>,
    TError,
    { id: string; data: UpdateModuleRequest },
    TContext
  >;
}) => {
  const mutationOptions = useUpdateModuleDataMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Update Module analysis
 */
export const useUpdateModuleAnalysisHook = () => {
  const updateModuleAnalysis = useCustomAxios<void>();

  return (id: string, moduleAnalysisDto: ModuleAnalysisDto) => {
    return updateModuleAnalysis({
      url: `/workspace/module/${id}/analysis`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: moduleAnalysisDto
    });
  };
};

export const useUpdateModuleAnalysisMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateModuleAnalysisHook>>>,
    TError,
    { id: string; data: ModuleAnalysisDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUpdateModuleAnalysisHook>>>,
  TError,
  { id: string; data: ModuleAnalysisDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const updateModuleAnalysis = useUpdateModuleAnalysisHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUpdateModuleAnalysisHook>>>,
    { id: string; data: ModuleAnalysisDto }
  > = props => {
    const { id, data } = props ?? {};

    return updateModuleAnalysis(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateModuleAnalysisMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUpdateModuleAnalysisHook>>>
>;
export type UpdateModuleAnalysisMutationBody = ModuleAnalysisDto;
export type UpdateModuleAnalysisMutationError = unknown;

/**
 * @summary Update Module analysis
 */
export const useUpdateModuleAnalysis = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateModuleAnalysisHook>>>,
    TError,
    { id: string; data: ModuleAnalysisDto },
    TContext
  >;
}) => {
  const mutationOptions = useUpdateModuleAnalysisMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Generate download file url
 */
export const useGenerateDownloadUrlHook = () => {
  const generateDownloadUrl = useCustomAxios<FileResponse>();

  return (name: string, params: GenerateDownloadUrlParams) => {
    return generateDownloadUrl({ url: `/workspace/file/${name}/download`, method: 'put', params });
  };
};

export const useGenerateDownloadUrlMutationOptions = <
  TError = FileResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useGenerateDownloadUrlHook>>>,
    TError,
    { name: string; params: GenerateDownloadUrlParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useGenerateDownloadUrlHook>>>,
  TError,
  { name: string; params: GenerateDownloadUrlParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const generateDownloadUrl = useGenerateDownloadUrlHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useGenerateDownloadUrlHook>>>,
    { name: string; params: GenerateDownloadUrlParams }
  > = props => {
    const { name, params } = props ?? {};

    return generateDownloadUrl(name, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type GenerateDownloadUrlMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGenerateDownloadUrlHook>>>
>;

export type GenerateDownloadUrlMutationError = FileResponse;

/**
 * @summary Generate download file url
 */
export const useGenerateDownloadUrl = <TError = FileResponse, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useGenerateDownloadUrlHook>>>,
    TError,
    { name: string; params: GenerateDownloadUrlParams },
    TContext
  >;
}) => {
  const mutationOptions = useGenerateDownloadUrlMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Update business Area rating
 */
export const useUpdateBusinessAreaRatingHook = () => {
  const updateBusinessAreaRating = useCustomAxios<void>();

  return (businessArea: string, updateRatingRequest: UpdateRatingRequest) => {
    return updateBusinessAreaRating({
      url: `/workspace/businessArea/${businessArea}/rating`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: updateRatingRequest
    });
  };
};

export const useUpdateBusinessAreaRatingMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateBusinessAreaRatingHook>>>,
    TError,
    { businessArea: string; data: UpdateRatingRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUpdateBusinessAreaRatingHook>>>,
  TError,
  { businessArea: string; data: UpdateRatingRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const updateBusinessAreaRating = useUpdateBusinessAreaRatingHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUpdateBusinessAreaRatingHook>>>,
    { businessArea: string; data: UpdateRatingRequest }
  > = props => {
    const { businessArea, data } = props ?? {};

    return updateBusinessAreaRating(businessArea, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateBusinessAreaRatingMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUpdateBusinessAreaRatingHook>>>
>;
export type UpdateBusinessAreaRatingMutationBody = UpdateRatingRequest;
export type UpdateBusinessAreaRatingMutationError = unknown;

/**
 * @summary Update business Area rating
 */
export const useUpdateBusinessAreaRating = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateBusinessAreaRatingHook>>>,
    TError,
    { businessArea: string; data: UpdateRatingRequest },
    TContext
  >;
}) => {
  const mutationOptions = useUpdateBusinessAreaRatingMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Update module rating
 */
export const useUpdateModuleRatingHook = () => {
  const updateModuleRating = useCustomAxios<void>();

  return (businessArea: string, moduleKey: string, updateRatingRequest: UpdateRatingRequest) => {
    return updateModuleRating({
      url: `/workspace/businessArea/${businessArea}/module/${moduleKey}/rating`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: updateRatingRequest
    });
  };
};

export const useUpdateModuleRatingMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateModuleRatingHook>>>,
    TError,
    { businessArea: string; moduleKey: string; data: UpdateRatingRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUpdateModuleRatingHook>>>,
  TError,
  { businessArea: string; moduleKey: string; data: UpdateRatingRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const updateModuleRating = useUpdateModuleRatingHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUpdateModuleRatingHook>>>,
    { businessArea: string; moduleKey: string; data: UpdateRatingRequest }
  > = props => {
    const { businessArea, moduleKey, data } = props ?? {};

    return updateModuleRating(businessArea, moduleKey, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateModuleRatingMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUpdateModuleRatingHook>>>
>;
export type UpdateModuleRatingMutationBody = UpdateRatingRequest;
export type UpdateModuleRatingMutationError = unknown;

/**
 * @summary Update module rating
 */
export const useUpdateModuleRating = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateModuleRatingHook>>>,
    TError,
    { businessArea: string; moduleKey: string; data: UpdateRatingRequest },
    TContext
  >;
}) => {
  const mutationOptions = useUpdateModuleRatingMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Update custom Business Area
 */
export const useUpdateCustomBusinessAreaHook = () => {
  const updateCustomBusinessArea = useCustomAxios<void>();

  return (id: string, customBusinessAreaRequest: CustomBusinessAreaRequest) => {
    return updateCustomBusinessArea({
      url: `/workspace/businessArea/metadata/${id}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: customBusinessAreaRequest
    });
  };
};

export const useUpdateCustomBusinessAreaMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateCustomBusinessAreaHook>>>,
    TError,
    { id: string; data: CustomBusinessAreaRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUpdateCustomBusinessAreaHook>>>,
  TError,
  { id: string; data: CustomBusinessAreaRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const updateCustomBusinessArea = useUpdateCustomBusinessAreaHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUpdateCustomBusinessAreaHook>>>,
    { id: string; data: CustomBusinessAreaRequest }
  > = props => {
    const { id, data } = props ?? {};

    return updateCustomBusinessArea(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateCustomBusinessAreaMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUpdateCustomBusinessAreaHook>>>
>;
export type UpdateCustomBusinessAreaMutationBody = CustomBusinessAreaRequest;
export type UpdateCustomBusinessAreaMutationError = unknown;

/**
 * @summary Update custom Business Area
 */
export const useUpdateCustomBusinessArea = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateCustomBusinessAreaHook>>>,
    TError,
    { id: string; data: CustomBusinessAreaRequest },
    TContext
  >;
}) => {
  const mutationOptions = useUpdateCustomBusinessAreaMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Delete custom Business Area
 */
export const useDeleteCustomBusinessAreaHook = () => {
  const deleteCustomBusinessArea = useCustomAxios<void>();

  return (id: string) => {
    return deleteCustomBusinessArea({
      url: `/workspace/businessArea/metadata/${id}`,
      method: 'delete'
    });
  };
};

export const useDeleteCustomBusinessAreaMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteCustomBusinessAreaHook>>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteCustomBusinessAreaHook>>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteCustomBusinessArea = useDeleteCustomBusinessAreaHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteCustomBusinessAreaHook>>>,
    { id: string }
  > = props => {
    const { id } = props ?? {};

    return deleteCustomBusinessArea(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteCustomBusinessAreaMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteCustomBusinessAreaHook>>>
>;

export type DeleteCustomBusinessAreaMutationError = unknown;

/**
 * @summary Delete custom Business Area
 */
export const useDeleteCustomBusinessArea = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteCustomBusinessAreaHook>>>,
    TError,
    { id: string },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteCustomBusinessAreaMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Update user
 */
export const useUpdateUserHook = () => {
  const updateUser = useCustomAxios<void>();

  return (id: string, updateUserRequest: UpdateUserRequest) => {
    return updateUser({
      url: `/user/${id}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: updateUserRequest
    });
  };
};

export const useUpdateUserMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateUserHook>>>,
    TError,
    { id: string; data: UpdateUserRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUpdateUserHook>>>,
  TError,
  { id: string; data: UpdateUserRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const updateUser = useUpdateUserHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUpdateUserHook>>>,
    { id: string; data: UpdateUserRequest }
  > = props => {
    const { id, data } = props ?? {};

    return updateUser(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateUserMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUpdateUserHook>>>
>;
export type UpdateUserMutationBody = UpdateUserRequest;
export type UpdateUserMutationError = unknown;

/**
 * @summary Update user
 */
export const useUpdateUser = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateUserHook>>>,
    TError,
    { id: string; data: UpdateUserRequest },
    TContext
  >;
}) => {
  const mutationOptions = useUpdateUserMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Delete user
 */
export const useDeleteUserHook = () => {
  const deleteUser = useCustomAxios<void>();

  return (id: string) => {
    return deleteUser({ url: `/user/${id}`, method: 'delete' });
  };
};

export const useDeleteUserMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteUserHook>>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteUserHook>>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteUser = useDeleteUserHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteUserHook>>>,
    { id: string }
  > = props => {
    const { id } = props ?? {};

    return deleteUser(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteUserMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteUserHook>>>
>;

export type DeleteUserMutationError = unknown;

/**
 * @summary Delete user
 */
export const useDeleteUser = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteUserHook>>>,
    TError,
    { id: string },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteUserMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Send user invite email
 */
export const useSendInviteEmailHook = () => {
  const sendInviteEmail = useCustomAxios<void>();

  return (id: string) => {
    return sendInviteEmail({ url: `/user/${id}/sendInviteEmail`, method: 'put' });
  };
};

export const useSendInviteEmailMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSendInviteEmailHook>>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useSendInviteEmailHook>>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const sendInviteEmail = useSendInviteEmailHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useSendInviteEmailHook>>>,
    { id: string }
  > = props => {
    const { id } = props ?? {};

    return sendInviteEmail(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type SendInviteEmailMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useSendInviteEmailHook>>>
>;

export type SendInviteEmailMutationError = unknown;

/**
 * @summary Send user invite email
 */
export const useSendInviteEmail = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSendInviteEmailHook>>>,
    TError,
    { id: string },
    TContext
  >;
}) => {
  const mutationOptions = useSendInviteEmailMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Update user photo
 */
export const useUpdateUserPhotoHook = () => {
  const updateUserPhoto = useCustomAxios<void>();

  return (id: string, updateUserPhotoBody: UpdateUserPhotoBody) => {
    return updateUserPhoto({
      url: `/user/${id}/photo`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: updateUserPhotoBody
    });
  };
};

export const useUpdateUserPhotoMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateUserPhotoHook>>>,
    TError,
    { id: string; data: UpdateUserPhotoBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUpdateUserPhotoHook>>>,
  TError,
  { id: string; data: UpdateUserPhotoBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const updateUserPhoto = useUpdateUserPhotoHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUpdateUserPhotoHook>>>,
    { id: string; data: UpdateUserPhotoBody }
  > = props => {
    const { id, data } = props ?? {};

    return updateUserPhoto(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateUserPhotoMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUpdateUserPhotoHook>>>
>;
export type UpdateUserPhotoMutationBody = UpdateUserPhotoBody;
export type UpdateUserPhotoMutationError = unknown;

/**
 * @summary Update user photo
 */
export const useUpdateUserPhoto = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateUserPhotoHook>>>,
    TError,
    { id: string; data: UpdateUserPhotoBody },
    TContext
  >;
}) => {
  const mutationOptions = useUpdateUserPhotoMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Update role
 */
export const useUpdateRoleHook = () => {
  const updateRole = useCustomAxios<void>();

  return (id: string, roleDto: RoleDto) => {
    return updateRole({
      url: `/role/${id}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: roleDto
    });
  };
};

export const useUpdateRoleMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateRoleHook>>>,
    TError,
    { id: string; data: RoleDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUpdateRoleHook>>>,
  TError,
  { id: string; data: RoleDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const updateRole = useUpdateRoleHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUpdateRoleHook>>>,
    { id: string; data: RoleDto }
  > = props => {
    const { id, data } = props ?? {};

    return updateRole(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateRoleMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUpdateRoleHook>>>
>;
export type UpdateRoleMutationBody = RoleDto;
export type UpdateRoleMutationError = unknown;

/**
 * @summary Update role
 */
export const useUpdateRole = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateRoleHook>>>,
    TError,
    { id: string; data: RoleDto },
    TContext
  >;
}) => {
  const mutationOptions = useUpdateRoleMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Delete role
 */
export const useDeleteRoleHook = () => {
  const deleteRole = useCustomAxios<void>();

  return (id: string) => {
    return deleteRole({ url: `/role/${id}`, method: 'delete' });
  };
};

export const useDeleteRoleMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteRoleHook>>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteRoleHook>>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteRole = useDeleteRoleHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteRoleHook>>>,
    { id: string }
  > = props => {
    const { id } = props ?? {};

    return deleteRole(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteRoleMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteRoleHook>>>
>;

export type DeleteRoleMutationError = unknown;

/**
 * @summary Delete role
 */
export const useDeleteRole = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteRoleHook>>>,
    TError,
    { id: string },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteRoleMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get metric definition
 */
export const useGetMetricDefinitionHook = () => {
  const getMetricDefinition = useCustomAxios<MetricDefinitionResponse>();

  return (id: string, signal?: AbortSignal) => {
    return getMetricDefinition({ url: `/metric/definition/${id}`, method: 'get', signal });
  };
};

export const getGetMetricDefinitionQueryKey = (id: string) => [`/metric/definition/${id}`] as const;

export const useGetMetricDefinitionQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetMetricDefinitionHook>>>,
  TError = MetricDefinitionResponse
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetMetricDefinitionHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetMetricDefinitionHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMetricDefinitionQueryKey(id);

  const getMetricDefinition = useGetMetricDefinitionHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetMetricDefinitionHook>>>
  > = ({ signal }) => getMetricDefinition(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type GetMetricDefinitionQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetMetricDefinitionHook>>>
>;
export type GetMetricDefinitionQueryError = MetricDefinitionResponse;

/**
 * @summary Get metric definition
 */
export const useGetMetricDefinition = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetMetricDefinitionHook>>>,
  TError = MetricDefinitionResponse
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetMetricDefinitionHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetMetricDefinitionQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Update metric definition
 */
export const useUpdateMetricDefinitionHook = () => {
  const updateMetricDefinition = useCustomAxios<void>();

  return (id: string, metricDefinitionDto: MetricDefinitionDto) => {
    return updateMetricDefinition({
      url: `/metric/definition/${id}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: metricDefinitionDto
    });
  };
};

export const useUpdateMetricDefinitionMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateMetricDefinitionHook>>>,
    TError,
    { id: string; data: MetricDefinitionDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUpdateMetricDefinitionHook>>>,
  TError,
  { id: string; data: MetricDefinitionDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const updateMetricDefinition = useUpdateMetricDefinitionHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUpdateMetricDefinitionHook>>>,
    { id: string; data: MetricDefinitionDto }
  > = props => {
    const { id, data } = props ?? {};

    return updateMetricDefinition(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateMetricDefinitionMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUpdateMetricDefinitionHook>>>
>;
export type UpdateMetricDefinitionMutationBody = MetricDefinitionDto;
export type UpdateMetricDefinitionMutationError = unknown;

/**
 * @summary Update metric definition
 */
export const useUpdateMetricDefinition = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateMetricDefinitionHook>>>,
    TError,
    { id: string; data: MetricDefinitionDto },
    TContext
  >;
}) => {
  const mutationOptions = useUpdateMetricDefinitionMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Delete metric definition
 */
export const useDeleteMetricDefinitionHook = () => {
  const deleteMetricDefinition = useCustomAxios<void>();

  return (id: string) => {
    return deleteMetricDefinition({ url: `/metric/definition/${id}`, method: 'delete' });
  };
};

export const useDeleteMetricDefinitionMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteMetricDefinitionHook>>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteMetricDefinitionHook>>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteMetricDefinition = useDeleteMetricDefinitionHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteMetricDefinitionHook>>>,
    { id: string }
  > = props => {
    const { id } = props ?? {};

    return deleteMetricDefinition(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteMetricDefinitionMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteMetricDefinitionHook>>>
>;

export type DeleteMetricDefinitionMutationError = unknown;

/**
 * @summary Delete metric definition
 */
export const useDeleteMetricDefinition = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteMetricDefinitionHook>>>,
    TError,
    { id: string },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteMetricDefinitionMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Patch metric definition
 */
export const usePatchMetricDefinitionHook = () => {
  const patchMetricDefinition = useCustomAxios<void>();

  return (id: string, metricDefinitionDto: MetricDefinitionDto) => {
    return patchMetricDefinition({
      url: `/metric/definition/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: metricDefinitionDto
    });
  };
};

export const usePatchMetricDefinitionMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchMetricDefinitionHook>>>,
    TError,
    { id: string; data: MetricDefinitionDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePatchMetricDefinitionHook>>>,
  TError,
  { id: string; data: MetricDefinitionDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const patchMetricDefinition = usePatchMetricDefinitionHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePatchMetricDefinitionHook>>>,
    { id: string; data: MetricDefinitionDto }
  > = props => {
    const { id, data } = props ?? {};

    return patchMetricDefinition(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchMetricDefinitionMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePatchMetricDefinitionHook>>>
>;
export type PatchMetricDefinitionMutationBody = MetricDefinitionDto;
export type PatchMetricDefinitionMutationError = unknown;

/**
 * @summary Patch metric definition
 */
export const usePatchMetricDefinition = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchMetricDefinitionHook>>>,
    TError,
    { id: string; data: MetricDefinitionDto },
    TContext
  >;
}) => {
  const mutationOptions = usePatchMetricDefinitionMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Calc metric definition progress
 */
export const useCalcMetricDefinitionProgressHook = () => {
  const calcMetricDefinitionProgress = useCustomAxios<MetricDefinitionProgressResponse>();

  return (id: string) => {
    return calcMetricDefinitionProgress({
      url: `/metric/definition/${id}/progress`,
      method: 'put'
    });
  };
};

export const useCalcMetricDefinitionProgressMutationOptions = <
  TError = MetricDefinitionProgressResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCalcMetricDefinitionProgressHook>>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCalcMetricDefinitionProgressHook>>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const calcMetricDefinitionProgress = useCalcMetricDefinitionProgressHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCalcMetricDefinitionProgressHook>>>,
    { id: string }
  > = props => {
    const { id } = props ?? {};

    return calcMetricDefinitionProgress(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type CalcMetricDefinitionProgressMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCalcMetricDefinitionProgressHook>>>
>;

export type CalcMetricDefinitionProgressMutationError = MetricDefinitionProgressResponse;

/**
 * @summary Calc metric definition progress
 */
export const useCalcMetricDefinitionProgress = <
  TError = MetricDefinitionProgressResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCalcMetricDefinitionProgressHook>>>,
    TError,
    { id: string },
    TContext
  >;
}) => {
  const mutationOptions = useCalcMetricDefinitionProgressMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Reset metric integration
 */
export const useResetMetricDefinitionIntegrationHook = () => {
  const resetMetricDefinitionIntegration = useCustomAxios<void>();

  return (id: string) => {
    return resetMetricDefinitionIntegration({
      url: `/metric/definition/${id}/integration/reset`,
      method: 'put'
    });
  };
};

export const useResetMetricDefinitionIntegrationMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useResetMetricDefinitionIntegrationHook>>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useResetMetricDefinitionIntegrationHook>>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const resetMetricDefinitionIntegration = useResetMetricDefinitionIntegrationHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useResetMetricDefinitionIntegrationHook>>>,
    { id: string }
  > = props => {
    const { id } = props ?? {};

    return resetMetricDefinitionIntegration(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type ResetMetricDefinitionIntegrationMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useResetMetricDefinitionIntegrationHook>>>
>;

export type ResetMetricDefinitionIntegrationMutationError = unknown;

/**
 * @summary Reset metric integration
 */
export const useResetMetricDefinitionIntegration = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useResetMetricDefinitionIntegrationHook>>>,
    TError,
    { id: string },
    TContext
  >;
}) => {
  const mutationOptions = useResetMetricDefinitionIntegrationMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary List metric definition data points
 */
export const useListMetricDataPointsHook = () => {
  const listMetricDataPoints = useCustomAxios<MetricDataPointResponse>();

  return (id: string, signal?: AbortSignal) => {
    return listMetricDataPoints({
      url: `/metric/definition/${id}/dataPoint`,
      method: 'get',
      signal
    });
  };
};

export const getListMetricDataPointsQueryKey = (id: string) =>
  [`/metric/definition/${id}/dataPoint`] as const;

export const useListMetricDataPointsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useListMetricDataPointsHook>>>,
  TError = MetricDataPointResponse
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useListMetricDataPointsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useListMetricDataPointsHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListMetricDataPointsQueryKey(id);

  const listMetricDataPoints = useListMetricDataPointsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useListMetricDataPointsHook>>>
  > = ({ signal }) => listMetricDataPoints(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type ListMetricDataPointsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useListMetricDataPointsHook>>>
>;
export type ListMetricDataPointsQueryError = MetricDataPointResponse;

/**
 * @summary List metric definition data points
 */
export const useListMetricDataPoints = <
  TData = Awaited<ReturnType<ReturnType<typeof useListMetricDataPointsHook>>>,
  TError = MetricDataPointResponse
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useListMetricDataPointsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useListMetricDataPointsQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Calc metric definition data points
 */
export const useCalcMetricDefinitionDataPointsHook = () => {
  const calcMetricDefinitionDataPoints = useCustomAxios<MetricDataPointResponse>();

  return (id: string) => {
    return calcMetricDefinitionDataPoints({
      url: `/metric/definition/${id}/dataPoint`,
      method: 'put'
    });
  };
};

export const useCalcMetricDefinitionDataPointsMutationOptions = <
  TError = MetricDataPointResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCalcMetricDefinitionDataPointsHook>>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCalcMetricDefinitionDataPointsHook>>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const calcMetricDefinitionDataPoints = useCalcMetricDefinitionDataPointsHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCalcMetricDefinitionDataPointsHook>>>,
    { id: string }
  > = props => {
    const { id } = props ?? {};

    return calcMetricDefinitionDataPoints(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type CalcMetricDefinitionDataPointsMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCalcMetricDefinitionDataPointsHook>>>
>;

export type CalcMetricDefinitionDataPointsMutationError = MetricDataPointResponse;

/**
 * @summary Calc metric definition data points
 */
export const useCalcMetricDefinitionDataPoints = <
  TError = MetricDataPointResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCalcMetricDefinitionDataPointsHook>>>,
    TError,
    { id: string },
    TContext
  >;
}) => {
  const mutationOptions = useCalcMetricDefinitionDataPointsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Update meeting topics
 */
export const useUpdateMeetingTopicsHook = () => {
  const updateMeetingTopics = useCustomAxios<void>();

  return (meetingId: string, updateMeetingTopicsRequest: UpdateMeetingTopicsRequest) => {
    return updateMeetingTopics({
      url: `/meeting/${meetingId}/topic`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: updateMeetingTopicsRequest
    });
  };
};

export const useUpdateMeetingTopicsMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateMeetingTopicsHook>>>,
    TError,
    { meetingId: string; data: UpdateMeetingTopicsRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUpdateMeetingTopicsHook>>>,
  TError,
  { meetingId: string; data: UpdateMeetingTopicsRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const updateMeetingTopics = useUpdateMeetingTopicsHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUpdateMeetingTopicsHook>>>,
    { meetingId: string; data: UpdateMeetingTopicsRequest }
  > = props => {
    const { meetingId, data } = props ?? {};

    return updateMeetingTopics(meetingId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateMeetingTopicsMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUpdateMeetingTopicsHook>>>
>;
export type UpdateMeetingTopicsMutationBody = UpdateMeetingTopicsRequest;
export type UpdateMeetingTopicsMutationError = unknown;

/**
 * @summary Update meeting topics
 */
export const useUpdateMeetingTopics = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateMeetingTopicsHook>>>,
    TError,
    { meetingId: string; data: UpdateMeetingTopicsRequest },
    TContext
  >;
}) => {
  const mutationOptions = useUpdateMeetingTopicsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Send meeting followup
 */
export const useSendMeetingFollowUpsHook = () => {
  const sendMeetingFollowUps = useCustomAxios<void>();

  return (meetingId: string) => {
    return sendMeetingFollowUps({ url: `/meeting/${meetingId}/sendFollowUp`, method: 'put' });
  };
};

export const useSendMeetingFollowUpsMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSendMeetingFollowUpsHook>>>,
    TError,
    { meetingId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useSendMeetingFollowUpsHook>>>,
  TError,
  { meetingId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const sendMeetingFollowUps = useSendMeetingFollowUpsHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useSendMeetingFollowUpsHook>>>,
    { meetingId: string }
  > = props => {
    const { meetingId } = props ?? {};

    return sendMeetingFollowUps(meetingId);
  };

  return { mutationFn, ...mutationOptions };
};

export type SendMeetingFollowUpsMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useSendMeetingFollowUpsHook>>>
>;

export type SendMeetingFollowUpsMutationError = unknown;

/**
 * @summary Send meeting followup
 */
export const useSendMeetingFollowUps = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSendMeetingFollowUpsHook>>>,
    TError,
    { meetingId: string },
    TContext
  >;
}) => {
  const mutationOptions = useSendMeetingFollowUpsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Update meeting notes
 */
export const useUpdateMeetingNotesHook = () => {
  const updateMeetingNotes = useCustomAxios<void>();

  return (meetingId: string, updateMeetingNotesRequest: UpdateMeetingNotesRequest) => {
    return updateMeetingNotes({
      url: `/meeting/${meetingId}/note`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: updateMeetingNotesRequest
    });
  };
};

export const useUpdateMeetingNotesMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateMeetingNotesHook>>>,
    TError,
    { meetingId: string; data: UpdateMeetingNotesRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUpdateMeetingNotesHook>>>,
  TError,
  { meetingId: string; data: UpdateMeetingNotesRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const updateMeetingNotes = useUpdateMeetingNotesHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUpdateMeetingNotesHook>>>,
    { meetingId: string; data: UpdateMeetingNotesRequest }
  > = props => {
    const { meetingId, data } = props ?? {};

    return updateMeetingNotes(meetingId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateMeetingNotesMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUpdateMeetingNotesHook>>>
>;
export type UpdateMeetingNotesMutationBody = UpdateMeetingNotesRequest;
export type UpdateMeetingNotesMutationError = unknown;

/**
 * @summary Update meeting notes
 */
export const useUpdateMeetingNotes = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateMeetingNotesHook>>>,
    TError,
    { meetingId: string; data: UpdateMeetingNotesRequest },
    TContext
  >;
}) => {
  const mutationOptions = useUpdateMeetingNotesMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Update meeting insights
 */
export const useUpdateMeetingInsightsHook = () => {
  const updateMeetingInsights = useCustomAxios<void>();

  return (meetingId: string, updateMeetingInsightsRequest: UpdateMeetingInsightsRequest) => {
    return updateMeetingInsights({
      url: `/meeting/${meetingId}/insight`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: updateMeetingInsightsRequest
    });
  };
};

export const useUpdateMeetingInsightsMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateMeetingInsightsHook>>>,
    TError,
    { meetingId: string; data: UpdateMeetingInsightsRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUpdateMeetingInsightsHook>>>,
  TError,
  { meetingId: string; data: UpdateMeetingInsightsRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const updateMeetingInsights = useUpdateMeetingInsightsHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUpdateMeetingInsightsHook>>>,
    { meetingId: string; data: UpdateMeetingInsightsRequest }
  > = props => {
    const { meetingId, data } = props ?? {};

    return updateMeetingInsights(meetingId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateMeetingInsightsMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUpdateMeetingInsightsHook>>>
>;
export type UpdateMeetingInsightsMutationBody = UpdateMeetingInsightsRequest;
export type UpdateMeetingInsightsMutationError = unknown;

/**
 * @summary Update meeting insights
 */
export const useUpdateMeetingInsights = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateMeetingInsightsHook>>>,
    TError,
    { meetingId: string; data: UpdateMeetingInsightsRequest },
    TContext
  >;
}) => {
  const mutationOptions = useUpdateMeetingInsightsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Update meeting followup
 */
export const useUpdateMeetingFollowUpHook = () => {
  const updateMeetingFollowUp = useCustomAxios<void>();

  return (meetingId: string, updateMeetingFollowUpRequest: UpdateMeetingFollowUpRequest) => {
    return updateMeetingFollowUp({
      url: `/meeting/${meetingId}/followup`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: updateMeetingFollowUpRequest
    });
  };
};

export const useUpdateMeetingFollowUpMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateMeetingFollowUpHook>>>,
    TError,
    { meetingId: string; data: UpdateMeetingFollowUpRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUpdateMeetingFollowUpHook>>>,
  TError,
  { meetingId: string; data: UpdateMeetingFollowUpRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const updateMeetingFollowUp = useUpdateMeetingFollowUpHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUpdateMeetingFollowUpHook>>>,
    { meetingId: string; data: UpdateMeetingFollowUpRequest }
  > = props => {
    const { meetingId, data } = props ?? {};

    return updateMeetingFollowUp(meetingId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateMeetingFollowUpMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUpdateMeetingFollowUpHook>>>
>;
export type UpdateMeetingFollowUpMutationBody = UpdateMeetingFollowUpRequest;
export type UpdateMeetingFollowUpMutationError = unknown;

/**
 * @summary Update meeting followup
 */
export const useUpdateMeetingFollowUp = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateMeetingFollowUpHook>>>,
    TError,
    { meetingId: string; data: UpdateMeetingFollowUpRequest },
    TContext
  >;
}) => {
  const mutationOptions = useUpdateMeetingFollowUpMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary End a meeting
 */
export const useEndMeetingHook = () => {
  const endMeeting = useCustomAxios<void>();

  return (meetingId: string) => {
    return endMeeting({ url: `/meeting/${meetingId}/end`, method: 'put' });
  };
};

export const useEndMeetingMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useEndMeetingHook>>>,
    TError,
    { meetingId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useEndMeetingHook>>>,
  TError,
  { meetingId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const endMeeting = useEndMeetingHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useEndMeetingHook>>>,
    { meetingId: string }
  > = props => {
    const { meetingId } = props ?? {};

    return endMeeting(meetingId);
  };

  return { mutationFn, ...mutationOptions };
};

export type EndMeetingMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useEndMeetingHook>>>
>;

export type EndMeetingMutationError = unknown;

/**
 * @summary End a meeting
 */
export const useEndMeeting = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useEndMeetingHook>>>,
    TError,
    { meetingId: string },
    TContext
  >;
}) => {
  const mutationOptions = useEndMeetingMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Trigger data source collect
 */
export const useCollectDataSourceHook = () => {
  const collectDataSource = useCustomAxios<void>();

  return (id: string) => {
    return collectDataSource({ url: `/integration/dataSource/${id}/collect`, method: 'put' });
  };
};

export const useCollectDataSourceMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCollectDataSourceHook>>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCollectDataSourceHook>>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const collectDataSource = useCollectDataSourceHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCollectDataSourceHook>>>,
    { id: string }
  > = props => {
    const { id } = props ?? {};

    return collectDataSource(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type CollectDataSourceMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCollectDataSourceHook>>>
>;

export type CollectDataSourceMutationError = unknown;

/**
 * @summary Trigger data source collect
 */
export const useCollectDataSource = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCollectDataSourceHook>>>,
    TError,
    { id: string },
    TContext
  >;
}) => {
  const mutationOptions = useCollectDataSourceMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Regenerate post answer
 */
export const useRegenerateAnswerHook = () => {
  const regenerateAnswer = useCustomAxios<void>();

  return (id: string) => {
    return regenerateAnswer({ url: `/forum/post/${id}/regenerate`, method: 'put' });
  };
};

export const useRegenerateAnswerMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useRegenerateAnswerHook>>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useRegenerateAnswerHook>>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const regenerateAnswer = useRegenerateAnswerHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useRegenerateAnswerHook>>>,
    { id: string }
  > = props => {
    const { id } = props ?? {};

    return regenerateAnswer(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type RegenerateAnswerMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useRegenerateAnswerHook>>>
>;

export type RegenerateAnswerMutationError = unknown;

/**
 * @summary Regenerate post answer
 */
export const useRegenerateAnswer = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useRegenerateAnswerHook>>>,
    TError,
    { id: string },
    TContext
  >;
}) => {
  const mutationOptions = useRegenerateAnswerMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Update answer rating
 */
export const useUpdateAnswerRatingHook = () => {
  const updateAnswerRating = useCustomAxios<void>();

  return (answerId: string, askJourneyUpdateRatingRequest: AskJourneyUpdateRatingRequest) => {
    return updateAnswerRating({
      url: `/ask/answer/${answerId}/rating`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: askJourneyUpdateRatingRequest
    });
  };
};

export const useUpdateAnswerRatingMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateAnswerRatingHook>>>,
    TError,
    { answerId: string; data: AskJourneyUpdateRatingRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUpdateAnswerRatingHook>>>,
  TError,
  { answerId: string; data: AskJourneyUpdateRatingRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const updateAnswerRating = useUpdateAnswerRatingHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUpdateAnswerRatingHook>>>,
    { answerId: string; data: AskJourneyUpdateRatingRequest }
  > = props => {
    const { answerId, data } = props ?? {};

    return updateAnswerRating(answerId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateAnswerRatingMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUpdateAnswerRatingHook>>>
>;
export type UpdateAnswerRatingMutationBody = AskJourneyUpdateRatingRequest;
export type UpdateAnswerRatingMutationError = unknown;

/**
 * @summary Update answer rating
 */
export const useUpdateAnswerRating = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateAnswerRatingHook>>>,
    TError,
    { answerId: string; data: AskJourneyUpdateRatingRequest },
    TContext
  >;
}) => {
  const mutationOptions = useUpdateAnswerRatingMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Update notification status
 */
export const useUpdateNotificationStatusHook = () => {
  const updateNotificationStatus = useCustomAxios<void>();

  return (id: string, updateAppNotificationStatusRequest: UpdateAppNotificationStatusRequest) => {
    return updateNotificationStatus({
      url: `/appNotification/${id}/status`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: updateAppNotificationStatusRequest
    });
  };
};

export const useUpdateNotificationStatusMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateNotificationStatusHook>>>,
    TError,
    { id: string; data: UpdateAppNotificationStatusRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUpdateNotificationStatusHook>>>,
  TError,
  { id: string; data: UpdateAppNotificationStatusRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const updateNotificationStatus = useUpdateNotificationStatusHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUpdateNotificationStatusHook>>>,
    { id: string; data: UpdateAppNotificationStatusRequest }
  > = props => {
    const { id, data } = props ?? {};

    return updateNotificationStatus(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateNotificationStatusMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUpdateNotificationStatusHook>>>
>;
export type UpdateNotificationStatusMutationBody = UpdateAppNotificationStatusRequest;
export type UpdateNotificationStatusMutationError = unknown;

/**
 * @summary Update notification status
 */
export const useUpdateNotificationStatus = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateNotificationStatusHook>>>,
    TError,
    { id: string; data: UpdateAppNotificationStatusRequest },
    TContext
  >;
}) => {
  const mutationOptions = useUpdateNotificationStatusMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Bulk update notifications status
 */
export const useBulkUpdateNotificationsStatusHook = () => {
  const bulkUpdateNotificationsStatus = useCustomAxios<void>();

  return (updateAppNotificationStatusRequest: UpdateAppNotificationStatusRequest) => {
    return bulkUpdateNotificationsStatus({
      url: `/appNotification/status/bulk`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: updateAppNotificationStatusRequest
    });
  };
};

export const useBulkUpdateNotificationsStatusMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useBulkUpdateNotificationsStatusHook>>>,
    TError,
    { data: UpdateAppNotificationStatusRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useBulkUpdateNotificationsStatusHook>>>,
  TError,
  { data: UpdateAppNotificationStatusRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const bulkUpdateNotificationsStatus = useBulkUpdateNotificationsStatusHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useBulkUpdateNotificationsStatusHook>>>,
    { data: UpdateAppNotificationStatusRequest }
  > = props => {
    const { data } = props ?? {};

    return bulkUpdateNotificationsStatus(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type BulkUpdateNotificationsStatusMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useBulkUpdateNotificationsStatusHook>>>
>;
export type BulkUpdateNotificationsStatusMutationBody = UpdateAppNotificationStatusRequest;
export type BulkUpdateNotificationsStatusMutationError = unknown;

/**
 * @summary Bulk update notifications status
 */
export const useBulkUpdateNotificationsStatus = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useBulkUpdateNotificationsStatusHook>>>,
    TError,
    { data: UpdateAppNotificationStatusRequest },
    TContext
  >;
}) => {
  const mutationOptions = useBulkUpdateNotificationsStatusMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get objective
 */
export const useGetActionPlanObjectiveHook = () => {
  const getActionPlanObjective = useCustomAxios<ActionPlanObjectiveResponse>();

  return (id: string, signal?: AbortSignal) => {
    return getActionPlanObjective({ url: `/actionPlan/objective/${id}`, method: 'get', signal });
  };
};

export const getGetActionPlanObjectiveQueryKey = (id: string) =>
  [`/actionPlan/objective/${id}`] as const;

export const useGetActionPlanObjectiveQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetActionPlanObjectiveHook>>>,
  TError = ActionPlanObjectiveResponse
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetActionPlanObjectiveHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetActionPlanObjectiveHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetActionPlanObjectiveQueryKey(id);

  const getActionPlanObjective = useGetActionPlanObjectiveHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetActionPlanObjectiveHook>>>
  > = ({ signal }) => getActionPlanObjective(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type GetActionPlanObjectiveQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetActionPlanObjectiveHook>>>
>;
export type GetActionPlanObjectiveQueryError = ActionPlanObjectiveResponse;

/**
 * @summary Get objective
 */
export const useGetActionPlanObjective = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetActionPlanObjectiveHook>>>,
  TError = ActionPlanObjectiveResponse
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetActionPlanObjectiveHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetActionPlanObjectiveQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Update objective
 */
export const useUpdateActionPlanObjectiveHook = () => {
  const updateActionPlanObjective = useCustomAxios<void>();

  return (id: string, actionPlanObjectiveDto: ActionPlanObjectiveDto) => {
    return updateActionPlanObjective({
      url: `/actionPlan/objective/${id}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: actionPlanObjectiveDto
    });
  };
};

export const useUpdateActionPlanObjectiveMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateActionPlanObjectiveHook>>>,
    TError,
    { id: string; data: ActionPlanObjectiveDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUpdateActionPlanObjectiveHook>>>,
  TError,
  { id: string; data: ActionPlanObjectiveDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const updateActionPlanObjective = useUpdateActionPlanObjectiveHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUpdateActionPlanObjectiveHook>>>,
    { id: string; data: ActionPlanObjectiveDto }
  > = props => {
    const { id, data } = props ?? {};

    return updateActionPlanObjective(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateActionPlanObjectiveMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUpdateActionPlanObjectiveHook>>>
>;
export type UpdateActionPlanObjectiveMutationBody = ActionPlanObjectiveDto;
export type UpdateActionPlanObjectiveMutationError = unknown;

/**
 * @summary Update objective
 */
export const useUpdateActionPlanObjective = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateActionPlanObjectiveHook>>>,
    TError,
    { id: string; data: ActionPlanObjectiveDto },
    TContext
  >;
}) => {
  const mutationOptions = useUpdateActionPlanObjectiveMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Delete objective
 */
export const useDeleteActionPlanObjectiveHook = () => {
  const deleteActionPlanObjective = useCustomAxios<void>();

  return (id: string, params?: DeleteActionPlanObjectiveParams) => {
    return deleteActionPlanObjective({
      url: `/actionPlan/objective/${id}`,
      method: 'delete',
      params
    });
  };
};

export const useDeleteActionPlanObjectiveMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteActionPlanObjectiveHook>>>,
    TError,
    { id: string; params?: DeleteActionPlanObjectiveParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteActionPlanObjectiveHook>>>,
  TError,
  { id: string; params?: DeleteActionPlanObjectiveParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteActionPlanObjective = useDeleteActionPlanObjectiveHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteActionPlanObjectiveHook>>>,
    { id: string; params?: DeleteActionPlanObjectiveParams }
  > = props => {
    const { id, params } = props ?? {};

    return deleteActionPlanObjective(id, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteActionPlanObjectiveMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteActionPlanObjectiveHook>>>
>;

export type DeleteActionPlanObjectiveMutationError = unknown;

/**
 * @summary Delete objective
 */
export const useDeleteActionPlanObjective = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteActionPlanObjectiveHook>>>,
    TError,
    { id: string; params?: DeleteActionPlanObjectiveParams },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteActionPlanObjectiveMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Patch objective
 */
export const usePatchActionPlanObjectiveHook = () => {
  const patchActionPlanObjective = useCustomAxios<void>();

  return (id: string, actionPlanObjectiveDto: ActionPlanObjectiveDto) => {
    return patchActionPlanObjective({
      url: `/actionPlan/objective/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: actionPlanObjectiveDto
    });
  };
};

export const usePatchActionPlanObjectiveMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchActionPlanObjectiveHook>>>,
    TError,
    { id: string; data: ActionPlanObjectiveDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePatchActionPlanObjectiveHook>>>,
  TError,
  { id: string; data: ActionPlanObjectiveDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const patchActionPlanObjective = usePatchActionPlanObjectiveHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePatchActionPlanObjectiveHook>>>,
    { id: string; data: ActionPlanObjectiveDto }
  > = props => {
    const { id, data } = props ?? {};

    return patchActionPlanObjective(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchActionPlanObjectiveMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePatchActionPlanObjectiveHook>>>
>;
export type PatchActionPlanObjectiveMutationBody = ActionPlanObjectiveDto;
export type PatchActionPlanObjectiveMutationError = unknown;

/**
 * @summary Patch objective
 */
export const usePatchActionPlanObjective = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchActionPlanObjectiveHook>>>,
    TError,
    { id: string; data: ActionPlanObjectiveDto },
    TContext
  >;
}) => {
  const mutationOptions = usePatchActionPlanObjectiveMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Duplicate objective
 */
export const useDuplicateActionPlanObjectiveHook = () => {
  const duplicateActionPlanObjective = useCustomAxios<void>();

  return (id: string, params?: DuplicateActionPlanObjectiveParams) => {
    return duplicateActionPlanObjective({
      url: `/actionPlan/objective/${id}/duplicate`,
      method: 'put',
      params
    });
  };
};

export const useDuplicateActionPlanObjectiveMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDuplicateActionPlanObjectiveHook>>>,
    TError,
    { id: string; params?: DuplicateActionPlanObjectiveParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDuplicateActionPlanObjectiveHook>>>,
  TError,
  { id: string; params?: DuplicateActionPlanObjectiveParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const duplicateActionPlanObjective = useDuplicateActionPlanObjectiveHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDuplicateActionPlanObjectiveHook>>>,
    { id: string; params?: DuplicateActionPlanObjectiveParams }
  > = props => {
    const { id, params } = props ?? {};

    return duplicateActionPlanObjective(id, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type DuplicateActionPlanObjectiveMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDuplicateActionPlanObjectiveHook>>>
>;

export type DuplicateActionPlanObjectiveMutationError = unknown;

/**
 * @summary Duplicate objective
 */
export const useDuplicateActionPlanObjective = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDuplicateActionPlanObjectiveHook>>>,
    TError,
    { id: string; params?: DuplicateActionPlanObjectiveParams },
    TContext
  >;
}) => {
  const mutationOptions = useDuplicateActionPlanObjectiveMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get initiative
 */
export const useGetActionPlanInitiativeHook = () => {
  const getActionPlanInitiative = useCustomAxios<ActionPlanInitiativeResponse>();

  return (id: string, signal?: AbortSignal) => {
    return getActionPlanInitiative({ url: `/actionPlan/initiative/${id}`, method: 'get', signal });
  };
};

export const getGetActionPlanInitiativeQueryKey = (id: string) =>
  [`/actionPlan/initiative/${id}`] as const;

export const useGetActionPlanInitiativeQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetActionPlanInitiativeHook>>>,
  TError = ActionPlanInitiativeResponse
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetActionPlanInitiativeHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetActionPlanInitiativeHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetActionPlanInitiativeQueryKey(id);

  const getActionPlanInitiative = useGetActionPlanInitiativeHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetActionPlanInitiativeHook>>>
  > = ({ signal }) => getActionPlanInitiative(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type GetActionPlanInitiativeQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetActionPlanInitiativeHook>>>
>;
export type GetActionPlanInitiativeQueryError = ActionPlanInitiativeResponse;

/**
 * @summary Get initiative
 */
export const useGetActionPlanInitiative = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetActionPlanInitiativeHook>>>,
  TError = ActionPlanInitiativeResponse
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetActionPlanInitiativeHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetActionPlanInitiativeQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Update initiative
 */
export const useUpdateActionPlanInitiativeHook = () => {
  const updateActionPlanInitiative = useCustomAxios<void>();

  return (id: string, actionPlanInitiativeDto: ActionPlanInitiativeDto) => {
    return updateActionPlanInitiative({
      url: `/actionPlan/initiative/${id}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: actionPlanInitiativeDto
    });
  };
};

export const useUpdateActionPlanInitiativeMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateActionPlanInitiativeHook>>>,
    TError,
    { id: string; data: ActionPlanInitiativeDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUpdateActionPlanInitiativeHook>>>,
  TError,
  { id: string; data: ActionPlanInitiativeDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const updateActionPlanInitiative = useUpdateActionPlanInitiativeHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUpdateActionPlanInitiativeHook>>>,
    { id: string; data: ActionPlanInitiativeDto }
  > = props => {
    const { id, data } = props ?? {};

    return updateActionPlanInitiative(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateActionPlanInitiativeMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUpdateActionPlanInitiativeHook>>>
>;
export type UpdateActionPlanInitiativeMutationBody = ActionPlanInitiativeDto;
export type UpdateActionPlanInitiativeMutationError = unknown;

/**
 * @summary Update initiative
 */
export const useUpdateActionPlanInitiative = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateActionPlanInitiativeHook>>>,
    TError,
    { id: string; data: ActionPlanInitiativeDto },
    TContext
  >;
}) => {
  const mutationOptions = useUpdateActionPlanInitiativeMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Delete initiative
 */
export const useDeleteActionPlanInitiativeHook = () => {
  const deleteActionPlanInitiative = useCustomAxios<void>();

  return (id: string, params?: DeleteActionPlanInitiativeParams) => {
    return deleteActionPlanInitiative({
      url: `/actionPlan/initiative/${id}`,
      method: 'delete',
      params
    });
  };
};

export const useDeleteActionPlanInitiativeMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteActionPlanInitiativeHook>>>,
    TError,
    { id: string; params?: DeleteActionPlanInitiativeParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteActionPlanInitiativeHook>>>,
  TError,
  { id: string; params?: DeleteActionPlanInitiativeParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteActionPlanInitiative = useDeleteActionPlanInitiativeHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteActionPlanInitiativeHook>>>,
    { id: string; params?: DeleteActionPlanInitiativeParams }
  > = props => {
    const { id, params } = props ?? {};

    return deleteActionPlanInitiative(id, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteActionPlanInitiativeMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteActionPlanInitiativeHook>>>
>;

export type DeleteActionPlanInitiativeMutationError = unknown;

/**
 * @summary Delete initiative
 */
export const useDeleteActionPlanInitiative = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteActionPlanInitiativeHook>>>,
    TError,
    { id: string; params?: DeleteActionPlanInitiativeParams },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteActionPlanInitiativeMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Patch initiative
 */
export const usePatchActionPlanInitiativeHook = () => {
  const patchActionPlanInitiative = useCustomAxios<void>();

  return (id: string, actionPlanInitiativeDto: ActionPlanInitiativeDto) => {
    return patchActionPlanInitiative({
      url: `/actionPlan/initiative/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: actionPlanInitiativeDto
    });
  };
};

export const usePatchActionPlanInitiativeMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchActionPlanInitiativeHook>>>,
    TError,
    { id: string; data: ActionPlanInitiativeDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePatchActionPlanInitiativeHook>>>,
  TError,
  { id: string; data: ActionPlanInitiativeDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const patchActionPlanInitiative = usePatchActionPlanInitiativeHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePatchActionPlanInitiativeHook>>>,
    { id: string; data: ActionPlanInitiativeDto }
  > = props => {
    const { id, data } = props ?? {};

    return patchActionPlanInitiative(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchActionPlanInitiativeMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePatchActionPlanInitiativeHook>>>
>;
export type PatchActionPlanInitiativeMutationBody = ActionPlanInitiativeDto;
export type PatchActionPlanInitiativeMutationError = unknown;

/**
 * @summary Patch initiative
 */
export const usePatchActionPlanInitiative = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchActionPlanInitiativeHook>>>,
    TError,
    { id: string; data: ActionPlanInitiativeDto },
    TContext
  >;
}) => {
  const mutationOptions = usePatchActionPlanInitiativeMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Duplicate initiative
 */
export const useDuplicateActionPlanInitiativeHook = () => {
  const duplicateActionPlanInitiative = useCustomAxios<void>();

  return (id: string, params?: DuplicateActionPlanInitiativeParams) => {
    return duplicateActionPlanInitiative({
      url: `/actionPlan/initiative/${id}/duplicate`,
      method: 'put',
      params
    });
  };
};

export const useDuplicateActionPlanInitiativeMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDuplicateActionPlanInitiativeHook>>>,
    TError,
    { id: string; params?: DuplicateActionPlanInitiativeParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDuplicateActionPlanInitiativeHook>>>,
  TError,
  { id: string; params?: DuplicateActionPlanInitiativeParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const duplicateActionPlanInitiative = useDuplicateActionPlanInitiativeHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDuplicateActionPlanInitiativeHook>>>,
    { id: string; params?: DuplicateActionPlanInitiativeParams }
  > = props => {
    const { id, params } = props ?? {};

    return duplicateActionPlanInitiative(id, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type DuplicateActionPlanInitiativeMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDuplicateActionPlanInitiativeHook>>>
>;

export type DuplicateActionPlanInitiativeMutationError = unknown;

/**
 * @summary Duplicate initiative
 */
export const useDuplicateActionPlanInitiative = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDuplicateActionPlanInitiativeHook>>>,
    TError,
    { id: string; params?: DuplicateActionPlanInitiativeParams },
    TContext
  >;
}) => {
  const mutationOptions = useDuplicateActionPlanInitiativeMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Update board
 */
export const useUpdateActionPlanBoardHook = () => {
  const updateActionPlanBoard = useCustomAxios<void>();

  return (id: string, actionPlanBoardDto: ActionPlanBoardDto) => {
    return updateActionPlanBoard({
      url: `/actionPlan/board/${id}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: actionPlanBoardDto
    });
  };
};

export const useUpdateActionPlanBoardMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateActionPlanBoardHook>>>,
    TError,
    { id: string; data: ActionPlanBoardDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUpdateActionPlanBoardHook>>>,
  TError,
  { id: string; data: ActionPlanBoardDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const updateActionPlanBoard = useUpdateActionPlanBoardHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUpdateActionPlanBoardHook>>>,
    { id: string; data: ActionPlanBoardDto }
  > = props => {
    const { id, data } = props ?? {};

    return updateActionPlanBoard(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateActionPlanBoardMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUpdateActionPlanBoardHook>>>
>;
export type UpdateActionPlanBoardMutationBody = ActionPlanBoardDto;
export type UpdateActionPlanBoardMutationError = unknown;

/**
 * @summary Update board
 */
export const useUpdateActionPlanBoard = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateActionPlanBoardHook>>>,
    TError,
    { id: string; data: ActionPlanBoardDto },
    TContext
  >;
}) => {
  const mutationOptions = useUpdateActionPlanBoardMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Delete  action item
 */
export const useDeleteActionPlanBoardHook = () => {
  const deleteActionPlanBoard = useCustomAxios<void>();

  return (id: string) => {
    return deleteActionPlanBoard({ url: `/actionPlan/board/${id}`, method: 'delete' });
  };
};

export const useDeleteActionPlanBoardMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteActionPlanBoardHook>>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteActionPlanBoardHook>>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteActionPlanBoard = useDeleteActionPlanBoardHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteActionPlanBoardHook>>>,
    { id: string }
  > = props => {
    const { id } = props ?? {};

    return deleteActionPlanBoard(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteActionPlanBoardMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteActionPlanBoardHook>>>
>;

export type DeleteActionPlanBoardMutationError = unknown;

/**
 * @summary Delete  action item
 */
export const useDeleteActionPlanBoard = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteActionPlanBoardHook>>>,
    TError,
    { id: string },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteActionPlanBoardMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Patch board
 */
export const usePatchActionPlanBoardHook = () => {
  const patchActionPlanBoard = useCustomAxios<void>();

  return (id: string, actionPlanBoardDto: ActionPlanBoardDto) => {
    return patchActionPlanBoard({
      url: `/actionPlan/board/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: actionPlanBoardDto
    });
  };
};

export const usePatchActionPlanBoardMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchActionPlanBoardHook>>>,
    TError,
    { id: string; data: ActionPlanBoardDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePatchActionPlanBoardHook>>>,
  TError,
  { id: string; data: ActionPlanBoardDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const patchActionPlanBoard = usePatchActionPlanBoardHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePatchActionPlanBoardHook>>>,
    { id: string; data: ActionPlanBoardDto }
  > = props => {
    const { id, data } = props ?? {};

    return patchActionPlanBoard(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchActionPlanBoardMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePatchActionPlanBoardHook>>>
>;
export type PatchActionPlanBoardMutationBody = ActionPlanBoardDto;
export type PatchActionPlanBoardMutationError = unknown;

/**
 * @summary Patch board
 */
export const usePatchActionPlanBoard = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchActionPlanBoardHook>>>,
    TError,
    { id: string; data: ActionPlanBoardDto },
    TContext
  >;
}) => {
  const mutationOptions = usePatchActionPlanBoardMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Update action item
 */
export const useUpdateActionPlanActionItemHook = () => {
  const updateActionPlanActionItem = useCustomAxios<void>();

  return (id: string, actionPlanActionItemDto: ActionPlanActionItemDto) => {
    return updateActionPlanActionItem({
      url: `/actionPlan/actionItem/${id}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: actionPlanActionItemDto
    });
  };
};

export const useUpdateActionPlanActionItemMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateActionPlanActionItemHook>>>,
    TError,
    { id: string; data: ActionPlanActionItemDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUpdateActionPlanActionItemHook>>>,
  TError,
  { id: string; data: ActionPlanActionItemDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const updateActionPlanActionItem = useUpdateActionPlanActionItemHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUpdateActionPlanActionItemHook>>>,
    { id: string; data: ActionPlanActionItemDto }
  > = props => {
    const { id, data } = props ?? {};

    return updateActionPlanActionItem(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateActionPlanActionItemMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUpdateActionPlanActionItemHook>>>
>;
export type UpdateActionPlanActionItemMutationBody = ActionPlanActionItemDto;
export type UpdateActionPlanActionItemMutationError = unknown;

/**
 * @summary Update action item
 */
export const useUpdateActionPlanActionItem = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateActionPlanActionItemHook>>>,
    TError,
    { id: string; data: ActionPlanActionItemDto },
    TContext
  >;
}) => {
  const mutationOptions = useUpdateActionPlanActionItemMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Delete  action item
 */
export const useDeleteActionPlanActionItemHook = () => {
  const deleteActionPlanActionItem = useCustomAxios<void>();

  return (id: string) => {
    return deleteActionPlanActionItem({ url: `/actionPlan/actionItem/${id}`, method: 'delete' });
  };
};

export const useDeleteActionPlanActionItemMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteActionPlanActionItemHook>>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteActionPlanActionItemHook>>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteActionPlanActionItem = useDeleteActionPlanActionItemHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteActionPlanActionItemHook>>>,
    { id: string }
  > = props => {
    const { id } = props ?? {};

    return deleteActionPlanActionItem(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteActionPlanActionItemMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteActionPlanActionItemHook>>>
>;

export type DeleteActionPlanActionItemMutationError = unknown;

/**
 * @summary Delete  action item
 */
export const useDeleteActionPlanActionItem = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteActionPlanActionItemHook>>>,
    TError,
    { id: string },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteActionPlanActionItemMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Patch  action item
 */
export const usePatchActionPlanActionItemHook = () => {
  const patchActionPlanActionItem = useCustomAxios<void>();

  return (id: string, actionPlanActionItemDto: ActionPlanActionItemDto) => {
    return patchActionPlanActionItem({
      url: `/actionPlan/actionItem/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: actionPlanActionItemDto
    });
  };
};

export const usePatchActionPlanActionItemMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchActionPlanActionItemHook>>>,
    TError,
    { id: string; data: ActionPlanActionItemDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePatchActionPlanActionItemHook>>>,
  TError,
  { id: string; data: ActionPlanActionItemDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const patchActionPlanActionItem = usePatchActionPlanActionItemHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePatchActionPlanActionItemHook>>>,
    { id: string; data: ActionPlanActionItemDto }
  > = props => {
    const { id, data } = props ?? {};

    return patchActionPlanActionItem(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchActionPlanActionItemMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePatchActionPlanActionItemHook>>>
>;
export type PatchActionPlanActionItemMutationBody = ActionPlanActionItemDto;
export type PatchActionPlanActionItemMutationError = unknown;

/**
 * @summary Patch  action item
 */
export const usePatchActionPlanActionItem = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchActionPlanActionItemHook>>>,
    TError,
    { id: string; data: ActionPlanActionItemDto },
    TContext
  >;
}) => {
  const mutationOptions = usePatchActionPlanActionItemMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary List whiteboards
 */
export const useListWhiteboardsHook = () => {
  const listWhiteboards = useCustomAxios<WhiteboardResponse>();

  return (params?: ListWhiteboardsParams, signal?: AbortSignal) => {
    return listWhiteboards({ url: `/workspace/whiteboard`, method: 'get', params, signal });
  };
};

export const getListWhiteboardsQueryKey = (params?: ListWhiteboardsParams) =>
  [`/workspace/whiteboard`, ...(params ? [params] : [])] as const;

export const useListWhiteboardsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useListWhiteboardsHook>>>,
  TError = WhiteboardResponse
>(
  params?: ListWhiteboardsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useListWhiteboardsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useListWhiteboardsHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListWhiteboardsQueryKey(params);

  const listWhiteboards = useListWhiteboardsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useListWhiteboardsHook>>>> = ({
    signal
  }) => listWhiteboards(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type ListWhiteboardsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useListWhiteboardsHook>>>
>;
export type ListWhiteboardsQueryError = WhiteboardResponse;

/**
 * @summary List whiteboards
 */
export const useListWhiteboards = <
  TData = Awaited<ReturnType<ReturnType<typeof useListWhiteboardsHook>>>,
  TError = WhiteboardResponse
>(
  params?: ListWhiteboardsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useListWhiteboardsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useListWhiteboardsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Create whiteboard
 */
export const useCreateWhiteboardHook = () => {
  const createWhiteboard = useCustomAxios<WhiteboardResponse>();

  return (whiteboardDto: WhiteboardDto) => {
    return createWhiteboard({
      url: `/workspace/whiteboard`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: whiteboardDto
    });
  };
};

export const useCreateWhiteboardMutationOptions = <
  TError = WhiteboardResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateWhiteboardHook>>>,
    TError,
    { data: WhiteboardDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCreateWhiteboardHook>>>,
  TError,
  { data: WhiteboardDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const createWhiteboard = useCreateWhiteboardHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCreateWhiteboardHook>>>,
    { data: WhiteboardDto }
  > = props => {
    const { data } = props ?? {};

    return createWhiteboard(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateWhiteboardMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCreateWhiteboardHook>>>
>;
export type CreateWhiteboardMutationBody = WhiteboardDto;
export type CreateWhiteboardMutationError = WhiteboardResponse;

/**
 * @summary Create whiteboard
 */
export const useCreateWhiteboard = <TError = WhiteboardResponse, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateWhiteboardHook>>>,
    TError,
    { data: WhiteboardDto },
    TContext
  >;
}) => {
  const mutationOptions = useCreateWhiteboardMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get Account Tags
 */
export const useGetAccountTagsHook = () => {
  const getAccountTags = useCustomAxios<AccountTagsResponse>();

  return (signal?: AbortSignal) => {
    return getAccountTags({ url: `/workspace/tag`, method: 'get', signal });
  };
};

export const getGetAccountTagsQueryKey = () => [`/workspace/tag`] as const;

export const useGetAccountTagsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetAccountTagsHook>>>,
  TError = AccountTagsResponse
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetAccountTagsHook>>>,
    TError,
    TData
  >;
}): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetAccountTagsHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAccountTagsQueryKey();

  const getAccountTags = useGetAccountTagsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAccountTagsHook>>>> = ({
    signal
  }) => getAccountTags(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetAccountTagsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetAccountTagsHook>>>
>;
export type GetAccountTagsQueryError = AccountTagsResponse;

/**
 * @summary Get Account Tags
 */
export const useGetAccountTags = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetAccountTagsHook>>>,
  TError = AccountTagsResponse
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetAccountTagsHook>>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetAccountTagsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Add Account Tags
 */
export const useAddAccountTagsHook = () => {
  const addAccountTags = useCustomAxios<void>();

  return (accountTagsDto: AccountTagsDto) => {
    return addAccountTags({
      url: `/workspace/tag`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: accountTagsDto
    });
  };
};

export const useAddAccountTagsMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddAccountTagsHook>>>,
    TError,
    { data: AccountTagsDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAddAccountTagsHook>>>,
  TError,
  { data: AccountTagsDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const addAccountTags = useAddAccountTagsHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAddAccountTagsHook>>>,
    { data: AccountTagsDto }
  > = props => {
    const { data } = props ?? {};

    return addAccountTags(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddAccountTagsMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAddAccountTagsHook>>>
>;
export type AddAccountTagsMutationBody = AccountTagsDto;
export type AddAccountTagsMutationError = unknown;

/**
 * @summary Add Account Tags
 */
export const useAddAccountTags = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddAccountTagsHook>>>,
    TError,
    { data: AccountTagsDto },
    TContext
  >;
}) => {
  const mutationOptions = useAddAccountTagsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary List shared workspaces
 */
export const useListSharedWorkspacesHook = () => {
  const listSharedWorkspaces = useCustomAxios<SharedWorkspaceResponse>();

  return (signal?: AbortSignal) => {
    return listSharedWorkspaces({ url: `/workspace/sharedWorkspace`, method: 'get', signal });
  };
};

export const getListSharedWorkspacesQueryKey = () => [`/workspace/sharedWorkspace`] as const;

export const useListSharedWorkspacesQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useListSharedWorkspacesHook>>>,
  TError = SharedWorkspaceResponse
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useListSharedWorkspacesHook>>>,
    TError,
    TData
  >;
}): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useListSharedWorkspacesHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListSharedWorkspacesQueryKey();

  const listSharedWorkspaces = useListSharedWorkspacesHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useListSharedWorkspacesHook>>>
  > = ({ signal }) => listSharedWorkspaces(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type ListSharedWorkspacesQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useListSharedWorkspacesHook>>>
>;
export type ListSharedWorkspacesQueryError = SharedWorkspaceResponse;

/**
 * @summary List shared workspaces
 */
export const useListSharedWorkspaces = <
  TData = Awaited<ReturnType<ReturnType<typeof useListSharedWorkspacesHook>>>,
  TError = SharedWorkspaceResponse
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useListSharedWorkspacesHook>>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useListSharedWorkspacesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Create shared workspace
 */
export const useCreateSharedWorkspaceHook = () => {
  const createSharedWorkspace = useCustomAxios<SharedWorkspaceResponse>();

  return (sharedWorkspaceDto: SharedWorkspaceDto) => {
    return createSharedWorkspace({
      url: `/workspace/sharedWorkspace`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: sharedWorkspaceDto
    });
  };
};

export const useCreateSharedWorkspaceMutationOptions = <
  TError = SharedWorkspaceResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateSharedWorkspaceHook>>>,
    TError,
    { data: SharedWorkspaceDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCreateSharedWorkspaceHook>>>,
  TError,
  { data: SharedWorkspaceDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const createSharedWorkspace = useCreateSharedWorkspaceHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCreateSharedWorkspaceHook>>>,
    { data: SharedWorkspaceDto }
  > = props => {
    const { data } = props ?? {};

    return createSharedWorkspace(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateSharedWorkspaceMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCreateSharedWorkspaceHook>>>
>;
export type CreateSharedWorkspaceMutationBody = SharedWorkspaceDto;
export type CreateSharedWorkspaceMutationError = SharedWorkspaceResponse;

/**
 * @summary Create shared workspace
 */
export const useCreateSharedWorkspace = <
  TError = SharedWorkspaceResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateSharedWorkspaceHook>>>,
    TError,
    { data: SharedWorkspaceDto },
    TContext
  >;
}) => {
  const mutationOptions = useCreateSharedWorkspaceMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Search shared workspaces points
 */
export const useSearchSharedWorkspacePointsHook = () => {
  const searchSharedWorkspacePoints = useCustomAxios<SharedWorkspacePointResponse>();

  return (
    id: string,
    searchEntityRequest: SearchEntityRequest,
    params?: SearchSharedWorkspacePointsParams
  ) => {
    return searchSharedWorkspacePoints({
      url: `/workspace/sharedWorkspace/${id}/point/search`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: searchEntityRequest,
      params
    });
  };
};

export const useSearchSharedWorkspacePointsMutationOptions = <
  TError = SharedWorkspacePointResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSearchSharedWorkspacePointsHook>>>,
    TError,
    { id: string; data: SearchEntityRequest; params?: SearchSharedWorkspacePointsParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useSearchSharedWorkspacePointsHook>>>,
  TError,
  { id: string; data: SearchEntityRequest; params?: SearchSharedWorkspacePointsParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const searchSharedWorkspacePoints = useSearchSharedWorkspacePointsHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useSearchSharedWorkspacePointsHook>>>,
    { id: string; data: SearchEntityRequest; params?: SearchSharedWorkspacePointsParams }
  > = props => {
    const { id, data, params } = props ?? {};

    return searchSharedWorkspacePoints(id, data, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type SearchSharedWorkspacePointsMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useSearchSharedWorkspacePointsHook>>>
>;
export type SearchSharedWorkspacePointsMutationBody = SearchEntityRequest;
export type SearchSharedWorkspacePointsMutationError = SharedWorkspacePointResponse;

/**
 * @summary Search shared workspaces points
 */
export const useSearchSharedWorkspacePoints = <
  TError = SharedWorkspacePointResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSearchSharedWorkspacePointsHook>>>,
    TError,
    { id: string; data: SearchEntityRequest; params?: SearchSharedWorkspacePointsParams },
    TContext
  >;
}) => {
  const mutationOptions = useSearchSharedWorkspacePointsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Start a shared workspace meeting
 */
export const useStartSharedWorkspaceMeetingHook = () => {
  const startSharedWorkspaceMeeting = useCustomAxios<SharedWorkspaceMeetingResponse>();

  return (id: string, startMeetingRequest: StartMeetingRequest) => {
    return startSharedWorkspaceMeeting({
      url: `/workspace/sharedWorkspace/${id}/meeting/start`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: startMeetingRequest
    });
  };
};

export const useStartSharedWorkspaceMeetingMutationOptions = <
  TError = SharedWorkspaceMeetingResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useStartSharedWorkspaceMeetingHook>>>,
    TError,
    { id: string; data: StartMeetingRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useStartSharedWorkspaceMeetingHook>>>,
  TError,
  { id: string; data: StartMeetingRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const startSharedWorkspaceMeeting = useStartSharedWorkspaceMeetingHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useStartSharedWorkspaceMeetingHook>>>,
    { id: string; data: StartMeetingRequest }
  > = props => {
    const { id, data } = props ?? {};

    return startSharedWorkspaceMeeting(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type StartSharedWorkspaceMeetingMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useStartSharedWorkspaceMeetingHook>>>
>;
export type StartSharedWorkspaceMeetingMutationBody = StartMeetingRequest;
export type StartSharedWorkspaceMeetingMutationError = SharedWorkspaceMeetingResponse;

/**
 * @summary Start a shared workspace meeting
 */
export const useStartSharedWorkspaceMeeting = <
  TError = SharedWorkspaceMeetingResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useStartSharedWorkspaceMeetingHook>>>,
    TError,
    { id: string; data: StartMeetingRequest },
    TContext
  >;
}) => {
  const mutationOptions = useStartSharedWorkspaceMeetingMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Create shared workspace update
 */
export const useCreateSharedWorkspaceUpdateHook = () => {
  const createSharedWorkspaceUpdate = useCustomAxios<SharedWorkspaceUpdateResponse>();

  return (sharedWorkspaceUpdateDto: SharedWorkspaceUpdateDto) => {
    return createSharedWorkspaceUpdate({
      url: `/workspace/sharedWorkspace/update`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: sharedWorkspaceUpdateDto
    });
  };
};

export const useCreateSharedWorkspaceUpdateMutationOptions = <
  TError = SharedWorkspaceUpdateResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateSharedWorkspaceUpdateHook>>>,
    TError,
    { data: SharedWorkspaceUpdateDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCreateSharedWorkspaceUpdateHook>>>,
  TError,
  { data: SharedWorkspaceUpdateDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const createSharedWorkspaceUpdate = useCreateSharedWorkspaceUpdateHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCreateSharedWorkspaceUpdateHook>>>,
    { data: SharedWorkspaceUpdateDto }
  > = props => {
    const { data } = props ?? {};

    return createSharedWorkspaceUpdate(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateSharedWorkspaceUpdateMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCreateSharedWorkspaceUpdateHook>>>
>;
export type CreateSharedWorkspaceUpdateMutationBody = SharedWorkspaceUpdateDto;
export type CreateSharedWorkspaceUpdateMutationError = SharedWorkspaceUpdateResponse;

/**
 * @summary Create shared workspace update
 */
export const useCreateSharedWorkspaceUpdate = <
  TError = SharedWorkspaceUpdateResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateSharedWorkspaceUpdateHook>>>,
    TError,
    { data: SharedWorkspaceUpdateDto },
    TContext
  >;
}) => {
  const mutationOptions = useCreateSharedWorkspaceUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Search shared workspaces
 */
export const useSearchSharedWorkspacesHook = () => {
  const searchSharedWorkspaces = useCustomAxios<SharedWorkspaceResponse>();

  return (searchEntityRequest: SearchEntityRequest) => {
    return searchSharedWorkspaces({
      url: `/workspace/sharedWorkspace/search`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: searchEntityRequest
    });
  };
};

export const useSearchSharedWorkspacesMutationOptions = <
  TError = SharedWorkspaceResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSearchSharedWorkspacesHook>>>,
    TError,
    { data: SearchEntityRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useSearchSharedWorkspacesHook>>>,
  TError,
  { data: SearchEntityRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const searchSharedWorkspaces = useSearchSharedWorkspacesHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useSearchSharedWorkspacesHook>>>,
    { data: SearchEntityRequest }
  > = props => {
    const { data } = props ?? {};

    return searchSharedWorkspaces(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type SearchSharedWorkspacesMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useSearchSharedWorkspacesHook>>>
>;
export type SearchSharedWorkspacesMutationBody = SearchEntityRequest;
export type SearchSharedWorkspacesMutationError = SharedWorkspaceResponse;

/**
 * @summary Search shared workspaces
 */
export const useSearchSharedWorkspaces = <
  TError = SharedWorkspaceResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSearchSharedWorkspacesHook>>>,
    TError,
    { data: SearchEntityRequest },
    TContext
  >;
}) => {
  const mutationOptions = useSearchSharedWorkspacesMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Create shared workspace point
 */
export const useCreateSharedWorkspacePointHook = () => {
  const createSharedWorkspacePoint = useCustomAxios<SharedWorkspacePointResponse>();

  return (sharedWorkspacePointDto: SharedWorkspacePointDto) => {
    return createSharedWorkspacePoint({
      url: `/workspace/sharedWorkspace/point`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: sharedWorkspacePointDto
    });
  };
};

export const useCreateSharedWorkspacePointMutationOptions = <
  TError = SharedWorkspacePointResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateSharedWorkspacePointHook>>>,
    TError,
    { data: SharedWorkspacePointDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCreateSharedWorkspacePointHook>>>,
  TError,
  { data: SharedWorkspacePointDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const createSharedWorkspacePoint = useCreateSharedWorkspacePointHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCreateSharedWorkspacePointHook>>>,
    { data: SharedWorkspacePointDto }
  > = props => {
    const { data } = props ?? {};

    return createSharedWorkspacePoint(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateSharedWorkspacePointMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCreateSharedWorkspacePointHook>>>
>;
export type CreateSharedWorkspacePointMutationBody = SharedWorkspacePointDto;
export type CreateSharedWorkspacePointMutationError = SharedWorkspacePointResponse;

/**
 * @summary Create shared workspace point
 */
export const useCreateSharedWorkspacePoint = <
  TError = SharedWorkspacePointResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateSharedWorkspacePointHook>>>,
    TError,
    { data: SharedWorkspacePointDto },
    TContext
  >;
}) => {
  const mutationOptions = useCreateSharedWorkspacePointMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Add starred point
 */
export const useAddSharedWorkspaceStarredPointHook = () => {
  const addSharedWorkspaceStarredPoint = useCustomAxios<void>();

  return (id: string) => {
    return addSharedWorkspaceStarredPoint({
      url: `/workspace/sharedWorkspace/point/${id}/starred`,
      method: 'post'
    });
  };
};

export const useAddSharedWorkspaceStarredPointMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddSharedWorkspaceStarredPointHook>>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAddSharedWorkspaceStarredPointHook>>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const addSharedWorkspaceStarredPoint = useAddSharedWorkspaceStarredPointHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAddSharedWorkspaceStarredPointHook>>>,
    { id: string }
  > = props => {
    const { id } = props ?? {};

    return addSharedWorkspaceStarredPoint(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddSharedWorkspaceStarredPointMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAddSharedWorkspaceStarredPointHook>>>
>;

export type AddSharedWorkspaceStarredPointMutationError = unknown;

/**
 * @summary Add starred point
 */
export const useAddSharedWorkspaceStarredPoint = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddSharedWorkspaceStarredPointHook>>>,
    TError,
    { id: string },
    TContext
  >;
}) => {
  const mutationOptions = useAddSharedWorkspaceStarredPointMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Remove starred point
 */
export const useRemoveSharedWorkspaceStarredPointHook = () => {
  const removeSharedWorkspaceStarredPoint = useCustomAxios<void>();

  return (id: string) => {
    return removeSharedWorkspaceStarredPoint({
      url: `/workspace/sharedWorkspace/point/${id}/starred`,
      method: 'delete'
    });
  };
};

export const useRemoveSharedWorkspaceStarredPointMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useRemoveSharedWorkspaceStarredPointHook>>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useRemoveSharedWorkspaceStarredPointHook>>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const removeSharedWorkspaceStarredPoint = useRemoveSharedWorkspaceStarredPointHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useRemoveSharedWorkspaceStarredPointHook>>>,
    { id: string }
  > = props => {
    const { id } = props ?? {};

    return removeSharedWorkspaceStarredPoint(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type RemoveSharedWorkspaceStarredPointMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useRemoveSharedWorkspaceStarredPointHook>>>
>;

export type RemoveSharedWorkspaceStarredPointMutationError = unknown;

/**
 * @summary Remove starred point
 */
export const useRemoveSharedWorkspaceStarredPoint = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useRemoveSharedWorkspaceStarredPointHook>>>,
    TError,
    { id: string },
    TContext
  >;
}) => {
  const mutationOptions = useRemoveSharedWorkspaceStarredPointMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Create shared workspace point comment
 */
export const useCreateSharedWorkspacePointCommentHook = () => {
  const createSharedWorkspacePointComment = useCustomAxios<SharedWorkspacePointCommentResponse>();

  return (sharedWorkspacePointCommentDto: SharedWorkspacePointCommentDto) => {
    return createSharedWorkspacePointComment({
      url: `/workspace/sharedWorkspace/point/comment`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: sharedWorkspacePointCommentDto
    });
  };
};

export const useCreateSharedWorkspacePointCommentMutationOptions = <
  TError = SharedWorkspacePointCommentResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateSharedWorkspacePointCommentHook>>>,
    TError,
    { data: SharedWorkspacePointCommentDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCreateSharedWorkspacePointCommentHook>>>,
  TError,
  { data: SharedWorkspacePointCommentDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const createSharedWorkspacePointComment = useCreateSharedWorkspacePointCommentHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCreateSharedWorkspacePointCommentHook>>>,
    { data: SharedWorkspacePointCommentDto }
  > = props => {
    const { data } = props ?? {};

    return createSharedWorkspacePointComment(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateSharedWorkspacePointCommentMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCreateSharedWorkspacePointCommentHook>>>
>;
export type CreateSharedWorkspacePointCommentMutationBody = SharedWorkspacePointCommentDto;
export type CreateSharedWorkspacePointCommentMutationError = SharedWorkspacePointCommentResponse;

/**
 * @summary Create shared workspace point comment
 */
export const useCreateSharedWorkspacePointComment = <
  TError = SharedWorkspacePointCommentResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateSharedWorkspacePointCommentHook>>>,
    TError,
    { data: SharedWorkspacePointCommentDto },
    TContext
  >;
}) => {
  const mutationOptions = useCreateSharedWorkspacePointCommentMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary List Shared Workspace meeting flows
 */
export const useListMeetingFlowsHook = () => {
  const listMeetingFlows = useCustomAxios<MeetingFlowResponse>();

  return (signal?: AbortSignal) => {
    return listMeetingFlows({
      url: `/workspace/sharedWorkspace/meetingFlow`,
      method: 'get',
      signal
    });
  };
};

export const getListMeetingFlowsQueryKey = () =>
  [`/workspace/sharedWorkspace/meetingFlow`] as const;

export const useListMeetingFlowsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useListMeetingFlowsHook>>>,
  TError = MeetingFlowResponse
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useListMeetingFlowsHook>>>,
    TError,
    TData
  >;
}): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useListMeetingFlowsHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListMeetingFlowsQueryKey();

  const listMeetingFlows = useListMeetingFlowsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useListMeetingFlowsHook>>>> = ({
    signal
  }) => listMeetingFlows(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type ListMeetingFlowsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useListMeetingFlowsHook>>>
>;
export type ListMeetingFlowsQueryError = MeetingFlowResponse;

/**
 * @summary List Shared Workspace meeting flows
 */
export const useListMeetingFlows = <
  TData = Awaited<ReturnType<ReturnType<typeof useListMeetingFlowsHook>>>,
  TError = MeetingFlowResponse
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useListMeetingFlowsHook>>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useListMeetingFlowsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Create meeting flow
 */
export const useCreateMeetingFlowHook = () => {
  const createMeetingFlow = useCustomAxios<MeetingFlowResponse>();

  return (meetingFlowDto: MeetingFlowDto) => {
    return createMeetingFlow({
      url: `/workspace/sharedWorkspace/meetingFlow`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: meetingFlowDto
    });
  };
};

export const useCreateMeetingFlowMutationOptions = <
  TError = MeetingFlowResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateMeetingFlowHook>>>,
    TError,
    { data: MeetingFlowDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCreateMeetingFlowHook>>>,
  TError,
  { data: MeetingFlowDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const createMeetingFlow = useCreateMeetingFlowHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCreateMeetingFlowHook>>>,
    { data: MeetingFlowDto }
  > = props => {
    const { data } = props ?? {};

    return createMeetingFlow(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateMeetingFlowMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCreateMeetingFlowHook>>>
>;
export type CreateMeetingFlowMutationBody = MeetingFlowDto;
export type CreateMeetingFlowMutationError = MeetingFlowResponse;

/**
 * @summary Create meeting flow
 */
export const useCreateMeetingFlow = <TError = MeetingFlowResponse, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateMeetingFlowHook>>>,
    TError,
    { data: MeetingFlowDto },
    TContext
  >;
}) => {
  const mutationOptions = useCreateMeetingFlowMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary List meeting flow templates
 */
export const useListMeetingFlowTemplatesHook = () => {
  const listMeetingFlowTemplates = useCustomAxios<MeetingFlowTemplateResponse>();

  return (signal?: AbortSignal) => {
    return listMeetingFlowTemplates({
      url: `/workspace/sharedWorkspace/meetingFlowTemplate`,
      method: 'get',
      signal
    });
  };
};

export const getListMeetingFlowTemplatesQueryKey = () =>
  [`/workspace/sharedWorkspace/meetingFlowTemplate`] as const;

export const useListMeetingFlowTemplatesQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useListMeetingFlowTemplatesHook>>>,
  TError = MeetingFlowTemplateResponse
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useListMeetingFlowTemplatesHook>>>,
    TError,
    TData
  >;
}): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useListMeetingFlowTemplatesHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListMeetingFlowTemplatesQueryKey();

  const listMeetingFlowTemplates = useListMeetingFlowTemplatesHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useListMeetingFlowTemplatesHook>>>
  > = ({ signal }) => listMeetingFlowTemplates(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type ListMeetingFlowTemplatesQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useListMeetingFlowTemplatesHook>>>
>;
export type ListMeetingFlowTemplatesQueryError = MeetingFlowTemplateResponse;

/**
 * @summary List meeting flow templates
 */
export const useListMeetingFlowTemplates = <
  TData = Awaited<ReturnType<ReturnType<typeof useListMeetingFlowTemplatesHook>>>,
  TError = MeetingFlowTemplateResponse
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useListMeetingFlowTemplatesHook>>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useListMeetingFlowTemplatesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Create meeting flow template
 */
export const useCreateMeetingFlowTemplateHook = () => {
  const createMeetingFlowTemplate = useCustomAxios<MeetingFlowTemplateResponse>();

  return (meetingFlowTemplateDto: MeetingFlowTemplateDto) => {
    return createMeetingFlowTemplate({
      url: `/workspace/sharedWorkspace/meetingFlowTemplate`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: meetingFlowTemplateDto
    });
  };
};

export const useCreateMeetingFlowTemplateMutationOptions = <
  TError = MeetingFlowTemplateResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateMeetingFlowTemplateHook>>>,
    TError,
    { data: MeetingFlowTemplateDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCreateMeetingFlowTemplateHook>>>,
  TError,
  { data: MeetingFlowTemplateDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const createMeetingFlowTemplate = useCreateMeetingFlowTemplateHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCreateMeetingFlowTemplateHook>>>,
    { data: MeetingFlowTemplateDto }
  > = props => {
    const { data } = props ?? {};

    return createMeetingFlowTemplate(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateMeetingFlowTemplateMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCreateMeetingFlowTemplateHook>>>
>;
export type CreateMeetingFlowTemplateMutationBody = MeetingFlowTemplateDto;
export type CreateMeetingFlowTemplateMutationError = MeetingFlowTemplateResponse;

/**
 * @summary Create meeting flow template
 */
export const useCreateMeetingFlowTemplate = <
  TError = MeetingFlowTemplateResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateMeetingFlowTemplateHook>>>,
    TError,
    { data: MeetingFlowTemplateDto },
    TContext
  >;
}) => {
  const mutationOptions = useCreateMeetingFlowTemplateMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary List all space meeting notes
 */
export const useListAllSpaceMeetingNotesHook = () => {
  const listAllSpaceMeetingNotes = useCustomAxios<SharedWorkspaceMeetingNoteResponse>();

  return (params: ListAllSpaceMeetingNotesParams, signal?: AbortSignal) => {
    return listAllSpaceMeetingNotes({
      url: `/workspace/sharedWorkspace/meeting/note`,
      method: 'get',
      params,
      signal
    });
  };
};

export const getListAllSpaceMeetingNotesQueryKey = (params: ListAllSpaceMeetingNotesParams) =>
  [`/workspace/sharedWorkspace/meeting/note`, ...(params ? [params] : [])] as const;

export const useListAllSpaceMeetingNotesQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useListAllSpaceMeetingNotesHook>>>,
  TError = SharedWorkspaceMeetingNoteResponse
>(
  params: ListAllSpaceMeetingNotesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useListAllSpaceMeetingNotesHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useListAllSpaceMeetingNotesHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListAllSpaceMeetingNotesQueryKey(params);

  const listAllSpaceMeetingNotes = useListAllSpaceMeetingNotesHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useListAllSpaceMeetingNotesHook>>>
  > = ({ signal }) => listAllSpaceMeetingNotes(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type ListAllSpaceMeetingNotesQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useListAllSpaceMeetingNotesHook>>>
>;
export type ListAllSpaceMeetingNotesQueryError = SharedWorkspaceMeetingNoteResponse;

/**
 * @summary List all space meeting notes
 */
export const useListAllSpaceMeetingNotes = <
  TData = Awaited<ReturnType<ReturnType<typeof useListAllSpaceMeetingNotesHook>>>,
  TError = SharedWorkspaceMeetingNoteResponse
>(
  params: ListAllSpaceMeetingNotesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useListAllSpaceMeetingNotesHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useListAllSpaceMeetingNotesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Create meeting note
 */
export const useCreateSpaceMeetingNoteHook = () => {
  const createSpaceMeetingNote = useCustomAxios<SharedWorkspaceMeetingNoteResponse>();

  return (sharedWorkspaceMeetingNoteDto: SharedWorkspaceMeetingNoteDto) => {
    return createSpaceMeetingNote({
      url: `/workspace/sharedWorkspace/meeting/note`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: sharedWorkspaceMeetingNoteDto
    });
  };
};

export const useCreateSpaceMeetingNoteMutationOptions = <
  TError = SharedWorkspaceMeetingNoteResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateSpaceMeetingNoteHook>>>,
    TError,
    { data: SharedWorkspaceMeetingNoteDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCreateSpaceMeetingNoteHook>>>,
  TError,
  { data: SharedWorkspaceMeetingNoteDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const createSpaceMeetingNote = useCreateSpaceMeetingNoteHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCreateSpaceMeetingNoteHook>>>,
    { data: SharedWorkspaceMeetingNoteDto }
  > = props => {
    const { data } = props ?? {};

    return createSpaceMeetingNote(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateSpaceMeetingNoteMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCreateSpaceMeetingNoteHook>>>
>;
export type CreateSpaceMeetingNoteMutationBody = SharedWorkspaceMeetingNoteDto;
export type CreateSpaceMeetingNoteMutationError = SharedWorkspaceMeetingNoteResponse;

/**
 * @summary Create meeting note
 */
export const useCreateSpaceMeetingNote = <
  TError = SharedWorkspaceMeetingNoteResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateSpaceMeetingNoteHook>>>,
    TError,
    { data: SharedWorkspaceMeetingNoteDto },
    TContext
  >;
}) => {
  const mutationOptions = useCreateSpaceMeetingNoteMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Create meeting activity
 */
export const useCreateMeetingActivityHook = () => {
  const createMeetingActivity = useCustomAxios<SharedWorkspaceMeetingActivityResponse>();

  return (sharedWorkspaceMeetingActivityDto: SharedWorkspaceMeetingActivityDto) => {
    return createMeetingActivity({
      url: `/workspace/sharedWorkspace/meeting/activity`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: sharedWorkspaceMeetingActivityDto
    });
  };
};

export const useCreateMeetingActivityMutationOptions = <
  TError = SharedWorkspaceMeetingActivityResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateMeetingActivityHook>>>,
    TError,
    { data: SharedWorkspaceMeetingActivityDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCreateMeetingActivityHook>>>,
  TError,
  { data: SharedWorkspaceMeetingActivityDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const createMeetingActivity = useCreateMeetingActivityHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCreateMeetingActivityHook>>>,
    { data: SharedWorkspaceMeetingActivityDto }
  > = props => {
    const { data } = props ?? {};

    return createMeetingActivity(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateMeetingActivityMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCreateMeetingActivityHook>>>
>;
export type CreateMeetingActivityMutationBody = SharedWorkspaceMeetingActivityDto;
export type CreateMeetingActivityMutationError = SharedWorkspaceMeetingActivityResponse;

/**
 * @summary Create meeting activity
 */
export const useCreateMeetingActivity = <
  TError = SharedWorkspaceMeetingActivityResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateMeetingActivityHook>>>,
    TError,
    { data: SharedWorkspaceMeetingActivityDto },
    TContext
  >;
}) => {
  const mutationOptions = useCreateMeetingActivityMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary List all resources
 */
export const useListResourcesHook = () => {
  const listResources = useCustomAxios<ResourceResponse>();

  return (signal?: AbortSignal) => {
    return listResources({ url: `/workspace/resource`, method: 'get', signal });
  };
};

export const getListResourcesQueryKey = () => [`/workspace/resource`] as const;

export const useListResourcesQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useListResourcesHook>>>,
  TError = ResourceResponse
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useListResourcesHook>>>,
    TError,
    TData
  >;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListResourcesHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListResourcesQueryKey();

  const listResources = useListResourcesHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useListResourcesHook>>>> = ({
    signal
  }) => listResources(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type ListResourcesQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useListResourcesHook>>>
>;
export type ListResourcesQueryError = ResourceResponse;

/**
 * @summary List all resources
 */
export const useListResources = <
  TData = Awaited<ReturnType<ReturnType<typeof useListResourcesHook>>>,
  TError = ResourceResponse
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useListResourcesHook>>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useListResourcesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Create resource
 */
export const useCreateResourceHook = () => {
  const createResource = useCustomAxios<ResourceResponse>();

  return (resourceDto: ResourceDto) => {
    return createResource({
      url: `/workspace/resource`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: resourceDto
    });
  };
};

export const useCreateResourceMutationOptions = <
  TError = ResourceResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateResourceHook>>>,
    TError,
    { data: ResourceDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCreateResourceHook>>>,
  TError,
  { data: ResourceDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const createResource = useCreateResourceHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCreateResourceHook>>>,
    { data: ResourceDto }
  > = props => {
    const { data } = props ?? {};

    return createResource(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateResourceMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCreateResourceHook>>>
>;
export type CreateResourceMutationBody = ResourceDto;
export type CreateResourceMutationError = ResourceResponse;

/**
 * @summary Create resource
 */
export const useCreateResource = <TError = ResourceResponse, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateResourceHook>>>,
    TError,
    { data: ResourceDto },
    TContext
  >;
}) => {
  const mutationOptions = useCreateResourceMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary List google drive files
 */
export const useListGoogleDriveFilesHook = () => {
  const listGoogleDriveFiles = useCustomAxios<DriveFileResponse>();

  return (params?: ListGoogleDriveFilesParams, signal?: AbortSignal) => {
    return listGoogleDriveFiles({
      url: `/workspace/resource/drive/file`,
      method: 'get',
      params,
      signal
    });
  };
};

export const getListGoogleDriveFilesQueryKey = (params?: ListGoogleDriveFilesParams) =>
  [`/workspace/resource/drive/file`, ...(params ? [params] : [])] as const;

export const useListGoogleDriveFilesQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useListGoogleDriveFilesHook>>>,
  TError = DriveFileResponse
>(
  params?: ListGoogleDriveFilesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useListGoogleDriveFilesHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useListGoogleDriveFilesHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListGoogleDriveFilesQueryKey(params);

  const listGoogleDriveFiles = useListGoogleDriveFilesHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useListGoogleDriveFilesHook>>>
  > = ({ signal }) => listGoogleDriveFiles(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type ListGoogleDriveFilesQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useListGoogleDriveFilesHook>>>
>;
export type ListGoogleDriveFilesQueryError = DriveFileResponse;

/**
 * @summary List google drive files
 */
export const useListGoogleDriveFiles = <
  TData = Awaited<ReturnType<ReturnType<typeof useListGoogleDriveFilesHook>>>,
  TError = DriveFileResponse
>(
  params?: ListGoogleDriveFilesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useListGoogleDriveFilesHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useListGoogleDriveFilesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Create google drive file
 */
export const useCreateGoogleDriveFileHook = () => {
  const createGoogleDriveFile = useCustomAxios<ResourceResponse>();

  return (createGoogleDriveFileRequest: CreateGoogleDriveFileRequest) => {
    return createGoogleDriveFile({
      url: `/workspace/resource/drive/file`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: createGoogleDriveFileRequest
    });
  };
};

export const useCreateGoogleDriveFileMutationOptions = <
  TError = ResourceResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateGoogleDriveFileHook>>>,
    TError,
    { data: CreateGoogleDriveFileRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCreateGoogleDriveFileHook>>>,
  TError,
  { data: CreateGoogleDriveFileRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const createGoogleDriveFile = useCreateGoogleDriveFileHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCreateGoogleDriveFileHook>>>,
    { data: CreateGoogleDriveFileRequest }
  > = props => {
    const { data } = props ?? {};

    return createGoogleDriveFile(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateGoogleDriveFileMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCreateGoogleDriveFileHook>>>
>;
export type CreateGoogleDriveFileMutationBody = CreateGoogleDriveFileRequest;
export type CreateGoogleDriveFileMutationError = ResourceResponse;

/**
 * @summary Create google drive file
 */
export const useCreateGoogleDriveFile = <TError = ResourceResponse, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateGoogleDriveFileHook>>>,
    TError,
    { data: CreateGoogleDriveFileRequest },
    TContext
  >;
}) => {
  const mutationOptions = useCreateGoogleDriveFileMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Upload google drive file
 */
export const useUploadGoogleDriveFileHook = () => {
  const uploadGoogleDriveFile = useCustomAxios<void>();

  return (
    uploadGoogleDriveFileBody: UploadGoogleDriveFileBody,
    params?: UploadGoogleDriveFileParams
  ) => {
    return uploadGoogleDriveFile({
      url: `/workspace/resource/drive/file/upload`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: uploadGoogleDriveFileBody,
      params
    });
  };
};

export const useUploadGoogleDriveFileMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUploadGoogleDriveFileHook>>>,
    TError,
    { data: UploadGoogleDriveFileBody; params?: UploadGoogleDriveFileParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUploadGoogleDriveFileHook>>>,
  TError,
  { data: UploadGoogleDriveFileBody; params?: UploadGoogleDriveFileParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const uploadGoogleDriveFile = useUploadGoogleDriveFileHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUploadGoogleDriveFileHook>>>,
    { data: UploadGoogleDriveFileBody; params?: UploadGoogleDriveFileParams }
  > = props => {
    const { data, params } = props ?? {};

    return uploadGoogleDriveFile(data, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type UploadGoogleDriveFileMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUploadGoogleDriveFileHook>>>
>;
export type UploadGoogleDriveFileMutationBody = UploadGoogleDriveFileBody;
export type UploadGoogleDriveFileMutationError = unknown;

/**
 * @summary Upload google drive file
 */
export const useUploadGoogleDriveFile = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUploadGoogleDriveFileHook>>>,
    TError,
    { data: UploadGoogleDriveFileBody; params?: UploadGoogleDriveFileParams },
    TContext
  >;
}) => {
  const mutationOptions = useUploadGoogleDriveFileMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary List all notes
 */
export const useListNotesHook = () => {
  const listNotes = useCustomAxios<NoteResponse>();

  return (params?: ListNotesParams, signal?: AbortSignal) => {
    return listNotes({ url: `/workspace/note`, method: 'get', params, signal });
  };
};

export const getListNotesQueryKey = (params?: ListNotesParams) =>
  [`/workspace/note`, ...(params ? [params] : [])] as const;

export const useListNotesQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useListNotesHook>>>,
  TError = NoteResponse
>(
  params?: ListNotesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useListNotesHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListNotesHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListNotesQueryKey(params);

  const listNotes = useListNotesHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useListNotesHook>>>> = ({
    signal
  }) => listNotes(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type ListNotesQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useListNotesHook>>>
>;
export type ListNotesQueryError = NoteResponse;

/**
 * @summary List all notes
 */
export const useListNotes = <
  TData = Awaited<ReturnType<ReturnType<typeof useListNotesHook>>>,
  TError = NoteResponse
>(
  params?: ListNotesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useListNotesHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useListNotesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Create note
 */
export const useCreateNoteHook = () => {
  const createNote = useCustomAxios<NoteResponse>();

  return (noteDto: NoteDto) => {
    return createNote({
      url: `/workspace/note`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: noteDto
    });
  };
};

export const useCreateNoteMutationOptions = <TError = NoteResponse, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateNoteHook>>>,
    TError,
    { data: NoteDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCreateNoteHook>>>,
  TError,
  { data: NoteDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const createNote = useCreateNoteHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCreateNoteHook>>>,
    { data: NoteDto }
  > = props => {
    const { data } = props ?? {};

    return createNote(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateNoteMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCreateNoteHook>>>
>;
export type CreateNoteMutationBody = NoteDto;
export type CreateNoteMutationError = NoteResponse;

/**
 * @summary Create note
 */
export const useCreateNote = <TError = NoteResponse, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateNoteHook>>>,
    TError,
    { data: NoteDto },
    TContext
  >;
}) => {
  const mutationOptions = useCreateNoteMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get Module playbook
 */
export const useGetModulePlaybookHook = () => {
  const getModulePlaybook = useCustomAxios<ModulePlaybookResponse>();

  return (id: string, signal?: AbortSignal) => {
    return getModulePlaybook({ url: `/workspace/module/${id}/playbook`, method: 'get', signal });
  };
};

export const getGetModulePlaybookQueryKey = (id: string) =>
  [`/workspace/module/${id}/playbook`] as const;

export const useGetModulePlaybookQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetModulePlaybookHook>>>,
  TError = ModulePlaybookResponse
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetModulePlaybookHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetModulePlaybookHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetModulePlaybookQueryKey(id);

  const getModulePlaybook = useGetModulePlaybookHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetModulePlaybookHook>>>
  > = ({ signal }) => getModulePlaybook(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type GetModulePlaybookQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetModulePlaybookHook>>>
>;
export type GetModulePlaybookQueryError = ModulePlaybookResponse;

/**
 * @summary Get Module playbook
 */
export const useGetModulePlaybook = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetModulePlaybookHook>>>,
  TError = ModulePlaybookResponse
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetModulePlaybookHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetModulePlaybookQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Create Module playbook
 */
export const useCreateModulePlaybookLevelHook = () => {
  const createModulePlaybookLevel = useCustomAxios<ModulePlaybookResponse>();

  return (id: string, createModulePlaybookRequest: CreateModulePlaybookRequest) => {
    return createModulePlaybookLevel({
      url: `/workspace/module/${id}/playbook`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: createModulePlaybookRequest
    });
  };
};

export const useCreateModulePlaybookLevelMutationOptions = <
  TError = ModulePlaybookResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateModulePlaybookLevelHook>>>,
    TError,
    { id: string; data: CreateModulePlaybookRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCreateModulePlaybookLevelHook>>>,
  TError,
  { id: string; data: CreateModulePlaybookRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const createModulePlaybookLevel = useCreateModulePlaybookLevelHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCreateModulePlaybookLevelHook>>>,
    { id: string; data: CreateModulePlaybookRequest }
  > = props => {
    const { id, data } = props ?? {};

    return createModulePlaybookLevel(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateModulePlaybookLevelMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCreateModulePlaybookLevelHook>>>
>;
export type CreateModulePlaybookLevelMutationBody = CreateModulePlaybookRequest;
export type CreateModulePlaybookLevelMutationError = ModulePlaybookResponse;

/**
 * @summary Create Module playbook
 */
export const useCreateModulePlaybookLevel = <
  TError = ModulePlaybookResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateModulePlaybookLevelHook>>>,
    TError,
    { id: string; data: CreateModulePlaybookRequest },
    TContext
  >;
}) => {
  const mutationOptions = useCreateModulePlaybookLevelMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Create Module playbook step
 */
export const useCreateModulePlaybookStepHook = () => {
  const createModulePlaybookStep = useCustomAxios<ModulePlaybookDto>();

  return (id: string, createModulePlaybookRequest: CreateModulePlaybookRequest) => {
    return createModulePlaybookStep({
      url: `/workspace/module/${id}/playbook/step`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: createModulePlaybookRequest
    });
  };
};

export const useCreateModulePlaybookStepMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateModulePlaybookStepHook>>>,
    TError,
    { id: string; data: CreateModulePlaybookRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCreateModulePlaybookStepHook>>>,
  TError,
  { id: string; data: CreateModulePlaybookRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const createModulePlaybookStep = useCreateModulePlaybookStepHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCreateModulePlaybookStepHook>>>,
    { id: string; data: CreateModulePlaybookRequest }
  > = props => {
    const { id, data } = props ?? {};

    return createModulePlaybookStep(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateModulePlaybookStepMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCreateModulePlaybookStepHook>>>
>;
export type CreateModulePlaybookStepMutationBody = CreateModulePlaybookRequest;
export type CreateModulePlaybookStepMutationError = unknown;

/**
 * @summary Create Module playbook step
 */
export const useCreateModulePlaybookStep = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateModulePlaybookStepHook>>>,
    TError,
    { id: string; data: CreateModulePlaybookRequest },
    TContext
  >;
}) => {
  const mutationOptions = useCreateModulePlaybookStepMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary List Module prioritization
 */
export const useListModulesPrioritizationHook = () => {
  const listModulesPrioritization = useCustomAxios<ModulePrioritizationResponse>();

  return (params: ListModulesPrioritizationParams, signal?: AbortSignal) => {
    return listModulesPrioritization({
      url: `/workspace/module/prioritization`,
      method: 'get',
      params,
      signal
    });
  };
};

export const getListModulesPrioritizationQueryKey = (params: ListModulesPrioritizationParams) =>
  [`/workspace/module/prioritization`, ...(params ? [params] : [])] as const;

export const useListModulesPrioritizationQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useListModulesPrioritizationHook>>>,
  TError = ModulePrioritizationResponse
>(
  params: ListModulesPrioritizationParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useListModulesPrioritizationHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useListModulesPrioritizationHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListModulesPrioritizationQueryKey(params);

  const listModulesPrioritization = useListModulesPrioritizationHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useListModulesPrioritizationHook>>>
  > = ({ signal }) => listModulesPrioritization(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type ListModulesPrioritizationQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useListModulesPrioritizationHook>>>
>;
export type ListModulesPrioritizationQueryError = ModulePrioritizationResponse;

/**
 * @summary List Module prioritization
 */
export const useListModulesPrioritization = <
  TData = Awaited<ReturnType<ReturnType<typeof useListModulesPrioritizationHook>>>,
  TError = ModulePrioritizationResponse
>(
  params: ListModulesPrioritizationParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useListModulesPrioritizationHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useListModulesPrioritizationQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Create Module prioritization
 */
export const useCreateModulePrioritizationHook = () => {
  const createModulePrioritization = useCustomAxios<ModulePrioritizationResponse>();

  return (modulePrioritizationDto: ModulePrioritizationDto) => {
    return createModulePrioritization({
      url: `/workspace/module/prioritization`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: modulePrioritizationDto
    });
  };
};

export const useCreateModulePrioritizationMutationOptions = <
  TError = ModulePrioritizationResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateModulePrioritizationHook>>>,
    TError,
    { data: ModulePrioritizationDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCreateModulePrioritizationHook>>>,
  TError,
  { data: ModulePrioritizationDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const createModulePrioritization = useCreateModulePrioritizationHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCreateModulePrioritizationHook>>>,
    { data: ModulePrioritizationDto }
  > = props => {
    const { data } = props ?? {};

    return createModulePrioritization(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateModulePrioritizationMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCreateModulePrioritizationHook>>>
>;
export type CreateModulePrioritizationMutationBody = ModulePrioritizationDto;
export type CreateModulePrioritizationMutationError = ModulePrioritizationResponse;

/**
 * @summary Create Module prioritization
 */
export const useCreateModulePrioritization = <
  TError = ModulePrioritizationResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateModulePrioritizationHook>>>,
    TError,
    { data: ModulePrioritizationDto },
    TContext
  >;
}) => {
  const mutationOptions = useCreateModulePrioritizationMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get Modules metadata
 */
export const useGetModulesMetadataHook = () => {
  const getModulesMetadata = useCustomAxios<ModuleMetadataResponse>();

  return (params?: GetModulesMetadataParams, signal?: AbortSignal) => {
    return getModulesMetadata({ url: `/workspace/module/metadata`, method: 'get', params, signal });
  };
};

export const getGetModulesMetadataQueryKey = (params?: GetModulesMetadataParams) =>
  [`/workspace/module/metadata`, ...(params ? [params] : [])] as const;

export const useGetModulesMetadataQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetModulesMetadataHook>>>,
  TError = ModuleMetadataResponse
>(
  params?: GetModulesMetadataParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetModulesMetadataHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetModulesMetadataHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetModulesMetadataQueryKey(params);

  const getModulesMetadata = useGetModulesMetadataHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetModulesMetadataHook>>>
  > = ({ signal }) => getModulesMetadata(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetModulesMetadataQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetModulesMetadataHook>>>
>;
export type GetModulesMetadataQueryError = ModuleMetadataResponse;

/**
 * @summary Get Modules metadata
 */
export const useGetModulesMetadata = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetModulesMetadataHook>>>,
  TError = ModuleMetadataResponse
>(
  params?: GetModulesMetadataParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetModulesMetadataHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetModulesMetadataQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Create custom module
 */
export const useCreateCustomModuleHook = () => {
  const createCustomModule = useCustomAxios<ModuleMetadataResponse>();

  return (customModuleRequest: CustomModuleRequest) => {
    return createCustomModule({
      url: `/workspace/module/metadata`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: customModuleRequest
    });
  };
};

export const useCreateCustomModuleMutationOptions = <
  TError = ModuleMetadataResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateCustomModuleHook>>>,
    TError,
    { data: CustomModuleRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCreateCustomModuleHook>>>,
  TError,
  { data: CustomModuleRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const createCustomModule = useCreateCustomModuleHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCreateCustomModuleHook>>>,
    { data: CustomModuleRequest }
  > = props => {
    const { data } = props ?? {};

    return createCustomModule(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateCustomModuleMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCreateCustomModuleHook>>>
>;
export type CreateCustomModuleMutationBody = CustomModuleRequest;
export type CreateCustomModuleMutationError = ModuleMetadataResponse;

/**
 * @summary Create custom module
 */
export const useCreateCustomModule = <
  TError = ModuleMetadataResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateCustomModuleHook>>>,
    TError,
    { data: CustomModuleRequest },
    TContext
  >;
}) => {
  const mutationOptions = useCreateCustomModuleMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary List Module ideas
 */
export const useListModuleIdeasHook = () => {
  const listModuleIdeas = useCustomAxios<ModuleIdeaResponse>();

  return (params?: ListModuleIdeasParams, signal?: AbortSignal) => {
    return listModuleIdeas({ url: `/workspace/module/idea`, method: 'get', params, signal });
  };
};

export const getListModuleIdeasQueryKey = (params?: ListModuleIdeasParams) =>
  [`/workspace/module/idea`, ...(params ? [params] : [])] as const;

export const useListModuleIdeasQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useListModuleIdeasHook>>>,
  TError = ModuleIdeaResponse
>(
  params?: ListModuleIdeasParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useListModuleIdeasHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useListModuleIdeasHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListModuleIdeasQueryKey(params);

  const listModuleIdeas = useListModuleIdeasHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useListModuleIdeasHook>>>> = ({
    signal
  }) => listModuleIdeas(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type ListModuleIdeasQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useListModuleIdeasHook>>>
>;
export type ListModuleIdeasQueryError = ModuleIdeaResponse;

/**
 * @summary List Module ideas
 */
export const useListModuleIdeas = <
  TData = Awaited<ReturnType<ReturnType<typeof useListModuleIdeasHook>>>,
  TError = ModuleIdeaResponse
>(
  params?: ListModuleIdeasParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useListModuleIdeasHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useListModuleIdeasQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Create Module idea
 */
export const useCreateModuleIdeaHook = () => {
  const createModuleIdea = useCustomAxios<ModuleIdeaResponse>();

  return (moduleIdeaDto: ModuleIdeaDto) => {
    return createModuleIdea({
      url: `/workspace/module/idea`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: moduleIdeaDto
    });
  };
};

export const useCreateModuleIdeaMutationOptions = <
  TError = ModuleIdeaResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateModuleIdeaHook>>>,
    TError,
    { data: ModuleIdeaDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCreateModuleIdeaHook>>>,
  TError,
  { data: ModuleIdeaDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const createModuleIdea = useCreateModuleIdeaHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCreateModuleIdeaHook>>>,
    { data: ModuleIdeaDto }
  > = props => {
    const { data } = props ?? {};

    return createModuleIdea(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateModuleIdeaMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCreateModuleIdeaHook>>>
>;
export type CreateModuleIdeaMutationBody = ModuleIdeaDto;
export type CreateModuleIdeaMutationError = ModuleIdeaResponse;

/**
 * @summary Create Module idea
 */
export const useCreateModuleIdea = <TError = ModuleIdeaResponse, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateModuleIdeaHook>>>,
    TError,
    { data: ModuleIdeaDto },
    TContext
  >;
}) => {
  const mutationOptions = useCreateModuleIdeaMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Generate Upload url
 */
export const useGenerateUploadUrlHook = () => {
  const generateUploadUrl = useCustomAxios<FileUploadResponse>();

  return (name: string, params: GenerateUploadUrlParams) => {
    return generateUploadUrl({ url: `/workspace/file/${name}`, method: 'post', params });
  };
};

export const useGenerateUploadUrlMutationOptions = <
  TError = FileUploadResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useGenerateUploadUrlHook>>>,
    TError,
    { name: string; params: GenerateUploadUrlParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useGenerateUploadUrlHook>>>,
  TError,
  { name: string; params: GenerateUploadUrlParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const generateUploadUrl = useGenerateUploadUrlHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useGenerateUploadUrlHook>>>,
    { name: string; params: GenerateUploadUrlParams }
  > = props => {
    const { name, params } = props ?? {};

    return generateUploadUrl(name, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type GenerateUploadUrlMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGenerateUploadUrlHook>>>
>;

export type GenerateUploadUrlMutationError = FileUploadResponse;

/**
 * @summary Generate Upload url
 */
export const useGenerateUploadUrl = <TError = FileUploadResponse, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useGenerateUploadUrlHook>>>,
    TError,
    { name: string; params: GenerateUploadUrlParams },
    TContext
  >;
}) => {
  const mutationOptions = useGenerateUploadUrlMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Delete file
 */
export const useDeleteFileHook = () => {
  const deleteFile = useCustomAxios<void>();

  return (name: string, params: DeleteFileParams) => {
    return deleteFile({ url: `/workspace/file/${name}`, method: 'delete', params });
  };
};

export const useDeleteFileMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteFileHook>>>,
    TError,
    { name: string; params: DeleteFileParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteFileHook>>>,
  TError,
  { name: string; params: DeleteFileParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteFile = useDeleteFileHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteFileHook>>>,
    { name: string; params: DeleteFileParams }
  > = props => {
    const { name, params } = props ?? {};

    return deleteFile(name, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteFileMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteFileHook>>>
>;

export type DeleteFileMutationError = unknown;

/**
 * @summary Delete file
 */
export const useDeleteFile = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteFileHook>>>,
    TError,
    { name: string; params: DeleteFileParams },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteFileMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary List business Areas metadata
 */
export const useListBusinessAreaMetadataHook = () => {
  const listBusinessAreaMetadata = useCustomAxios<BusinessAreaMetadataResponse>();

  return (signal?: AbortSignal) => {
    return listBusinessAreaMetadata({
      url: `/workspace/businessArea/metadata`,
      method: 'get',
      signal
    });
  };
};

export const getListBusinessAreaMetadataQueryKey = () =>
  [`/workspace/businessArea/metadata`] as const;

export const useListBusinessAreaMetadataQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useListBusinessAreaMetadataHook>>>,
  TError = BusinessAreaMetadataResponse
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useListBusinessAreaMetadataHook>>>,
    TError,
    TData
  >;
}): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useListBusinessAreaMetadataHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListBusinessAreaMetadataQueryKey();

  const listBusinessAreaMetadata = useListBusinessAreaMetadataHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useListBusinessAreaMetadataHook>>>
  > = ({ signal }) => listBusinessAreaMetadata(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type ListBusinessAreaMetadataQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useListBusinessAreaMetadataHook>>>
>;
export type ListBusinessAreaMetadataQueryError = BusinessAreaMetadataResponse;

/**
 * @summary List business Areas metadata
 */
export const useListBusinessAreaMetadata = <
  TData = Awaited<ReturnType<ReturnType<typeof useListBusinessAreaMetadataHook>>>,
  TError = BusinessAreaMetadataResponse
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useListBusinessAreaMetadataHook>>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useListBusinessAreaMetadataQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Create custom Business Area
 */
export const useCreateCustomBusinessAreaHook = () => {
  const createCustomBusinessArea = useCustomAxios<BusinessAreaMetadataResponse>();

  return (customBusinessAreaRequest: CustomBusinessAreaRequest) => {
    return createCustomBusinessArea({
      url: `/workspace/businessArea/metadata`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: customBusinessAreaRequest
    });
  };
};

export const useCreateCustomBusinessAreaMutationOptions = <
  TError = BusinessAreaMetadataResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateCustomBusinessAreaHook>>>,
    TError,
    { data: CustomBusinessAreaRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCreateCustomBusinessAreaHook>>>,
  TError,
  { data: CustomBusinessAreaRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const createCustomBusinessArea = useCreateCustomBusinessAreaHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCreateCustomBusinessAreaHook>>>,
    { data: CustomBusinessAreaRequest }
  > = props => {
    const { data } = props ?? {};

    return createCustomBusinessArea(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateCustomBusinessAreaMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCreateCustomBusinessAreaHook>>>
>;
export type CreateCustomBusinessAreaMutationBody = CustomBusinessAreaRequest;
export type CreateCustomBusinessAreaMutationError = BusinessAreaMetadataResponse;

/**
 * @summary Create custom Business Area
 */
export const useCreateCustomBusinessArea = <
  TError = BusinessAreaMetadataResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateCustomBusinessAreaHook>>>,
    TError,
    { data: CustomBusinessAreaRequest },
    TContext
  >;
}) => {
  const mutationOptions = useCreateCustomBusinessAreaMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary List users
 */
export const useListUsersHook = () => {
  const listUsers = useCustomAxios<AccountUserResponse>();

  return (params?: ListUsersParams, signal?: AbortSignal) => {
    return listUsers({ url: `/user`, method: 'get', params, signal });
  };
};

export const getListUsersQueryKey = (params?: ListUsersParams) =>
  [`/user`, ...(params ? [params] : [])] as const;

export const useListUsersQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useListUsersHook>>>,
  TError = AccountUserResponse
>(
  params?: ListUsersParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useListUsersHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListUsersHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListUsersQueryKey(params);

  const listUsers = useListUsersHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useListUsersHook>>>> = ({
    signal
  }) => listUsers(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type ListUsersQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useListUsersHook>>>
>;
export type ListUsersQueryError = AccountUserResponse;

/**
 * @summary List users
 */
export const useListUsers = <
  TData = Awaited<ReturnType<ReturnType<typeof useListUsersHook>>>,
  TError = AccountUserResponse
>(
  params?: ListUsersParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useListUsersHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useListUsersQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Create user
 */
export const useCreateUserHook = () => {
  const createUser = useCustomAxios<AccountUserResponse>();

  return (createUserRequest: CreateUserRequest, params?: CreateUserParams) => {
    return createUser({
      url: `/user`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: createUserRequest,
      params
    });
  };
};

export const useCreateUserMutationOptions = <
  TError = AccountUserResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateUserHook>>>,
    TError,
    { data: CreateUserRequest; params?: CreateUserParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCreateUserHook>>>,
  TError,
  { data: CreateUserRequest; params?: CreateUserParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const createUser = useCreateUserHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCreateUserHook>>>,
    { data: CreateUserRequest; params?: CreateUserParams }
  > = props => {
    const { data, params } = props ?? {};

    return createUser(data, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateUserMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCreateUserHook>>>
>;
export type CreateUserMutationBody = CreateUserRequest;
export type CreateUserMutationError = AccountUserResponse;

/**
 * @summary Create user
 */
export const useCreateUser = <TError = AccountUserResponse, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateUserHook>>>,
    TError,
    { data: CreateUserRequest; params?: CreateUserParams },
    TContext
  >;
}) => {
  const mutationOptions = useCreateUserMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary List teams
 */
export const useListTeamsHook = () => {
  const listTeams = useCustomAxios<TeamResponse>();

  return (signal?: AbortSignal) => {
    return listTeams({ url: `/team`, method: 'get', signal });
  };
};

export const getListTeamsQueryKey = () => [`/team`] as const;

export const useListTeamsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useListTeamsHook>>>,
  TError = TeamResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListTeamsHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListTeamsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListTeamsQueryKey();

  const listTeams = useListTeamsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useListTeamsHook>>>> = ({
    signal
  }) => listTeams(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type ListTeamsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useListTeamsHook>>>
>;
export type ListTeamsQueryError = TeamResponse;

/**
 * @summary List teams
 */
export const useListTeams = <
  TData = Awaited<ReturnType<ReturnType<typeof useListTeamsHook>>>,
  TError = TeamResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListTeamsHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useListTeamsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary create team
 */
export const useCreateTeamHook = () => {
  const createTeam = useCustomAxios<TeamResponse>();

  return (teamDto: TeamDto) => {
    return createTeam({
      url: `/team`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: teamDto
    });
  };
};

export const useCreateTeamMutationOptions = <TError = TeamResponse, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateTeamHook>>>,
    TError,
    { data: TeamDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCreateTeamHook>>>,
  TError,
  { data: TeamDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const createTeam = useCreateTeamHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCreateTeamHook>>>,
    { data: TeamDto }
  > = props => {
    const { data } = props ?? {};

    return createTeam(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateTeamMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCreateTeamHook>>>
>;
export type CreateTeamMutationBody = TeamDto;
export type CreateTeamMutationError = TeamResponse;

/**
 * @summary create team
 */
export const useCreateTeam = <TError = TeamResponse, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateTeamHook>>>,
    TError,
    { data: TeamDto },
    TContext
  >;
}) => {
  const mutationOptions = useCreateTeamMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Send contact us message
 */
export const useSendContactUsMessageHook = () => {
  const sendContactUsMessage = useCustomAxios<void>();

  return (contactUsRequest: ContactUsRequest) => {
    return sendContactUsMessage({
      url: `/support/contactUs`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: contactUsRequest
    });
  };
};

export const useSendContactUsMessageMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSendContactUsMessageHook>>>,
    TError,
    { data: ContactUsRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useSendContactUsMessageHook>>>,
  TError,
  { data: ContactUsRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const sendContactUsMessage = useSendContactUsMessageHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useSendContactUsMessageHook>>>,
    { data: ContactUsRequest }
  > = props => {
    const { data } = props ?? {};

    return sendContactUsMessage(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type SendContactUsMessageMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useSendContactUsMessageHook>>>
>;
export type SendContactUsMessageMutationBody = ContactUsRequest;
export type SendContactUsMessageMutationError = unknown;

/**
 * @summary Send contact us message
 */
export const useSendContactUsMessage = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSendContactUsMessageHook>>>,
    TError,
    { data: ContactUsRequest },
    TContext
  >;
}) => {
  const mutationOptions = useSendContactUsMessageMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary List roles
 */
export const useListRolesHook = () => {
  const listRoles = useCustomAxios<RoleResponse>();

  return (signal?: AbortSignal) => {
    return listRoles({ url: `/role`, method: 'get', signal });
  };
};

export const getListRolesQueryKey = () => [`/role`] as const;

export const useListRolesQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useListRolesHook>>>,
  TError = RoleResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListRolesHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListRolesHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListRolesQueryKey();

  const listRoles = useListRolesHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useListRolesHook>>>> = ({
    signal
  }) => listRoles(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type ListRolesQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useListRolesHook>>>
>;
export type ListRolesQueryError = RoleResponse;

/**
 * @summary List roles
 */
export const useListRoles = <
  TData = Awaited<ReturnType<ReturnType<typeof useListRolesHook>>>,
  TError = RoleResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListRolesHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useListRolesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Create role
 */
export const useCreateRoleHook = () => {
  const createRole = useCustomAxios<RoleResponse>();

  return (roleDto: RoleDto) => {
    return createRole({
      url: `/role`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: roleDto
    });
  };
};

export const useCreateRoleMutationOptions = <TError = RoleResponse, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateRoleHook>>>,
    TError,
    { data: RoleDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCreateRoleHook>>>,
  TError,
  { data: RoleDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const createRole = useCreateRoleHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCreateRoleHook>>>,
    { data: RoleDto }
  > = props => {
    const { data } = props ?? {};

    return createRole(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateRoleMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCreateRoleHook>>>
>;
export type CreateRoleMutationBody = RoleDto;
export type CreateRoleMutationError = RoleResponse;

/**
 * @summary Create role
 */
export const useCreateRole = <TError = RoleResponse, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateRoleHook>>>,
    TError,
    { data: RoleDto },
    TContext
  >;
}) => {
  const mutationOptions = useCreateRoleMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary List all metrics
 */
export const useListMetricsHook = () => {
  const listMetrics = useCustomAxios<MetricResponse>();

  return (signal?: AbortSignal) => {
    return listMetrics({ url: `/metric`, method: 'get', signal });
  };
};

export const getListMetricsQueryKey = () => [`/metric`] as const;

export const useListMetricsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useListMetricsHook>>>,
  TError = MetricResponse
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useListMetricsHook>>>,
    TError,
    TData
  >;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListMetricsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListMetricsQueryKey();

  const listMetrics = useListMetricsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useListMetricsHook>>>> = ({
    signal
  }) => listMetrics(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type ListMetricsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useListMetricsHook>>>
>;
export type ListMetricsQueryError = MetricResponse;

/**
 * @summary List all metrics
 */
export const useListMetrics = <
  TData = Awaited<ReturnType<ReturnType<typeof useListMetricsHook>>>,
  TError = MetricResponse
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useListMetricsHook>>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useListMetricsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Create metric
 */
export const useCreateMetricHook = () => {
  const createMetric = useCustomAxios<MetricResponse>();

  return (metricDto: MetricDto) => {
    return createMetric({
      url: `/metric`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: metricDto
    });
  };
};

export const useCreateMetricMutationOptions = <
  TError = MetricResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateMetricHook>>>,
    TError,
    { data: MetricDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCreateMetricHook>>>,
  TError,
  { data: MetricDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const createMetric = useCreateMetricHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCreateMetricHook>>>,
    { data: MetricDto }
  > = props => {
    const { data } = props ?? {};

    return createMetric(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateMetricMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCreateMetricHook>>>
>;
export type CreateMetricMutationBody = MetricDto;
export type CreateMetricMutationError = MetricResponse;

/**
 * @summary Create metric
 */
export const useCreateMetric = <TError = MetricResponse, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateMetricHook>>>,
    TError,
    { data: MetricDto },
    TContext
  >;
}) => {
  const mutationOptions = useCreateMetricMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary upload metric values
 */
export const useUploadMetricValuesHook = () => {
  const uploadMetricValues = useCustomAxios<RegistrationResponse>();

  return (id: string, uploadMetricValuesBody: UploadMetricValuesBody) => {
    return uploadMetricValues({
      url: `/metric/${id}/value`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: uploadMetricValuesBody
    });
  };
};

export const useUploadMetricValuesMutationOptions = <
  TError = RegistrationResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUploadMetricValuesHook>>>,
    TError,
    { id: string; data: UploadMetricValuesBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUploadMetricValuesHook>>>,
  TError,
  { id: string; data: UploadMetricValuesBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const uploadMetricValues = useUploadMetricValuesHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUploadMetricValuesHook>>>,
    { id: string; data: UploadMetricValuesBody }
  > = props => {
    const { id, data } = props ?? {};

    return uploadMetricValues(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UploadMetricValuesMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUploadMetricValuesHook>>>
>;
export type UploadMetricValuesMutationBody = UploadMetricValuesBody;
export type UploadMetricValuesMutationError = RegistrationResponse;

/**
 * @summary upload metric values
 */
export const useUploadMetricValues = <TError = RegistrationResponse, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUploadMetricValuesHook>>>,
    TError,
    { id: string; data: UploadMetricValuesBody },
    TContext
  >;
}) => {
  const mutationOptions = useUploadMetricValuesMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary upload metric targets
 */
export const useUploadMetricTargetsHook = () => {
  const uploadMetricTargets = useCustomAxios<RegistrationResponse>();

  return (id: string, uploadMetricTargetsBody: UploadMetricTargetsBody) => {
    return uploadMetricTargets({
      url: `/metric/${id}/target`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: uploadMetricTargetsBody
    });
  };
};

export const useUploadMetricTargetsMutationOptions = <
  TError = RegistrationResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUploadMetricTargetsHook>>>,
    TError,
    { id: string; data: UploadMetricTargetsBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUploadMetricTargetsHook>>>,
  TError,
  { id: string; data: UploadMetricTargetsBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const uploadMetricTargets = useUploadMetricTargetsHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUploadMetricTargetsHook>>>,
    { id: string; data: UploadMetricTargetsBody }
  > = props => {
    const { id, data } = props ?? {};

    return uploadMetricTargets(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UploadMetricTargetsMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUploadMetricTargetsHook>>>
>;
export type UploadMetricTargetsMutationBody = UploadMetricTargetsBody;
export type UploadMetricTargetsMutationError = RegistrationResponse;

/**
 * @summary upload metric targets
 */
export const useUploadMetricTargets = <
  TError = RegistrationResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUploadMetricTargetsHook>>>,
    TError,
    { id: string; data: UploadMetricTargetsBody },
    TContext
  >;
}) => {
  const mutationOptions = useUploadMetricTargetsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary List all metric definitions
 */
export const useListMetricDefinitionsHook = () => {
  const listMetricDefinitions = useCustomAxios<MetricDefinitionResponse>();

  return (params?: ListMetricDefinitionsParams, signal?: AbortSignal) => {
    return listMetricDefinitions({ url: `/metric/definition`, method: 'get', params, signal });
  };
};

export const getListMetricDefinitionsQueryKey = (params?: ListMetricDefinitionsParams) =>
  [`/metric/definition`, ...(params ? [params] : [])] as const;

export const useListMetricDefinitionsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useListMetricDefinitionsHook>>>,
  TError = MetricDefinitionResponse
>(
  params?: ListMetricDefinitionsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useListMetricDefinitionsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useListMetricDefinitionsHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListMetricDefinitionsQueryKey(params);

  const listMetricDefinitions = useListMetricDefinitionsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useListMetricDefinitionsHook>>>
  > = ({ signal }) => listMetricDefinitions(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type ListMetricDefinitionsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useListMetricDefinitionsHook>>>
>;
export type ListMetricDefinitionsQueryError = MetricDefinitionResponse;

/**
 * @summary List all metric definitions
 */
export const useListMetricDefinitions = <
  TData = Awaited<ReturnType<ReturnType<typeof useListMetricDefinitionsHook>>>,
  TError = MetricDefinitionResponse
>(
  params?: ListMetricDefinitionsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useListMetricDefinitionsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useListMetricDefinitionsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Create metric definition
 */
export const useCreateMetricDefinitionHook = () => {
  const createMetricDefinition = useCustomAxios<MetricDefinitionResponse>();

  return (metricDefinitionDto: MetricDefinitionDto) => {
    return createMetricDefinition({
      url: `/metric/definition`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: metricDefinitionDto
    });
  };
};

export const useCreateMetricDefinitionMutationOptions = <
  TError = MetricDefinitionResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateMetricDefinitionHook>>>,
    TError,
    { data: MetricDefinitionDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCreateMetricDefinitionHook>>>,
  TError,
  { data: MetricDefinitionDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const createMetricDefinition = useCreateMetricDefinitionHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCreateMetricDefinitionHook>>>,
    { data: MetricDefinitionDto }
  > = props => {
    const { data } = props ?? {};

    return createMetricDefinition(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateMetricDefinitionMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCreateMetricDefinitionHook>>>
>;
export type CreateMetricDefinitionMutationBody = MetricDefinitionDto;
export type CreateMetricDefinitionMutationError = MetricDefinitionResponse;

/**
 * @summary Create metric definition
 */
export const useCreateMetricDefinition = <
  TError = MetricDefinitionResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateMetricDefinitionHook>>>,
    TError,
    { data: MetricDefinitionDto },
    TContext
  >;
}) => {
  const mutationOptions = useCreateMetricDefinitionMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Search metric definitions
 */
export const useSearchMetricDefinitionsHook = () => {
  const searchMetricDefinitions = useCustomAxios<MetricDefinitionResponse>();

  return (searchEntityRequest: SearchEntityRequest) => {
    return searchMetricDefinitions({
      url: `/metric/definition/search`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: searchEntityRequest
    });
  };
};

export const useSearchMetricDefinitionsMutationOptions = <
  TError = MetricDefinitionResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSearchMetricDefinitionsHook>>>,
    TError,
    { data: SearchEntityRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useSearchMetricDefinitionsHook>>>,
  TError,
  { data: SearchEntityRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const searchMetricDefinitions = useSearchMetricDefinitionsHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useSearchMetricDefinitionsHook>>>,
    { data: SearchEntityRequest }
  > = props => {
    const { data } = props ?? {};

    return searchMetricDefinitions(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type SearchMetricDefinitionsMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useSearchMetricDefinitionsHook>>>
>;
export type SearchMetricDefinitionsMutationBody = SearchEntityRequest;
export type SearchMetricDefinitionsMutationError = MetricDefinitionResponse;

/**
 * @summary Search metric definitions
 */
export const useSearchMetricDefinitions = <
  TError = MetricDefinitionResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSearchMetricDefinitionsHook>>>,
    TError,
    { data: SearchEntityRequest },
    TContext
  >;
}) => {
  const mutationOptions = useSearchMetricDefinitionsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get metric definitions progress
 */
export const useGetMetricDefinitionsProgressHook = () => {
  const getMetricDefinitionsProgress = useCustomAxios<MetricDefinitionProgressResponse>();

  return (searchMetricsRequest: SearchMetricsRequest) => {
    return getMetricDefinitionsProgress({
      url: `/metric/definition/progress`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: searchMetricsRequest
    });
  };
};

export const useGetMetricDefinitionsProgressMutationOptions = <
  TError = MetricDefinitionProgressResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useGetMetricDefinitionsProgressHook>>>,
    TError,
    { data: SearchMetricsRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useGetMetricDefinitionsProgressHook>>>,
  TError,
  { data: SearchMetricsRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const getMetricDefinitionsProgress = useGetMetricDefinitionsProgressHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useGetMetricDefinitionsProgressHook>>>,
    { data: SearchMetricsRequest }
  > = props => {
    const { data } = props ?? {};

    return getMetricDefinitionsProgress(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type GetMetricDefinitionsProgressMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetMetricDefinitionsProgressHook>>>
>;
export type GetMetricDefinitionsProgressMutationBody = SearchMetricsRequest;
export type GetMetricDefinitionsProgressMutationError = MetricDefinitionProgressResponse;

/**
 * @summary Get metric definitions progress
 */
export const useGetMetricDefinitionsProgress = <
  TError = MetricDefinitionProgressResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useGetMetricDefinitionsProgressHook>>>,
    TError,
    { data: SearchMetricsRequest },
    TContext
  >;
}) => {
  const mutationOptions = useGetMetricDefinitionsProgressMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Create metric data points
 */
export const useCreateMetricDataPointsHook = () => {
  const createMetricDataPoints = useCustomAxios<MetricDataPointResponse>();

  return (bulkMetricDataPointsRequest: BulkMetricDataPointsRequest) => {
    return createMetricDataPoints({
      url: `/metric/dataPoint/bulk`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: bulkMetricDataPointsRequest
    });
  };
};

export const useCreateMetricDataPointsMutationOptions = <
  TError = MetricDataPointResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateMetricDataPointsHook>>>,
    TError,
    { data: BulkMetricDataPointsRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCreateMetricDataPointsHook>>>,
  TError,
  { data: BulkMetricDataPointsRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const createMetricDataPoints = useCreateMetricDataPointsHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCreateMetricDataPointsHook>>>,
    { data: BulkMetricDataPointsRequest }
  > = props => {
    const { data } = props ?? {};

    return createMetricDataPoints(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateMetricDataPointsMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCreateMetricDataPointsHook>>>
>;
export type CreateMetricDataPointsMutationBody = BulkMetricDataPointsRequest;
export type CreateMetricDataPointsMutationError = MetricDataPointResponse;

/**
 * @summary Create metric data points
 */
export const useCreateMetricDataPoints = <
  TError = MetricDataPointResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateMetricDataPointsHook>>>,
    TError,
    { data: BulkMetricDataPointsRequest },
    TContext
  >;
}) => {
  const mutationOptions = useCreateMetricDataPointsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary List all meetings
 */
export const useListMeetingsHook = () => {
  const listMeetings = useCustomAxios<MeetingResponse>();

  return (signal?: AbortSignal) => {
    return listMeetings({ url: `/meeting`, method: 'get', signal });
  };
};

export const getListMeetingsQueryKey = () => [`/meeting`] as const;

export const useListMeetingsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useListMeetingsHook>>>,
  TError = MeetingResponse
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useListMeetingsHook>>>,
    TError,
    TData
  >;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListMeetingsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListMeetingsQueryKey();

  const listMeetings = useListMeetingsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useListMeetingsHook>>>> = ({
    signal
  }) => listMeetings(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type ListMeetingsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useListMeetingsHook>>>
>;
export type ListMeetingsQueryError = MeetingResponse;

/**
 * @summary List all meetings
 */
export const useListMeetings = <
  TData = Awaited<ReturnType<ReturnType<typeof useListMeetingsHook>>>,
  TError = MeetingResponse
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useListMeetingsHook>>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useListMeetingsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Start a meeting
 */
export const useStartMeetingHook = () => {
  const startMeeting = useCustomAxios<LiveMeetingResponse>();

  return () => {
    return startMeeting({ url: `/meeting`, method: 'post' });
  };
};

export const useStartMeetingMutationOptions = <
  TError = LiveMeetingResponse,
  TVariables = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useStartMeetingHook>>>,
    TError,
    TVariables,
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useStartMeetingHook>>>,
  TError,
  TVariables,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const startMeeting = useStartMeetingHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useStartMeetingHook>>>,
    TVariables
  > = () => {
    return startMeeting();
  };

  return { mutationFn, ...mutationOptions };
};

export type StartMeetingMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useStartMeetingHook>>>
>;

export type StartMeetingMutationError = LiveMeetingResponse;

/**
 * @summary Start a meeting
 */
export const useStartMeeting = <
  TError = LiveMeetingResponse,
  TVariables = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useStartMeetingHook>>>,
    TError,
    TVariables,
    TContext
  >;
}) => {
  const mutationOptions = useStartMeetingMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Search Meetings
 */
export const useSearchMeetingsHook = () => {
  const searchMeetings = useCustomAxios<MeetingDetailsResponse>();

  return (searchRequest: SearchRequest) => {
    return searchMeetings({
      url: `/meeting/search`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: searchRequest
    });
  };
};

export const useSearchMeetingsMutationOptions = <
  TError = MeetingDetailsResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSearchMeetingsHook>>>,
    TError,
    { data: SearchRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useSearchMeetingsHook>>>,
  TError,
  { data: SearchRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const searchMeetings = useSearchMeetingsHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useSearchMeetingsHook>>>,
    { data: SearchRequest }
  > = props => {
    const { data } = props ?? {};

    return searchMeetings(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type SearchMeetingsMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useSearchMeetingsHook>>>
>;
export type SearchMeetingsMutationBody = SearchRequest;
export type SearchMeetingsMutationError = MeetingDetailsResponse;

/**
 * @summary Search Meetings
 */
export const useSearchMeetings = <TError = MeetingDetailsResponse, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSearchMeetingsHook>>>,
    TError,
    { data: SearchRequest },
    TContext
  >;
}) => {
  const mutationOptions = useSearchMeetingsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary List scheduled meetings
 */
export const useListScheduledMeetingsHook = () => {
  const listScheduledMeetings = useCustomAxios<ScheduledMeetingResponse>();

  return (signal?: AbortSignal) => {
    return listScheduledMeetings({ url: `/meeting/scheduled`, method: 'get', signal });
  };
};

export const getListScheduledMeetingsQueryKey = () => [`/meeting/scheduled`] as const;

export const useListScheduledMeetingsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useListScheduledMeetingsHook>>>,
  TError = ScheduledMeetingResponse
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useListScheduledMeetingsHook>>>,
    TError,
    TData
  >;
}): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useListScheduledMeetingsHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListScheduledMeetingsQueryKey();

  const listScheduledMeetings = useListScheduledMeetingsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useListScheduledMeetingsHook>>>
  > = ({ signal }) => listScheduledMeetings(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type ListScheduledMeetingsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useListScheduledMeetingsHook>>>
>;
export type ListScheduledMeetingsQueryError = ScheduledMeetingResponse;

/**
 * @summary List scheduled meetings
 */
export const useListScheduledMeetings = <
  TData = Awaited<ReturnType<ReturnType<typeof useListScheduledMeetingsHook>>>,
  TError = ScheduledMeetingResponse
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useListScheduledMeetingsHook>>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useListScheduledMeetingsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Create scheduled meeting
 */
export const useCreateScheduledMeetingHook = () => {
  const createScheduledMeeting = useCustomAxios<ScheduledMeetingResponse>();

  return (scheduledMeetingDto: ScheduledMeetingDto) => {
    return createScheduledMeeting({
      url: `/meeting/scheduled`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: scheduledMeetingDto
    });
  };
};

export const useCreateScheduledMeetingMutationOptions = <
  TError = ScheduledMeetingResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateScheduledMeetingHook>>>,
    TError,
    { data: ScheduledMeetingDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCreateScheduledMeetingHook>>>,
  TError,
  { data: ScheduledMeetingDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const createScheduledMeeting = useCreateScheduledMeetingHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCreateScheduledMeetingHook>>>,
    { data: ScheduledMeetingDto }
  > = props => {
    const { data } = props ?? {};

    return createScheduledMeeting(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateScheduledMeetingMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCreateScheduledMeetingHook>>>
>;
export type CreateScheduledMeetingMutationBody = ScheduledMeetingDto;
export type CreateScheduledMeetingMutationError = ScheduledMeetingResponse;

/**
 * @summary Create scheduled meeting
 */
export const useCreateScheduledMeeting = <
  TError = ScheduledMeetingResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateScheduledMeetingHook>>>,
    TError,
    { data: ScheduledMeetingDto },
    TContext
  >;
}) => {
  const mutationOptions = useCreateScheduledMeetingMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Create meeting feedback
 */
export const useCreateMeetingFeedbackHook = () => {
  const createMeetingFeedback = useCustomAxios<MeetingFeedbackResponse>();

  return (meetingFeedbackDto: MeetingFeedbackDto) => {
    return createMeetingFeedback({
      url: `/meeting/feedback`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: meetingFeedbackDto
    });
  };
};

export const useCreateMeetingFeedbackMutationOptions = <
  TError = MeetingFeedbackResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateMeetingFeedbackHook>>>,
    TError,
    { data: MeetingFeedbackDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCreateMeetingFeedbackHook>>>,
  TError,
  { data: MeetingFeedbackDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const createMeetingFeedback = useCreateMeetingFeedbackHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCreateMeetingFeedbackHook>>>,
    { data: MeetingFeedbackDto }
  > = props => {
    const { data } = props ?? {};

    return createMeetingFeedback(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateMeetingFeedbackMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCreateMeetingFeedbackHook>>>
>;
export type CreateMeetingFeedbackMutationBody = MeetingFeedbackDto;
export type CreateMeetingFeedbackMutationError = MeetingFeedbackResponse;

/**
 * @summary Create meeting feedback
 */
export const useCreateMeetingFeedback = <
  TError = MeetingFeedbackResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateMeetingFeedbackHook>>>,
    TError,
    { data: MeetingFeedbackDto },
    TContext
  >;
}) => {
  const mutationOptions = useCreateMeetingFeedbackMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get next meeting agenda
 */
export const useGetNextMeetingAgendaHook = () => {
  const getNextMeetingAgenda = useCustomAxios<MeetingAgendaResponse>();

  return (signal?: AbortSignal) => {
    return getNextMeetingAgenda({ url: `/meeting/agenda`, method: 'get', signal });
  };
};

export const getGetNextMeetingAgendaQueryKey = () => [`/meeting/agenda`] as const;

export const useGetNextMeetingAgendaQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetNextMeetingAgendaHook>>>,
  TError = MeetingAgendaResponse
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetNextMeetingAgendaHook>>>,
    TError,
    TData
  >;
}): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetNextMeetingAgendaHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetNextMeetingAgendaQueryKey();

  const getNextMeetingAgenda = useGetNextMeetingAgendaHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetNextMeetingAgendaHook>>>
  > = ({ signal }) => getNextMeetingAgenda(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetNextMeetingAgendaQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetNextMeetingAgendaHook>>>
>;
export type GetNextMeetingAgendaQueryError = MeetingAgendaResponse;

/**
 * @summary Get next meeting agenda
 */
export const useGetNextMeetingAgenda = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetNextMeetingAgendaHook>>>,
  TError = MeetingAgendaResponse
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetNextMeetingAgendaHook>>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetNextMeetingAgendaQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Create meeting agenda
 */
export const useCreateMeetingAgendaHook = () => {
  const createMeetingAgenda = useCustomAxios<MeetingAgendaResponse>();

  return (meetingAgendaDto: MeetingAgendaDto) => {
    return createMeetingAgenda({
      url: `/meeting/agenda`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: meetingAgendaDto
    });
  };
};

export const useCreateMeetingAgendaMutationOptions = <
  TError = MeetingAgendaResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateMeetingAgendaHook>>>,
    TError,
    { data: MeetingAgendaDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCreateMeetingAgendaHook>>>,
  TError,
  { data: MeetingAgendaDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const createMeetingAgenda = useCreateMeetingAgendaHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCreateMeetingAgendaHook>>>,
    { data: MeetingAgendaDto }
  > = props => {
    const { data } = props ?? {};

    return createMeetingAgenda(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateMeetingAgendaMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCreateMeetingAgendaHook>>>
>;
export type CreateMeetingAgendaMutationBody = MeetingAgendaDto;
export type CreateMeetingAgendaMutationError = MeetingAgendaResponse;

/**
 * @summary Create meeting agenda
 */
export const useCreateMeetingAgenda = <
  TError = MeetingAgendaResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateMeetingAgendaHook>>>,
    TError,
    { data: MeetingAgendaDto },
    TContext
  >;
}) => {
  const mutationOptions = useCreateMeetingAgendaMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary List account data source
 */
export const useListDataSourcesHook = () => {
  const listDataSources = useCustomAxios<DataSourceResponse>();

  return (signal?: AbortSignal) => {
    return listDataSources({ url: `/integration/dataSource`, method: 'get', signal });
  };
};

export const getListDataSourcesQueryKey = () => [`/integration/dataSource`] as const;

export const useListDataSourcesQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useListDataSourcesHook>>>,
  TError = DataSourceResponse
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useListDataSourcesHook>>>,
    TError,
    TData
  >;
}): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useListDataSourcesHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListDataSourcesQueryKey();

  const listDataSources = useListDataSourcesHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useListDataSourcesHook>>>> = ({
    signal
  }) => listDataSources(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type ListDataSourcesQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useListDataSourcesHook>>>
>;
export type ListDataSourcesQueryError = DataSourceResponse;

/**
 * @summary List account data source
 */
export const useListDataSources = <
  TData = Awaited<ReturnType<ReturnType<typeof useListDataSourcesHook>>>,
  TError = DataSourceResponse
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useListDataSourcesHook>>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useListDataSourcesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Create account data source
 */
export const useCreateDataSourceHook = () => {
  const createDataSource = useCustomAxios<DataSourceResponse>();

  return (dataSourceDto: DataSourceDto) => {
    return createDataSource({
      url: `/integration/dataSource`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: dataSourceDto
    });
  };
};

export const useCreateDataSourceMutationOptions = <
  TError = DataSourceResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateDataSourceHook>>>,
    TError,
    { data: DataSourceDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCreateDataSourceHook>>>,
  TError,
  { data: DataSourceDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const createDataSource = useCreateDataSourceHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCreateDataSourceHook>>>,
    { data: DataSourceDto }
  > = props => {
    const { data } = props ?? {};

    return createDataSource(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateDataSourceMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCreateDataSourceHook>>>
>;
export type CreateDataSourceMutationBody = DataSourceDto;
export type CreateDataSourceMutationError = DataSourceResponse;

/**
 * @summary Create account data source
 */
export const useCreateDataSource = <TError = DataSourceResponse, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateDataSourceHook>>>,
    TError,
    { data: DataSourceDto },
    TContext
  >;
}) => {
  const mutationOptions = useCreateDataSourceMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary List account data providers
 */
export const useListDataProvidersHook = () => {
  const listDataProviders = useCustomAxios<IntegrationDataProviderResponse>();

  return (params?: ListDataProvidersParams, signal?: AbortSignal) => {
    return listDataProviders({ url: `/integration/dataProvider`, method: 'get', params, signal });
  };
};

export const getListDataProvidersQueryKey = (params?: ListDataProvidersParams) =>
  [`/integration/dataProvider`, ...(params ? [params] : [])] as const;

export const useListDataProvidersQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useListDataProvidersHook>>>,
  TError = IntegrationDataProviderResponse
>(
  params?: ListDataProvidersParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useListDataProvidersHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useListDataProvidersHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListDataProvidersQueryKey(params);

  const listDataProviders = useListDataProvidersHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useListDataProvidersHook>>>
  > = ({ signal }) => listDataProviders(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type ListDataProvidersQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useListDataProvidersHook>>>
>;
export type ListDataProvidersQueryError = IntegrationDataProviderResponse;

/**
 * @summary List account data providers
 */
export const useListDataProviders = <
  TData = Awaited<ReturnType<ReturnType<typeof useListDataProvidersHook>>>,
  TError = IntegrationDataProviderResponse
>(
  params?: ListDataProvidersParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useListDataProvidersHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useListDataProvidersQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Create account data provider
 */
export const useCreateDataProviderHook = () => {
  const createDataProvider = useCustomAxios<IntegrationDataProviderResponse>();

  return (accountDataProviderRequest: AccountDataProviderRequest) => {
    return createDataProvider({
      url: `/integration/dataProvider`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: accountDataProviderRequest
    });
  };
};

export const useCreateDataProviderMutationOptions = <
  TError = IntegrationDataProviderResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateDataProviderHook>>>,
    TError,
    { data: AccountDataProviderRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCreateDataProviderHook>>>,
  TError,
  { data: AccountDataProviderRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const createDataProvider = useCreateDataProviderHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCreateDataProviderHook>>>,
    { data: AccountDataProviderRequest }
  > = props => {
    const { data } = props ?? {};

    return createDataProvider(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateDataProviderMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCreateDataProviderHook>>>
>;
export type CreateDataProviderMutationBody = AccountDataProviderRequest;
export type CreateDataProviderMutationError = IntegrationDataProviderResponse;

/**
 * @summary Create account data provider
 */
export const useCreateDataProvider = <
  TError = IntegrationDataProviderResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateDataProviderHook>>>,
    TError,
    { data: AccountDataProviderRequest },
    TContext
  >;
}) => {
  const mutationOptions = useCreateDataProviderMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Create quickbooks data provider
 */
export const useCreateQuickBooksDataProviderHook = () => {
  const createQuickBooksDataProvider = useCustomAxios<IntegrationDataProviderResponse>();

  return (quickBooksDataProviderCreateRequest: QuickBooksDataProviderCreateRequest) => {
    return createQuickBooksDataProvider({
      url: `/integration/dataProvider/quickBooks`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: quickBooksDataProviderCreateRequest
    });
  };
};

export const useCreateQuickBooksDataProviderMutationOptions = <
  TError = IntegrationDataProviderResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateQuickBooksDataProviderHook>>>,
    TError,
    { data: QuickBooksDataProviderCreateRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCreateQuickBooksDataProviderHook>>>,
  TError,
  { data: QuickBooksDataProviderCreateRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const createQuickBooksDataProvider = useCreateQuickBooksDataProviderHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCreateQuickBooksDataProviderHook>>>,
    { data: QuickBooksDataProviderCreateRequest }
  > = props => {
    const { data } = props ?? {};

    return createQuickBooksDataProvider(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateQuickBooksDataProviderMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCreateQuickBooksDataProviderHook>>>
>;
export type CreateQuickBooksDataProviderMutationBody = QuickBooksDataProviderCreateRequest;
export type CreateQuickBooksDataProviderMutationError = IntegrationDataProviderResponse;

/**
 * @summary Create quickbooks data provider
 */
export const useCreateQuickBooksDataProvider = <
  TError = IntegrationDataProviderResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateQuickBooksDataProviderHook>>>,
    TError,
    { data: QuickBooksDataProviderCreateRequest },
    TContext
  >;
}) => {
  const mutationOptions = useCreateQuickBooksDataProviderMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Create hubspot data provider
 */
export const useCreateHubspotDataProviderHook = () => {
  const createHubspotDataProvider = useCustomAxios<IntegrationDataProviderResponse>();

  return (hubspotDataProviderCreateRequest: HubspotDataProviderCreateRequest) => {
    return createHubspotDataProvider({
      url: `/integration/dataProvider/hubspot`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: hubspotDataProviderCreateRequest
    });
  };
};

export const useCreateHubspotDataProviderMutationOptions = <
  TError = IntegrationDataProviderResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateHubspotDataProviderHook>>>,
    TError,
    { data: HubspotDataProviderCreateRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCreateHubspotDataProviderHook>>>,
  TError,
  { data: HubspotDataProviderCreateRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const createHubspotDataProvider = useCreateHubspotDataProviderHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCreateHubspotDataProviderHook>>>,
    { data: HubspotDataProviderCreateRequest }
  > = props => {
    const { data } = props ?? {};

    return createHubspotDataProvider(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateHubspotDataProviderMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCreateHubspotDataProviderHook>>>
>;
export type CreateHubspotDataProviderMutationBody = HubspotDataProviderCreateRequest;
export type CreateHubspotDataProviderMutationError = IntegrationDataProviderResponse;

/**
 * @summary Create hubspot data provider
 */
export const useCreateHubspotDataProvider = <
  TError = IntegrationDataProviderResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateHubspotDataProviderHook>>>,
    TError,
    { data: HubspotDataProviderCreateRequest },
    TContext
  >;
}) => {
  const mutationOptions = useCreateHubspotDataProviderMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Create google data provider
 */
export const useCreateGoogleDataProviderHook = () => {
  const createGoogleDataProvider = useCustomAxios<IntegrationDataProviderResponse>();

  return (googleDataProviderCreateRequest: GoogleDataProviderCreateRequest) => {
    return createGoogleDataProvider({
      url: `/integration/dataProvider/google`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: googleDataProviderCreateRequest
    });
  };
};

export const useCreateGoogleDataProviderMutationOptions = <
  TError = IntegrationDataProviderResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateGoogleDataProviderHook>>>,
    TError,
    { data: GoogleDataProviderCreateRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCreateGoogleDataProviderHook>>>,
  TError,
  { data: GoogleDataProviderCreateRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const createGoogleDataProvider = useCreateGoogleDataProviderHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCreateGoogleDataProviderHook>>>,
    { data: GoogleDataProviderCreateRequest }
  > = props => {
    const { data } = props ?? {};

    return createGoogleDataProvider(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateGoogleDataProviderMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCreateGoogleDataProviderHook>>>
>;
export type CreateGoogleDataProviderMutationBody = GoogleDataProviderCreateRequest;
export type CreateGoogleDataProviderMutationError = IntegrationDataProviderResponse;

/**
 * @summary Create google data provider
 */
export const useCreateGoogleDataProvider = <
  TError = IntegrationDataProviderResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateGoogleDataProviderHook>>>,
    TError,
    { data: GoogleDataProviderCreateRequest },
    TContext
  >;
}) => {
  const mutationOptions = useCreateGoogleDataProviderMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary List saved posts
 */
export const useGetSavedPostsHook = () => {
  const getSavedPosts = useCustomAxios<SavedPostsResponse>();

  return (signal?: AbortSignal) => {
    return getSavedPosts({ url: `/forum/savedPosts`, method: 'get', signal });
  };
};

export const getGetSavedPostsQueryKey = () => [`/forum/savedPosts`] as const;

export const useGetSavedPostsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetSavedPostsHook>>>,
  TError = SavedPostsResponse
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetSavedPostsHook>>>,
    TError,
    TData
  >;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetSavedPostsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSavedPostsQueryKey();

  const getSavedPosts = useGetSavedPostsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetSavedPostsHook>>>> = ({
    signal
  }) => getSavedPosts(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetSavedPostsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetSavedPostsHook>>>
>;
export type GetSavedPostsQueryError = SavedPostsResponse;

/**
 * @summary List saved posts
 */
export const useGetSavedPosts = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetSavedPostsHook>>>,
  TError = SavedPostsResponse
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetSavedPostsHook>>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetSavedPostsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Create saved posts
 */
export const useCreateSavedPostsHook = () => {
  const createSavedPosts = useCustomAxios<SavedPostsResponse>();

  return (savedPostsDto: SavedPostsDto) => {
    return createSavedPosts({
      url: `/forum/savedPosts`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: savedPostsDto
    });
  };
};

export const useCreateSavedPostsMutationOptions = <
  TError = SavedPostsResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateSavedPostsHook>>>,
    TError,
    { data: SavedPostsDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCreateSavedPostsHook>>>,
  TError,
  { data: SavedPostsDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const createSavedPosts = useCreateSavedPostsHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCreateSavedPostsHook>>>,
    { data: SavedPostsDto }
  > = props => {
    const { data } = props ?? {};

    return createSavedPosts(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateSavedPostsMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCreateSavedPostsHook>>>
>;
export type CreateSavedPostsMutationBody = SavedPostsDto;
export type CreateSavedPostsMutationError = SavedPostsResponse;

/**
 * @summary Create saved posts
 */
export const useCreateSavedPosts = <TError = SavedPostsResponse, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateSavedPostsHook>>>,
    TError,
    { data: SavedPostsDto },
    TContext
  >;
}) => {
  const mutationOptions = useCreateSavedPostsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Patch saved posts
 */
export const usePatchSavedPostsHook = () => {
  const patchSavedPosts = useCustomAxios<void>();

  return (savedPostsDto: SavedPostsDto) => {
    return patchSavedPosts({
      url: `/forum/savedPosts`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: savedPostsDto
    });
  };
};

export const usePatchSavedPostsMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchSavedPostsHook>>>,
    TError,
    { data: SavedPostsDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePatchSavedPostsHook>>>,
  TError,
  { data: SavedPostsDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const patchSavedPosts = usePatchSavedPostsHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePatchSavedPostsHook>>>,
    { data: SavedPostsDto }
  > = props => {
    const { data } = props ?? {};

    return patchSavedPosts(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchSavedPostsMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePatchSavedPostsHook>>>
>;
export type PatchSavedPostsMutationBody = SavedPostsDto;
export type PatchSavedPostsMutationError = unknown;

/**
 * @summary Patch saved posts
 */
export const usePatchSavedPosts = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchSavedPostsHook>>>,
    TError,
    { data: SavedPostsDto },
    TContext
  >;
}) => {
  const mutationOptions = usePatchSavedPostsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary List posts
 */
export const useListPostsHook = () => {
  const listPosts = useCustomAxios<PostResponse>();

  return (params?: ListPostsParams, signal?: AbortSignal) => {
    return listPosts({ url: `/forum/post`, method: 'get', params, signal });
  };
};

export const getListPostsQueryKey = (params?: ListPostsParams) =>
  [`/forum/post`, ...(params ? [params] : [])] as const;

export const useListPostsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useListPostsHook>>>,
  TError = PostResponse
>(
  params?: ListPostsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useListPostsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListPostsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListPostsQueryKey(params);

  const listPosts = useListPostsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useListPostsHook>>>> = ({
    signal
  }) => listPosts(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type ListPostsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useListPostsHook>>>
>;
export type ListPostsQueryError = PostResponse;

/**
 * @summary List posts
 */
export const useListPosts = <
  TData = Awaited<ReturnType<ReturnType<typeof useListPostsHook>>>,
  TError = PostResponse
>(
  params?: ListPostsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useListPostsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useListPostsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Create post
 */
export const useCreatePostHook = () => {
  const createPost = useCustomAxios<PostResponse>();

  return (postDto: PostDto) => {
    return createPost({
      url: `/forum/post`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: postDto
    });
  };
};

export const useCreatePostMutationOptions = <TError = PostResponse, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreatePostHook>>>,
    TError,
    { data: PostDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCreatePostHook>>>,
  TError,
  { data: PostDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const createPost = useCreatePostHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCreatePostHook>>>,
    { data: PostDto }
  > = props => {
    const { data } = props ?? {};

    return createPost(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreatePostMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCreatePostHook>>>
>;
export type CreatePostMutationBody = PostDto;
export type CreatePostMutationError = PostResponse;

/**
 * @summary Create post
 */
export const useCreatePost = <TError = PostResponse, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreatePostHook>>>,
    TError,
    { data: PostDto },
    TContext
  >;
}) => {
  const mutationOptions = useCreatePostMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary List comments
 */
export const useListCommentsHook = () => {
  const listComments = useCustomAxios<CommentResponse>();

  return (params: ListCommentsParams, signal?: AbortSignal) => {
    return listComments({ url: `/forum/comment`, method: 'get', params, signal });
  };
};

export const getListCommentsQueryKey = (params: ListCommentsParams) =>
  [`/forum/comment`, ...(params ? [params] : [])] as const;

export const useListCommentsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useListCommentsHook>>>,
  TError = CommentResponse
>(
  params: ListCommentsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useListCommentsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListCommentsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListCommentsQueryKey(params);

  const listComments = useListCommentsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useListCommentsHook>>>> = ({
    signal
  }) => listComments(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type ListCommentsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useListCommentsHook>>>
>;
export type ListCommentsQueryError = CommentResponse;

/**
 * @summary List comments
 */
export const useListComments = <
  TData = Awaited<ReturnType<ReturnType<typeof useListCommentsHook>>>,
  TError = CommentResponse
>(
  params: ListCommentsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useListCommentsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useListCommentsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Create comment
 */
export const useCreateCommentHook = () => {
  const createComment = useCustomAxios<CommentResponse>();

  return (commentDto: CommentDto) => {
    return createComment({
      url: `/forum/comment`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: commentDto
    });
  };
};

export const useCreateCommentMutationOptions = <
  TError = CommentResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateCommentHook>>>,
    TError,
    { data: CommentDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCreateCommentHook>>>,
  TError,
  { data: CommentDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const createComment = useCreateCommentHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCreateCommentHook>>>,
    { data: CommentDto }
  > = props => {
    const { data } = props ?? {};

    return createComment(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateCommentMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCreateCommentHook>>>
>;
export type CreateCommentMutationBody = CommentDto;
export type CreateCommentMutationError = CommentResponse;

/**
 * @summary Create comment
 */
export const useCreateComment = <TError = CommentResponse, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateCommentHook>>>,
    TError,
    { data: CommentDto },
    TContext
  >;
}) => {
  const mutationOptions = useCreateCommentMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Sign up
 */
export const useSignUpHook = () => {
  const signUp = useCustomAxios<SignInResponse>();

  return (signUpRequest: SignUpRequest) => {
    return signUp({
      url: `/auth/signUp`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: signUpRequest
    });
  };
};

export const useSignUpMutationOptions = <TError = SignInResponse, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSignUpHook>>>,
    TError,
    { data: SignUpRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useSignUpHook>>>,
  TError,
  { data: SignUpRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const signUp = useSignUpHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useSignUpHook>>>,
    { data: SignUpRequest }
  > = props => {
    const { data } = props ?? {};

    return signUp(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type SignUpMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useSignUpHook>>>
>;
export type SignUpMutationBody = SignUpRequest;
export type SignUpMutationError = SignInResponse;

/**
 * @summary Sign up
 */
export const useSignUp = <TError = SignInResponse, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSignUpHook>>>,
    TError,
    { data: SignUpRequest },
    TContext
  >;
}) => {
  const mutationOptions = useSignUpMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Sign out
 */
export const useSignOutHook = () => {
  const signOut = useCustomAxios<void>();

  return () => {
    return signOut({ url: `/auth/signOut`, method: 'post' });
  };
};

export const useSignOutMutationOptions = <
  TError = unknown,
  TVariables = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSignOutHook>>>,
    TError,
    TVariables,
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useSignOutHook>>>,
  TError,
  TVariables,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const signOut = useSignOutHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useSignOutHook>>>,
    TVariables
  > = () => {
    return signOut();
  };

  return { mutationFn, ...mutationOptions };
};

export type SignOutMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useSignOutHook>>>
>;

export type SignOutMutationError = unknown;

/**
 * @summary Sign out
 */
export const useSignOut = <TError = unknown, TVariables = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSignOutHook>>>,
    TError,
    TVariables,
    TContext
  >;
}) => {
  const mutationOptions = useSignOutMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Sign in
 */
export const useSignInHook = () => {
  const signIn = useCustomAxios<SignInResponse>();

  return (signInRequest: SignInRequest) => {
    return signIn({
      url: `/auth/signIn`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: signInRequest
    });
  };
};

export const useSignInMutationOptions = <TError = SignInResponse, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSignInHook>>>,
    TError,
    { data: SignInRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useSignInHook>>>,
  TError,
  { data: SignInRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const signIn = useSignInHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useSignInHook>>>,
    { data: SignInRequest }
  > = props => {
    const { data } = props ?? {};

    return signIn(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type SignInMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useSignInHook>>>
>;
export type SignInMutationBody = SignInRequest;
export type SignInMutationError = SignInResponse;

/**
 * @summary Sign in
 */
export const useSignIn = <TError = SignInResponse, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSignInHook>>>,
    TError,
    { data: SignInRequest },
    TContext
  >;
}) => {
  const mutationOptions = useSignInMutationOptions(options);

  return useMutation(mutationOptions);
};

export const useSignInRefreshHook = () => {
  const signInRefresh = useCustomAxios<SignInResponse>();

  return (signInRefreshRequest: SignInRefreshRequest) => {
    return signInRefresh({
      url: `/auth/signIn/refresh`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: signInRefreshRequest
    });
  };
};

export const useSignInRefreshMutationOptions = <
  TError = SignInResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSignInRefreshHook>>>,
    TError,
    { data: SignInRefreshRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useSignInRefreshHook>>>,
  TError,
  { data: SignInRefreshRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const signInRefresh = useSignInRefreshHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useSignInRefreshHook>>>,
    { data: SignInRefreshRequest }
  > = props => {
    const { data } = props ?? {};

    return signInRefresh(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type SignInRefreshMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useSignInRefreshHook>>>
>;
export type SignInRefreshMutationBody = SignInRefreshRequest;
export type SignInRefreshMutationError = SignInResponse;

export const useSignInRefresh = <TError = SignInResponse, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSignInRefreshHook>>>,
    TError,
    { data: SignInRefreshRequest },
    TContext
  >;
}) => {
  const mutationOptions = useSignInRefreshMutationOptions(options);

  return useMutation(mutationOptions);
};

export const useResetPasswordHook = () => {
  const resetPassword = useCustomAxios<void>();

  return (resetPasswordRequest: ResetPasswordRequest) => {
    return resetPassword({
      url: `/auth/resetPassword`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: resetPasswordRequest
    });
  };
};

export const useResetPasswordMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useResetPasswordHook>>>,
    TError,
    { data: ResetPasswordRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useResetPasswordHook>>>,
  TError,
  { data: ResetPasswordRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const resetPassword = useResetPasswordHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useResetPasswordHook>>>,
    { data: ResetPasswordRequest }
  > = props => {
    const { data } = props ?? {};

    return resetPassword(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ResetPasswordMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useResetPasswordHook>>>
>;
export type ResetPasswordMutationBody = ResetPasswordRequest;
export type ResetPasswordMutationError = unknown;

export const useResetPassword = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useResetPasswordHook>>>,
    TError,
    { data: ResetPasswordRequest },
    TContext
  >;
}) => {
  const mutationOptions = useResetPasswordMutationOptions(options);

  return useMutation(mutationOptions);
};

export const useForgotPasswordHook = () => {
  const forgotPassword = useCustomAxios<void>();

  return (forgotPasswordRequest: ForgotPasswordRequest) => {
    return forgotPassword({
      url: `/auth/forgotPassword`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: forgotPasswordRequest
    });
  };
};

export const useForgotPasswordMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useForgotPasswordHook>>>,
    TError,
    { data: ForgotPasswordRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useForgotPasswordHook>>>,
  TError,
  { data: ForgotPasswordRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const forgotPassword = useForgotPasswordHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useForgotPasswordHook>>>,
    { data: ForgotPasswordRequest }
  > = props => {
    const { data } = props ?? {};

    return forgotPassword(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ForgotPasswordMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useForgotPasswordHook>>>
>;
export type ForgotPasswordMutationBody = ForgotPasswordRequest;
export type ForgotPasswordMutationError = unknown;

export const useForgotPassword = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useForgotPasswordHook>>>,
    TError,
    { data: ForgotPasswordRequest },
    TContext
  >;
}) => {
  const mutationOptions = useForgotPasswordMutationOptions(options);

  return useMutation(mutationOptions);
};

export const useChangePasswordHook = () => {
  const changePassword = useCustomAxios<void>();

  return (changePasswordRequest: ChangePasswordRequest) => {
    return changePassword({
      url: `/auth/changePassword`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: changePasswordRequest
    });
  };
};

export const useChangePasswordMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useChangePasswordHook>>>,
    TError,
    { data: ChangePasswordRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useChangePasswordHook>>>,
  TError,
  { data: ChangePasswordRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const changePassword = useChangePasswordHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useChangePasswordHook>>>,
    { data: ChangePasswordRequest }
  > = props => {
    const { data } = props ?? {};

    return changePassword(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ChangePasswordMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useChangePasswordHook>>>
>;
export type ChangePasswordMutationBody = ChangePasswordRequest;
export type ChangePasswordMutationError = unknown;

export const useChangePassword = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useChangePasswordHook>>>,
    TError,
    { data: ChangePasswordRequest },
    TContext
  >;
}) => {
  const mutationOptions = useChangePasswordMutationOptions(options);

  return useMutation(mutationOptions);
};

export const useAccountSignInHook = () => {
  const accountSignIn = useCustomAxios<SignInResponse>();

  return (accountSignInRequest: AccountSignInRequest) => {
    return accountSignIn({
      url: `/auth/accountSignIn`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: accountSignInRequest
    });
  };
};

export const useAccountSignInMutationOptions = <
  TError = SignInResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAccountSignInHook>>>,
    TError,
    { data: AccountSignInRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAccountSignInHook>>>,
  TError,
  { data: AccountSignInRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const accountSignIn = useAccountSignInHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAccountSignInHook>>>,
    { data: AccountSignInRequest }
  > = props => {
    const { data } = props ?? {};

    return accountSignIn(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AccountSignInMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAccountSignInHook>>>
>;
export type AccountSignInMutationBody = AccountSignInRequest;
export type AccountSignInMutationError = SignInResponse;

export const useAccountSignIn = <TError = SignInResponse, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAccountSignInHook>>>,
    TError,
    { data: AccountSignInRequest },
    TContext
  >;
}) => {
  const mutationOptions = useAccountSignInMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Ask Journey
 */
export const useAskJourneyHook = () => {
  const askJourney = useCustomAxios<AskJourneyResponse>();

  return (askJourneyRequest: AskJourneyRequest) => {
    return askJourney({
      url: `/ask`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: askJourneyRequest
    });
  };
};

export const useAskJourneyMutationOptions = <
  TError = AskJourneyResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAskJourneyHook>>>,
    TError,
    { data: AskJourneyRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAskJourneyHook>>>,
  TError,
  { data: AskJourneyRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const askJourney = useAskJourneyHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAskJourneyHook>>>,
    { data: AskJourneyRequest }
  > = props => {
    const { data } = props ?? {};

    return askJourney(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AskJourneyMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAskJourneyHook>>>
>;
export type AskJourneyMutationBody = AskJourneyRequest;
export type AskJourneyMutationError = AskJourneyResponse;

/**
 * @summary Ask Journey
 */
export const useAskJourney = <TError = AskJourneyResponse, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAskJourneyHook>>>,
    TError,
    { data: AskJourneyRequest },
    TContext
  >;
}) => {
  const mutationOptions = useAskJourneyMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Create answer
 */
export const useCreateAnswerHook = () => {
  const createAnswer = useCustomAxios<void>();

  return (askJourneyAnswerDto: AskJourneyAnswerDto) => {
    return createAnswer({
      url: `/ask/answer`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: askJourneyAnswerDto
    });
  };
};

export const useCreateAnswerMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateAnswerHook>>>,
    TError,
    { data: AskJourneyAnswerDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCreateAnswerHook>>>,
  TError,
  { data: AskJourneyAnswerDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const createAnswer = useCreateAnswerHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCreateAnswerHook>>>,
    { data: AskJourneyAnswerDto }
  > = props => {
    const { data } = props ?? {};

    return createAnswer(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateAnswerMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCreateAnswerHook>>>
>;
export type CreateAnswerMutationBody = AskJourneyAnswerDto;
export type CreateAnswerMutationError = unknown;

/**
 * @summary Create answer
 */
export const useCreateAnswer = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateAnswerHook>>>,
    TError,
    { data: AskJourneyAnswerDto },
    TContext
  >;
}) => {
  const mutationOptions = useCreateAnswerMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary List objectives
 */
export const useListActionPlanObjectivesHook = () => {
  const listActionPlanObjectives = useCustomAxios<ActionPlanObjectiveResponse>();

  return (params?: ListActionPlanObjectivesParams, signal?: AbortSignal) => {
    return listActionPlanObjectives({
      url: `/actionPlan/objective`,
      method: 'get',
      params,
      signal
    });
  };
};

export const getListActionPlanObjectivesQueryKey = (params?: ListActionPlanObjectivesParams) =>
  [`/actionPlan/objective`, ...(params ? [params] : [])] as const;

export const useListActionPlanObjectivesQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useListActionPlanObjectivesHook>>>,
  TError = ActionPlanObjectiveResponse
>(
  params?: ListActionPlanObjectivesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useListActionPlanObjectivesHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useListActionPlanObjectivesHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListActionPlanObjectivesQueryKey(params);

  const listActionPlanObjectives = useListActionPlanObjectivesHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useListActionPlanObjectivesHook>>>
  > = ({ signal }) => listActionPlanObjectives(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type ListActionPlanObjectivesQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useListActionPlanObjectivesHook>>>
>;
export type ListActionPlanObjectivesQueryError = ActionPlanObjectiveResponse;

/**
 * @summary List objectives
 */
export const useListActionPlanObjectives = <
  TData = Awaited<ReturnType<ReturnType<typeof useListActionPlanObjectivesHook>>>,
  TError = ActionPlanObjectiveResponse
>(
  params?: ListActionPlanObjectivesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useListActionPlanObjectivesHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useListActionPlanObjectivesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Create objective
 */
export const useCreateActionPlanObjectiveHook = () => {
  const createActionPlanObjective = useCustomAxios<ActionPlanObjectiveResponse>();

  return (actionPlanObjectiveDto: ActionPlanObjectiveDto) => {
    return createActionPlanObjective({
      url: `/actionPlan/objective`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: actionPlanObjectiveDto
    });
  };
};

export const useCreateActionPlanObjectiveMutationOptions = <
  TError = ActionPlanObjectiveResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateActionPlanObjectiveHook>>>,
    TError,
    { data: ActionPlanObjectiveDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCreateActionPlanObjectiveHook>>>,
  TError,
  { data: ActionPlanObjectiveDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const createActionPlanObjective = useCreateActionPlanObjectiveHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCreateActionPlanObjectiveHook>>>,
    { data: ActionPlanObjectiveDto }
  > = props => {
    const { data } = props ?? {};

    return createActionPlanObjective(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateActionPlanObjectiveMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCreateActionPlanObjectiveHook>>>
>;
export type CreateActionPlanObjectiveMutationBody = ActionPlanObjectiveDto;
export type CreateActionPlanObjectiveMutationError = ActionPlanObjectiveResponse;

/**
 * @summary Create objective
 */
export const useCreateActionPlanObjective = <
  TError = ActionPlanObjectiveResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateActionPlanObjectiveHook>>>,
    TError,
    { data: ActionPlanObjectiveDto },
    TContext
  >;
}) => {
  const mutationOptions = useCreateActionPlanObjectiveMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Search objectives
 */
export const useSearchActionPlanObjectivesHook = () => {
  const searchActionPlanObjectives = useCustomAxios<ActionPlanObjectiveResponse>();

  return (searchEntityRequest: SearchEntityRequest, params?: SearchActionPlanObjectivesParams) => {
    return searchActionPlanObjectives({
      url: `/actionPlan/objective/search`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: searchEntityRequest,
      params
    });
  };
};

export const useSearchActionPlanObjectivesMutationOptions = <
  TError = ActionPlanObjectiveResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSearchActionPlanObjectivesHook>>>,
    TError,
    { data: SearchEntityRequest; params?: SearchActionPlanObjectivesParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useSearchActionPlanObjectivesHook>>>,
  TError,
  { data: SearchEntityRequest; params?: SearchActionPlanObjectivesParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const searchActionPlanObjectives = useSearchActionPlanObjectivesHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useSearchActionPlanObjectivesHook>>>,
    { data: SearchEntityRequest; params?: SearchActionPlanObjectivesParams }
  > = props => {
    const { data, params } = props ?? {};

    return searchActionPlanObjectives(data, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type SearchActionPlanObjectivesMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useSearchActionPlanObjectivesHook>>>
>;
export type SearchActionPlanObjectivesMutationBody = SearchEntityRequest;
export type SearchActionPlanObjectivesMutationError = ActionPlanObjectiveResponse;

/**
 * @summary Search objectives
 */
export const useSearchActionPlanObjectives = <
  TError = ActionPlanObjectiveResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSearchActionPlanObjectivesHook>>>,
    TError,
    { data: SearchEntityRequest; params?: SearchActionPlanObjectivesParams },
    TContext
  >;
}) => {
  const mutationOptions = useSearchActionPlanObjectivesMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Search objectives detailed
 */
export const useSearchActionPlanDetailedObjectivesHook = () => {
  const searchActionPlanDetailedObjectives = useCustomAxios<ActionPlanObjectiveDetailedResponse>();

  return (
    searchEntityRequest: SearchEntityRequest,
    params: SearchActionPlanDetailedObjectivesParams
  ) => {
    return searchActionPlanDetailedObjectives({
      url: `/actionPlan/objective/detailed/search`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: searchEntityRequest,
      params
    });
  };
};

export const useSearchActionPlanDetailedObjectivesMutationOptions = <
  TError = ActionPlanObjectiveDetailedResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSearchActionPlanDetailedObjectivesHook>>>,
    TError,
    { data: SearchEntityRequest; params: SearchActionPlanDetailedObjectivesParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useSearchActionPlanDetailedObjectivesHook>>>,
  TError,
  { data: SearchEntityRequest; params: SearchActionPlanDetailedObjectivesParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const searchActionPlanDetailedObjectives = useSearchActionPlanDetailedObjectivesHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useSearchActionPlanDetailedObjectivesHook>>>,
    { data: SearchEntityRequest; params: SearchActionPlanDetailedObjectivesParams }
  > = props => {
    const { data, params } = props ?? {};

    return searchActionPlanDetailedObjectives(data, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type SearchActionPlanDetailedObjectivesMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useSearchActionPlanDetailedObjectivesHook>>>
>;
export type SearchActionPlanDetailedObjectivesMutationBody = SearchEntityRequest;
export type SearchActionPlanDetailedObjectivesMutationError = ActionPlanObjectiveDetailedResponse;

/**
 * @summary Search objectives detailed
 */
export const useSearchActionPlanDetailedObjectives = <
  TError = ActionPlanObjectiveDetailedResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSearchActionPlanDetailedObjectivesHook>>>,
    TError,
    { data: SearchEntityRequest; params: SearchActionPlanDetailedObjectivesParams },
    TContext
  >;
}) => {
  const mutationOptions = useSearchActionPlanDetailedObjectivesMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Create objective comment
 */
export const useCreateActionPlanObjectiveCommentHook = () => {
  const createActionPlanObjectiveComment = useCustomAxios<ActionPlanObjectiveCommentResponse>();

  return (actionPlanObjectiveCommentDto: ActionPlanObjectiveCommentDto) => {
    return createActionPlanObjectiveComment({
      url: `/actionPlan/objective/comment`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: actionPlanObjectiveCommentDto
    });
  };
};

export const useCreateActionPlanObjectiveCommentMutationOptions = <
  TError = ActionPlanObjectiveCommentResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateActionPlanObjectiveCommentHook>>>,
    TError,
    { data: ActionPlanObjectiveCommentDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCreateActionPlanObjectiveCommentHook>>>,
  TError,
  { data: ActionPlanObjectiveCommentDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const createActionPlanObjectiveComment = useCreateActionPlanObjectiveCommentHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCreateActionPlanObjectiveCommentHook>>>,
    { data: ActionPlanObjectiveCommentDto }
  > = props => {
    const { data } = props ?? {};

    return createActionPlanObjectiveComment(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateActionPlanObjectiveCommentMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCreateActionPlanObjectiveCommentHook>>>
>;
export type CreateActionPlanObjectiveCommentMutationBody = ActionPlanObjectiveCommentDto;
export type CreateActionPlanObjectiveCommentMutationError = ActionPlanObjectiveCommentResponse;

/**
 * @summary Create objective comment
 */
export const useCreateActionPlanObjectiveComment = <
  TError = ActionPlanObjectiveCommentResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateActionPlanObjectiveCommentHook>>>,
    TError,
    { data: ActionPlanObjectiveCommentDto },
    TContext
  >;
}) => {
  const mutationOptions = useCreateActionPlanObjectiveCommentMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Search objective comments
 */
export const useSearchActionPlanObjectiveCommentsHook = () => {
  const searchActionPlanObjectiveComments =
    useCustomAxios<ActionPlanObjectiveDetailedCommentResponse>();

  return (searchCommentsRequest: SearchCommentsRequest) => {
    return searchActionPlanObjectiveComments({
      url: `/actionPlan/objective/comment/search`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: searchCommentsRequest
    });
  };
};

export const useSearchActionPlanObjectiveCommentsMutationOptions = <
  TError = ActionPlanObjectiveDetailedCommentResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSearchActionPlanObjectiveCommentsHook>>>,
    TError,
    { data: SearchCommentsRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useSearchActionPlanObjectiveCommentsHook>>>,
  TError,
  { data: SearchCommentsRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const searchActionPlanObjectiveComments = useSearchActionPlanObjectiveCommentsHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useSearchActionPlanObjectiveCommentsHook>>>,
    { data: SearchCommentsRequest }
  > = props => {
    const { data } = props ?? {};

    return searchActionPlanObjectiveComments(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type SearchActionPlanObjectiveCommentsMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useSearchActionPlanObjectiveCommentsHook>>>
>;
export type SearchActionPlanObjectiveCommentsMutationBody = SearchCommentsRequest;
export type SearchActionPlanObjectiveCommentsMutationError =
  ActionPlanObjectiveDetailedCommentResponse;

/**
 * @summary Search objective comments
 */
export const useSearchActionPlanObjectiveComments = <
  TError = ActionPlanObjectiveDetailedCommentResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSearchActionPlanObjectiveCommentsHook>>>,
    TError,
    { data: SearchCommentsRequest },
    TContext
  >;
}) => {
  const mutationOptions = useSearchActionPlanObjectiveCommentsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary search metric metadata
 */
export const useSearchMetricsMetadataHook = () => {
  const searchMetricsMetadata = useCustomAxios<ActionPlanMetricMetadataResponse>();

  return (searchMetricMetadataRequest: SearchMetricMetadataRequest) => {
    return searchMetricsMetadata({
      url: `/actionPlan/metadata/metric/search`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: searchMetricMetadataRequest
    });
  };
};

export const useSearchMetricsMetadataMutationOptions = <
  TError = ActionPlanMetricMetadataResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSearchMetricsMetadataHook>>>,
    TError,
    { data: SearchMetricMetadataRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useSearchMetricsMetadataHook>>>,
  TError,
  { data: SearchMetricMetadataRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const searchMetricsMetadata = useSearchMetricsMetadataHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useSearchMetricsMetadataHook>>>,
    { data: SearchMetricMetadataRequest }
  > = props => {
    const { data } = props ?? {};

    return searchMetricsMetadata(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type SearchMetricsMetadataMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useSearchMetricsMetadataHook>>>
>;
export type SearchMetricsMetadataMutationBody = SearchMetricMetadataRequest;
export type SearchMetricsMetadataMutationError = ActionPlanMetricMetadataResponse;

/**
 * @summary search metric metadata
 */
export const useSearchMetricsMetadata = <
  TError = ActionPlanMetricMetadataResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSearchMetricsMetadataHook>>>,
    TError,
    { data: SearchMetricMetadataRequest },
    TContext
  >;
}) => {
  const mutationOptions = useSearchMetricsMetadataMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary List initiatives
 */
export const useListActionPlanInitiativesHook = () => {
  const listActionPlanInitiatives = useCustomAxios<ActionPlanInitiativeResponse>();

  return (params?: ListActionPlanInitiativesParams, signal?: AbortSignal) => {
    return listActionPlanInitiatives({
      url: `/actionPlan/initiative`,
      method: 'get',
      params,
      signal
    });
  };
};

export const getListActionPlanInitiativesQueryKey = (params?: ListActionPlanInitiativesParams) =>
  [`/actionPlan/initiative`, ...(params ? [params] : [])] as const;

export const useListActionPlanInitiativesQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useListActionPlanInitiativesHook>>>,
  TError = ActionPlanInitiativeResponse
>(
  params?: ListActionPlanInitiativesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useListActionPlanInitiativesHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useListActionPlanInitiativesHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListActionPlanInitiativesQueryKey(params);

  const listActionPlanInitiatives = useListActionPlanInitiativesHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useListActionPlanInitiativesHook>>>
  > = ({ signal }) => listActionPlanInitiatives(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type ListActionPlanInitiativesQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useListActionPlanInitiativesHook>>>
>;
export type ListActionPlanInitiativesQueryError = ActionPlanInitiativeResponse;

/**
 * @summary List initiatives
 */
export const useListActionPlanInitiatives = <
  TData = Awaited<ReturnType<ReturnType<typeof useListActionPlanInitiativesHook>>>,
  TError = ActionPlanInitiativeResponse
>(
  params?: ListActionPlanInitiativesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useListActionPlanInitiativesHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useListActionPlanInitiativesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Create initiative
 */
export const useCreateActionPlanInitiativeHook = () => {
  const createActionPlanInitiative = useCustomAxios<ActionPlanInitiativeResponse>();

  return (actionPlanInitiativeDto: ActionPlanInitiativeDto) => {
    return createActionPlanInitiative({
      url: `/actionPlan/initiative`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: actionPlanInitiativeDto
    });
  };
};

export const useCreateActionPlanInitiativeMutationOptions = <
  TError = ActionPlanInitiativeResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateActionPlanInitiativeHook>>>,
    TError,
    { data: ActionPlanInitiativeDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCreateActionPlanInitiativeHook>>>,
  TError,
  { data: ActionPlanInitiativeDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const createActionPlanInitiative = useCreateActionPlanInitiativeHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCreateActionPlanInitiativeHook>>>,
    { data: ActionPlanInitiativeDto }
  > = props => {
    const { data } = props ?? {};

    return createActionPlanInitiative(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateActionPlanInitiativeMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCreateActionPlanInitiativeHook>>>
>;
export type CreateActionPlanInitiativeMutationBody = ActionPlanInitiativeDto;
export type CreateActionPlanInitiativeMutationError = ActionPlanInitiativeResponse;

/**
 * @summary Create initiative
 */
export const useCreateActionPlanInitiative = <
  TError = ActionPlanInitiativeResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateActionPlanInitiativeHook>>>,
    TError,
    { data: ActionPlanInitiativeDto },
    TContext
  >;
}) => {
  const mutationOptions = useCreateActionPlanInitiativeMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Search initiatives
 */
export const useSearchActionPlanInitiativesHook = () => {
  const searchActionPlanInitiatives = useCustomAxios<ActionPlanInitiativeResponse>();

  return (searchEntityRequest: SearchEntityRequest, params?: SearchActionPlanInitiativesParams) => {
    return searchActionPlanInitiatives({
      url: `/actionPlan/initiative/search`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: searchEntityRequest,
      params
    });
  };
};

export const useSearchActionPlanInitiativesMutationOptions = <
  TError = ActionPlanInitiativeResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSearchActionPlanInitiativesHook>>>,
    TError,
    { data: SearchEntityRequest; params?: SearchActionPlanInitiativesParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useSearchActionPlanInitiativesHook>>>,
  TError,
  { data: SearchEntityRequest; params?: SearchActionPlanInitiativesParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const searchActionPlanInitiatives = useSearchActionPlanInitiativesHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useSearchActionPlanInitiativesHook>>>,
    { data: SearchEntityRequest; params?: SearchActionPlanInitiativesParams }
  > = props => {
    const { data, params } = props ?? {};

    return searchActionPlanInitiatives(data, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type SearchActionPlanInitiativesMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useSearchActionPlanInitiativesHook>>>
>;
export type SearchActionPlanInitiativesMutationBody = SearchEntityRequest;
export type SearchActionPlanInitiativesMutationError = ActionPlanInitiativeResponse;

/**
 * @summary Search initiatives
 */
export const useSearchActionPlanInitiatives = <
  TError = ActionPlanInitiativeResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSearchActionPlanInitiativesHook>>>,
    TError,
    { data: SearchEntityRequest; params?: SearchActionPlanInitiativesParams },
    TContext
  >;
}) => {
  const mutationOptions = useSearchActionPlanInitiativesMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Search detailed initiatives
 */
export const useSearchActionPlanInitiativeDetailedHook = () => {
  const searchActionPlanInitiativeDetailed = useCustomAxios<ActionPlanInitiativeDetailedResponse>();

  return (
    searchDetailedInitiativeRequest: SearchDetailedInitiativeRequest,
    params?: SearchActionPlanInitiativeDetailedParams
  ) => {
    return searchActionPlanInitiativeDetailed({
      url: `/actionPlan/initiative/detailed/search`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: searchDetailedInitiativeRequest,
      params
    });
  };
};

export const useSearchActionPlanInitiativeDetailedMutationOptions = <
  TError = ActionPlanInitiativeDetailedResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSearchActionPlanInitiativeDetailedHook>>>,
    TError,
    { data: SearchDetailedInitiativeRequest; params?: SearchActionPlanInitiativeDetailedParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useSearchActionPlanInitiativeDetailedHook>>>,
  TError,
  { data: SearchDetailedInitiativeRequest; params?: SearchActionPlanInitiativeDetailedParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const searchActionPlanInitiativeDetailed = useSearchActionPlanInitiativeDetailedHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useSearchActionPlanInitiativeDetailedHook>>>,
    { data: SearchDetailedInitiativeRequest; params?: SearchActionPlanInitiativeDetailedParams }
  > = props => {
    const { data, params } = props ?? {};

    return searchActionPlanInitiativeDetailed(data, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type SearchActionPlanInitiativeDetailedMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useSearchActionPlanInitiativeDetailedHook>>>
>;
export type SearchActionPlanInitiativeDetailedMutationBody = SearchDetailedInitiativeRequest;
export type SearchActionPlanInitiativeDetailedMutationError = ActionPlanInitiativeDetailedResponse;

/**
 * @summary Search detailed initiatives
 */
export const useSearchActionPlanInitiativeDetailed = <
  TError = ActionPlanInitiativeDetailedResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSearchActionPlanInitiativeDetailedHook>>>,
    TError,
    { data: SearchDetailedInitiativeRequest; params?: SearchActionPlanInitiativeDetailedParams },
    TContext
  >;
}) => {
  const mutationOptions = useSearchActionPlanInitiativeDetailedMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Create initiative comment
 */
export const useCreateActionPlanInitiativeCommentHook = () => {
  const createActionPlanInitiativeComment = useCustomAxios<ActionPlanInitiativeCommentResponse>();

  return (actionPlanInitiativeCommentDto: ActionPlanInitiativeCommentDto) => {
    return createActionPlanInitiativeComment({
      url: `/actionPlan/initiative/comment`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: actionPlanInitiativeCommentDto
    });
  };
};

export const useCreateActionPlanInitiativeCommentMutationOptions = <
  TError = ActionPlanInitiativeCommentResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateActionPlanInitiativeCommentHook>>>,
    TError,
    { data: ActionPlanInitiativeCommentDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCreateActionPlanInitiativeCommentHook>>>,
  TError,
  { data: ActionPlanInitiativeCommentDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const createActionPlanInitiativeComment = useCreateActionPlanInitiativeCommentHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCreateActionPlanInitiativeCommentHook>>>,
    { data: ActionPlanInitiativeCommentDto }
  > = props => {
    const { data } = props ?? {};

    return createActionPlanInitiativeComment(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateActionPlanInitiativeCommentMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCreateActionPlanInitiativeCommentHook>>>
>;
export type CreateActionPlanInitiativeCommentMutationBody = ActionPlanInitiativeCommentDto;
export type CreateActionPlanInitiativeCommentMutationError = ActionPlanInitiativeCommentResponse;

/**
 * @summary Create initiative comment
 */
export const useCreateActionPlanInitiativeComment = <
  TError = ActionPlanInitiativeCommentResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateActionPlanInitiativeCommentHook>>>,
    TError,
    { data: ActionPlanInitiativeCommentDto },
    TContext
  >;
}) => {
  const mutationOptions = useCreateActionPlanInitiativeCommentMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Search initiative comments
 */
export const useSearchActionPlanInitiativeCommentsHook = () => {
  const searchActionPlanInitiativeComments =
    useCustomAxios<ActionPlanInitiativeDetailedCommentResponse>();

  return (searchCommentsRequest: SearchCommentsRequest) => {
    return searchActionPlanInitiativeComments({
      url: `/actionPlan/initiative/comment/search`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: searchCommentsRequest
    });
  };
};

export const useSearchActionPlanInitiativeCommentsMutationOptions = <
  TError = ActionPlanInitiativeDetailedCommentResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSearchActionPlanInitiativeCommentsHook>>>,
    TError,
    { data: SearchCommentsRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useSearchActionPlanInitiativeCommentsHook>>>,
  TError,
  { data: SearchCommentsRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const searchActionPlanInitiativeComments = useSearchActionPlanInitiativeCommentsHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useSearchActionPlanInitiativeCommentsHook>>>,
    { data: SearchCommentsRequest }
  > = props => {
    const { data } = props ?? {};

    return searchActionPlanInitiativeComments(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type SearchActionPlanInitiativeCommentsMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useSearchActionPlanInitiativeCommentsHook>>>
>;
export type SearchActionPlanInitiativeCommentsMutationBody = SearchCommentsRequest;
export type SearchActionPlanInitiativeCommentsMutationError =
  ActionPlanInitiativeDetailedCommentResponse;

/**
 * @summary Search initiative comments
 */
export const useSearchActionPlanInitiativeComments = <
  TError = ActionPlanInitiativeDetailedCommentResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSearchActionPlanInitiativeCommentsHook>>>,
    TError,
    { data: SearchCommentsRequest },
    TContext
  >;
}) => {
  const mutationOptions = useSearchActionPlanInitiativeCommentsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary List boards
 */
export const useListActionPlanBoardsHook = () => {
  const listActionPlanBoards = useCustomAxios<ActionPlanBoardResponse>();

  return (params?: ListActionPlanBoardsParams, signal?: AbortSignal) => {
    return listActionPlanBoards({ url: `/actionPlan/board`, method: 'get', params, signal });
  };
};

export const getListActionPlanBoardsQueryKey = (params?: ListActionPlanBoardsParams) =>
  [`/actionPlan/board`, ...(params ? [params] : [])] as const;

export const useListActionPlanBoardsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useListActionPlanBoardsHook>>>,
  TError = ActionPlanBoardResponse
>(
  params?: ListActionPlanBoardsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useListActionPlanBoardsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useListActionPlanBoardsHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListActionPlanBoardsQueryKey(params);

  const listActionPlanBoards = useListActionPlanBoardsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useListActionPlanBoardsHook>>>
  > = ({ signal }) => listActionPlanBoards(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type ListActionPlanBoardsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useListActionPlanBoardsHook>>>
>;
export type ListActionPlanBoardsQueryError = ActionPlanBoardResponse;

/**
 * @summary List boards
 */
export const useListActionPlanBoards = <
  TData = Awaited<ReturnType<ReturnType<typeof useListActionPlanBoardsHook>>>,
  TError = ActionPlanBoardResponse
>(
  params?: ListActionPlanBoardsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useListActionPlanBoardsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useListActionPlanBoardsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Create board
 */
export const useCreateActionPlanBoardHook = () => {
  const createActionPlanBoard = useCustomAxios<ActionPlanBoardResponse>();

  return (actionPlanBoardDto: ActionPlanBoardDto) => {
    return createActionPlanBoard({
      url: `/actionPlan/board`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: actionPlanBoardDto
    });
  };
};

export const useCreateActionPlanBoardMutationOptions = <
  TError = ActionPlanBoardResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateActionPlanBoardHook>>>,
    TError,
    { data: ActionPlanBoardDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCreateActionPlanBoardHook>>>,
  TError,
  { data: ActionPlanBoardDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const createActionPlanBoard = useCreateActionPlanBoardHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCreateActionPlanBoardHook>>>,
    { data: ActionPlanBoardDto }
  > = props => {
    const { data } = props ?? {};

    return createActionPlanBoard(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateActionPlanBoardMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCreateActionPlanBoardHook>>>
>;
export type CreateActionPlanBoardMutationBody = ActionPlanBoardDto;
export type CreateActionPlanBoardMutationError = ActionPlanBoardResponse;

/**
 * @summary Create board
 */
export const useCreateActionPlanBoard = <
  TError = ActionPlanBoardResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateActionPlanBoardHook>>>,
    TError,
    { data: ActionPlanBoardDto },
    TContext
  >;
}) => {
  const mutationOptions = useCreateActionPlanBoardMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary List action items
 */
export const useListActionPlanActionItemsHook = () => {
  const listActionPlanActionItems = useCustomAxios<ActionPlanActionItemResponse>();

  return (params?: ListActionPlanActionItemsParams, signal?: AbortSignal) => {
    return listActionPlanActionItems({
      url: `/actionPlan/actionItem`,
      method: 'get',
      params,
      signal
    });
  };
};

export const getListActionPlanActionItemsQueryKey = (params?: ListActionPlanActionItemsParams) =>
  [`/actionPlan/actionItem`, ...(params ? [params] : [])] as const;

export const useListActionPlanActionItemsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useListActionPlanActionItemsHook>>>,
  TError = ActionPlanActionItemResponse
>(
  params?: ListActionPlanActionItemsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useListActionPlanActionItemsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useListActionPlanActionItemsHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListActionPlanActionItemsQueryKey(params);

  const listActionPlanActionItems = useListActionPlanActionItemsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useListActionPlanActionItemsHook>>>
  > = ({ signal }) => listActionPlanActionItems(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type ListActionPlanActionItemsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useListActionPlanActionItemsHook>>>
>;
export type ListActionPlanActionItemsQueryError = ActionPlanActionItemResponse;

/**
 * @summary List action items
 */
export const useListActionPlanActionItems = <
  TData = Awaited<ReturnType<ReturnType<typeof useListActionPlanActionItemsHook>>>,
  TError = ActionPlanActionItemResponse
>(
  params?: ListActionPlanActionItemsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useListActionPlanActionItemsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useListActionPlanActionItemsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Create action item
 */
export const useCreateActionPlanActionItemHook = () => {
  const createActionPlanActionItem = useCustomAxios<ActionPlanActionItemResponse>();

  return (actionPlanActionItemDto: ActionPlanActionItemDto) => {
    return createActionPlanActionItem({
      url: `/actionPlan/actionItem`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: actionPlanActionItemDto
    });
  };
};

export const useCreateActionPlanActionItemMutationOptions = <
  TError = ActionPlanActionItemResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateActionPlanActionItemHook>>>,
    TError,
    { data: ActionPlanActionItemDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCreateActionPlanActionItemHook>>>,
  TError,
  { data: ActionPlanActionItemDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const createActionPlanActionItem = useCreateActionPlanActionItemHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCreateActionPlanActionItemHook>>>,
    { data: ActionPlanActionItemDto }
  > = props => {
    const { data } = props ?? {};

    return createActionPlanActionItem(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateActionPlanActionItemMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCreateActionPlanActionItemHook>>>
>;
export type CreateActionPlanActionItemMutationBody = ActionPlanActionItemDto;
export type CreateActionPlanActionItemMutationError = ActionPlanActionItemResponse;

/**
 * @summary Create action item
 */
export const useCreateActionPlanActionItem = <
  TError = ActionPlanActionItemResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateActionPlanActionItemHook>>>,
    TError,
    { data: ActionPlanActionItemDto },
    TContext
  >;
}) => {
  const mutationOptions = useCreateActionPlanActionItemMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Search action items
 */
export const useSearchActionPlanActionItemsHook = () => {
  const searchActionPlanActionItems = useCustomAxios<ActionPlanActionItemResponse>();

  return (searchEntityRequest: SearchEntityRequest, params?: SearchActionPlanActionItemsParams) => {
    return searchActionPlanActionItems({
      url: `/actionPlan/actionItem/search`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: searchEntityRequest,
      params
    });
  };
};

export const useSearchActionPlanActionItemsMutationOptions = <
  TError = ActionPlanActionItemResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSearchActionPlanActionItemsHook>>>,
    TError,
    { data: SearchEntityRequest; params?: SearchActionPlanActionItemsParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useSearchActionPlanActionItemsHook>>>,
  TError,
  { data: SearchEntityRequest; params?: SearchActionPlanActionItemsParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const searchActionPlanActionItems = useSearchActionPlanActionItemsHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useSearchActionPlanActionItemsHook>>>,
    { data: SearchEntityRequest; params?: SearchActionPlanActionItemsParams }
  > = props => {
    const { data, params } = props ?? {};

    return searchActionPlanActionItems(data, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type SearchActionPlanActionItemsMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useSearchActionPlanActionItemsHook>>>
>;
export type SearchActionPlanActionItemsMutationBody = SearchEntityRequest;
export type SearchActionPlanActionItemsMutationError = ActionPlanActionItemResponse;

/**
 * @summary Search action items
 */
export const useSearchActionPlanActionItems = <
  TError = ActionPlanActionItemResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSearchActionPlanActionItemsHook>>>,
    TError,
    { data: SearchEntityRequest; params?: SearchActionPlanActionItemsParams },
    TContext
  >;
}) => {
  const mutationOptions = useSearchActionPlanActionItemsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Create action item comment
 */
export const useCreateActionPlanActionItemCommentHook = () => {
  const createActionPlanActionItemComment = useCustomAxios<ActionPlanActionItemCommentResponse>();

  return (actionPlanActionItemCommentDto: ActionPlanActionItemCommentDto) => {
    return createActionPlanActionItemComment({
      url: `/actionPlan/actionItem/comment`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: actionPlanActionItemCommentDto
    });
  };
};

export const useCreateActionPlanActionItemCommentMutationOptions = <
  TError = ActionPlanActionItemCommentResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateActionPlanActionItemCommentHook>>>,
    TError,
    { data: ActionPlanActionItemCommentDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCreateActionPlanActionItemCommentHook>>>,
  TError,
  { data: ActionPlanActionItemCommentDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const createActionPlanActionItemComment = useCreateActionPlanActionItemCommentHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCreateActionPlanActionItemCommentHook>>>,
    { data: ActionPlanActionItemCommentDto }
  > = props => {
    const { data } = props ?? {};

    return createActionPlanActionItemComment(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateActionPlanActionItemCommentMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCreateActionPlanActionItemCommentHook>>>
>;
export type CreateActionPlanActionItemCommentMutationBody = ActionPlanActionItemCommentDto;
export type CreateActionPlanActionItemCommentMutationError = ActionPlanActionItemCommentResponse;

/**
 * @summary Create action item comment
 */
export const useCreateActionPlanActionItemComment = <
  TError = ActionPlanActionItemCommentResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateActionPlanActionItemCommentHook>>>,
    TError,
    { data: ActionPlanActionItemCommentDto },
    TContext
  >;
}) => {
  const mutationOptions = useCreateActionPlanActionItemCommentMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Search action items comments
 */
export const useSearchActionPlanActionItemCommentsHook = () => {
  const searchActionPlanActionItemComments =
    useCustomAxios<ActionPlanActionItemDetailedCommentResponse>();

  return (searchCommentsRequest: SearchCommentsRequest) => {
    return searchActionPlanActionItemComments({
      url: `/actionPlan/actionItem/comment/search`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: searchCommentsRequest
    });
  };
};

export const useSearchActionPlanActionItemCommentsMutationOptions = <
  TError = ActionPlanActionItemDetailedCommentResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSearchActionPlanActionItemCommentsHook>>>,
    TError,
    { data: SearchCommentsRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useSearchActionPlanActionItemCommentsHook>>>,
  TError,
  { data: SearchCommentsRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const searchActionPlanActionItemComments = useSearchActionPlanActionItemCommentsHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useSearchActionPlanActionItemCommentsHook>>>,
    { data: SearchCommentsRequest }
  > = props => {
    const { data } = props ?? {};

    return searchActionPlanActionItemComments(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type SearchActionPlanActionItemCommentsMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useSearchActionPlanActionItemCommentsHook>>>
>;
export type SearchActionPlanActionItemCommentsMutationBody = SearchCommentsRequest;
export type SearchActionPlanActionItemCommentsMutationError =
  ActionPlanActionItemDetailedCommentResponse;

/**
 * @summary Search action items comments
 */
export const useSearchActionPlanActionItemComments = <
  TError = ActionPlanActionItemDetailedCommentResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSearchActionPlanActionItemCommentsHook>>>,
    TError,
    { data: SearchCommentsRequest },
    TContext
  >;
}) => {
  const mutationOptions = useSearchActionPlanActionItemCommentsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get whiteboard
 */
export const useGetWhiteboardHook = () => {
  const getWhiteboard = useCustomAxios<WhiteboardResponse>();

  return (id: string, signal?: AbortSignal) => {
    return getWhiteboard({ url: `/workspace/whiteboard/${id}`, method: 'get', signal });
  };
};

export const getGetWhiteboardQueryKey = (id: string) => [`/workspace/whiteboard/${id}`] as const;

export const useGetWhiteboardQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetWhiteboardHook>>>,
  TError = WhiteboardResponse
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetWhiteboardHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWhiteboardHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetWhiteboardQueryKey(id);

  const getWhiteboard = useGetWhiteboardHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetWhiteboardHook>>>> = ({
    signal
  }) => getWhiteboard(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type GetWhiteboardQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetWhiteboardHook>>>
>;
export type GetWhiteboardQueryError = WhiteboardResponse;

/**
 * @summary Get whiteboard
 */
export const useGetWhiteboard = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetWhiteboardHook>>>,
  TError = WhiteboardResponse
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetWhiteboardHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetWhiteboardQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Delete whiteboard
 */
export const useDeleteWhiteboardHook = () => {
  const deleteWhiteboard = useCustomAxios<void>();

  return (id: string) => {
    return deleteWhiteboard({ url: `/workspace/whiteboard/${id}`, method: 'delete' });
  };
};

export const useDeleteWhiteboardMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteWhiteboardHook>>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteWhiteboardHook>>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteWhiteboard = useDeleteWhiteboardHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteWhiteboardHook>>>,
    { id: string }
  > = props => {
    const { id } = props ?? {};

    return deleteWhiteboard(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteWhiteboardMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteWhiteboardHook>>>
>;

export type DeleteWhiteboardMutationError = unknown;

/**
 * @summary Delete whiteboard
 */
export const useDeleteWhiteboard = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteWhiteboardHook>>>,
    TError,
    { id: string },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteWhiteboardMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Patch whiteboard
 */
export const usePatchWhiteboardHook = () => {
  const patchWhiteboard = useCustomAxios<void>();

  return (id: string, whiteboardDto: WhiteboardDto) => {
    return patchWhiteboard({
      url: `/workspace/whiteboard/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: whiteboardDto
    });
  };
};

export const usePatchWhiteboardMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchWhiteboardHook>>>,
    TError,
    { id: string; data: WhiteboardDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePatchWhiteboardHook>>>,
  TError,
  { id: string; data: WhiteboardDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const patchWhiteboard = usePatchWhiteboardHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePatchWhiteboardHook>>>,
    { id: string; data: WhiteboardDto }
  > = props => {
    const { id, data } = props ?? {};

    return patchWhiteboard(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchWhiteboardMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePatchWhiteboardHook>>>
>;
export type PatchWhiteboardMutationBody = WhiteboardDto;
export type PatchWhiteboardMutationError = unknown;

/**
 * @summary Patch whiteboard
 */
export const usePatchWhiteboard = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchWhiteboardHook>>>,
    TError,
    { id: string; data: WhiteboardDto },
    TContext
  >;
}) => {
  const mutationOptions = usePatchWhiteboardMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get shared workspace meeting
 */
export const useGetSharedWorkspaceMeetingHook = () => {
  const getSharedWorkspaceMeeting = useCustomAxios<SharedWorkspaceMeetingResponse>();

  return (id: string, meetingId: string, signal?: AbortSignal) => {
    return getSharedWorkspaceMeeting({
      url: `/workspace/sharedWorkspace/${id}/meeting/${meetingId}`,
      method: 'get',
      signal
    });
  };
};

export const getGetSharedWorkspaceMeetingQueryKey = (id: string, meetingId: string) =>
  [`/workspace/sharedWorkspace/${id}/meeting/${meetingId}`] as const;

export const useGetSharedWorkspaceMeetingQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetSharedWorkspaceMeetingHook>>>,
  TError = SharedWorkspaceMeetingResponse
>(
  id: string,
  meetingId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetSharedWorkspaceMeetingHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetSharedWorkspaceMeetingHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSharedWorkspaceMeetingQueryKey(id, meetingId);

  const getSharedWorkspaceMeeting = useGetSharedWorkspaceMeetingHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetSharedWorkspaceMeetingHook>>>
  > = ({ signal }) => getSharedWorkspaceMeeting(id, meetingId, signal);

  return { queryKey, queryFn, enabled: !!(id && meetingId), ...queryOptions };
};

export type GetSharedWorkspaceMeetingQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetSharedWorkspaceMeetingHook>>>
>;
export type GetSharedWorkspaceMeetingQueryError = SharedWorkspaceMeetingResponse;

/**
 * @summary Get shared workspace meeting
 */
export const useGetSharedWorkspaceMeeting = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetSharedWorkspaceMeetingHook>>>,
  TError = SharedWorkspaceMeetingResponse
>(
  id: string,
  meetingId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetSharedWorkspaceMeetingHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetSharedWorkspaceMeetingQueryOptions(id, meetingId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Delete shared workspace meeting
 */
export const useDeleteSharedWorkspaceMeetingHook = () => {
  const deleteSharedWorkspaceMeeting = useCustomAxios<void>();

  return (id: string, meetingId: string) => {
    return deleteSharedWorkspaceMeeting({
      url: `/workspace/sharedWorkspace/${id}/meeting/${meetingId}`,
      method: 'delete'
    });
  };
};

export const useDeleteSharedWorkspaceMeetingMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteSharedWorkspaceMeetingHook>>>,
    TError,
    { id: string; meetingId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteSharedWorkspaceMeetingHook>>>,
  TError,
  { id: string; meetingId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteSharedWorkspaceMeeting = useDeleteSharedWorkspaceMeetingHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteSharedWorkspaceMeetingHook>>>,
    { id: string; meetingId: string }
  > = props => {
    const { id, meetingId } = props ?? {};

    return deleteSharedWorkspaceMeeting(id, meetingId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteSharedWorkspaceMeetingMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteSharedWorkspaceMeetingHook>>>
>;

export type DeleteSharedWorkspaceMeetingMutationError = unknown;

/**
 * @summary Delete shared workspace meeting
 */
export const useDeleteSharedWorkspaceMeeting = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteSharedWorkspaceMeetingHook>>>,
    TError,
    { id: string; meetingId: string },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteSharedWorkspaceMeetingMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Patch shared workspace meeting
 */
export const usePatchSharedWorkspaceMeetingHook = () => {
  const patchSharedWorkspaceMeeting = useCustomAxios<void>();

  return (id: string, meetingId: string, sharedWorkspaceMeetingDto: SharedWorkspaceMeetingDto) => {
    return patchSharedWorkspaceMeeting({
      url: `/workspace/sharedWorkspace/${id}/meeting/${meetingId}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: sharedWorkspaceMeetingDto
    });
  };
};

export const usePatchSharedWorkspaceMeetingMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchSharedWorkspaceMeetingHook>>>,
    TError,
    { id: string; meetingId: string; data: SharedWorkspaceMeetingDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePatchSharedWorkspaceMeetingHook>>>,
  TError,
  { id: string; meetingId: string; data: SharedWorkspaceMeetingDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const patchSharedWorkspaceMeeting = usePatchSharedWorkspaceMeetingHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePatchSharedWorkspaceMeetingHook>>>,
    { id: string; meetingId: string; data: SharedWorkspaceMeetingDto }
  > = props => {
    const { id, meetingId, data } = props ?? {};

    return patchSharedWorkspaceMeeting(id, meetingId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchSharedWorkspaceMeetingMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePatchSharedWorkspaceMeetingHook>>>
>;
export type PatchSharedWorkspaceMeetingMutationBody = SharedWorkspaceMeetingDto;
export type PatchSharedWorkspaceMeetingMutationError = unknown;

/**
 * @summary Patch shared workspace meeting
 */
export const usePatchSharedWorkspaceMeeting = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchSharedWorkspaceMeetingHook>>>,
    TError,
    { id: string; meetingId: string; data: SharedWorkspaceMeetingDto },
    TContext
  >;
}) => {
  const mutationOptions = usePatchSharedWorkspaceMeetingMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Delete shared workspace update
 */
export const useDeleteSharedWorkspaceUpdateHook = () => {
  const deleteSharedWorkspaceUpdate = useCustomAxios<void>();

  return (id: string) => {
    return deleteSharedWorkspaceUpdate({
      url: `/workspace/sharedWorkspace/update/${id}`,
      method: 'delete'
    });
  };
};

export const useDeleteSharedWorkspaceUpdateMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteSharedWorkspaceUpdateHook>>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteSharedWorkspaceUpdateHook>>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteSharedWorkspaceUpdate = useDeleteSharedWorkspaceUpdateHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteSharedWorkspaceUpdateHook>>>,
    { id: string }
  > = props => {
    const { id } = props ?? {};

    return deleteSharedWorkspaceUpdate(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteSharedWorkspaceUpdateMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteSharedWorkspaceUpdateHook>>>
>;

export type DeleteSharedWorkspaceUpdateMutationError = unknown;

/**
 * @summary Delete shared workspace update
 */
export const useDeleteSharedWorkspaceUpdate = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteSharedWorkspaceUpdateHook>>>,
    TError,
    { id: string },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteSharedWorkspaceUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Patch shared workspace update
 */
export const usePatchSharedWorkspaceUpdateHook = () => {
  const patchSharedWorkspaceUpdate = useCustomAxios<void>();

  return (id: string, sharedWorkspaceUpdateDto: SharedWorkspaceUpdateDto) => {
    return patchSharedWorkspaceUpdate({
      url: `/workspace/sharedWorkspace/update/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: sharedWorkspaceUpdateDto
    });
  };
};

export const usePatchSharedWorkspaceUpdateMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchSharedWorkspaceUpdateHook>>>,
    TError,
    { id: string; data: SharedWorkspaceUpdateDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePatchSharedWorkspaceUpdateHook>>>,
  TError,
  { id: string; data: SharedWorkspaceUpdateDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const patchSharedWorkspaceUpdate = usePatchSharedWorkspaceUpdateHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePatchSharedWorkspaceUpdateHook>>>,
    { id: string; data: SharedWorkspaceUpdateDto }
  > = props => {
    const { id, data } = props ?? {};

    return patchSharedWorkspaceUpdate(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchSharedWorkspaceUpdateMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePatchSharedWorkspaceUpdateHook>>>
>;
export type PatchSharedWorkspaceUpdateMutationBody = SharedWorkspaceUpdateDto;
export type PatchSharedWorkspaceUpdateMutationError = unknown;

/**
 * @summary Patch shared workspace update
 */
export const usePatchSharedWorkspaceUpdate = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchSharedWorkspaceUpdateHook>>>,
    TError,
    { id: string; data: SharedWorkspaceUpdateDto },
    TContext
  >;
}) => {
  const mutationOptions = usePatchSharedWorkspaceUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Delete shared workspace point comment
 */
export const useDeleteSharedWorkspacePointCommentHook = () => {
  const deleteSharedWorkspacePointComment = useCustomAxios<void>();

  return (id: string) => {
    return deleteSharedWorkspacePointComment({
      url: `/workspace/sharedWorkspace/point/comment/${id}`,
      method: 'delete'
    });
  };
};

export const useDeleteSharedWorkspacePointCommentMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteSharedWorkspacePointCommentHook>>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteSharedWorkspacePointCommentHook>>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteSharedWorkspacePointComment = useDeleteSharedWorkspacePointCommentHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteSharedWorkspacePointCommentHook>>>,
    { id: string }
  > = props => {
    const { id } = props ?? {};

    return deleteSharedWorkspacePointComment(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteSharedWorkspacePointCommentMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteSharedWorkspacePointCommentHook>>>
>;

export type DeleteSharedWorkspacePointCommentMutationError = unknown;

/**
 * @summary Delete shared workspace point comment
 */
export const useDeleteSharedWorkspacePointComment = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteSharedWorkspacePointCommentHook>>>,
    TError,
    { id: string },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteSharedWorkspacePointCommentMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Patch shared workspace point comment
 */
export const usePatchSharedWorkspacePointCommentHook = () => {
  const patchSharedWorkspacePointComment = useCustomAxios<void>();

  return (id: string, sharedWorkspacePointCommentDto: SharedWorkspacePointCommentDto) => {
    return patchSharedWorkspacePointComment({
      url: `/workspace/sharedWorkspace/point/comment/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: sharedWorkspacePointCommentDto
    });
  };
};

export const usePatchSharedWorkspacePointCommentMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchSharedWorkspacePointCommentHook>>>,
    TError,
    { id: string; data: SharedWorkspacePointCommentDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePatchSharedWorkspacePointCommentHook>>>,
  TError,
  { id: string; data: SharedWorkspacePointCommentDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const patchSharedWorkspacePointComment = usePatchSharedWorkspacePointCommentHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePatchSharedWorkspacePointCommentHook>>>,
    { id: string; data: SharedWorkspacePointCommentDto }
  > = props => {
    const { id, data } = props ?? {};

    return patchSharedWorkspacePointComment(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchSharedWorkspacePointCommentMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePatchSharedWorkspacePointCommentHook>>>
>;
export type PatchSharedWorkspacePointCommentMutationBody = SharedWorkspacePointCommentDto;
export type PatchSharedWorkspacePointCommentMutationError = unknown;

/**
 * @summary Patch shared workspace point comment
 */
export const usePatchSharedWorkspacePointComment = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchSharedWorkspacePointCommentHook>>>,
    TError,
    { id: string; data: SharedWorkspacePointCommentDto },
    TContext
  >;
}) => {
  const mutationOptions = usePatchSharedWorkspacePointCommentMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Bulk Patch shared workspace points
 */
export const useBulkPatchSharedWorkspacePointsHook = () => {
  const bulkPatchSharedWorkspacePoints = useCustomAxios<void>();

  return (bulkPatchRequestSharedWorkspacePointDto: BulkPatchRequestSharedWorkspacePointDto) => {
    return bulkPatchSharedWorkspacePoints({
      url: `/workspace/sharedWorkspace/point/bulk`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: bulkPatchRequestSharedWorkspacePointDto
    });
  };
};

export const useBulkPatchSharedWorkspacePointsMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useBulkPatchSharedWorkspacePointsHook>>>,
    TError,
    { data: BulkPatchRequestSharedWorkspacePointDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useBulkPatchSharedWorkspacePointsHook>>>,
  TError,
  { data: BulkPatchRequestSharedWorkspacePointDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const bulkPatchSharedWorkspacePoints = useBulkPatchSharedWorkspacePointsHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useBulkPatchSharedWorkspacePointsHook>>>,
    { data: BulkPatchRequestSharedWorkspacePointDto }
  > = props => {
    const { data } = props ?? {};

    return bulkPatchSharedWorkspacePoints(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type BulkPatchSharedWorkspacePointsMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useBulkPatchSharedWorkspacePointsHook>>>
>;
export type BulkPatchSharedWorkspacePointsMutationBody = BulkPatchRequestSharedWorkspacePointDto;
export type BulkPatchSharedWorkspacePointsMutationError = unknown;

/**
 * @summary Bulk Patch shared workspace points
 */
export const useBulkPatchSharedWorkspacePoints = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useBulkPatchSharedWorkspacePointsHook>>>,
    TError,
    { data: BulkPatchRequestSharedWorkspacePointDto },
    TContext
  >;
}) => {
  const mutationOptions = useBulkPatchSharedWorkspacePointsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Delete meeting flow template
 */
export const useDeleteMeetingFlowTemplateHook = () => {
  const deleteMeetingFlowTemplate = useCustomAxios<void>();

  return (id: string) => {
    return deleteMeetingFlowTemplate({
      url: `/workspace/sharedWorkspace/meetingFlowTemplate/${id}`,
      method: 'delete'
    });
  };
};

export const useDeleteMeetingFlowTemplateMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteMeetingFlowTemplateHook>>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteMeetingFlowTemplateHook>>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteMeetingFlowTemplate = useDeleteMeetingFlowTemplateHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteMeetingFlowTemplateHook>>>,
    { id: string }
  > = props => {
    const { id } = props ?? {};

    return deleteMeetingFlowTemplate(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteMeetingFlowTemplateMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteMeetingFlowTemplateHook>>>
>;

export type DeleteMeetingFlowTemplateMutationError = unknown;

/**
 * @summary Delete meeting flow template
 */
export const useDeleteMeetingFlowTemplate = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteMeetingFlowTemplateHook>>>,
    TError,
    { id: string },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteMeetingFlowTemplateMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Patch meeting flow template
 */
export const usePatchMeetingFlowTemplateHook = () => {
  const patchMeetingFlowTemplate = useCustomAxios<void>();

  return (id: string, meetingFlowTemplateDto: MeetingFlowTemplateDto) => {
    return patchMeetingFlowTemplate({
      url: `/workspace/sharedWorkspace/meetingFlowTemplate/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: meetingFlowTemplateDto
    });
  };
};

export const usePatchMeetingFlowTemplateMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchMeetingFlowTemplateHook>>>,
    TError,
    { id: string; data: MeetingFlowTemplateDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePatchMeetingFlowTemplateHook>>>,
  TError,
  { id: string; data: MeetingFlowTemplateDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const patchMeetingFlowTemplate = usePatchMeetingFlowTemplateHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePatchMeetingFlowTemplateHook>>>,
    { id: string; data: MeetingFlowTemplateDto }
  > = props => {
    const { id, data } = props ?? {};

    return patchMeetingFlowTemplate(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchMeetingFlowTemplateMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePatchMeetingFlowTemplateHook>>>
>;
export type PatchMeetingFlowTemplateMutationBody = MeetingFlowTemplateDto;
export type PatchMeetingFlowTemplateMutationError = unknown;

/**
 * @summary Patch meeting flow template
 */
export const usePatchMeetingFlowTemplate = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchMeetingFlowTemplateHook>>>,
    TError,
    { id: string; data: MeetingFlowTemplateDto },
    TContext
  >;
}) => {
  const mutationOptions = usePatchMeetingFlowTemplateMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get Shared Workspace meeting flow
 */
export const useGetMeetingFlowHook = () => {
  const getMeetingFlow = useCustomAxios<MeetingFlowResponse>();

  return (id: string, signal?: AbortSignal) => {
    return getMeetingFlow({
      url: `/workspace/sharedWorkspace/meetingFlow/${id}`,
      method: 'get',
      signal
    });
  };
};

export const getGetMeetingFlowQueryKey = (id: string) =>
  [`/workspace/sharedWorkspace/meetingFlow/${id}`] as const;

export const useGetMeetingFlowQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetMeetingFlowHook>>>,
  TError = MeetingFlowResponse
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetMeetingFlowHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetMeetingFlowHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMeetingFlowQueryKey(id);

  const getMeetingFlow = useGetMeetingFlowHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetMeetingFlowHook>>>> = ({
    signal
  }) => getMeetingFlow(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type GetMeetingFlowQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetMeetingFlowHook>>>
>;
export type GetMeetingFlowQueryError = MeetingFlowResponse;

/**
 * @summary Get Shared Workspace meeting flow
 */
export const useGetMeetingFlow = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetMeetingFlowHook>>>,
  TError = MeetingFlowResponse
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetMeetingFlowHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetMeetingFlowQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Patch meeting flow
 */
export const useDeleteMeetingFlowHook = () => {
  const deleteMeetingFlow = useCustomAxios<void>();

  return (id: string) => {
    return deleteMeetingFlow({
      url: `/workspace/sharedWorkspace/meetingFlow/${id}`,
      method: 'delete'
    });
  };
};

export const useDeleteMeetingFlowMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteMeetingFlowHook>>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteMeetingFlowHook>>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteMeetingFlow = useDeleteMeetingFlowHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteMeetingFlowHook>>>,
    { id: string }
  > = props => {
    const { id } = props ?? {};

    return deleteMeetingFlow(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteMeetingFlowMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteMeetingFlowHook>>>
>;

export type DeleteMeetingFlowMutationError = unknown;

/**
 * @summary Patch meeting flow
 */
export const useDeleteMeetingFlow = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteMeetingFlowHook>>>,
    TError,
    { id: string },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteMeetingFlowMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Patch meeting flow
 */
export const usePatchMeetingFlowHook = () => {
  const patchMeetingFlow = useCustomAxios<void>();

  return (id: string, meetingFlowDto: MeetingFlowDto) => {
    return patchMeetingFlow({
      url: `/workspace/sharedWorkspace/meetingFlow/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: meetingFlowDto
    });
  };
};

export const usePatchMeetingFlowMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchMeetingFlowHook>>>,
    TError,
    { id: string; data: MeetingFlowDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePatchMeetingFlowHook>>>,
  TError,
  { id: string; data: MeetingFlowDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const patchMeetingFlow = usePatchMeetingFlowHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePatchMeetingFlowHook>>>,
    { id: string; data: MeetingFlowDto }
  > = props => {
    const { id, data } = props ?? {};

    return patchMeetingFlow(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchMeetingFlowMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePatchMeetingFlowHook>>>
>;
export type PatchMeetingFlowMutationBody = MeetingFlowDto;
export type PatchMeetingFlowMutationError = unknown;

/**
 * @summary Patch meeting flow
 */
export const usePatchMeetingFlow = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchMeetingFlowHook>>>,
    TError,
    { id: string; data: MeetingFlowDto },
    TContext
  >;
}) => {
  const mutationOptions = usePatchMeetingFlowMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get shared workspace meeting transcription analysis
 */
export const useGetSharedWorkspaceMeetingTranscriptionAnalysisHook = () => {
  const getSharedWorkspaceMeetingTranscriptionAnalysis =
    useCustomAxios<MeetingTranscriptionAnalysisResponse>();

  return (meetingId: string, signal?: AbortSignal) => {
    return getSharedWorkspaceMeetingTranscriptionAnalysis({
      url: `/workspace/sharedWorkspace/meeting/${meetingId}/analysis/transcript`,
      method: 'get',
      signal
    });
  };
};

export const getGetSharedWorkspaceMeetingTranscriptionAnalysisQueryKey = (meetingId: string) =>
  [`/workspace/sharedWorkspace/meeting/${meetingId}/analysis/transcript`] as const;

export const useGetSharedWorkspaceMeetingTranscriptionAnalysisQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useGetSharedWorkspaceMeetingTranscriptionAnalysisHook>>
  >,
  TError = MeetingTranscriptionAnalysisResponse
>(
  meetingId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetSharedWorkspaceMeetingTranscriptionAnalysisHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetSharedWorkspaceMeetingTranscriptionAnalysisHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetSharedWorkspaceMeetingTranscriptionAnalysisQueryKey(meetingId);

  const getSharedWorkspaceMeetingTranscriptionAnalysis =
    useGetSharedWorkspaceMeetingTranscriptionAnalysisHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetSharedWorkspaceMeetingTranscriptionAnalysisHook>>>
  > = ({ signal }) => getSharedWorkspaceMeetingTranscriptionAnalysis(meetingId, signal);

  return { queryKey, queryFn, enabled: !!meetingId, ...queryOptions };
};

export type GetSharedWorkspaceMeetingTranscriptionAnalysisQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetSharedWorkspaceMeetingTranscriptionAnalysisHook>>>
>;
export type GetSharedWorkspaceMeetingTranscriptionAnalysisQueryError =
  MeetingTranscriptionAnalysisResponse;

/**
 * @summary Get shared workspace meeting transcription analysis
 */
export const useGetSharedWorkspaceMeetingTranscriptionAnalysis = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useGetSharedWorkspaceMeetingTranscriptionAnalysisHook>>
  >,
  TError = MeetingTranscriptionAnalysisResponse
>(
  meetingId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetSharedWorkspaceMeetingTranscriptionAnalysisHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetSharedWorkspaceMeetingTranscriptionAnalysisQueryOptions(
    meetingId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Patch shared workspace meeting transcription analysis
 */
export const usePatchSharedWorkspaceMeetingTranscriptionAnalysisHook = () => {
  const patchSharedWorkspaceMeetingTranscriptionAnalysis = useCustomAxios<void>();

  return (meetingId: string, meetingTranscriptionAnalysisDto: MeetingTranscriptionAnalysisDto) => {
    return patchSharedWorkspaceMeetingTranscriptionAnalysis({
      url: `/workspace/sharedWorkspace/meeting/${meetingId}/analysis/transcript`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: meetingTranscriptionAnalysisDto
    });
  };
};

export const usePatchSharedWorkspaceMeetingTranscriptionAnalysisMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchSharedWorkspaceMeetingTranscriptionAnalysisHook>>>,
    TError,
    { meetingId: string; data: MeetingTranscriptionAnalysisDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePatchSharedWorkspaceMeetingTranscriptionAnalysisHook>>>,
  TError,
  { meetingId: string; data: MeetingTranscriptionAnalysisDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const patchSharedWorkspaceMeetingTranscriptionAnalysis =
    usePatchSharedWorkspaceMeetingTranscriptionAnalysisHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePatchSharedWorkspaceMeetingTranscriptionAnalysisHook>>>,
    { meetingId: string; data: MeetingTranscriptionAnalysisDto }
  > = props => {
    const { meetingId, data } = props ?? {};

    return patchSharedWorkspaceMeetingTranscriptionAnalysis(meetingId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchSharedWorkspaceMeetingTranscriptionAnalysisMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePatchSharedWorkspaceMeetingTranscriptionAnalysisHook>>>
>;
export type PatchSharedWorkspaceMeetingTranscriptionAnalysisMutationBody =
  MeetingTranscriptionAnalysisDto;
export type PatchSharedWorkspaceMeetingTranscriptionAnalysisMutationError = unknown;

/**
 * @summary Patch shared workspace meeting transcription analysis
 */
export const usePatchSharedWorkspaceMeetingTranscriptionAnalysis = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchSharedWorkspaceMeetingTranscriptionAnalysisHook>>>,
    TError,
    { meetingId: string; data: MeetingTranscriptionAnalysisDto },
    TContext
  >;
}) => {
  const mutationOptions =
    usePatchSharedWorkspaceMeetingTranscriptionAnalysisMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Delete meeting note
 */
export const useDeleteSpaceMeetingNoteHook = () => {
  const deleteSpaceMeetingNote = useCustomAxios<void>();

  return (id: string) => {
    return deleteSpaceMeetingNote({
      url: `/workspace/sharedWorkspace/meeting/note/${id}`,
      method: 'delete'
    });
  };
};

export const useDeleteSpaceMeetingNoteMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteSpaceMeetingNoteHook>>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteSpaceMeetingNoteHook>>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteSpaceMeetingNote = useDeleteSpaceMeetingNoteHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteSpaceMeetingNoteHook>>>,
    { id: string }
  > = props => {
    const { id } = props ?? {};

    return deleteSpaceMeetingNote(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteSpaceMeetingNoteMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteSpaceMeetingNoteHook>>>
>;

export type DeleteSpaceMeetingNoteMutationError = unknown;

/**
 * @summary Delete meeting note
 */
export const useDeleteSpaceMeetingNote = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteSpaceMeetingNoteHook>>>,
    TError,
    { id: string },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteSpaceMeetingNoteMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Patch meeting note
 */
export const usePatchSpaceMeetingNoteHook = () => {
  const patchSpaceMeetingNote = useCustomAxios<void>();

  return (id: string, sharedWorkspaceMeetingNoteDto: SharedWorkspaceMeetingNoteDto) => {
    return patchSpaceMeetingNote({
      url: `/workspace/sharedWorkspace/meeting/note/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: sharedWorkspaceMeetingNoteDto
    });
  };
};

export const usePatchSpaceMeetingNoteMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchSpaceMeetingNoteHook>>>,
    TError,
    { id: string; data: SharedWorkspaceMeetingNoteDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePatchSpaceMeetingNoteHook>>>,
  TError,
  { id: string; data: SharedWorkspaceMeetingNoteDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const patchSpaceMeetingNote = usePatchSpaceMeetingNoteHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePatchSpaceMeetingNoteHook>>>,
    { id: string; data: SharedWorkspaceMeetingNoteDto }
  > = props => {
    const { id, data } = props ?? {};

    return patchSpaceMeetingNote(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchSpaceMeetingNoteMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePatchSpaceMeetingNoteHook>>>
>;
export type PatchSpaceMeetingNoteMutationBody = SharedWorkspaceMeetingNoteDto;
export type PatchSpaceMeetingNoteMutationError = unknown;

/**
 * @summary Patch meeting note
 */
export const usePatchSpaceMeetingNote = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchSpaceMeetingNoteHook>>>,
    TError,
    { id: string; data: SharedWorkspaceMeetingNoteDto },
    TContext
  >;
}) => {
  const mutationOptions = usePatchSpaceMeetingNoteMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Delete resource
 */
export const useDeleteResourceHook = () => {
  const deleteResource = useCustomAxios<void>();

  return (id: string) => {
    return deleteResource({ url: `/workspace/resource/${id}`, method: 'delete' });
  };
};

export const useDeleteResourceMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteResourceHook>>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteResourceHook>>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteResource = useDeleteResourceHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteResourceHook>>>,
    { id: string }
  > = props => {
    const { id } = props ?? {};

    return deleteResource(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteResourceMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteResourceHook>>>
>;

export type DeleteResourceMutationError = unknown;

/**
 * @summary Delete resource
 */
export const useDeleteResource = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteResourceHook>>>,
    TError,
    { id: string },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteResourceMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Patch resource
 */
export const usePatchResourceHook = () => {
  const patchResource = useCustomAxios<void>();

  return (id: string, resourceDto: ResourceDto) => {
    return patchResource({
      url: `/workspace/resource/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: resourceDto
    });
  };
};

export const usePatchResourceMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchResourceHook>>>,
    TError,
    { id: string; data: ResourceDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePatchResourceHook>>>,
  TError,
  { id: string; data: ResourceDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const patchResource = usePatchResourceHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePatchResourceHook>>>,
    { id: string; data: ResourceDto }
  > = props => {
    const { id, data } = props ?? {};

    return patchResource(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchResourceMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePatchResourceHook>>>
>;
export type PatchResourceMutationBody = ResourceDto;
export type PatchResourceMutationError = unknown;

/**
 * @summary Patch resource
 */
export const usePatchResource = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchResourceHook>>>,
    TError,
    { id: string; data: ResourceDto },
    TContext
  >;
}) => {
  const mutationOptions = usePatchResourceMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Bulk Patch resources
 */
export const useBulkPatchResourcesHook = () => {
  const bulkPatchResources = useCustomAxios<void>();

  return (bulkPatchRequestResourceDto: BulkPatchRequestResourceDto) => {
    return bulkPatchResources({
      url: `/workspace/resource/bulk`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: bulkPatchRequestResourceDto
    });
  };
};

export const useBulkPatchResourcesMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useBulkPatchResourcesHook>>>,
    TError,
    { data: BulkPatchRequestResourceDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useBulkPatchResourcesHook>>>,
  TError,
  { data: BulkPatchRequestResourceDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const bulkPatchResources = useBulkPatchResourcesHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useBulkPatchResourcesHook>>>,
    { data: BulkPatchRequestResourceDto }
  > = props => {
    const { data } = props ?? {};

    return bulkPatchResources(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type BulkPatchResourcesMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useBulkPatchResourcesHook>>>
>;
export type BulkPatchResourcesMutationBody = BulkPatchRequestResourceDto;
export type BulkPatchResourcesMutationError = unknown;

/**
 * @summary Bulk Patch resources
 */
export const useBulkPatchResources = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useBulkPatchResourcesHook>>>,
    TError,
    { data: BulkPatchRequestResourceDto },
    TContext
  >;
}) => {
  const mutationOptions = useBulkPatchResourcesMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Delete note
 */
export const useDeleteNoteHook = () => {
  const deleteNote = useCustomAxios<void>();

  return (id: string) => {
    return deleteNote({ url: `/workspace/note/${id}`, method: 'delete' });
  };
};

export const useDeleteNoteMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteNoteHook>>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteNoteHook>>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteNote = useDeleteNoteHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteNoteHook>>>,
    { id: string }
  > = props => {
    const { id } = props ?? {};

    return deleteNote(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteNoteMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteNoteHook>>>
>;

export type DeleteNoteMutationError = unknown;

/**
 * @summary Delete note
 */
export const useDeleteNote = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteNoteHook>>>,
    TError,
    { id: string },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteNoteMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Patch note
 */
export const usePatchNoteHook = () => {
  const patchNote = useCustomAxios<void>();

  return (id: string, noteDto: NoteDto) => {
    return patchNote({
      url: `/workspace/note/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: noteDto
    });
  };
};

export const usePatchNoteMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchNoteHook>>>,
    TError,
    { id: string; data: NoteDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePatchNoteHook>>>,
  TError,
  { id: string; data: NoteDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const patchNote = usePatchNoteHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePatchNoteHook>>>,
    { id: string; data: NoteDto }
  > = props => {
    const { id, data } = props ?? {};

    return patchNote(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchNoteMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePatchNoteHook>>>
>;
export type PatchNoteMutationBody = NoteDto;
export type PatchNoteMutationError = unknown;

/**
 * @summary Patch note
 */
export const usePatchNote = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchNoteHook>>>,
    TError,
    { id: string; data: NoteDto },
    TContext
  >;
}) => {
  const mutationOptions = usePatchNoteMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Bulk Patch notes
 */
export const useBulkPatchNotesHook = () => {
  const bulkPatchNotes = useCustomAxios<void>();

  return (bulkPatchRequestNoteDto: BulkPatchRequestNoteDto) => {
    return bulkPatchNotes({
      url: `/workspace/note/bulk`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: bulkPatchRequestNoteDto
    });
  };
};

export const useBulkPatchNotesMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useBulkPatchNotesHook>>>,
    TError,
    { data: BulkPatchRequestNoteDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useBulkPatchNotesHook>>>,
  TError,
  { data: BulkPatchRequestNoteDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const bulkPatchNotes = useBulkPatchNotesHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useBulkPatchNotesHook>>>,
    { data: BulkPatchRequestNoteDto }
  > = props => {
    const { data } = props ?? {};

    return bulkPatchNotes(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type BulkPatchNotesMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useBulkPatchNotesHook>>>
>;
export type BulkPatchNotesMutationBody = BulkPatchRequestNoteDto;
export type BulkPatchNotesMutationError = unknown;

/**
 * @summary Bulk Patch notes
 */
export const useBulkPatchNotes = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useBulkPatchNotesHook>>>,
    TError,
    { data: BulkPatchRequestNoteDto },
    TContext
  >;
}) => {
  const mutationOptions = useBulkPatchNotesMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Delete Module playbook level
 */
export const useDeleteModulePlaybookLevelHook = () => {
  const deleteModulePlaybookLevel = useCustomAxios<void>();

  return (id: string, levelId: number) => {
    return deleteModulePlaybookLevel({
      url: `/workspace/module/${id}/playbook/${levelId}`,
      method: 'delete'
    });
  };
};

export const useDeleteModulePlaybookLevelMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteModulePlaybookLevelHook>>>,
    TError,
    { id: string; levelId: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteModulePlaybookLevelHook>>>,
  TError,
  { id: string; levelId: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteModulePlaybookLevel = useDeleteModulePlaybookLevelHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteModulePlaybookLevelHook>>>,
    { id: string; levelId: number }
  > = props => {
    const { id, levelId } = props ?? {};

    return deleteModulePlaybookLevel(id, levelId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteModulePlaybookLevelMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteModulePlaybookLevelHook>>>
>;

export type DeleteModulePlaybookLevelMutationError = unknown;

/**
 * @summary Delete Module playbook level
 */
export const useDeleteModulePlaybookLevel = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteModulePlaybookLevelHook>>>,
    TError,
    { id: string; levelId: number },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteModulePlaybookLevelMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Patch Module playbook level
 */
export const usePatchModulePlaybookLevelHook = () => {
  const patchModulePlaybookLevel = useCustomAxios<void>();

  return (id: string, levelId: number, patchModulePlaybookRequest: PatchModulePlaybookRequest) => {
    return patchModulePlaybookLevel({
      url: `/workspace/module/${id}/playbook/${levelId}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: patchModulePlaybookRequest
    });
  };
};

export const usePatchModulePlaybookLevelMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchModulePlaybookLevelHook>>>,
    TError,
    { id: string; levelId: number; data: PatchModulePlaybookRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePatchModulePlaybookLevelHook>>>,
  TError,
  { id: string; levelId: number; data: PatchModulePlaybookRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const patchModulePlaybookLevel = usePatchModulePlaybookLevelHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePatchModulePlaybookLevelHook>>>,
    { id: string; levelId: number; data: PatchModulePlaybookRequest }
  > = props => {
    const { id, levelId, data } = props ?? {};

    return patchModulePlaybookLevel(id, levelId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchModulePlaybookLevelMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePatchModulePlaybookLevelHook>>>
>;
export type PatchModulePlaybookLevelMutationBody = PatchModulePlaybookRequest;
export type PatchModulePlaybookLevelMutationError = unknown;

/**
 * @summary Patch Module playbook level
 */
export const usePatchModulePlaybookLevel = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchModulePlaybookLevelHook>>>,
    TError,
    { id: string; levelId: number; data: PatchModulePlaybookRequest },
    TContext
  >;
}) => {
  const mutationOptions = usePatchModulePlaybookLevelMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Delete Module prioritization
 */
export const useDeleteModulePrioritizationHook = () => {
  const deleteModulePrioritization = useCustomAxios<void>();

  return (id: string) => {
    return deleteModulePrioritization({
      url: `/workspace/module/prioritization/${id}`,
      method: 'delete'
    });
  };
};

export const useDeleteModulePrioritizationMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteModulePrioritizationHook>>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteModulePrioritizationHook>>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteModulePrioritization = useDeleteModulePrioritizationHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteModulePrioritizationHook>>>,
    { id: string }
  > = props => {
    const { id } = props ?? {};

    return deleteModulePrioritization(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteModulePrioritizationMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteModulePrioritizationHook>>>
>;

export type DeleteModulePrioritizationMutationError = unknown;

/**
 * @summary Delete Module prioritization
 */
export const useDeleteModulePrioritization = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteModulePrioritizationHook>>>,
    TError,
    { id: string },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteModulePrioritizationMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Patch Module prioritization
 */
export const usePatchModulePrioritizationHook = () => {
  const patchModulePrioritization = useCustomAxios<void>();

  return (id: string, modulePrioritizationDto: ModulePrioritizationDto) => {
    return patchModulePrioritization({
      url: `/workspace/module/prioritization/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: modulePrioritizationDto
    });
  };
};

export const usePatchModulePrioritizationMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchModulePrioritizationHook>>>,
    TError,
    { id: string; data: ModulePrioritizationDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePatchModulePrioritizationHook>>>,
  TError,
  { id: string; data: ModulePrioritizationDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const patchModulePrioritization = usePatchModulePrioritizationHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePatchModulePrioritizationHook>>>,
    { id: string; data: ModulePrioritizationDto }
  > = props => {
    const { id, data } = props ?? {};

    return patchModulePrioritization(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchModulePrioritizationMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePatchModulePrioritizationHook>>>
>;
export type PatchModulePrioritizationMutationBody = ModulePrioritizationDto;
export type PatchModulePrioritizationMutationError = unknown;

/**
 * @summary Patch Module prioritization
 */
export const usePatchModulePrioritization = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchModulePrioritizationHook>>>,
    TError,
    { id: string; data: ModulePrioritizationDto },
    TContext
  >;
}) => {
  const mutationOptions = usePatchModulePrioritizationMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Delete custom module
 */
export const useDeleteCustomModuleHook = () => {
  const deleteCustomModule = useCustomAxios<void>();

  return (id: string) => {
    return deleteCustomModule({ url: `/workspace/module/metadata/${id}`, method: 'delete' });
  };
};

export const useDeleteCustomModuleMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteCustomModuleHook>>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteCustomModuleHook>>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteCustomModule = useDeleteCustomModuleHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteCustomModuleHook>>>,
    { id: string }
  > = props => {
    const { id } = props ?? {};

    return deleteCustomModule(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteCustomModuleMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteCustomModuleHook>>>
>;

export type DeleteCustomModuleMutationError = unknown;

/**
 * @summary Delete custom module
 */
export const useDeleteCustomModule = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteCustomModuleHook>>>,
    TError,
    { id: string },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteCustomModuleMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Patch custom module
 */
export const usePatchCustomModuleHook = () => {
  const patchCustomModule = useCustomAxios<void>();

  return (id: string, customModuleRequest: CustomModuleRequest) => {
    return patchCustomModule({
      url: `/workspace/module/metadata/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: customModuleRequest
    });
  };
};

export const usePatchCustomModuleMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchCustomModuleHook>>>,
    TError,
    { id: string; data: CustomModuleRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePatchCustomModuleHook>>>,
  TError,
  { id: string; data: CustomModuleRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const patchCustomModule = usePatchCustomModuleHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePatchCustomModuleHook>>>,
    { id: string; data: CustomModuleRequest }
  > = props => {
    const { id, data } = props ?? {};

    return patchCustomModule(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchCustomModuleMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePatchCustomModuleHook>>>
>;
export type PatchCustomModuleMutationBody = CustomModuleRequest;
export type PatchCustomModuleMutationError = unknown;

/**
 * @summary Patch custom module
 */
export const usePatchCustomModule = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchCustomModuleHook>>>,
    TError,
    { id: string; data: CustomModuleRequest },
    TContext
  >;
}) => {
  const mutationOptions = usePatchCustomModuleMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Delete Module idea
 */
export const useDeleteModuleIdeaHook = () => {
  const deleteModuleIdea = useCustomAxios<void>();

  return (id: string) => {
    return deleteModuleIdea({ url: `/workspace/module/idea/${id}`, method: 'delete' });
  };
};

export const useDeleteModuleIdeaMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteModuleIdeaHook>>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteModuleIdeaHook>>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteModuleIdea = useDeleteModuleIdeaHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteModuleIdeaHook>>>,
    { id: string }
  > = props => {
    const { id } = props ?? {};

    return deleteModuleIdea(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteModuleIdeaMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteModuleIdeaHook>>>
>;

export type DeleteModuleIdeaMutationError = unknown;

/**
 * @summary Delete Module idea
 */
export const useDeleteModuleIdea = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteModuleIdeaHook>>>,
    TError,
    { id: string },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteModuleIdeaMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Patch Module idea
 */
export const usePatchModuleIdeaHook = () => {
  const patchModuleIdea = useCustomAxios<void>();

  return (id: string, moduleIdeaDto: ModuleIdeaDto) => {
    return patchModuleIdea({
      url: `/workspace/module/idea/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: moduleIdeaDto
    });
  };
};

export const usePatchModuleIdeaMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchModuleIdeaHook>>>,
    TError,
    { id: string; data: ModuleIdeaDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePatchModuleIdeaHook>>>,
  TError,
  { id: string; data: ModuleIdeaDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const patchModuleIdea = usePatchModuleIdeaHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePatchModuleIdeaHook>>>,
    { id: string; data: ModuleIdeaDto }
  > = props => {
    const { id, data } = props ?? {};

    return patchModuleIdea(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchModuleIdeaMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePatchModuleIdeaHook>>>
>;
export type PatchModuleIdeaMutationBody = ModuleIdeaDto;
export type PatchModuleIdeaMutationError = unknown;

/**
 * @summary Patch Module idea
 */
export const usePatchModuleIdea = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchModuleIdeaHook>>>,
    TError,
    { id: string; data: ModuleIdeaDto },
    TContext
  >;
}) => {
  const mutationOptions = usePatchModuleIdeaMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get team
 */
export const useGetTeamHook = () => {
  const getTeam = useCustomAxios<TeamResponse>();

  return (id: string, signal?: AbortSignal) => {
    return getTeam({ url: `/team/${id}`, method: 'get', signal });
  };
};

export const getGetTeamQueryKey = (id: string) => [`/team/${id}`] as const;

export const useGetTeamQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetTeamHook>>>,
  TError = TeamResponse
>(
  id: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTeamHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTeamHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTeamQueryKey(id);

  const getTeam = useGetTeamHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetTeamHook>>>> = ({
    signal
  }) => getTeam(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type GetTeamQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetTeamHook>>>
>;
export type GetTeamQueryError = TeamResponse;

/**
 * @summary Get team
 */
export const useGetTeam = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetTeamHook>>>,
  TError = TeamResponse
>(
  id: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTeamHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetTeamQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary delete team
 */
export const useDeleteTeamHook = () => {
  const deleteTeam = useCustomAxios<void>();

  return (id: string) => {
    return deleteTeam({ url: `/team/${id}`, method: 'delete' });
  };
};

export const useDeleteTeamMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteTeamHook>>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteTeamHook>>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteTeam = useDeleteTeamHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteTeamHook>>>,
    { id: string }
  > = props => {
    const { id } = props ?? {};

    return deleteTeam(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteTeamMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteTeamHook>>>
>;

export type DeleteTeamMutationError = unknown;

/**
 * @summary delete team
 */
export const useDeleteTeam = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteTeamHook>>>,
    TError,
    { id: string },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteTeamMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary patch team
 */
export const usePatchTeamHook = () => {
  const patchTeam = useCustomAxios<void>();

  return (id: string, teamDto: TeamDto) => {
    return patchTeam({
      url: `/team/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: teamDto
    });
  };
};

export const usePatchTeamMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchTeamHook>>>,
    TError,
    { id: string; data: TeamDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePatchTeamHook>>>,
  TError,
  { id: string; data: TeamDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const patchTeam = usePatchTeamHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePatchTeamHook>>>,
    { id: string; data: TeamDto }
  > = props => {
    const { id, data } = props ?? {};

    return patchTeam(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchTeamMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePatchTeamHook>>>
>;
export type PatchTeamMutationBody = TeamDto;
export type PatchTeamMutationError = unknown;

/**
 * @summary patch team
 */
export const usePatchTeam = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchTeamHook>>>,
    TError,
    { id: string; data: TeamDto },
    TContext
  >;
}) => {
  const mutationOptions = usePatchTeamMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Delete metric
 */
export const useDeleteMetricHook = () => {
  const deleteMetric = useCustomAxios<void>();

  return (id: string) => {
    return deleteMetric({ url: `/metric/${id}`, method: 'delete' });
  };
};

export const useDeleteMetricMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteMetricHook>>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteMetricHook>>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteMetric = useDeleteMetricHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteMetricHook>>>,
    { id: string }
  > = props => {
    const { id } = props ?? {};

    return deleteMetric(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteMetricMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteMetricHook>>>
>;

export type DeleteMetricMutationError = unknown;

/**
 * @summary Delete metric
 */
export const useDeleteMetric = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteMetricHook>>>,
    TError,
    { id: string },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteMetricMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Patch metric
 */
export const usePatchMetricHook = () => {
  const patchMetric = useCustomAxios<void>();

  return (id: string, metricDto: MetricDto) => {
    return patchMetric({
      url: `/metric/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: metricDto
    });
  };
};

export const usePatchMetricMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchMetricHook>>>,
    TError,
    { id: string; data: MetricDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePatchMetricHook>>>,
  TError,
  { id: string; data: MetricDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const patchMetric = usePatchMetricHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePatchMetricHook>>>,
    { id: string; data: MetricDto }
  > = props => {
    const { id, data } = props ?? {};

    return patchMetric(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchMetricMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePatchMetricHook>>>
>;
export type PatchMetricMutationBody = MetricDto;
export type PatchMetricMutationError = unknown;

/**
 * @summary Patch metric
 */
export const usePatchMetric = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchMetricHook>>>,
    TError,
    { id: string; data: MetricDto },
    TContext
  >;
}) => {
  const mutationOptions = usePatchMetricMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get okr metric
 */
export const useGetOkrMetricHook = () => {
  const getOkrMetric = useCustomAxios<OkrMetricResponse>();

  return (id: string, signal?: AbortSignal) => {
    return getOkrMetric({ url: `/metric/okr/${id}`, method: 'get', signal });
  };
};

export const getGetOkrMetricQueryKey = (id: string) => [`/metric/okr/${id}`] as const;

export const useGetOkrMetricQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetOkrMetricHook>>>,
  TError = OkrMetricResponse
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetOkrMetricHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOkrMetricHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetOkrMetricQueryKey(id);

  const getOkrMetric = useGetOkrMetricHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetOkrMetricHook>>>> = ({
    signal
  }) => getOkrMetric(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type GetOkrMetricQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetOkrMetricHook>>>
>;
export type GetOkrMetricQueryError = OkrMetricResponse;

/**
 * @summary Get okr metric
 */
export const useGetOkrMetric = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetOkrMetricHook>>>,
  TError = OkrMetricResponse
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetOkrMetricHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetOkrMetricQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Delete okr metric
 */
export const useDeleteOkrMetricHook = () => {
  const deleteOkrMetric = useCustomAxios<void>();

  return (id: string) => {
    return deleteOkrMetric({ url: `/metric/okr/${id}`, method: 'delete' });
  };
};

export const useDeleteOkrMetricMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteOkrMetricHook>>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteOkrMetricHook>>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteOkrMetric = useDeleteOkrMetricHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteOkrMetricHook>>>,
    { id: string }
  > = props => {
    const { id } = props ?? {};

    return deleteOkrMetric(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteOkrMetricMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteOkrMetricHook>>>
>;

export type DeleteOkrMetricMutationError = unknown;

/**
 * @summary Delete okr metric
 */
export const useDeleteOkrMetric = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteOkrMetricHook>>>,
    TError,
    { id: string },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteOkrMetricMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Patch okr metric
 */
export const usePatchOkrMetricHook = () => {
  const patchOkrMetric = useCustomAxios<void>();

  return (id: string, okrMetricDto: OkrMetricDto) => {
    return patchOkrMetric({
      url: `/metric/okr/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: okrMetricDto
    });
  };
};

export const usePatchOkrMetricMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchOkrMetricHook>>>,
    TError,
    { id: string; data: OkrMetricDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePatchOkrMetricHook>>>,
  TError,
  { id: string; data: OkrMetricDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const patchOkrMetric = usePatchOkrMetricHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePatchOkrMetricHook>>>,
    { id: string; data: OkrMetricDto }
  > = props => {
    const { id, data } = props ?? {};

    return patchOkrMetric(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchOkrMetricMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePatchOkrMetricHook>>>
>;
export type PatchOkrMetricMutationBody = OkrMetricDto;
export type PatchOkrMetricMutationError = unknown;

/**
 * @summary Patch okr metric
 */
export const usePatchOkrMetric = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchOkrMetricHook>>>,
    TError,
    { id: string; data: OkrMetricDto },
    TContext
  >;
}) => {
  const mutationOptions = usePatchOkrMetricMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Bulk patch metric definitions
 */
export const useBulkPatchMetricDefinitionsHook = () => {
  const bulkPatchMetricDefinitions = useCustomAxios<void>();

  return (bulkPatchRequestMetricDefinitionDto: BulkPatchRequestMetricDefinitionDto) => {
    return bulkPatchMetricDefinitions({
      url: `/metric/definition/bulk`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: bulkPatchRequestMetricDefinitionDto
    });
  };
};

export const useBulkPatchMetricDefinitionsMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useBulkPatchMetricDefinitionsHook>>>,
    TError,
    { data: BulkPatchRequestMetricDefinitionDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useBulkPatchMetricDefinitionsHook>>>,
  TError,
  { data: BulkPatchRequestMetricDefinitionDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const bulkPatchMetricDefinitions = useBulkPatchMetricDefinitionsHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useBulkPatchMetricDefinitionsHook>>>,
    { data: BulkPatchRequestMetricDefinitionDto }
  > = props => {
    const { data } = props ?? {};

    return bulkPatchMetricDefinitions(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type BulkPatchMetricDefinitionsMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useBulkPatchMetricDefinitionsHook>>>
>;
export type BulkPatchMetricDefinitionsMutationBody = BulkPatchRequestMetricDefinitionDto;
export type BulkPatchMetricDefinitionsMutationError = unknown;

/**
 * @summary Bulk patch metric definitions
 */
export const useBulkPatchMetricDefinitions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useBulkPatchMetricDefinitionsHook>>>,
    TError,
    { data: BulkPatchRequestMetricDefinitionDto },
    TContext
  >;
}) => {
  const mutationOptions = useBulkPatchMetricDefinitionsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Delete metric definition data points
 */
export const useDeleteMetricDataPointHook = () => {
  const deleteMetricDataPoint = useCustomAxios<void>();

  return (id: string) => {
    return deleteMetricDataPoint({ url: `/metric/dataPoint/${id}`, method: 'delete' });
  };
};

export const useDeleteMetricDataPointMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteMetricDataPointHook>>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteMetricDataPointHook>>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteMetricDataPoint = useDeleteMetricDataPointHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteMetricDataPointHook>>>,
    { id: string }
  > = props => {
    const { id } = props ?? {};

    return deleteMetricDataPoint(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteMetricDataPointMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteMetricDataPointHook>>>
>;

export type DeleteMetricDataPointMutationError = unknown;

/**
 * @summary Delete metric definition data points
 */
export const useDeleteMetricDataPoint = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteMetricDataPointHook>>>,
    TError,
    { id: string },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteMetricDataPointMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Patch metric definition data point
 */
export const usePatchMetricDataPointHook = () => {
  const patchMetricDataPoint = useCustomAxios<void>();

  return (id: string, metricDataPointDto: MetricDataPointDto) => {
    return patchMetricDataPoint({
      url: `/metric/dataPoint/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: metricDataPointDto
    });
  };
};

export const usePatchMetricDataPointMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchMetricDataPointHook>>>,
    TError,
    { id: string; data: MetricDataPointDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePatchMetricDataPointHook>>>,
  TError,
  { id: string; data: MetricDataPointDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const patchMetricDataPoint = usePatchMetricDataPointHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePatchMetricDataPointHook>>>,
    { id: string; data: MetricDataPointDto }
  > = props => {
    const { id, data } = props ?? {};

    return patchMetricDataPoint(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchMetricDataPointMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePatchMetricDataPointHook>>>
>;
export type PatchMetricDataPointMutationBody = MetricDataPointDto;
export type PatchMetricDataPointMutationError = unknown;

/**
 * @summary Patch metric definition data point
 */
export const usePatchMetricDataPoint = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchMetricDataPointHook>>>,
    TError,
    { id: string; data: MetricDataPointDto },
    TContext
  >;
}) => {
  const mutationOptions = usePatchMetricDataPointMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Bulk Patch metrics
 */
export const useBulkPatchMetricsHook = () => {
  const bulkPatchMetrics = useCustomAxios<void>();

  return (bulkPatchRequestMetricDto: BulkPatchRequestMetricDto) => {
    return bulkPatchMetrics({
      url: `/metric/bulk`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: bulkPatchRequestMetricDto
    });
  };
};

export const useBulkPatchMetricsMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useBulkPatchMetricsHook>>>,
    TError,
    { data: BulkPatchRequestMetricDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useBulkPatchMetricsHook>>>,
  TError,
  { data: BulkPatchRequestMetricDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const bulkPatchMetrics = useBulkPatchMetricsHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useBulkPatchMetricsHook>>>,
    { data: BulkPatchRequestMetricDto }
  > = props => {
    const { data } = props ?? {};

    return bulkPatchMetrics(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type BulkPatchMetricsMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useBulkPatchMetricsHook>>>
>;
export type BulkPatchMetricsMutationBody = BulkPatchRequestMetricDto;
export type BulkPatchMetricsMutationError = unknown;

/**
 * @summary Bulk Patch metrics
 */
export const useBulkPatchMetrics = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useBulkPatchMetricsHook>>>,
    TError,
    { data: BulkPatchRequestMetricDto },
    TContext
  >;
}) => {
  const mutationOptions = useBulkPatchMetricsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Delete scheduled meeting
 */
export const useDeleteScheduledMeetingHook = () => {
  const deleteScheduledMeeting = useCustomAxios<void>();

  return (id: string) => {
    return deleteScheduledMeeting({ url: `/meeting/scheduled/${id}`, method: 'delete' });
  };
};

export const useDeleteScheduledMeetingMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteScheduledMeetingHook>>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteScheduledMeetingHook>>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteScheduledMeeting = useDeleteScheduledMeetingHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteScheduledMeetingHook>>>,
    { id: string }
  > = props => {
    const { id } = props ?? {};

    return deleteScheduledMeeting(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteScheduledMeetingMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteScheduledMeetingHook>>>
>;

export type DeleteScheduledMeetingMutationError = unknown;

/**
 * @summary Delete scheduled meeting
 */
export const useDeleteScheduledMeeting = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteScheduledMeetingHook>>>,
    TError,
    { id: string },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteScheduledMeetingMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Patch scheduled meeting
 */
export const usePatchScheduledMeetingHook = () => {
  const patchScheduledMeeting = useCustomAxios<void>();

  return (id: string, scheduledMeetingDto: ScheduledMeetingDto) => {
    return patchScheduledMeeting({
      url: `/meeting/scheduled/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: scheduledMeetingDto
    });
  };
};

export const usePatchScheduledMeetingMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchScheduledMeetingHook>>>,
    TError,
    { id: string; data: ScheduledMeetingDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePatchScheduledMeetingHook>>>,
  TError,
  { id: string; data: ScheduledMeetingDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const patchScheduledMeeting = usePatchScheduledMeetingHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePatchScheduledMeetingHook>>>,
    { id: string; data: ScheduledMeetingDto }
  > = props => {
    const { id, data } = props ?? {};

    return patchScheduledMeeting(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchScheduledMeetingMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePatchScheduledMeetingHook>>>
>;
export type PatchScheduledMeetingMutationBody = ScheduledMeetingDto;
export type PatchScheduledMeetingMutationError = unknown;

/**
 * @summary Patch scheduled meeting
 */
export const usePatchScheduledMeeting = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchScheduledMeetingHook>>>,
    TError,
    { id: string; data: ScheduledMeetingDto },
    TContext
  >;
}) => {
  const mutationOptions = usePatchScheduledMeetingMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Delete meeting agenda
 */
export const useDeleteMeetingAgendaHook = () => {
  const deleteMeetingAgenda = useCustomAxios<void>();

  return (id: string) => {
    return deleteMeetingAgenda({ url: `/meeting/agenda/${id}`, method: 'delete' });
  };
};

export const useDeleteMeetingAgendaMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteMeetingAgendaHook>>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteMeetingAgendaHook>>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteMeetingAgenda = useDeleteMeetingAgendaHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteMeetingAgendaHook>>>,
    { id: string }
  > = props => {
    const { id } = props ?? {};

    return deleteMeetingAgenda(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteMeetingAgendaMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteMeetingAgendaHook>>>
>;

export type DeleteMeetingAgendaMutationError = unknown;

/**
 * @summary Delete meeting agenda
 */
export const useDeleteMeetingAgenda = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteMeetingAgendaHook>>>,
    TError,
    { id: string },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteMeetingAgendaMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Patch meeting agenda
 */
export const usePatchMeetingAgendaHook = () => {
  const patchMeetingAgenda = useCustomAxios<void>();

  return (id: string, meetingAgendaDto: MeetingAgendaDto) => {
    return patchMeetingAgenda({
      url: `/meeting/agenda/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: meetingAgendaDto
    });
  };
};

export const usePatchMeetingAgendaMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchMeetingAgendaHook>>>,
    TError,
    { id: string; data: MeetingAgendaDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePatchMeetingAgendaHook>>>,
  TError,
  { id: string; data: MeetingAgendaDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const patchMeetingAgenda = usePatchMeetingAgendaHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePatchMeetingAgendaHook>>>,
    { id: string; data: MeetingAgendaDto }
  > = props => {
    const { id, data } = props ?? {};

    return patchMeetingAgenda(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchMeetingAgendaMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePatchMeetingAgendaHook>>>
>;
export type PatchMeetingAgendaMutationBody = MeetingAgendaDto;
export type PatchMeetingAgendaMutationError = unknown;

/**
 * @summary Patch meeting agenda
 */
export const usePatchMeetingAgenda = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchMeetingAgendaHook>>>,
    TError,
    { id: string; data: MeetingAgendaDto },
    TContext
  >;
}) => {
  const mutationOptions = usePatchMeetingAgendaMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Delete account data provider
 */
export const useDeleteAccountDataProviderHook = () => {
  const deleteAccountDataProvider = useCustomAxios<void>();

  return (id: string) => {
    return deleteAccountDataProvider({ url: `/integration/dataProvider/${id}`, method: 'delete' });
  };
};

export const useDeleteAccountDataProviderMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteAccountDataProviderHook>>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteAccountDataProviderHook>>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteAccountDataProvider = useDeleteAccountDataProviderHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteAccountDataProviderHook>>>,
    { id: string }
  > = props => {
    const { id } = props ?? {};

    return deleteAccountDataProvider(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteAccountDataProviderMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteAccountDataProviderHook>>>
>;

export type DeleteAccountDataProviderMutationError = unknown;

/**
 * @summary Delete account data provider
 */
export const useDeleteAccountDataProvider = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteAccountDataProviderHook>>>,
    TError,
    { id: string },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteAccountDataProviderMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Patch data provider
 */
export const usePatchDataProviderHook = () => {
  const patchDataProvider = useCustomAxios<void>();

  return (id: string, partialAccountDataProviderDto: PartialAccountDataProviderDto) => {
    return patchDataProvider({
      url: `/integration/dataProvider/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: partialAccountDataProviderDto
    });
  };
};

export const usePatchDataProviderMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchDataProviderHook>>>,
    TError,
    { id: string; data: PartialAccountDataProviderDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePatchDataProviderHook>>>,
  TError,
  { id: string; data: PartialAccountDataProviderDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const patchDataProvider = usePatchDataProviderHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePatchDataProviderHook>>>,
    { id: string; data: PartialAccountDataProviderDto }
  > = props => {
    const { id, data } = props ?? {};

    return patchDataProvider(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchDataProviderMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePatchDataProviderHook>>>
>;
export type PatchDataProviderMutationBody = PartialAccountDataProviderDto;
export type PatchDataProviderMutationError = unknown;

/**
 * @summary Patch data provider
 */
export const usePatchDataProvider = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchDataProviderHook>>>,
    TError,
    { id: string; data: PartialAccountDataProviderDto },
    TContext
  >;
}) => {
  const mutationOptions = usePatchDataProviderMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get post
 */
export const useGetPostHook = () => {
  const getPost = useCustomAxios<PostResponse>();

  return (id: string, signal?: AbortSignal) => {
    return getPost({ url: `/forum/post/${id}`, method: 'get', signal });
  };
};

export const getGetPostQueryKey = (id: string) => [`/forum/post/${id}`] as const;

export const useGetPostQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetPostHook>>>,
  TError = PostResponse
>(
  id: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPostHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPostHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPostQueryKey(id);

  const getPost = useGetPostHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetPostHook>>>> = ({
    signal
  }) => getPost(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type GetPostQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetPostHook>>>
>;
export type GetPostQueryError = PostResponse;

/**
 * @summary Get post
 */
export const useGetPost = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetPostHook>>>,
  TError = PostResponse
>(
  id: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPostHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetPostQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Delete post
 */
export const useDeletePostHook = () => {
  const deletePost = useCustomAxios<void>();

  return (id: string) => {
    return deletePost({ url: `/forum/post/${id}`, method: 'delete' });
  };
};

export const useDeletePostMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeletePostHook>>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeletePostHook>>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deletePost = useDeletePostHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeletePostHook>>>,
    { id: string }
  > = props => {
    const { id } = props ?? {};

    return deletePost(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeletePostMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeletePostHook>>>
>;

export type DeletePostMutationError = unknown;

/**
 * @summary Delete post
 */
export const useDeletePost = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeletePostHook>>>,
    TError,
    { id: string },
    TContext
  >;
}) => {
  const mutationOptions = useDeletePostMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Patch post
 */
export const usePatchPostHook = () => {
  const patchPost = useCustomAxios<void>();

  return (id: string, postDto: PostDto) => {
    return patchPost({
      url: `/forum/post/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: postDto
    });
  };
};

export const usePatchPostMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchPostHook>>>,
    TError,
    { id: string; data: PostDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePatchPostHook>>>,
  TError,
  { id: string; data: PostDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const patchPost = usePatchPostHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePatchPostHook>>>,
    { id: string; data: PostDto }
  > = props => {
    const { id, data } = props ?? {};

    return patchPost(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchPostMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePatchPostHook>>>
>;
export type PatchPostMutationBody = PostDto;
export type PatchPostMutationError = unknown;

/**
 * @summary Patch post
 */
export const usePatchPost = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchPostHook>>>,
    TError,
    { id: string; data: PostDto },
    TContext
  >;
}) => {
  const mutationOptions = usePatchPostMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Delete comment
 */
export const useDeleteCommentHook = () => {
  const deleteComment = useCustomAxios<void>();

  return (id: string) => {
    return deleteComment({ url: `/forum/comment/${id}`, method: 'delete' });
  };
};

export const useDeleteCommentMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteCommentHook>>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteCommentHook>>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteComment = useDeleteCommentHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteCommentHook>>>,
    { id: string }
  > = props => {
    const { id } = props ?? {};

    return deleteComment(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteCommentMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteCommentHook>>>
>;

export type DeleteCommentMutationError = unknown;

/**
 * @summary Delete comment
 */
export const useDeleteComment = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteCommentHook>>>,
    TError,
    { id: string },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteCommentMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Patch comment
 */
export const usePatchCommentHook = () => {
  const patchComment = useCustomAxios<void>();

  return (id: string, commentDto: CommentDto) => {
    return patchComment({
      url: `/forum/comment/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: commentDto
    });
  };
};

export const usePatchCommentMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchCommentHook>>>,
    TError,
    { id: string; data: CommentDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePatchCommentHook>>>,
  TError,
  { id: string; data: CommentDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const patchComment = usePatchCommentHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePatchCommentHook>>>,
    { id: string; data: CommentDto }
  > = props => {
    const { id, data } = props ?? {};

    return patchComment(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchCommentMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePatchCommentHook>>>
>;
export type PatchCommentMutationBody = CommentDto;
export type PatchCommentMutationError = unknown;

/**
 * @summary Patch comment
 */
export const usePatchComment = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchCommentHook>>>,
    TError,
    { id: string; data: CommentDto },
    TContext
  >;
}) => {
  const mutationOptions = usePatchCommentMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Delete objective comment
 */
export const useDeleteActionPlanObjectiveCommentHook = () => {
  const deleteActionPlanObjectiveComment = useCustomAxios<void>();

  return (id: string) => {
    return deleteActionPlanObjectiveComment({
      url: `/actionPlan/objective/comment/${id}`,
      method: 'delete'
    });
  };
};

export const useDeleteActionPlanObjectiveCommentMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteActionPlanObjectiveCommentHook>>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteActionPlanObjectiveCommentHook>>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteActionPlanObjectiveComment = useDeleteActionPlanObjectiveCommentHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteActionPlanObjectiveCommentHook>>>,
    { id: string }
  > = props => {
    const { id } = props ?? {};

    return deleteActionPlanObjectiveComment(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteActionPlanObjectiveCommentMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteActionPlanObjectiveCommentHook>>>
>;

export type DeleteActionPlanObjectiveCommentMutationError = unknown;

/**
 * @summary Delete objective comment
 */
export const useDeleteActionPlanObjectiveComment = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteActionPlanObjectiveCommentHook>>>,
    TError,
    { id: string },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteActionPlanObjectiveCommentMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Patch objective comment
 */
export const usePatchActionPlanObjectiveCommentHook = () => {
  const patchActionPlanObjectiveComment = useCustomAxios<void>();

  return (id: string, actionPlanObjectiveCommentDto: ActionPlanObjectiveCommentDto) => {
    return patchActionPlanObjectiveComment({
      url: `/actionPlan/objective/comment/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: actionPlanObjectiveCommentDto
    });
  };
};

export const usePatchActionPlanObjectiveCommentMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchActionPlanObjectiveCommentHook>>>,
    TError,
    { id: string; data: ActionPlanObjectiveCommentDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePatchActionPlanObjectiveCommentHook>>>,
  TError,
  { id: string; data: ActionPlanObjectiveCommentDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const patchActionPlanObjectiveComment = usePatchActionPlanObjectiveCommentHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePatchActionPlanObjectiveCommentHook>>>,
    { id: string; data: ActionPlanObjectiveCommentDto }
  > = props => {
    const { id, data } = props ?? {};

    return patchActionPlanObjectiveComment(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchActionPlanObjectiveCommentMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePatchActionPlanObjectiveCommentHook>>>
>;
export type PatchActionPlanObjectiveCommentMutationBody = ActionPlanObjectiveCommentDto;
export type PatchActionPlanObjectiveCommentMutationError = unknown;

/**
 * @summary Patch objective comment
 */
export const usePatchActionPlanObjectiveComment = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchActionPlanObjectiveCommentHook>>>,
    TError,
    { id: string; data: ActionPlanObjectiveCommentDto },
    TContext
  >;
}) => {
  const mutationOptions = usePatchActionPlanObjectiveCommentMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Bulk Patch objectives
 */
export const useBulkPatchObjectivesHook = () => {
  const bulkPatchObjectives = useCustomAxios<void>();

  return (bulkPatchRequestActionPlanObjectiveDto: BulkPatchRequestActionPlanObjectiveDto) => {
    return bulkPatchObjectives({
      url: `/actionPlan/objective/bulk`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: bulkPatchRequestActionPlanObjectiveDto
    });
  };
};

export const useBulkPatchObjectivesMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useBulkPatchObjectivesHook>>>,
    TError,
    { data: BulkPatchRequestActionPlanObjectiveDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useBulkPatchObjectivesHook>>>,
  TError,
  { data: BulkPatchRequestActionPlanObjectiveDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const bulkPatchObjectives = useBulkPatchObjectivesHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useBulkPatchObjectivesHook>>>,
    { data: BulkPatchRequestActionPlanObjectiveDto }
  > = props => {
    const { data } = props ?? {};

    return bulkPatchObjectives(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type BulkPatchObjectivesMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useBulkPatchObjectivesHook>>>
>;
export type BulkPatchObjectivesMutationBody = BulkPatchRequestActionPlanObjectiveDto;
export type BulkPatchObjectivesMutationError = unknown;

/**
 * @summary Bulk Patch objectives
 */
export const useBulkPatchObjectives = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useBulkPatchObjectivesHook>>>,
    TError,
    { data: BulkPatchRequestActionPlanObjectiveDto },
    TContext
  >;
}) => {
  const mutationOptions = useBulkPatchObjectivesMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Delete initiative comment
 */
export const useDeleteActionPlanInitiativeCommentHook = () => {
  const deleteActionPlanInitiativeComment = useCustomAxios<void>();

  return (id: string) => {
    return deleteActionPlanInitiativeComment({
      url: `/actionPlan/initiative/comment/${id}`,
      method: 'delete'
    });
  };
};

export const useDeleteActionPlanInitiativeCommentMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteActionPlanInitiativeCommentHook>>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteActionPlanInitiativeCommentHook>>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteActionPlanInitiativeComment = useDeleteActionPlanInitiativeCommentHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteActionPlanInitiativeCommentHook>>>,
    { id: string }
  > = props => {
    const { id } = props ?? {};

    return deleteActionPlanInitiativeComment(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteActionPlanInitiativeCommentMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteActionPlanInitiativeCommentHook>>>
>;

export type DeleteActionPlanInitiativeCommentMutationError = unknown;

/**
 * @summary Delete initiative comment
 */
export const useDeleteActionPlanInitiativeComment = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteActionPlanInitiativeCommentHook>>>,
    TError,
    { id: string },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteActionPlanInitiativeCommentMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Patch initiative comment
 */
export const usePatchActionPlanInitiativeCommentHook = () => {
  const patchActionPlanInitiativeComment = useCustomAxios<void>();

  return (id: string, actionPlanInitiativeCommentDto: ActionPlanInitiativeCommentDto) => {
    return patchActionPlanInitiativeComment({
      url: `/actionPlan/initiative/comment/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: actionPlanInitiativeCommentDto
    });
  };
};

export const usePatchActionPlanInitiativeCommentMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchActionPlanInitiativeCommentHook>>>,
    TError,
    { id: string; data: ActionPlanInitiativeCommentDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePatchActionPlanInitiativeCommentHook>>>,
  TError,
  { id: string; data: ActionPlanInitiativeCommentDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const patchActionPlanInitiativeComment = usePatchActionPlanInitiativeCommentHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePatchActionPlanInitiativeCommentHook>>>,
    { id: string; data: ActionPlanInitiativeCommentDto }
  > = props => {
    const { id, data } = props ?? {};

    return patchActionPlanInitiativeComment(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchActionPlanInitiativeCommentMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePatchActionPlanInitiativeCommentHook>>>
>;
export type PatchActionPlanInitiativeCommentMutationBody = ActionPlanInitiativeCommentDto;
export type PatchActionPlanInitiativeCommentMutationError = unknown;

/**
 * @summary Patch initiative comment
 */
export const usePatchActionPlanInitiativeComment = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchActionPlanInitiativeCommentHook>>>,
    TError,
    { id: string; data: ActionPlanInitiativeCommentDto },
    TContext
  >;
}) => {
  const mutationOptions = usePatchActionPlanInitiativeCommentMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Bulk Patch initiatives
 */
export const useBulkPatchInitiativesHook = () => {
  const bulkPatchInitiatives = useCustomAxios<void>();

  return (bulkPatchRequestActionPlanInitiativeDto: BulkPatchRequestActionPlanInitiativeDto) => {
    return bulkPatchInitiatives({
      url: `/actionPlan/initiative/bulk`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: bulkPatchRequestActionPlanInitiativeDto
    });
  };
};

export const useBulkPatchInitiativesMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useBulkPatchInitiativesHook>>>,
    TError,
    { data: BulkPatchRequestActionPlanInitiativeDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useBulkPatchInitiativesHook>>>,
  TError,
  { data: BulkPatchRequestActionPlanInitiativeDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const bulkPatchInitiatives = useBulkPatchInitiativesHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useBulkPatchInitiativesHook>>>,
    { data: BulkPatchRequestActionPlanInitiativeDto }
  > = props => {
    const { data } = props ?? {};

    return bulkPatchInitiatives(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type BulkPatchInitiativesMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useBulkPatchInitiativesHook>>>
>;
export type BulkPatchInitiativesMutationBody = BulkPatchRequestActionPlanInitiativeDto;
export type BulkPatchInitiativesMutationError = unknown;

/**
 * @summary Bulk Patch initiatives
 */
export const useBulkPatchInitiatives = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useBulkPatchInitiativesHook>>>,
    TError,
    { data: BulkPatchRequestActionPlanInitiativeDto },
    TContext
  >;
}) => {
  const mutationOptions = useBulkPatchInitiativesMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Patch main board
 */
export const usePatchActionPlanMainBoardHook = () => {
  const patchActionPlanMainBoard = useCustomAxios<void>();

  return (actionPlanBoardDto: ActionPlanBoardDto) => {
    return patchActionPlanMainBoard({
      url: `/actionPlan/board/main`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: actionPlanBoardDto
    });
  };
};

export const usePatchActionPlanMainBoardMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchActionPlanMainBoardHook>>>,
    TError,
    { data: ActionPlanBoardDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePatchActionPlanMainBoardHook>>>,
  TError,
  { data: ActionPlanBoardDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const patchActionPlanMainBoard = usePatchActionPlanMainBoardHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePatchActionPlanMainBoardHook>>>,
    { data: ActionPlanBoardDto }
  > = props => {
    const { data } = props ?? {};

    return patchActionPlanMainBoard(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchActionPlanMainBoardMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePatchActionPlanMainBoardHook>>>
>;
export type PatchActionPlanMainBoardMutationBody = ActionPlanBoardDto;
export type PatchActionPlanMainBoardMutationError = unknown;

/**
 * @summary Patch main board
 */
export const usePatchActionPlanMainBoard = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchActionPlanMainBoardHook>>>,
    TError,
    { data: ActionPlanBoardDto },
    TContext
  >;
}) => {
  const mutationOptions = usePatchActionPlanMainBoardMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Delete action item comment
 */
export const useDeleteActionPlanActionItemCommentHook = () => {
  const deleteActionPlanActionItemComment = useCustomAxios<void>();

  return (id: string) => {
    return deleteActionPlanActionItemComment({
      url: `/actionPlan/actionItem/comment/${id}`,
      method: 'delete'
    });
  };
};

export const useDeleteActionPlanActionItemCommentMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteActionPlanActionItemCommentHook>>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteActionPlanActionItemCommentHook>>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteActionPlanActionItemComment = useDeleteActionPlanActionItemCommentHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteActionPlanActionItemCommentHook>>>,
    { id: string }
  > = props => {
    const { id } = props ?? {};

    return deleteActionPlanActionItemComment(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteActionPlanActionItemCommentMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteActionPlanActionItemCommentHook>>>
>;

export type DeleteActionPlanActionItemCommentMutationError = unknown;

/**
 * @summary Delete action item comment
 */
export const useDeleteActionPlanActionItemComment = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteActionPlanActionItemCommentHook>>>,
    TError,
    { id: string },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteActionPlanActionItemCommentMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Patch action item comment
 */
export const usePatchActionPlanActionItemCommentHook = () => {
  const patchActionPlanActionItemComment = useCustomAxios<void>();

  return (id: string, actionPlanActionItemCommentDto: ActionPlanActionItemCommentDto) => {
    return patchActionPlanActionItemComment({
      url: `/actionPlan/actionItem/comment/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: actionPlanActionItemCommentDto
    });
  };
};

export const usePatchActionPlanActionItemCommentMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchActionPlanActionItemCommentHook>>>,
    TError,
    { id: string; data: ActionPlanActionItemCommentDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePatchActionPlanActionItemCommentHook>>>,
  TError,
  { id: string; data: ActionPlanActionItemCommentDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const patchActionPlanActionItemComment = usePatchActionPlanActionItemCommentHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePatchActionPlanActionItemCommentHook>>>,
    { id: string; data: ActionPlanActionItemCommentDto }
  > = props => {
    const { id, data } = props ?? {};

    return patchActionPlanActionItemComment(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchActionPlanActionItemCommentMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePatchActionPlanActionItemCommentHook>>>
>;
export type PatchActionPlanActionItemCommentMutationBody = ActionPlanActionItemCommentDto;
export type PatchActionPlanActionItemCommentMutationError = unknown;

/**
 * @summary Patch action item comment
 */
export const usePatchActionPlanActionItemComment = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchActionPlanActionItemCommentHook>>>,
    TError,
    { id: string; data: ActionPlanActionItemCommentDto },
    TContext
  >;
}) => {
  const mutationOptions = usePatchActionPlanActionItemCommentMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Bulk Patch actionItems
 */
export const useBulkPatchActionItemsHook = () => {
  const bulkPatchActionItems = useCustomAxios<void>();

  return (bulkPatchRequestActionPlanActionItemDto: BulkPatchRequestActionPlanActionItemDto) => {
    return bulkPatchActionItems({
      url: `/actionPlan/actionItem/bulk`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: bulkPatchRequestActionPlanActionItemDto
    });
  };
};

export const useBulkPatchActionItemsMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useBulkPatchActionItemsHook>>>,
    TError,
    { data: BulkPatchRequestActionPlanActionItemDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useBulkPatchActionItemsHook>>>,
  TError,
  { data: BulkPatchRequestActionPlanActionItemDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const bulkPatchActionItems = useBulkPatchActionItemsHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useBulkPatchActionItemsHook>>>,
    { data: BulkPatchRequestActionPlanActionItemDto }
  > = props => {
    const { data } = props ?? {};

    return bulkPatchActionItems(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type BulkPatchActionItemsMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useBulkPatchActionItemsHook>>>
>;
export type BulkPatchActionItemsMutationBody = BulkPatchRequestActionPlanActionItemDto;
export type BulkPatchActionItemsMutationError = unknown;

/**
 * @summary Bulk Patch actionItems
 */
export const useBulkPatchActionItems = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useBulkPatchActionItemsHook>>>,
    TError,
    { data: BulkPatchRequestActionPlanActionItemDto },
    TContext
  >;
}) => {
  const mutationOptions = useBulkPatchActionItemsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Patch account
 */
export const usePatchAccountHook = () => {
  const patchAccount = useCustomAxios<void>();

  return (id: number, patchAccountRequest: PatchAccountRequest) => {
    return patchAccount({
      url: `/account/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: patchAccountRequest
    });
  };
};

export const usePatchAccountMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchAccountHook>>>,
    TError,
    { id: number; data: PatchAccountRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePatchAccountHook>>>,
  TError,
  { id: number; data: PatchAccountRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const patchAccount = usePatchAccountHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePatchAccountHook>>>,
    { id: number; data: PatchAccountRequest }
  > = props => {
    const { id, data } = props ?? {};

    return patchAccount(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchAccountMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePatchAccountHook>>>
>;
export type PatchAccountMutationBody = PatchAccountRequest;
export type PatchAccountMutationError = unknown;

/**
 * @summary Patch account
 */
export const usePatchAccount = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePatchAccountHook>>>,
    TError,
    { id: number; data: PatchAccountRequest },
    TContext
  >;
}) => {
  const mutationOptions = usePatchAccountMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary List whiteboards ids
 */
export const useListWhiteboardsIdsHook = () => {
  const listWhiteboardsIds = useCustomAxios<IdsResponse>();

  return (params?: ListWhiteboardsIdsParams, signal?: AbortSignal) => {
    return listWhiteboardsIds({ url: `/workspace/whiteboard/id`, method: 'get', params, signal });
  };
};

export const getListWhiteboardsIdsQueryKey = (params?: ListWhiteboardsIdsParams) =>
  [`/workspace/whiteboard/id`, ...(params ? [params] : [])] as const;

export const useListWhiteboardsIdsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useListWhiteboardsIdsHook>>>,
  TError = IdsResponse
>(
  params?: ListWhiteboardsIdsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useListWhiteboardsIdsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useListWhiteboardsIdsHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListWhiteboardsIdsQueryKey(params);

  const listWhiteboardsIds = useListWhiteboardsIdsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useListWhiteboardsIdsHook>>>
  > = ({ signal }) => listWhiteboardsIds(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type ListWhiteboardsIdsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useListWhiteboardsIdsHook>>>
>;
export type ListWhiteboardsIdsQueryError = IdsResponse;

/**
 * @summary List whiteboards ids
 */
export const useListWhiteboardsIds = <
  TData = Awaited<ReturnType<ReturnType<typeof useListWhiteboardsIdsHook>>>,
  TError = IdsResponse
>(
  params?: ListWhiteboardsIdsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useListWhiteboardsIdsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useListWhiteboardsIdsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary List shared workspace updates
 */
export const useListSharedWorkspaceUpdatesHook = () => {
  const listSharedWorkspaceUpdates = useCustomAxios<SharedWorkspaceUpdateResponse>();

  return (id: string, signal?: AbortSignal) => {
    return listSharedWorkspaceUpdates({
      url: `/workspace/sharedWorkspace/${id}/update`,
      method: 'get',
      signal
    });
  };
};

export const getListSharedWorkspaceUpdatesQueryKey = (id: string) =>
  [`/workspace/sharedWorkspace/${id}/update`] as const;

export const useListSharedWorkspaceUpdatesQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useListSharedWorkspaceUpdatesHook>>>,
  TError = SharedWorkspaceUpdateResponse
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useListSharedWorkspaceUpdatesHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useListSharedWorkspaceUpdatesHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListSharedWorkspaceUpdatesQueryKey(id);

  const listSharedWorkspaceUpdates = useListSharedWorkspaceUpdatesHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useListSharedWorkspaceUpdatesHook>>>
  > = ({ signal }) => listSharedWorkspaceUpdates(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type ListSharedWorkspaceUpdatesQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useListSharedWorkspaceUpdatesHook>>>
>;
export type ListSharedWorkspaceUpdatesQueryError = SharedWorkspaceUpdateResponse;

/**
 * @summary List shared workspace updates
 */
export const useListSharedWorkspaceUpdates = <
  TData = Awaited<ReturnType<ReturnType<typeof useListSharedWorkspaceUpdatesHook>>>,
  TError = SharedWorkspaceUpdateResponse
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useListSharedWorkspaceUpdatesHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useListSharedWorkspaceUpdatesQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary List shared workspace points
 */
export const useListSharedWorkspacePointsHook = () => {
  const listSharedWorkspacePoints = useCustomAxios<SharedWorkspacePointResponse>();

  return (id: string, params?: ListSharedWorkspacePointsParams, signal?: AbortSignal) => {
    return listSharedWorkspacePoints({
      url: `/workspace/sharedWorkspace/${id}/point`,
      method: 'get',
      params,
      signal
    });
  };
};

export const getListSharedWorkspacePointsQueryKey = (
  id: string,
  params?: ListSharedWorkspacePointsParams
) => [`/workspace/sharedWorkspace/${id}/point`, ...(params ? [params] : [])] as const;

export const useListSharedWorkspacePointsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useListSharedWorkspacePointsHook>>>,
  TError = SharedWorkspacePointResponse
>(
  id: string,
  params?: ListSharedWorkspacePointsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useListSharedWorkspacePointsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useListSharedWorkspacePointsHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListSharedWorkspacePointsQueryKey(id, params);

  const listSharedWorkspacePoints = useListSharedWorkspacePointsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useListSharedWorkspacePointsHook>>>
  > = ({ signal }) => listSharedWorkspacePoints(id, params, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type ListSharedWorkspacePointsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useListSharedWorkspacePointsHook>>>
>;
export type ListSharedWorkspacePointsQueryError = SharedWorkspacePointResponse;

/**
 * @summary List shared workspace points
 */
export const useListSharedWorkspacePoints = <
  TData = Awaited<ReturnType<ReturnType<typeof useListSharedWorkspacePointsHook>>>,
  TError = SharedWorkspacePointResponse
>(
  id: string,
  params?: ListSharedWorkspacePointsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useListSharedWorkspacePointsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useListSharedWorkspacePointsQueryOptions(id, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary List shared workspace meetings
 */
export const useListSharedWorkspaceMeetingsHook = () => {
  const listSharedWorkspaceMeetings = useCustomAxios<SharedWorkspaceMeetingResponse>();

  return (id: string, signal?: AbortSignal) => {
    return listSharedWorkspaceMeetings({
      url: `/workspace/sharedWorkspace/${id}/meeting`,
      method: 'get',
      signal
    });
  };
};

export const getListSharedWorkspaceMeetingsQueryKey = (id: string) =>
  [`/workspace/sharedWorkspace/${id}/meeting`] as const;

export const useListSharedWorkspaceMeetingsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useListSharedWorkspaceMeetingsHook>>>,
  TError = SharedWorkspaceMeetingResponse
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useListSharedWorkspaceMeetingsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useListSharedWorkspaceMeetingsHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListSharedWorkspaceMeetingsQueryKey(id);

  const listSharedWorkspaceMeetings = useListSharedWorkspaceMeetingsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useListSharedWorkspaceMeetingsHook>>>
  > = ({ signal }) => listSharedWorkspaceMeetings(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type ListSharedWorkspaceMeetingsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useListSharedWorkspaceMeetingsHook>>>
>;
export type ListSharedWorkspaceMeetingsQueryError = SharedWorkspaceMeetingResponse;

/**
 * @summary List shared workspace meetings
 */
export const useListSharedWorkspaceMeetings = <
  TData = Awaited<ReturnType<ReturnType<typeof useListSharedWorkspaceMeetingsHook>>>,
  TError = SharedWorkspaceMeetingResponse
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useListSharedWorkspaceMeetingsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useListSharedWorkspaceMeetingsQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get last meeting
 */
export const useGetSharedWorkspaceLastMeetingHook = () => {
  const getSharedWorkspaceLastMeeting = useCustomAxios<SharedWorkspaceMeetingResponse>();

  return (id: string, signal?: AbortSignal) => {
    return getSharedWorkspaceLastMeeting({
      url: `/workspace/sharedWorkspace/${id}/meeting/last`,
      method: 'get',
      signal
    });
  };
};

export const getGetSharedWorkspaceLastMeetingQueryKey = (id: string) =>
  [`/workspace/sharedWorkspace/${id}/meeting/last`] as const;

export const useGetSharedWorkspaceLastMeetingQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetSharedWorkspaceLastMeetingHook>>>,
  TError = SharedWorkspaceMeetingResponse
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetSharedWorkspaceLastMeetingHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetSharedWorkspaceLastMeetingHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSharedWorkspaceLastMeetingQueryKey(id);

  const getSharedWorkspaceLastMeeting = useGetSharedWorkspaceLastMeetingHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetSharedWorkspaceLastMeetingHook>>>
  > = ({ signal }) => getSharedWorkspaceLastMeeting(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type GetSharedWorkspaceLastMeetingQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetSharedWorkspaceLastMeetingHook>>>
>;
export type GetSharedWorkspaceLastMeetingQueryError = SharedWorkspaceMeetingResponse;

/**
 * @summary Get last meeting
 */
export const useGetSharedWorkspaceLastMeeting = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetSharedWorkspaceLastMeetingHook>>>,
  TError = SharedWorkspaceMeetingResponse
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetSharedWorkspaceLastMeetingHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetSharedWorkspaceLastMeetingQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary List shared workspace point comments
 */
export const useListSharedWorkspacePointCommentsHook = () => {
  const listSharedWorkspacePointComments = useCustomAxios<SharedWorkspacePointCommentResponse>();

  return (id: string, signal?: AbortSignal) => {
    return listSharedWorkspacePointComments({
      url: `/workspace/sharedWorkspace/point/${id}/comment`,
      method: 'get',
      signal
    });
  };
};

export const getListSharedWorkspacePointCommentsQueryKey = (id: string) =>
  [`/workspace/sharedWorkspace/point/${id}/comment`] as const;

export const useListSharedWorkspacePointCommentsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useListSharedWorkspacePointCommentsHook>>>,
  TError = SharedWorkspacePointCommentResponse
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useListSharedWorkspacePointCommentsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useListSharedWorkspacePointCommentsHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListSharedWorkspacePointCommentsQueryKey(id);

  const listSharedWorkspacePointComments = useListSharedWorkspacePointCommentsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useListSharedWorkspacePointCommentsHook>>>
  > = ({ signal }) => listSharedWorkspacePointComments(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type ListSharedWorkspacePointCommentsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useListSharedWorkspacePointCommentsHook>>>
>;
export type ListSharedWorkspacePointCommentsQueryError = SharedWorkspacePointCommentResponse;

/**
 * @summary List shared workspace point comments
 */
export const useListSharedWorkspacePointComments = <
  TData = Awaited<ReturnType<ReturnType<typeof useListSharedWorkspacePointCommentsHook>>>,
  TError = SharedWorkspacePointCommentResponse
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useListSharedWorkspacePointCommentsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useListSharedWorkspacePointCommentsQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary List all starred shared workspaces points
 */
export const useListStarredSharedWorkspacePointsHook = () => {
  const listStarredSharedWorkspacePoints = useCustomAxios<SharedWorkspacePointResponse>();

  return (signal?: AbortSignal) => {
    return listStarredSharedWorkspacePoints({
      url: `/workspace/sharedWorkspace/point/starred`,
      method: 'get',
      signal
    });
  };
};

export const getListStarredSharedWorkspacePointsQueryKey = () =>
  [`/workspace/sharedWorkspace/point/starred`] as const;

export const useListStarredSharedWorkspacePointsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useListStarredSharedWorkspacePointsHook>>>,
  TError = SharedWorkspacePointResponse
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useListStarredSharedWorkspacePointsHook>>>,
    TError,
    TData
  >;
}): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useListStarredSharedWorkspacePointsHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListStarredSharedWorkspacePointsQueryKey();

  const listStarredSharedWorkspacePoints = useListStarredSharedWorkspacePointsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useListStarredSharedWorkspacePointsHook>>>
  > = ({ signal }) => listStarredSharedWorkspacePoints(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type ListStarredSharedWorkspacePointsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useListStarredSharedWorkspacePointsHook>>>
>;
export type ListStarredSharedWorkspacePointsQueryError = SharedWorkspacePointResponse;

/**
 * @summary List all starred shared workspaces points
 */
export const useListStarredSharedWorkspacePoints = <
  TData = Awaited<ReturnType<ReturnType<typeof useListStarredSharedWorkspacePointsHook>>>,
  TError = SharedWorkspacePointResponse
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useListStarredSharedWorkspacePointsHook>>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useListStarredSharedWorkspacePointsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get shared workspace meeting media
 */
export const useGetSharedWorkspaceMeetingMediaHook = () => {
  const getSharedWorkspaceMeetingMedia = useCustomAxios<MeetingMediaResponse>();

  return (
    meetingId: string,
    params: GetSharedWorkspaceMeetingMediaParams,
    signal?: AbortSignal
  ) => {
    return getSharedWorkspaceMeetingMedia({
      url: `/workspace/sharedWorkspace/meeting/${meetingId}/media`,
      method: 'get',
      params,
      signal
    });
  };
};

export const getGetSharedWorkspaceMeetingMediaQueryKey = (
  meetingId: string,
  params: GetSharedWorkspaceMeetingMediaParams
) =>
  [`/workspace/sharedWorkspace/meeting/${meetingId}/media`, ...(params ? [params] : [])] as const;

export const useGetSharedWorkspaceMeetingMediaQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetSharedWorkspaceMeetingMediaHook>>>,
  TError = MeetingMediaResponse
>(
  meetingId: string,
  params: GetSharedWorkspaceMeetingMediaParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetSharedWorkspaceMeetingMediaHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetSharedWorkspaceMeetingMediaHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetSharedWorkspaceMeetingMediaQueryKey(meetingId, params);

  const getSharedWorkspaceMeetingMedia = useGetSharedWorkspaceMeetingMediaHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetSharedWorkspaceMeetingMediaHook>>>
  > = ({ signal }) => getSharedWorkspaceMeetingMedia(meetingId, params, signal);

  return { queryKey, queryFn, enabled: !!meetingId, ...queryOptions };
};

export type GetSharedWorkspaceMeetingMediaQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetSharedWorkspaceMeetingMediaHook>>>
>;
export type GetSharedWorkspaceMeetingMediaQueryError = MeetingMediaResponse;

/**
 * @summary Get shared workspace meeting media
 */
export const useGetSharedWorkspaceMeetingMedia = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetSharedWorkspaceMeetingMediaHook>>>,
  TError = MeetingMediaResponse
>(
  meetingId: string,
  params: GetSharedWorkspaceMeetingMediaParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetSharedWorkspaceMeetingMediaHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetSharedWorkspaceMeetingMediaQueryOptions(meetingId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get meeting analysis suggestions
 */
export const useGetSharedWorkspaceMeetingAnalysisSuggestionsHook = () => {
  const getSharedWorkspaceMeetingAnalysisSuggestions =
    useCustomAxios<MeetingAnalysisSuggestionResponse>();

  return (meetingId: string, signal?: AbortSignal) => {
    return getSharedWorkspaceMeetingAnalysisSuggestions({
      url: `/workspace/sharedWorkspace/meeting/${meetingId}/analysis/suggestion`,
      method: 'get',
      signal
    });
  };
};

export const getGetSharedWorkspaceMeetingAnalysisSuggestionsQueryKey = (meetingId: string) =>
  [`/workspace/sharedWorkspace/meeting/${meetingId}/analysis/suggestion`] as const;

export const useGetSharedWorkspaceMeetingAnalysisSuggestionsQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useGetSharedWorkspaceMeetingAnalysisSuggestionsHook>>
  >,
  TError = MeetingAnalysisSuggestionResponse
>(
  meetingId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetSharedWorkspaceMeetingAnalysisSuggestionsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetSharedWorkspaceMeetingAnalysisSuggestionsHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetSharedWorkspaceMeetingAnalysisSuggestionsQueryKey(meetingId);

  const getSharedWorkspaceMeetingAnalysisSuggestions =
    useGetSharedWorkspaceMeetingAnalysisSuggestionsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetSharedWorkspaceMeetingAnalysisSuggestionsHook>>>
  > = ({ signal }) => getSharedWorkspaceMeetingAnalysisSuggestions(meetingId, signal);

  return { queryKey, queryFn, enabled: !!meetingId, ...queryOptions };
};

export type GetSharedWorkspaceMeetingAnalysisSuggestionsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetSharedWorkspaceMeetingAnalysisSuggestionsHook>>>
>;
export type GetSharedWorkspaceMeetingAnalysisSuggestionsQueryError =
  MeetingAnalysisSuggestionResponse;

/**
 * @summary Get meeting analysis suggestions
 */
export const useGetSharedWorkspaceMeetingAnalysisSuggestions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useGetSharedWorkspaceMeetingAnalysisSuggestionsHook>>
  >,
  TError = MeetingAnalysisSuggestionResponse
>(
  meetingId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetSharedWorkspaceMeetingAnalysisSuggestionsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetSharedWorkspaceMeetingAnalysisSuggestionsQueryOptions(
    meetingId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary List meeting activities
 */
export const useListMeetingActivitiesHook = () => {
  const listMeetingActivities = useCustomAxios<SharedWorkspaceMeetingActivityResponse>();

  return (meetingId: string, signal?: AbortSignal) => {
    return listMeetingActivities({
      url: `/workspace/sharedWorkspace/meeting/${meetingId}/activity`,
      method: 'get',
      signal
    });
  };
};

export const getListMeetingActivitiesQueryKey = (meetingId: string) =>
  [`/workspace/sharedWorkspace/meeting/${meetingId}/activity`] as const;

export const useListMeetingActivitiesQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useListMeetingActivitiesHook>>>,
  TError = SharedWorkspaceMeetingActivityResponse
>(
  meetingId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useListMeetingActivitiesHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useListMeetingActivitiesHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListMeetingActivitiesQueryKey(meetingId);

  const listMeetingActivities = useListMeetingActivitiesHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useListMeetingActivitiesHook>>>
  > = ({ signal }) => listMeetingActivities(meetingId, signal);

  return { queryKey, queryFn, enabled: !!meetingId, ...queryOptions };
};

export type ListMeetingActivitiesQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useListMeetingActivitiesHook>>>
>;
export type ListMeetingActivitiesQueryError = SharedWorkspaceMeetingActivityResponse;

/**
 * @summary List meeting activities
 */
export const useListMeetingActivities = <
  TData = Awaited<ReturnType<ReturnType<typeof useListMeetingActivitiesHook>>>,
  TError = SharedWorkspaceMeetingActivityResponse
>(
  meetingId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useListMeetingActivitiesHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useListMeetingActivitiesQueryOptions(meetingId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary List space meeting notes
 */
export const useListSpaceMeetingNotesHook = () => {
  const listSpaceMeetingNotes = useCustomAxios<SharedWorkspaceMeetingNoteResponse>();

  return (id: string, signal?: AbortSignal) => {
    return listSpaceMeetingNotes({
      url: `/workspace/sharedWorkspace/meeting/${id}/note`,
      method: 'get',
      signal
    });
  };
};

export const getListSpaceMeetingNotesQueryKey = (id: string) =>
  [`/workspace/sharedWorkspace/meeting/${id}/note`] as const;

export const useListSpaceMeetingNotesQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useListSpaceMeetingNotesHook>>>,
  TError = SharedWorkspaceMeetingNoteResponse
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useListSpaceMeetingNotesHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useListSpaceMeetingNotesHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListSpaceMeetingNotesQueryKey(id);

  const listSpaceMeetingNotes = useListSpaceMeetingNotesHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useListSpaceMeetingNotesHook>>>
  > = ({ signal }) => listSpaceMeetingNotes(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type ListSpaceMeetingNotesQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useListSpaceMeetingNotesHook>>>
>;
export type ListSpaceMeetingNotesQueryError = SharedWorkspaceMeetingNoteResponse;

/**
 * @summary List space meeting notes
 */
export const useListSpaceMeetingNotes = <
  TData = Awaited<ReturnType<ReturnType<typeof useListSpaceMeetingNotesHook>>>,
  TError = SharedWorkspaceMeetingNoteResponse
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useListSpaceMeetingNotesHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useListSpaceMeetingNotesQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary List workspace calendar event suggestions
 */
export const useListWorkspaceCalendarEventSuggestionHook = () => {
  const listWorkspaceCalendarEventSuggestion =
    useCustomAxios<CalendarEventWorkspaceSuggestionResponse>();

  return (signal?: AbortSignal) => {
    return listWorkspaceCalendarEventSuggestion({
      url: `/workspace/sharedWorkspace/calendar/event/suggestion`,
      method: 'get',
      signal
    });
  };
};

export const getListWorkspaceCalendarEventSuggestionQueryKey = () =>
  [`/workspace/sharedWorkspace/calendar/event/suggestion`] as const;

export const useListWorkspaceCalendarEventSuggestionQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useListWorkspaceCalendarEventSuggestionHook>>>,
  TError = CalendarEventWorkspaceSuggestionResponse
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useListWorkspaceCalendarEventSuggestionHook>>>,
    TError,
    TData
  >;
}): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useListWorkspaceCalendarEventSuggestionHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListWorkspaceCalendarEventSuggestionQueryKey();

  const listWorkspaceCalendarEventSuggestion = useListWorkspaceCalendarEventSuggestionHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useListWorkspaceCalendarEventSuggestionHook>>>
  > = ({ signal }) => listWorkspaceCalendarEventSuggestion(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type ListWorkspaceCalendarEventSuggestionQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useListWorkspaceCalendarEventSuggestionHook>>>
>;
export type ListWorkspaceCalendarEventSuggestionQueryError =
  CalendarEventWorkspaceSuggestionResponse;

/**
 * @summary List workspace calendar event suggestions
 */
export const useListWorkspaceCalendarEventSuggestion = <
  TData = Awaited<ReturnType<ReturnType<typeof useListWorkspaceCalendarEventSuggestionHook>>>,
  TError = CalendarEventWorkspaceSuggestionResponse
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useListWorkspaceCalendarEventSuggestionHook>>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useListWorkspaceCalendarEventSuggestionQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get Module example
 */
export const useGetModuleExampleHook = () => {
  const getModuleExample = useCustomAxios<ModuleResponse>();

  return (id: string, signal?: AbortSignal) => {
    return getModuleExample({ url: `/workspace/module/${id}/example`, method: 'get', signal });
  };
};

export const getGetModuleExampleQueryKey = (id: string) =>
  [`/workspace/module/${id}/example`] as const;

export const useGetModuleExampleQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetModuleExampleHook>>>,
  TError = ModuleResponse
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetModuleExampleHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetModuleExampleHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetModuleExampleQueryKey(id);

  const getModuleExample = useGetModuleExampleHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetModuleExampleHook>>>> = ({
    signal
  }) => getModuleExample(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type GetModuleExampleQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetModuleExampleHook>>>
>;
export type GetModuleExampleQueryError = ModuleResponse;

/**
 * @summary Get Module example
 */
export const useGetModuleExample = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetModuleExampleHook>>>,
  TError = ModuleResponse
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetModuleExampleHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetModuleExampleQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get Latest used modules
 */
export const useGetLatestUsedModulesHook = () => {
  const getLatestUsedModules = useCustomAxios<LastUsedModuleResponse>();

  return (signal?: AbortSignal) => {
    return getLatestUsedModules({ url: `/workspace/module/latest`, method: 'get', signal });
  };
};

export const getGetLatestUsedModulesQueryKey = () => [`/workspace/module/latest`] as const;

export const useGetLatestUsedModulesQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetLatestUsedModulesHook>>>,
  TError = LastUsedModuleResponse
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetLatestUsedModulesHook>>>,
    TError,
    TData
  >;
}): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetLatestUsedModulesHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetLatestUsedModulesQueryKey();

  const getLatestUsedModules = useGetLatestUsedModulesHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetLatestUsedModulesHook>>>
  > = ({ signal }) => getLatestUsedModules(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetLatestUsedModulesQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetLatestUsedModulesHook>>>
>;
export type GetLatestUsedModulesQueryError = LastUsedModuleResponse;

/**
 * @summary Get Latest used modules
 */
export const useGetLatestUsedModules = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetLatestUsedModulesHook>>>,
  TError = LastUsedModuleResponse
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetLatestUsedModulesHook>>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetLatestUsedModulesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary List Modules analysis
 */
export const useListModulesAnalysisHook = () => {
  const listModulesAnalysis = useCustomAxios<ModuleAnalysisResponse>();

  return (params: ListModulesAnalysisParams, signal?: AbortSignal) => {
    return listModulesAnalysis({
      url: `/workspace/module/analysis`,
      method: 'get',
      params,
      signal
    });
  };
};

export const getListModulesAnalysisQueryKey = (params: ListModulesAnalysisParams) =>
  [`/workspace/module/analysis`, ...(params ? [params] : [])] as const;

export const useListModulesAnalysisQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useListModulesAnalysisHook>>>,
  TError = ModuleAnalysisResponse
>(
  params: ListModulesAnalysisParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useListModulesAnalysisHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useListModulesAnalysisHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListModulesAnalysisQueryKey(params);

  const listModulesAnalysis = useListModulesAnalysisHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useListModulesAnalysisHook>>>
  > = ({ signal }) => listModulesAnalysis(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type ListModulesAnalysisQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useListModulesAnalysisHook>>>
>;
export type ListModulesAnalysisQueryError = ModuleAnalysisResponse;

/**
 * @summary List Modules analysis
 */
export const useListModulesAnalysis = <
  TData = Awaited<ReturnType<ReturnType<typeof useListModulesAnalysisHook>>>,
  TError = ModuleAnalysisResponse
>(
  params: ListModulesAnalysisParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useListModulesAnalysisHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useListModulesAnalysisQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get module drive file
 */
export const useGetModuleDriveFileHook = () => {
  const getModuleDriveFile = useCustomAxios<ModuleDriveFileResponse>();

  return (businessArea: string, moduleKey: string, signal?: AbortSignal) => {
    return getModuleDriveFile({
      url: `/workspace/businessArea/${businessArea}/module/${moduleKey}/file`,
      method: 'get',
      signal
    });
  };
};

export const getGetModuleDriveFileQueryKey = (businessArea: string, moduleKey: string) =>
  [`/workspace/businessArea/${businessArea}/module/${moduleKey}/file`] as const;

export const useGetModuleDriveFileQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetModuleDriveFileHook>>>,
  TError = ModuleDriveFileResponse
>(
  businessArea: string,
  moduleKey: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetModuleDriveFileHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetModuleDriveFileHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetModuleDriveFileQueryKey(businessArea, moduleKey);

  const getModuleDriveFile = useGetModuleDriveFileHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetModuleDriveFileHook>>>
  > = ({ signal }) => getModuleDriveFile(businessArea, moduleKey, signal);

  return { queryKey, queryFn, enabled: !!(businessArea && moduleKey), ...queryOptions };
};

export type GetModuleDriveFileQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetModuleDriveFileHook>>>
>;
export type GetModuleDriveFileQueryError = ModuleDriveFileResponse;

/**
 * @summary Get module drive file
 */
export const useGetModuleDriveFile = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetModuleDriveFileHook>>>,
  TError = ModuleDriveFileResponse
>(
  businessArea: string,
  moduleKey: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetModuleDriveFileHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetModuleDriveFileQueryOptions(businessArea, moduleKey, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary List modules rating
 */
export const useListModuleRatingsHook = () => {
  const listModuleRatings = useCustomAxios<ModuleRatingResponse>();

  return (businessArea: string, signal?: AbortSignal) => {
    return listModuleRatings({
      url: `/workspace/businessArea/${businessArea}/module/rating`,
      method: 'get',
      signal
    });
  };
};

export const getListModuleRatingsQueryKey = (businessArea: string) =>
  [`/workspace/businessArea/${businessArea}/module/rating`] as const;

export const useListModuleRatingsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useListModuleRatingsHook>>>,
  TError = ModuleRatingResponse
>(
  businessArea: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useListModuleRatingsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useListModuleRatingsHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListModuleRatingsQueryKey(businessArea);

  const listModuleRatings = useListModuleRatingsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useListModuleRatingsHook>>>
  > = ({ signal }) => listModuleRatings(businessArea, signal);

  return { queryKey, queryFn, enabled: !!businessArea, ...queryOptions };
};

export type ListModuleRatingsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useListModuleRatingsHook>>>
>;
export type ListModuleRatingsQueryError = ModuleRatingResponse;

/**
 * @summary List modules rating
 */
export const useListModuleRatings = <
  TData = Awaited<ReturnType<ReturnType<typeof useListModuleRatingsHook>>>,
  TError = ModuleRatingResponse
>(
  businessArea: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useListModuleRatingsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useListModuleRatingsQueryOptions(businessArea, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary List business Areas rating
 */
export const useListBusinessAreaRatingsHook = () => {
  const listBusinessAreaRatings = useCustomAxios<BusinessAreaRatingResponse>();

  return (signal?: AbortSignal) => {
    return listBusinessAreaRatings({
      url: `/workspace/businessArea/rating`,
      method: 'get',
      signal
    });
  };
};

export const getListBusinessAreaRatingsQueryKey = () => [`/workspace/businessArea/rating`] as const;

export const useListBusinessAreaRatingsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useListBusinessAreaRatingsHook>>>,
  TError = BusinessAreaRatingResponse
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useListBusinessAreaRatingsHook>>>,
    TError,
    TData
  >;
}): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useListBusinessAreaRatingsHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListBusinessAreaRatingsQueryKey();

  const listBusinessAreaRatings = useListBusinessAreaRatingsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useListBusinessAreaRatingsHook>>>
  > = ({ signal }) => listBusinessAreaRatings(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type ListBusinessAreaRatingsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useListBusinessAreaRatingsHook>>>
>;
export type ListBusinessAreaRatingsQueryError = BusinessAreaRatingResponse;

/**
 * @summary List business Areas rating
 */
export const useListBusinessAreaRatings = <
  TData = Awaited<ReturnType<ReturnType<typeof useListBusinessAreaRatingsHook>>>,
  TError = BusinessAreaRatingResponse
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useListBusinessAreaRatingsHook>>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useListBusinessAreaRatingsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get user details
 */
export const useGetUserDetailsHook = () => {
  const getUserDetails = useCustomAxios<UserDetailsResponse>();

  return (signal?: AbortSignal) => {
    return getUserDetails({ url: `/user/details`, method: 'get', signal });
  };
};

export const getGetUserDetailsQueryKey = () => [`/user/details`] as const;

export const useGetUserDetailsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserDetailsHook>>>,
  TError = UserDetailsResponse
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetUserDetailsHook>>>,
    TError,
    TData
  >;
}): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetUserDetailsHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserDetailsQueryKey();

  const getUserDetails = useGetUserDetailsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetUserDetailsHook>>>> = ({
    signal
  }) => getUserDetails(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetUserDetailsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetUserDetailsHook>>>
>;
export type GetUserDetailsQueryError = UserDetailsResponse;

/**
 * @summary Get user details
 */
export const useGetUserDetails = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserDetailsHook>>>,
  TError = UserDetailsResponse
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetUserDetailsHook>>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetUserDetailsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary List my teams
 */
export const useListMyTeamsHook = () => {
  const listMyTeams = useCustomAxios<TeamResponse>();

  return (signal?: AbortSignal) => {
    return listMyTeams({ url: `/team/my`, method: 'get', signal });
  };
};

export const getListMyTeamsQueryKey = () => [`/team/my`] as const;

export const useListMyTeamsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useListMyTeamsHook>>>,
  TError = TeamResponse
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useListMyTeamsHook>>>,
    TError,
    TData
  >;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListMyTeamsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListMyTeamsQueryKey();

  const listMyTeams = useListMyTeamsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useListMyTeamsHook>>>> = ({
    signal
  }) => listMyTeams(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type ListMyTeamsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useListMyTeamsHook>>>
>;
export type ListMyTeamsQueryError = TeamResponse;

/**
 * @summary List my teams
 */
export const useListMyTeams = <
  TData = Awaited<ReturnType<ReturnType<typeof useListMyTeamsHook>>>,
  TError = TeamResponse
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useListMyTeamsHook>>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useListMyTeamsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary List metric suggestions
 */
export const useListMetricSuggestionsHook = () => {
  const listMetricSuggestions = useCustomAxios<MetricSuggestionResponse>();

  return (signal?: AbortSignal) => {
    return listMetricSuggestions({ url: `/metric/suggestion`, method: 'get', signal });
  };
};

export const getListMetricSuggestionsQueryKey = () => [`/metric/suggestion`] as const;

export const useListMetricSuggestionsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useListMetricSuggestionsHook>>>,
  TError = MetricSuggestionResponse
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useListMetricSuggestionsHook>>>,
    TError,
    TData
  >;
}): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useListMetricSuggestionsHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListMetricSuggestionsQueryKey();

  const listMetricSuggestions = useListMetricSuggestionsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useListMetricSuggestionsHook>>>
  > = ({ signal }) => listMetricSuggestions(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type ListMetricSuggestionsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useListMetricSuggestionsHook>>>
>;
export type ListMetricSuggestionsQueryError = MetricSuggestionResponse;

/**
 * @summary List metric suggestions
 */
export const useListMetricSuggestions = <
  TData = Awaited<ReturnType<ReturnType<typeof useListMetricSuggestionsHook>>>,
  TError = MetricSuggestionResponse
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useListMetricSuggestionsHook>>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useListMetricSuggestionsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary List all okr metrics
 */
export const useListOkrMetricsHook = () => {
  const listOkrMetrics = useCustomAxios<OkrMetricResponse>();

  return (signal?: AbortSignal) => {
    return listOkrMetrics({ url: `/metric/okr`, method: 'get', signal });
  };
};

export const getListOkrMetricsQueryKey = () => [`/metric/okr`] as const;

export const useListOkrMetricsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useListOkrMetricsHook>>>,
  TError = OkrMetricResponse
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useListOkrMetricsHook>>>,
    TError,
    TData
  >;
}): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useListOkrMetricsHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListOkrMetricsQueryKey();

  const listOkrMetrics = useListOkrMetricsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useListOkrMetricsHook>>>> = ({
    signal
  }) => listOkrMetrics(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type ListOkrMetricsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useListOkrMetricsHook>>>
>;
export type ListOkrMetricsQueryError = OkrMetricResponse;

/**
 * @summary List all okr metrics
 */
export const useListOkrMetrics = <
  TData = Awaited<ReturnType<ReturnType<typeof useListOkrMetricsHook>>>,
  TError = OkrMetricResponse
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useListOkrMetricsHook>>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useListOkrMetricsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get metric definition aggregated data points
 */
export const useAggregateMetricDataPointsHook = () => {
  const aggregateMetricDataPoints = useCustomAxios<MetricAggregationDataPointResponse>();

  return (id: string, params: AggregateMetricDataPointsParams, signal?: AbortSignal) => {
    return aggregateMetricDataPoints({
      url: `/metric/definition/${id}/dataPoint/aggregate`,
      method: 'get',
      params,
      signal
    });
  };
};

export const getAggregateMetricDataPointsQueryKey = (
  id: string,
  params: AggregateMetricDataPointsParams
) => [`/metric/definition/${id}/dataPoint/aggregate`, ...(params ? [params] : [])] as const;

export const useAggregateMetricDataPointsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useAggregateMetricDataPointsHook>>>,
  TError = MetricAggregationDataPointResponse
>(
  id: string,
  params: AggregateMetricDataPointsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useAggregateMetricDataPointsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useAggregateMetricDataPointsHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAggregateMetricDataPointsQueryKey(id, params);

  const aggregateMetricDataPoints = useAggregateMetricDataPointsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useAggregateMetricDataPointsHook>>>
  > = ({ signal }) => aggregateMetricDataPoints(id, params, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type AggregateMetricDataPointsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAggregateMetricDataPointsHook>>>
>;
export type AggregateMetricDataPointsQueryError = MetricAggregationDataPointResponse;

/**
 * @summary Get metric definition aggregated data points
 */
export const useAggregateMetricDataPoints = <
  TData = Awaited<ReturnType<ReturnType<typeof useAggregateMetricDataPointsHook>>>,
  TError = MetricAggregationDataPointResponse
>(
  id: string,
  params: AggregateMetricDataPointsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useAggregateMetricDataPointsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useAggregateMetricDataPointsQueryOptions(id, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get meeting details
 */
export const useGetMeetingDetailsHook = () => {
  const getMeetingDetails = useCustomAxios<MeetingDetailsResponse>();

  return (meetingId: string, signal?: AbortSignal) => {
    return getMeetingDetails({ url: `/meeting/${meetingId}`, method: 'get', signal });
  };
};

export const getGetMeetingDetailsQueryKey = (meetingId: string) =>
  [`/meeting/${meetingId}`] as const;

export const useGetMeetingDetailsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetMeetingDetailsHook>>>,
  TError = MeetingDetailsResponse
>(
  meetingId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetMeetingDetailsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetMeetingDetailsHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMeetingDetailsQueryKey(meetingId);

  const getMeetingDetails = useGetMeetingDetailsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetMeetingDetailsHook>>>
  > = ({ signal }) => getMeetingDetails(meetingId, signal);

  return { queryKey, queryFn, enabled: !!meetingId, ...queryOptions };
};

export type GetMeetingDetailsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetMeetingDetailsHook>>>
>;
export type GetMeetingDetailsQueryError = MeetingDetailsResponse;

/**
 * @summary Get meeting details
 */
export const useGetMeetingDetails = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetMeetingDetailsHook>>>,
  TError = MeetingDetailsResponse
>(
  meetingId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetMeetingDetailsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetMeetingDetailsQueryOptions(meetingId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Delete meeting
 */
export const useDeleteMeetingHook = () => {
  const deleteMeeting = useCustomAxios<void>();

  return (meetingId: string) => {
    return deleteMeeting({ url: `/meeting/${meetingId}`, method: 'delete' });
  };
};

export const useDeleteMeetingMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteMeetingHook>>>,
    TError,
    { meetingId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteMeetingHook>>>,
  TError,
  { meetingId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteMeeting = useDeleteMeetingHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteMeetingHook>>>,
    { meetingId: string }
  > = props => {
    const { meetingId } = props ?? {};

    return deleteMeeting(meetingId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteMeetingMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteMeetingHook>>>
>;

export type DeleteMeetingMutationError = unknown;

/**
 * @summary Delete meeting
 */
export const useDeleteMeeting = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteMeetingHook>>>,
    TError,
    { meetingId: string },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteMeetingMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Get meeting transcript
 */
export const useGetMeetingTranscriptHook = () => {
  const getMeetingTranscript = useCustomAxios<MeetingTranscriptResponse>();

  return (meetingId: string, signal?: AbortSignal) => {
    return getMeetingTranscript({ url: `/meeting/${meetingId}/transcript`, method: 'get', signal });
  };
};

export const getGetMeetingTranscriptQueryKey = (meetingId: string) =>
  [`/meeting/${meetingId}/transcript`] as const;

export const useGetMeetingTranscriptQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetMeetingTranscriptHook>>>,
  TError = MeetingTranscriptResponse
>(
  meetingId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetMeetingTranscriptHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetMeetingTranscriptHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMeetingTranscriptQueryKey(meetingId);

  const getMeetingTranscript = useGetMeetingTranscriptHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetMeetingTranscriptHook>>>
  > = ({ signal }) => getMeetingTranscript(meetingId, signal);

  return { queryKey, queryFn, enabled: !!meetingId, ...queryOptions };
};

export type GetMeetingTranscriptQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetMeetingTranscriptHook>>>
>;
export type GetMeetingTranscriptQueryError = MeetingTranscriptResponse;

/**
 * @summary Get meeting transcript
 */
export const useGetMeetingTranscript = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetMeetingTranscriptHook>>>,
  TError = MeetingTranscriptResponse
>(
  meetingId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetMeetingTranscriptHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetMeetingTranscriptQueryOptions(meetingId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get meeting recording
 */
export const useGetMeetingRecordingHook = () => {
  const getMeetingRecording = useCustomAxios<MeetingRecordingResponse>();

  return (meetingId: string, signal?: AbortSignal) => {
    return getMeetingRecording({ url: `/meeting/${meetingId}/recording`, method: 'get', signal });
  };
};

export const getGetMeetingRecordingQueryKey = (meetingId: string) =>
  [`/meeting/${meetingId}/recording`] as const;

export const useGetMeetingRecordingQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetMeetingRecordingHook>>>,
  TError = MeetingRecordingResponse
>(
  meetingId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetMeetingRecordingHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetMeetingRecordingHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMeetingRecordingQueryKey(meetingId);

  const getMeetingRecording = useGetMeetingRecordingHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetMeetingRecordingHook>>>
  > = ({ signal }) => getMeetingRecording(meetingId, signal);

  return { queryKey, queryFn, enabled: !!meetingId, ...queryOptions };
};

export type GetMeetingRecordingQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetMeetingRecordingHook>>>
>;
export type GetMeetingRecordingQueryError = MeetingRecordingResponse;

/**
 * @summary Get meeting recording
 */
export const useGetMeetingRecording = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetMeetingRecordingHook>>>,
  TError = MeetingRecordingResponse
>(
  meetingId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetMeetingRecordingHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetMeetingRecordingQueryOptions(meetingId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get meeting audio
 */
export const useGetMeetingAudioHook = () => {
  const getMeetingAudio = useCustomAxios<MeetingRecordingResponse>();

  return (meetingId: string, signal?: AbortSignal) => {
    return getMeetingAudio({ url: `/meeting/${meetingId}/audio`, method: 'get', signal });
  };
};

export const getGetMeetingAudioQueryKey = (meetingId: string) =>
  [`/meeting/${meetingId}/audio`] as const;

export const useGetMeetingAudioQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetMeetingAudioHook>>>,
  TError = MeetingRecordingResponse
>(
  meetingId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetMeetingAudioHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetMeetingAudioHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMeetingAudioQueryKey(meetingId);

  const getMeetingAudio = useGetMeetingAudioHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetMeetingAudioHook>>>> = ({
    signal
  }) => getMeetingAudio(meetingId, signal);

  return { queryKey, queryFn, enabled: !!meetingId, ...queryOptions };
};

export type GetMeetingAudioQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetMeetingAudioHook>>>
>;
export type GetMeetingAudioQueryError = MeetingRecordingResponse;

/**
 * @summary Get meeting audio
 */
export const useGetMeetingAudio = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetMeetingAudioHook>>>,
  TError = MeetingRecordingResponse
>(
  meetingId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetMeetingAudioHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetMeetingAudioQueryOptions(meetingId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get meeting agenda
 */
export const useGetMeetingAgendaHook = () => {
  const getMeetingAgenda = useCustomAxios<MeetingAgendaResponse>();

  return (meetingId: string, signal?: AbortSignal) => {
    return getMeetingAgenda({ url: `/meeting/${meetingId}/agenda`, method: 'get', signal });
  };
};

export const getGetMeetingAgendaQueryKey = (meetingId: string) =>
  [`/meeting/${meetingId}/agenda`] as const;

export const useGetMeetingAgendaQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetMeetingAgendaHook>>>,
  TError = MeetingAgendaResponse
>(
  meetingId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetMeetingAgendaHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetMeetingAgendaHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMeetingAgendaQueryKey(meetingId);

  const getMeetingAgenda = useGetMeetingAgendaHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetMeetingAgendaHook>>>> = ({
    signal
  }) => getMeetingAgenda(meetingId, signal);

  return { queryKey, queryFn, enabled: !!meetingId, ...queryOptions };
};

export type GetMeetingAgendaQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetMeetingAgendaHook>>>
>;
export type GetMeetingAgendaQueryError = MeetingAgendaResponse;

/**
 * @summary Get meeting agenda
 */
export const useGetMeetingAgenda = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetMeetingAgendaHook>>>,
  TError = MeetingAgendaResponse
>(
  meetingId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetMeetingAgendaHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetMeetingAgendaQueryOptions(meetingId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get live meeting
 */
export const useGetLiveMeetingHook = () => {
  const getLiveMeeting = useCustomAxios<LiveMeetingResponse>();

  return (signal?: AbortSignal) => {
    return getLiveMeeting({ url: `/meeting/live`, method: 'get', signal });
  };
};

export const getGetLiveMeetingQueryKey = () => [`/meeting/live`] as const;

export const useGetLiveMeetingQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetLiveMeetingHook>>>,
  TError = LiveMeetingResponse
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetLiveMeetingHook>>>,
    TError,
    TData
  >;
}): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetLiveMeetingHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetLiveMeetingQueryKey();

  const getLiveMeeting = useGetLiveMeetingHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetLiveMeetingHook>>>> = ({
    signal
  }) => getLiveMeeting(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetLiveMeetingQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetLiveMeetingHook>>>
>;
export type GetLiveMeetingQueryError = LiveMeetingResponse;

/**
 * @summary Get live meeting
 */
export const useGetLiveMeeting = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetLiveMeetingHook>>>,
  TError = LiveMeetingResponse
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetLiveMeetingHook>>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetLiveMeetingQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get latest meeting
 */
export const useGetLatestMeetingHook = () => {
  const getLatestMeeting = useCustomAxios<MeetingResponse>();

  return (signal?: AbortSignal) => {
    return getLatestMeeting({ url: `/meeting/latest`, method: 'get', signal });
  };
};

export const getGetLatestMeetingQueryKey = () => [`/meeting/latest`] as const;

export const useGetLatestMeetingQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetLatestMeetingHook>>>,
  TError = MeetingResponse
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetLatestMeetingHook>>>,
    TError,
    TData
  >;
}): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetLatestMeetingHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetLatestMeetingQueryKey();

  const getLatestMeeting = useGetLatestMeetingHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetLatestMeetingHook>>>> = ({
    signal
  }) => getLatestMeeting(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetLatestMeetingQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetLatestMeetingHook>>>
>;
export type GetLatestMeetingQueryError = MeetingResponse;

/**
 * @summary Get latest meeting
 */
export const useGetLatestMeeting = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetLatestMeetingHook>>>,
  TError = MeetingResponse
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetLatestMeetingHook>>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetLatestMeetingQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get meeting connection
 */
export const useGetMeetingConnectionHook = () => {
  const getMeetingConnection = useCustomAxios<MeetingConnectionResponse>();

  return (params?: GetMeetingConnectionParams, signal?: AbortSignal) => {
    return getMeetingConnection({ url: `/meeting/connection`, method: 'get', params, signal });
  };
};

export const getGetMeetingConnectionQueryKey = (params?: GetMeetingConnectionParams) =>
  [`/meeting/connection`, ...(params ? [params] : [])] as const;

export const useGetMeetingConnectionQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetMeetingConnectionHook>>>,
  TError = MeetingConnectionResponse
>(
  params?: GetMeetingConnectionParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetMeetingConnectionHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetMeetingConnectionHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMeetingConnectionQueryKey(params);

  const getMeetingConnection = useGetMeetingConnectionHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetMeetingConnectionHook>>>
  > = ({ signal }) => getMeetingConnection(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetMeetingConnectionQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetMeetingConnectionHook>>>
>;
export type GetMeetingConnectionQueryError = MeetingConnectionResponse;

/**
 * @summary Get meeting connection
 */
export const useGetMeetingConnection = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetMeetingConnectionHook>>>,
  TError = MeetingConnectionResponse
>(
  params?: GetMeetingConnectionParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetMeetingConnectionHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetMeetingConnectionQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Fetch google calendar events
 */
export const useFetchGoogleCalendarEventsHook = () => {
  const fetchGoogleCalendarEvents = useCustomAxios<GoogleCalendarEventResponse>();

  return (params: FetchGoogleCalendarEventsParams, signal?: AbortSignal) => {
    return fetchGoogleCalendarEvents({
      url: `/integration/google/calendar/event`,
      method: 'get',
      params,
      signal
    });
  };
};

export const getFetchGoogleCalendarEventsQueryKey = (params: FetchGoogleCalendarEventsParams) =>
  [`/integration/google/calendar/event`, ...(params ? [params] : [])] as const;

export const useFetchGoogleCalendarEventsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useFetchGoogleCalendarEventsHook>>>,
  TError = GoogleCalendarEventResponse
>(
  params: FetchGoogleCalendarEventsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useFetchGoogleCalendarEventsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useFetchGoogleCalendarEventsHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getFetchGoogleCalendarEventsQueryKey(params);

  const fetchGoogleCalendarEvents = useFetchGoogleCalendarEventsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useFetchGoogleCalendarEventsHook>>>
  > = ({ signal }) => fetchGoogleCalendarEvents(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type FetchGoogleCalendarEventsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useFetchGoogleCalendarEventsHook>>>
>;
export type FetchGoogleCalendarEventsQueryError = GoogleCalendarEventResponse;

/**
 * @summary Fetch google calendar events
 */
export const useFetchGoogleCalendarEvents = <
  TData = Awaited<ReturnType<ReturnType<typeof useFetchGoogleCalendarEventsHook>>>,
  TError = GoogleCalendarEventResponse
>(
  params: FetchGoogleCalendarEventsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useFetchGoogleCalendarEventsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useFetchGoogleCalendarEventsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Fetch google calendar recurring event instances
 */
export const useFetchUpcomingGoogleCalendarEventHook = () => {
  const fetchUpcomingGoogleCalendarEvent = useCustomAxios<GoogleCalendarEventResponse>();

  return (id: string, params?: FetchUpcomingGoogleCalendarEventParams, signal?: AbortSignal) => {
    return fetchUpcomingGoogleCalendarEvent({
      url: `/integration/google/calendar/event/${id}/upcoming`,
      method: 'get',
      params,
      signal
    });
  };
};

export const getFetchUpcomingGoogleCalendarEventQueryKey = (
  id: string,
  params?: FetchUpcomingGoogleCalendarEventParams
) => [`/integration/google/calendar/event/${id}/upcoming`, ...(params ? [params] : [])] as const;

export const useFetchUpcomingGoogleCalendarEventQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useFetchUpcomingGoogleCalendarEventHook>>>,
  TError = GoogleCalendarEventResponse
>(
  id: string,
  params?: FetchUpcomingGoogleCalendarEventParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useFetchUpcomingGoogleCalendarEventHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useFetchUpcomingGoogleCalendarEventHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getFetchUpcomingGoogleCalendarEventQueryKey(id, params);

  const fetchUpcomingGoogleCalendarEvent = useFetchUpcomingGoogleCalendarEventHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useFetchUpcomingGoogleCalendarEventHook>>>
  > = ({ signal }) => fetchUpcomingGoogleCalendarEvent(id, params, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type FetchUpcomingGoogleCalendarEventQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useFetchUpcomingGoogleCalendarEventHook>>>
>;
export type FetchUpcomingGoogleCalendarEventQueryError = GoogleCalendarEventResponse;

/**
 * @summary Fetch google calendar recurring event instances
 */
export const useFetchUpcomingGoogleCalendarEvent = <
  TData = Awaited<ReturnType<ReturnType<typeof useFetchUpcomingGoogleCalendarEventHook>>>,
  TError = GoogleCalendarEventResponse
>(
  id: string,
  params?: FetchUpcomingGoogleCalendarEventParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useFetchUpcomingGoogleCalendarEventHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useFetchUpcomingGoogleCalendarEventQueryOptions(id, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Fetch google calendar recurring event instances
 */
export const useFetchGoogleCalendarRecurringEventInstancesHook = () => {
  const fetchGoogleCalendarRecurringEventInstances = useCustomAxios<GoogleCalendarEventResponse>();

  return (id: string, signal?: AbortSignal) => {
    return fetchGoogleCalendarRecurringEventInstances({
      url: `/integration/google/calendar/event/${id}/instance`,
      method: 'get',
      signal
    });
  };
};

export const getFetchGoogleCalendarRecurringEventInstancesQueryKey = (id: string) =>
  [`/integration/google/calendar/event/${id}/instance`] as const;

export const useFetchGoogleCalendarRecurringEventInstancesQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useFetchGoogleCalendarRecurringEventInstancesHook>>>,
  TError = GoogleCalendarEventResponse
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useFetchGoogleCalendarRecurringEventInstancesHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useFetchGoogleCalendarRecurringEventInstancesHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getFetchGoogleCalendarRecurringEventInstancesQueryKey(id);

  const fetchGoogleCalendarRecurringEventInstances =
    useFetchGoogleCalendarRecurringEventInstancesHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useFetchGoogleCalendarRecurringEventInstancesHook>>>
  > = ({ signal }) => fetchGoogleCalendarRecurringEventInstances(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type FetchGoogleCalendarRecurringEventInstancesQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useFetchGoogleCalendarRecurringEventInstancesHook>>>
>;
export type FetchGoogleCalendarRecurringEventInstancesQueryError = GoogleCalendarEventResponse;

/**
 * @summary Fetch google calendar recurring event instances
 */
export const useFetchGoogleCalendarRecurringEventInstances = <
  TData = Awaited<ReturnType<ReturnType<typeof useFetchGoogleCalendarRecurringEventInstancesHook>>>,
  TError = GoogleCalendarEventResponse
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useFetchGoogleCalendarRecurringEventInstancesHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useFetchGoogleCalendarRecurringEventInstancesQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get data source latest run
 */
export const useGetDataSourceLatestRunHook = () => {
  const getDataSourceLatestRun = useCustomAxios<CollectorLatestRunResponse>();

  return (id: string, signal?: AbortSignal) => {
    return getDataSourceLatestRun({
      url: `/integration/dataSource/${id}/latestRun`,
      method: 'get',
      signal
    });
  };
};

export const getGetDataSourceLatestRunQueryKey = (id: string) =>
  [`/integration/dataSource/${id}/latestRun`] as const;

export const useGetDataSourceLatestRunQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetDataSourceLatestRunHook>>>,
  TError = CollectorLatestRunResponse
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetDataSourceLatestRunHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetDataSourceLatestRunHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDataSourceLatestRunQueryKey(id);

  const getDataSourceLatestRun = useGetDataSourceLatestRunHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetDataSourceLatestRunHook>>>
  > = ({ signal }) => getDataSourceLatestRun(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type GetDataSourceLatestRunQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetDataSourceLatestRunHook>>>
>;
export type GetDataSourceLatestRunQueryError = CollectorLatestRunResponse;

/**
 * @summary Get data source latest run
 */
export const useGetDataSourceLatestRun = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetDataSourceLatestRunHook>>>,
  TError = CollectorLatestRunResponse
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetDataSourceLatestRunHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetDataSourceLatestRunQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary List data source fields mapping
 */
export const useListDataSourceFieldMappingsHook = () => {
  const listDataSourceFieldMappings = useCustomAxios<DataSourceFieldMappingResponse>();

  return (id: string, params?: ListDataSourceFieldMappingsParams, signal?: AbortSignal) => {
    return listDataSourceFieldMappings({
      url: `/integration/dataSource/${id}/fieldMapping`,
      method: 'get',
      params,
      signal
    });
  };
};

export const getListDataSourceFieldMappingsQueryKey = (
  id: string,
  params?: ListDataSourceFieldMappingsParams
) => [`/integration/dataSource/${id}/fieldMapping`, ...(params ? [params] : [])] as const;

export const useListDataSourceFieldMappingsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useListDataSourceFieldMappingsHook>>>,
  TError = DataSourceFieldMappingResponse
>(
  id: string,
  params?: ListDataSourceFieldMappingsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useListDataSourceFieldMappingsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useListDataSourceFieldMappingsHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListDataSourceFieldMappingsQueryKey(id, params);

  const listDataSourceFieldMappings = useListDataSourceFieldMappingsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useListDataSourceFieldMappingsHook>>>
  > = ({ signal }) => listDataSourceFieldMappings(id, params, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type ListDataSourceFieldMappingsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useListDataSourceFieldMappingsHook>>>
>;
export type ListDataSourceFieldMappingsQueryError = DataSourceFieldMappingResponse;

/**
 * @summary List data source fields mapping
 */
export const useListDataSourceFieldMappings = <
  TData = Awaited<ReturnType<ReturnType<typeof useListDataSourceFieldMappingsHook>>>,
  TError = DataSourceFieldMappingResponse
>(
  id: string,
  params?: ListDataSourceFieldMappingsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useListDataSourceFieldMappingsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useListDataSourceFieldMappingsQueryOptions(id, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary List data types types
 */
export const useListDataSourceTypesHook = () => {
  const listDataSourceTypes = useCustomAxios<DataSourceTypeResponse>();

  return (params?: ListDataSourceTypesParams, signal?: AbortSignal) => {
    return listDataSourceTypes({
      url: `/integration/dataSource/type`,
      method: 'get',
      params,
      signal
    });
  };
};

export const getListDataSourceTypesQueryKey = (params?: ListDataSourceTypesParams) =>
  [`/integration/dataSource/type`, ...(params ? [params] : [])] as const;

export const useListDataSourceTypesQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useListDataSourceTypesHook>>>,
  TError = DataSourceTypeResponse
>(
  params?: ListDataSourceTypesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useListDataSourceTypesHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useListDataSourceTypesHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListDataSourceTypesQueryKey(params);

  const listDataSourceTypes = useListDataSourceTypesHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useListDataSourceTypesHook>>>
  > = ({ signal }) => listDataSourceTypes(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type ListDataSourceTypesQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useListDataSourceTypesHook>>>
>;
export type ListDataSourceTypesQueryError = DataSourceTypeResponse;

/**
 * @summary List data types types
 */
export const useListDataSourceTypes = <
  TData = Awaited<ReturnType<ReturnType<typeof useListDataSourceTypesHook>>>,
  TError = DataSourceTypeResponse
>(
  params?: ListDataSourceTypesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useListDataSourceTypesHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useListDataSourceTypesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get data provider latest runs
 */
export const useGetDataProviderLatestRunsHook = () => {
  const getDataProviderLatestRuns = useCustomAxios<CollectorLatestRunResponse>();

  return (params: GetDataProviderLatestRunsParams, signal?: AbortSignal) => {
    return getDataProviderLatestRuns({
      url: `/integration/dataProvider/latestRun`,
      method: 'get',
      params,
      signal
    });
  };
};

export const getGetDataProviderLatestRunsQueryKey = (params: GetDataProviderLatestRunsParams) =>
  [`/integration/dataProvider/latestRun`, ...(params ? [params] : [])] as const;

export const useGetDataProviderLatestRunsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetDataProviderLatestRunsHook>>>,
  TError = CollectorLatestRunResponse
>(
  params: GetDataProviderLatestRunsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetDataProviderLatestRunsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetDataProviderLatestRunsHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDataProviderLatestRunsQueryKey(params);

  const getDataProviderLatestRuns = useGetDataProviderLatestRunsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetDataProviderLatestRunsHook>>>
  > = ({ signal }) => getDataProviderLatestRuns(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetDataProviderLatestRunsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetDataProviderLatestRunsHook>>>
>;
export type GetDataProviderLatestRunsQueryError = CollectorLatestRunResponse;

/**
 * @summary Get data provider latest runs
 */
export const useGetDataProviderLatestRuns = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetDataProviderLatestRunsHook>>>,
  TError = CollectorLatestRunResponse
>(
  params: GetDataProviderLatestRunsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetDataProviderLatestRunsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetDataProviderLatestRunsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Sign up verification
 */
export const useVerifySignUpHook = () => {
  const verifySignUp = useCustomAxios<UserDetailsResponse>();

  return (params: VerifySignUpParams, signal?: AbortSignal) => {
    return verifySignUp({ url: `/auth/signUp/verification`, method: 'get', params, signal });
  };
};

export const getVerifySignUpQueryKey = (params: VerifySignUpParams) =>
  [`/auth/signUp/verification`, ...(params ? [params] : [])] as const;

export const useVerifySignUpQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useVerifySignUpHook>>>,
  TError = UserDetailsResponse
>(
  params: VerifySignUpParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useVerifySignUpHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useVerifySignUpHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getVerifySignUpQueryKey(params);

  const verifySignUp = useVerifySignUpHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useVerifySignUpHook>>>> = ({
    signal
  }) => verifySignUp(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type VerifySignUpQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useVerifySignUpHook>>>
>;
export type VerifySignUpQueryError = UserDetailsResponse;

/**
 * @summary Sign up verification
 */
export const useVerifySignUp = <
  TData = Awaited<ReturnType<ReturnType<typeof useVerifySignUpHook>>>,
  TError = UserDetailsResponse
>(
  params: VerifySignUpParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useVerifySignUpHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useVerifySignUpQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get Ask Journey history
 */
export const useGetAskJourneyHistoryHook = () => {
  const getAskJourneyHistory = useCustomAxios<AskJourneyAnswerResponse>();

  return (signal?: AbortSignal) => {
    return getAskJourneyHistory({ url: `/ask/history`, method: 'get', signal });
  };
};

export const getGetAskJourneyHistoryQueryKey = () => [`/ask/history`] as const;

export const useGetAskJourneyHistoryQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetAskJourneyHistoryHook>>>,
  TError = AskJourneyAnswerResponse
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetAskJourneyHistoryHook>>>,
    TError,
    TData
  >;
}): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetAskJourneyHistoryHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAskJourneyHistoryQueryKey();

  const getAskJourneyHistory = useGetAskJourneyHistoryHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetAskJourneyHistoryHook>>>
  > = ({ signal }) => getAskJourneyHistory(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetAskJourneyHistoryQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetAskJourneyHistoryHook>>>
>;
export type GetAskJourneyHistoryQueryError = AskJourneyAnswerResponse;

/**
 * @summary Get Ask Journey history
 */
export const useGetAskJourneyHistory = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetAskJourneyHistoryHook>>>,
  TError = AskJourneyAnswerResponse
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetAskJourneyHistoryHook>>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetAskJourneyHistoryQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary List app notifications
 */
export const useListAppNotificationsHook = () => {
  const listAppNotifications = useCustomAxios<AppNotificationResponse>();

  return (params?: ListAppNotificationsParams, signal?: AbortSignal) => {
    return listAppNotifications({ url: `/appNotification`, method: 'get', params, signal });
  };
};

export const getListAppNotificationsQueryKey = (params?: ListAppNotificationsParams) =>
  [`/appNotification`, ...(params ? [params] : [])] as const;

export const useListAppNotificationsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useListAppNotificationsHook>>>,
  TError = AppNotificationResponse
>(
  params?: ListAppNotificationsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useListAppNotificationsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useListAppNotificationsHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListAppNotificationsQueryKey(params);

  const listAppNotifications = useListAppNotificationsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useListAppNotificationsHook>>>
  > = ({ signal }) => listAppNotifications(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type ListAppNotificationsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useListAppNotificationsHook>>>
>;
export type ListAppNotificationsQueryError = AppNotificationResponse;

/**
 * @summary List app notifications
 */
export const useListAppNotifications = <
  TData = Awaited<ReturnType<ReturnType<typeof useListAppNotificationsHook>>>,
  TError = AppNotificationResponse
>(
  params?: ListAppNotificationsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useListAppNotificationsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useListAppNotificationsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary List objective comments
 */
export const useListActionPlanObjectiveCommentsHook = () => {
  const listActionPlanObjectiveComments = useCustomAxios<ActionPlanObjectiveCommentResponse>();

  return (objectiveId: string, signal?: AbortSignal) => {
    return listActionPlanObjectiveComments({
      url: `/actionPlan/objective/${objectiveId}/comment`,
      method: 'get',
      signal
    });
  };
};

export const getListActionPlanObjectiveCommentsQueryKey = (objectiveId: string) =>
  [`/actionPlan/objective/${objectiveId}/comment`] as const;

export const useListActionPlanObjectiveCommentsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useListActionPlanObjectiveCommentsHook>>>,
  TError = ActionPlanObjectiveCommentResponse
>(
  objectiveId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useListActionPlanObjectiveCommentsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useListActionPlanObjectiveCommentsHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getListActionPlanObjectiveCommentsQueryKey(objectiveId);

  const listActionPlanObjectiveComments = useListActionPlanObjectiveCommentsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useListActionPlanObjectiveCommentsHook>>>
  > = ({ signal }) => listActionPlanObjectiveComments(objectiveId, signal);

  return { queryKey, queryFn, enabled: !!objectiveId, ...queryOptions };
};

export type ListActionPlanObjectiveCommentsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useListActionPlanObjectiveCommentsHook>>>
>;
export type ListActionPlanObjectiveCommentsQueryError = ActionPlanObjectiveCommentResponse;

/**
 * @summary List objective comments
 */
export const useListActionPlanObjectiveComments = <
  TData = Awaited<ReturnType<ReturnType<typeof useListActionPlanObjectiveCommentsHook>>>,
  TError = ActionPlanObjectiveCommentResponse
>(
  objectiveId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useListActionPlanObjectiveCommentsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useListActionPlanObjectiveCommentsQueryOptions(objectiveId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get detailed objective
 */
export const useGetActionPlanDetailedObjectiveHook = () => {
  const getActionPlanDetailedObjective = useCustomAxios<ActionPlanObjectiveDetailedResponse>();

  return (id: string, params?: GetActionPlanDetailedObjectiveParams, signal?: AbortSignal) => {
    return getActionPlanDetailedObjective({
      url: `/actionPlan/objective/${id}/detailed`,
      method: 'get',
      params,
      signal
    });
  };
};

export const getGetActionPlanDetailedObjectiveQueryKey = (
  id: string,
  params?: GetActionPlanDetailedObjectiveParams
) => [`/actionPlan/objective/${id}/detailed`, ...(params ? [params] : [])] as const;

export const useGetActionPlanDetailedObjectiveQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetActionPlanDetailedObjectiveHook>>>,
  TError = ActionPlanObjectiveDetailedResponse
>(
  id: string,
  params?: GetActionPlanDetailedObjectiveParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetActionPlanDetailedObjectiveHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetActionPlanDetailedObjectiveHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetActionPlanDetailedObjectiveQueryKey(id, params);

  const getActionPlanDetailedObjective = useGetActionPlanDetailedObjectiveHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetActionPlanDetailedObjectiveHook>>>
  > = ({ signal }) => getActionPlanDetailedObjective(id, params, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type GetActionPlanDetailedObjectiveQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetActionPlanDetailedObjectiveHook>>>
>;
export type GetActionPlanDetailedObjectiveQueryError = ActionPlanObjectiveDetailedResponse;

/**
 * @summary Get detailed objective
 */
export const useGetActionPlanDetailedObjective = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetActionPlanDetailedObjectiveHook>>>,
  TError = ActionPlanObjectiveDetailedResponse
>(
  id: string,
  params?: GetActionPlanDetailedObjectiveParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetActionPlanDetailedObjectiveHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetActionPlanDetailedObjectiveQueryOptions(id, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary List objectives detailed
 */
export const useListActionPlanDetailedObjectivesHook = () => {
  const listActionPlanDetailedObjectives = useCustomAxios<ActionPlanObjectiveDetailedResponse>();

  return (params?: ListActionPlanDetailedObjectivesParams, signal?: AbortSignal) => {
    return listActionPlanDetailedObjectives({
      url: `/actionPlan/objective/detailed`,
      method: 'get',
      params,
      signal
    });
  };
};

export const getListActionPlanDetailedObjectivesQueryKey = (
  params?: ListActionPlanDetailedObjectivesParams
) => [`/actionPlan/objective/detailed`, ...(params ? [params] : [])] as const;

export const useListActionPlanDetailedObjectivesQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useListActionPlanDetailedObjectivesHook>>>,
  TError = ActionPlanObjectiveDetailedResponse
>(
  params?: ListActionPlanDetailedObjectivesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useListActionPlanDetailedObjectivesHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useListActionPlanDetailedObjectivesHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListActionPlanDetailedObjectivesQueryKey(params);

  const listActionPlanDetailedObjectives = useListActionPlanDetailedObjectivesHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useListActionPlanDetailedObjectivesHook>>>
  > = ({ signal }) => listActionPlanDetailedObjectives(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type ListActionPlanDetailedObjectivesQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useListActionPlanDetailedObjectivesHook>>>
>;
export type ListActionPlanDetailedObjectivesQueryError = ActionPlanObjectiveDetailedResponse;

/**
 * @summary List objectives detailed
 */
export const useListActionPlanDetailedObjectives = <
  TData = Awaited<ReturnType<ReturnType<typeof useListActionPlanDetailedObjectivesHook>>>,
  TError = ActionPlanObjectiveDetailedResponse
>(
  params?: ListActionPlanDetailedObjectivesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useListActionPlanDetailedObjectivesHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useListActionPlanDetailedObjectivesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary List initiative comments
 */
export const useListActionPlanInitiativeCommentsHook = () => {
  const listActionPlanInitiativeComments = useCustomAxios<ActionPlanInitiativeCommentResponse>();

  return (initiativeId: string, signal?: AbortSignal) => {
    return listActionPlanInitiativeComments({
      url: `/actionPlan/initiative/${initiativeId}/comment`,
      method: 'get',
      signal
    });
  };
};

export const getListActionPlanInitiativeCommentsQueryKey = (initiativeId: string) =>
  [`/actionPlan/initiative/${initiativeId}/comment`] as const;

export const useListActionPlanInitiativeCommentsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useListActionPlanInitiativeCommentsHook>>>,
  TError = ActionPlanInitiativeCommentResponse
>(
  initiativeId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useListActionPlanInitiativeCommentsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useListActionPlanInitiativeCommentsHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getListActionPlanInitiativeCommentsQueryKey(initiativeId);

  const listActionPlanInitiativeComments = useListActionPlanInitiativeCommentsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useListActionPlanInitiativeCommentsHook>>>
  > = ({ signal }) => listActionPlanInitiativeComments(initiativeId, signal);

  return { queryKey, queryFn, enabled: !!initiativeId, ...queryOptions };
};

export type ListActionPlanInitiativeCommentsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useListActionPlanInitiativeCommentsHook>>>
>;
export type ListActionPlanInitiativeCommentsQueryError = ActionPlanInitiativeCommentResponse;

/**
 * @summary List initiative comments
 */
export const useListActionPlanInitiativeComments = <
  TData = Awaited<ReturnType<ReturnType<typeof useListActionPlanInitiativeCommentsHook>>>,
  TError = ActionPlanInitiativeCommentResponse
>(
  initiativeId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useListActionPlanInitiativeCommentsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useListActionPlanInitiativeCommentsQueryOptions(initiativeId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get initiatives suggestion
 */
export const useGetInitiativeSuggestionHook = () => {
  const getInitiativeSuggestion = useCustomAxios<ActionPlanSuggestionResponse>();

  return (params: GetInitiativeSuggestionParams, signal?: AbortSignal) => {
    return getInitiativeSuggestion({
      url: `/actionPlan/initiative/suggestion`,
      method: 'get',
      params,
      signal
    });
  };
};

export const getGetInitiativeSuggestionQueryKey = (params: GetInitiativeSuggestionParams) =>
  [`/actionPlan/initiative/suggestion`, ...(params ? [params] : [])] as const;

export const useGetInitiativeSuggestionQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetInitiativeSuggestionHook>>>,
  TError = ActionPlanSuggestionResponse
>(
  params: GetInitiativeSuggestionParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetInitiativeSuggestionHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetInitiativeSuggestionHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetInitiativeSuggestionQueryKey(params);

  const getInitiativeSuggestion = useGetInitiativeSuggestionHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetInitiativeSuggestionHook>>>
  > = ({ signal }) => getInitiativeSuggestion(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetInitiativeSuggestionQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetInitiativeSuggestionHook>>>
>;
export type GetInitiativeSuggestionQueryError = ActionPlanSuggestionResponse;

/**
 * @summary Get initiatives suggestion
 */
export const useGetInitiativeSuggestion = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetInitiativeSuggestionHook>>>,
  TError = ActionPlanSuggestionResponse
>(
  params: GetInitiativeSuggestionParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetInitiativeSuggestionHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetInitiativeSuggestionQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary List last updated initiatives
 */
export const useListLatestUpdatedInitiativesHook = () => {
  const listLatestUpdatedInitiatives = useCustomAxios<LastUpdatedInitiativeResponse>();

  return (signal?: AbortSignal) => {
    return listLatestUpdatedInitiatives({
      url: `/actionPlan/initiative/latest`,
      method: 'get',
      signal
    });
  };
};

export const getListLatestUpdatedInitiativesQueryKey = () =>
  [`/actionPlan/initiative/latest`] as const;

export const useListLatestUpdatedInitiativesQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useListLatestUpdatedInitiativesHook>>>,
  TError = LastUpdatedInitiativeResponse
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useListLatestUpdatedInitiativesHook>>>,
    TError,
    TData
  >;
}): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useListLatestUpdatedInitiativesHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListLatestUpdatedInitiativesQueryKey();

  const listLatestUpdatedInitiatives = useListLatestUpdatedInitiativesHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useListLatestUpdatedInitiativesHook>>>
  > = ({ signal }) => listLatestUpdatedInitiatives(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type ListLatestUpdatedInitiativesQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useListLatestUpdatedInitiativesHook>>>
>;
export type ListLatestUpdatedInitiativesQueryError = LastUpdatedInitiativeResponse;

/**
 * @summary List last updated initiatives
 */
export const useListLatestUpdatedInitiatives = <
  TData = Awaited<ReturnType<ReturnType<typeof useListLatestUpdatedInitiativesHook>>>,
  TError = LastUpdatedInitiativeResponse
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useListLatestUpdatedInitiativesHook>>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useListLatestUpdatedInitiativesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary List action item comments
 */
export const useListActionPlanActionItemCommentsHook = () => {
  const listActionPlanActionItemComments = useCustomAxios<ActionPlanActionItemCommentResponse>();

  return (actionItemId: string, signal?: AbortSignal) => {
    return listActionPlanActionItemComments({
      url: `/actionPlan/actionItem/${actionItemId}/comment`,
      method: 'get',
      signal
    });
  };
};

export const getListActionPlanActionItemCommentsQueryKey = (actionItemId: string) =>
  [`/actionPlan/actionItem/${actionItemId}/comment`] as const;

export const useListActionPlanActionItemCommentsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useListActionPlanActionItemCommentsHook>>>,
  TError = ActionPlanActionItemCommentResponse
>(
  actionItemId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useListActionPlanActionItemCommentsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useListActionPlanActionItemCommentsHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getListActionPlanActionItemCommentsQueryKey(actionItemId);

  const listActionPlanActionItemComments = useListActionPlanActionItemCommentsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useListActionPlanActionItemCommentsHook>>>
  > = ({ signal }) => listActionPlanActionItemComments(actionItemId, signal);

  return { queryKey, queryFn, enabled: !!actionItemId, ...queryOptions };
};

export type ListActionPlanActionItemCommentsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useListActionPlanActionItemCommentsHook>>>
>;
export type ListActionPlanActionItemCommentsQueryError = ActionPlanActionItemCommentResponse;

/**
 * @summary List action item comments
 */
export const useListActionPlanActionItemComments = <
  TData = Awaited<ReturnType<ReturnType<typeof useListActionPlanActionItemCommentsHook>>>,
  TError = ActionPlanActionItemCommentResponse
>(
  actionItemId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useListActionPlanActionItemCommentsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useListActionPlanActionItemCommentsQueryOptions(actionItemId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get action items suggestion
 */
export const useGetActionItemsSuggestionHook = () => {
  const getActionItemsSuggestion = useCustomAxios<ActionPlanSuggestionResponse>();

  return (params: GetActionItemsSuggestionParams, signal?: AbortSignal) => {
    return getActionItemsSuggestion({
      url: `/actionPlan/actionItem/suggestion`,
      method: 'get',
      params,
      signal
    });
  };
};

export const getGetActionItemsSuggestionQueryKey = (params: GetActionItemsSuggestionParams) =>
  [`/actionPlan/actionItem/suggestion`, ...(params ? [params] : [])] as const;

export const useGetActionItemsSuggestionQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetActionItemsSuggestionHook>>>,
  TError = ActionPlanSuggestionResponse
>(
  params: GetActionItemsSuggestionParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetActionItemsSuggestionHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetActionItemsSuggestionHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetActionItemsSuggestionQueryKey(params);

  const getActionItemsSuggestion = useGetActionItemsSuggestionHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetActionItemsSuggestionHook>>>
  > = ({ signal }) => getActionItemsSuggestion(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetActionItemsSuggestionQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetActionItemsSuggestionHook>>>
>;
export type GetActionItemsSuggestionQueryError = ActionPlanSuggestionResponse;

/**
 * @summary Get action items suggestion
 */
export const useGetActionItemsSuggestion = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetActionItemsSuggestionHook>>>,
  TError = ActionPlanSuggestionResponse
>(
  params: GetActionItemsSuggestionParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetActionItemsSuggestionHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetActionItemsSuggestionQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Delete meeting analysis suggestion
 */
export const useDeleteSharedWorkspaceMeetingAnalysisSuggestionHook = () => {
  const deleteSharedWorkspaceMeetingAnalysisSuggestion = useCustomAxios<void>();

  return (id: string) => {
    return deleteSharedWorkspaceMeetingAnalysisSuggestion({
      url: `/workspace/sharedWorkspace/meeting/analysis/suggestion/${id}`,
      method: 'delete'
    });
  };
};

export const useDeleteSharedWorkspaceMeetingAnalysisSuggestionMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteSharedWorkspaceMeetingAnalysisSuggestionHook>>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteSharedWorkspaceMeetingAnalysisSuggestionHook>>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteSharedWorkspaceMeetingAnalysisSuggestion =
    useDeleteSharedWorkspaceMeetingAnalysisSuggestionHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteSharedWorkspaceMeetingAnalysisSuggestionHook>>>,
    { id: string }
  > = props => {
    const { id } = props ?? {};

    return deleteSharedWorkspaceMeetingAnalysisSuggestion(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteSharedWorkspaceMeetingAnalysisSuggestionMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteSharedWorkspaceMeetingAnalysisSuggestionHook>>>
>;

export type DeleteSharedWorkspaceMeetingAnalysisSuggestionMutationError = unknown;

/**
 * @summary Delete meeting analysis suggestion
 */
export const useDeleteSharedWorkspaceMeetingAnalysisSuggestion = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteSharedWorkspaceMeetingAnalysisSuggestionHook>>>,
    TError,
    { id: string },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteSharedWorkspaceMeetingAnalysisSuggestionMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Delete workspace calendar event suggestion
 */
export const useDeleteWorkspaceCalendarEventSuggestionHook = () => {
  const deleteWorkspaceCalendarEventSuggestion = useCustomAxios<void>();

  return (id: string) => {
    return deleteWorkspaceCalendarEventSuggestion({
      url: `/workspace/sharedWorkspace/calendar/event/suggestion/${id}`,
      method: 'delete'
    });
  };
};

export const useDeleteWorkspaceCalendarEventSuggestionMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteWorkspaceCalendarEventSuggestionHook>>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteWorkspaceCalendarEventSuggestionHook>>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteWorkspaceCalendarEventSuggestion = useDeleteWorkspaceCalendarEventSuggestionHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteWorkspaceCalendarEventSuggestionHook>>>,
    { id: string }
  > = props => {
    const { id } = props ?? {};

    return deleteWorkspaceCalendarEventSuggestion(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteWorkspaceCalendarEventSuggestionMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteWorkspaceCalendarEventSuggestionHook>>>
>;

export type DeleteWorkspaceCalendarEventSuggestionMutationError = unknown;

/**
 * @summary Delete workspace calendar event suggestion
 */
export const useDeleteWorkspaceCalendarEventSuggestion = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteWorkspaceCalendarEventSuggestionHook>>>,
    TError,
    { id: string },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteWorkspaceCalendarEventSuggestionMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Delete google drive file
 */
export const useDeleteGoogleDriveFileHook = () => {
  const deleteGoogleDriveFile = useCustomAxios<void>();

  return (id: string) => {
    return deleteGoogleDriveFile({ url: `/workspace/resource/drive/file/${id}`, method: 'delete' });
  };
};

export const useDeleteGoogleDriveFileMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteGoogleDriveFileHook>>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteGoogleDriveFileHook>>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteGoogleDriveFile = useDeleteGoogleDriveFileHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteGoogleDriveFileHook>>>,
    { id: string }
  > = props => {
    const { id } = props ?? {};

    return deleteGoogleDriveFile(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteGoogleDriveFileMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteGoogleDriveFileHook>>>
>;

export type DeleteGoogleDriveFileMutationError = unknown;

/**
 * @summary Delete google drive file
 */
export const useDeleteGoogleDriveFile = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteGoogleDriveFileHook>>>,
    TError,
    { id: string },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteGoogleDriveFileMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Delete data source
 */
export const useDeleteDataSourceHook = () => {
  const deleteDataSource = useCustomAxios<void>();

  return (id: string) => {
    return deleteDataSource({ url: `/integration/dataSource/${id}`, method: 'delete' });
  };
};

export const useDeleteDataSourceMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteDataSourceHook>>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteDataSourceHook>>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteDataSource = useDeleteDataSourceHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteDataSourceHook>>>,
    { id: string }
  > = props => {
    const { id } = props ?? {};

    return deleteDataSource(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteDataSourceMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteDataSourceHook>>>
>;

export type DeleteDataSourceMutationError = unknown;

/**
 * @summary Delete data source
 */
export const useDeleteDataSource = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteDataSourceHook>>>,
    TError,
    { id: string },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteDataSourceMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Delete answer
 */
export const useDeleteAskJourneyAnswerHook = () => {
  const deleteAskJourneyAnswer = useCustomAxios<void>();

  return (id: string) => {
    return deleteAskJourneyAnswer({ url: `/ask/answer/${id}`, method: 'delete' });
  };
};

export const useDeleteAskJourneyAnswerMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteAskJourneyAnswerHook>>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteAskJourneyAnswerHook>>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteAskJourneyAnswer = useDeleteAskJourneyAnswerHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteAskJourneyAnswerHook>>>,
    { id: string }
  > = props => {
    const { id } = props ?? {};

    return deleteAskJourneyAnswer(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteAskJourneyAnswerMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteAskJourneyAnswerHook>>>
>;

export type DeleteAskJourneyAnswerMutationError = unknown;

/**
 * @summary Delete answer
 */
export const useDeleteAskJourneyAnswer = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteAskJourneyAnswerHook>>>,
    TError,
    { id: string },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteAskJourneyAnswerMutationOptions(options);

  return useMutation(mutationOptions);
};
